import { localStorageKeys, setLocalStorage } from 'services/localStorage'
import MainAppActionTypes from '../MainAppActionTypes'

const setUser = user => {
    setLocalStorage(localStorageKeys.user, user)

    return {
        type: MainAppActionTypes.setUser,
        payload: { user },
    }
}

export default setUser
