const newBookingLocaleKeys = {
    bookingHeader: 'newBooking.bookingHeader',
    passengerHeader: 'newBooking.passengerHeader',
    tipPromoHeader: 'newBooking.tipPromoHeader',
    dispatchHeader: 'newBooking.dispatchHeader',
    paymentHeader: 'newBooking.paymentHeader',

    pickupField: 'newBooking.pickupField',
    destinationField: 'newBooking.destinationField',
    pickupTimeField: 'newBooking.pickupTimeField',
    serviceField: 'newBooking.serviceField',
    phoneNumberField: 'newBooking.phoneNumberField',
    fullNameField: 'newBooking.fullNameField',
    emailField: 'newBooking.emailField',
    amountField: 'newBooking.amountField',
    promotionField: 'newBooking.promotionField',

    addressPlaceholder: 'newBooking.addressPlaceholder',
    pickupAddressPlaceholder: 'newBooking.pickupAddressPlaceholder',
    destinationAddressPlaceholder: 'newBooking.destinationAddressPlaceholder',
    phoneNumberPlaceholder: 'newBooking.phoneNumberPlaceholder',
    fullNamePlaceholder: 'newBooking.fullNamePlaceholder',
    emailPlaceholder: 'newBooking.emailPlaceholder',
    amountPlaceholder: 'newBooking.amountPlaceholder',
    promotionPlaceholder: 'newBooking.promotionPlaceholder',

    estimate: 'newBooking.estimate',
    estimateDistance: 'newBooking.estimateDistance',
    estimateDuration: 'newBooking.estimateDuration',
    estimateFare: 'newBooking.estimateFare',

    btnCreate: 'newBooking.btnCreate',
    btnCreateSwitchboard: 'btnCreateSwitchboard',
    btnCancel: 'newBooking.btnCancel',

    noteField: 'newBooking.noteField',
    noteBookingAssigned: 'newBooking.noteBookingAssigned',
    dispatchAuto: 'newBooking.dispatchAuto',
    dispatchAssign: 'newBooking.dispatchAssign',
    title: 'newBooking.title',
}

export default newBookingLocaleKeys
