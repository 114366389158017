const setHandlingTripInfoToDefault = (state, payload) => {
    return { ...state, handlingTrip: payload }
}

export const addressFieldNames = {
    pickup: 'pickup',
    destination: 'destination',
    note: 'note',
}

const setAddressField = (state, payload) => {
    const { fieldName, value } = payload
    
    const { canCreateTrip, errorMessage } = checkCanCreateTrip({
        state,
        nextPickup: fieldName === addressFieldNames.pickup ? value : null,
        fieldName: fieldName,
    })

    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                address: {
                    ...state.handlingTrip.info.address,
                    [fieldName]: value,
                },
            },
            metadata: {
                ...state.handlingTrip.metadata,
                estimated: false,
                canCreateTrip,
                errorMessage,
            },
        },
    }
}

const setEstimateData = (state, payload) => {
    const { estimated, estimatedData } = payload
    let newEstimateData = {}
    if (estimatedData) newEstimateData.estimatedData = estimatedData

    const { canCreateTrip, errorMessage } = checkCanCreateTrip({ state })

    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: { ...state.handlingTrip.info, ...newEstimateData },
            metadata: {
                ...state.handlingTrip.metadata,
                estimated,
                canCreateTrip,
                errorMessage,
            },
        },
    }
}

const setFocusingEl = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,

            metadata: {
                ...state.handlingTrip.metadata,
                focusingEl: payload,
            },
        },
    }
}

const setPhoneNumber = (state, payload) => {
    const { canCreateTrip, errorMessage } = checkCanCreateTrip({
        state,
        nextPhoneNumber: payload,
    })

    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                phoneNumber: payload,
            },
            metadata: {
                ...state.handlingTrip.metadata,
                canCreateTrip,
                errorMessage,
            },
        },
    }
}

const setOnlyOneCoor = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            metadata: {
                ...state.handlingTrip.metadata,
                hasOnlyOneCoor: payload,
            },
        },
    }
}

const setServices = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                services: payload,
            },
        },
    }
}

const setPickupTime = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                pickupTime: payload,
            },
        },
    }
}

const setNote = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                note: payload,
            },
        },
    }
}

const setDriverAssigned = (state, payload) => {
    return {
        ...state,
        handlingTrip: {
            ...state.handlingTrip,
            info: {
                ...state.handlingTrip.info,
                driverAssigned: payload,
            },
        },
    }
}

const checkCanCreateTrip = ({ state, nextPhoneNumber, nextPickup, fieldName }) => {
    const {
        handlingTrip: {
            info: {
                phoneNumber,
                address: { pickup, destination },
            },
        },
    } = state

    let canCreateTrip = true
    let errorMessage = null

    if (nextPhoneNumber === undefined) {
        if (phoneNumber.length < 10) {
            canCreateTrip = false
            errorMessage =
                'Số điện thoại không được để trống hoặc không đúng định dạng'
        }
    } else if (nextPhoneNumber === '' || nextPhoneNumber.length < 10) {
        canCreateTrip = false
        errorMessage =
            'Số điện thoại không được để trống hoặc không đúng định dạng'
    }

    if (fieldName === addressFieldNames.pickup && nextPickup === undefined) {
        if (!pickup) {
            canCreateTrip = false
            errorMessage = 'Điểm đón không được để trống'
        }
    } else if (fieldName === addressFieldNames.pickup && nextPickup === null) {
        canCreateTrip = false
        errorMessage = 'Điểm đón không được để trống'
    }

    return { canCreateTrip, errorMessage }
}

const tripInfoReducers = {
    setHandlingTripInfoToDefault,
    setAddressField,
    setEstimateData,
    setFocusingEl,
    setPhoneNumber,
    setOnlyOneCoor,
    setServices,
    setPickupTime,
    setNote,
    setDriverAssigned,
}

export default tripInfoReducers
