import bookingsKeys from './bookingsKeys'

const bookingsLocale = {
    en: {
        [bookingsKeys.tabHeader.active]: 'Active',
        [bookingsKeys.tabHeader.finished]: 'Finished',

        [bookingsKeys.filter.searchPlaceholder]: 'Search...',
        [bookingsKeys.filter.localOrAffiliates]: 'Local/Affiliates',
        [bookingsKeys.filter.bookingType]: 'Booking type',
        [bookingsKeys.filter.status]: 'Status',
        [bookingsKeys.filter.operator]: ' Operator',
        [bookingsKeys.filter.carType]: 'Car type',
        [bookingsKeys.filter.bookingFrom]: 'Booking from',
        [bookingsKeys.filter.paymentMethod]: 'Payment method',

        [bookingsKeys.filter.customerType]: 'Customer Type',

        [bookingsKeys.tableHeader.bookingId]: 'Booking #',
        [bookingsKeys.tableHeader.address]: 'Pick up/Drop off',
        [bookingsKeys.tableHeader.vehicle]: 'Vehicle',
        [bookingsKeys.tableHeader.driver]: 'Driver',
        [bookingsKeys.tableHeader.passenger]: 'Passenger',
        [bookingsKeys.tableHeader.payment]: 'Payment',
        [bookingsKeys.tableHeader.note]: 'Note',
        [bookingsKeys.tableHeader.operator]: 'Operator',
    },

    vi: {
        [bookingsKeys.tabHeader.active]: 'Đang hoạt động',
        [bookingsKeys.tabHeader.finished]: 'Đã kết thúc',

        [bookingsKeys.filter.searchPlaceholder]: 'Tìm kiếm...',
        [bookingsKeys.filter.localOrAffiliates]: 'Local/Affiliates',
        [bookingsKeys.filter.bookingType]: 'Loại chuyến',
        [bookingsKeys.filter.status]: 'Trạng thái',
        [bookingsKeys.filter.operator]: ' Điều hành viên',
        [bookingsKeys.filter.carType]: 'Loại xe',
        [bookingsKeys.filter.bookingFrom]: 'Nguồn khách',
        [bookingsKeys.filter.paymentMethod]: 'Phương thức thanh toán',

        [bookingsKeys.filter.customerType]: 'Loại KH',

        [bookingsKeys.tableHeader.bookingId]: 'Cuốc #',
        [bookingsKeys.tableHeader.address]: 'Điểm đón/Điểm trả',
        [bookingsKeys.tableHeader.vehicle]: 'Phương tiện',
        [bookingsKeys.tableHeader.driver]: 'Tài xế',
        [bookingsKeys.tableHeader.passenger]: 'Khách hàng',
        [bookingsKeys.tableHeader.payment]: 'Thanh toán',
        [bookingsKeys.tableHeader.note]: 'Ghi chú',
        [bookingsKeys.tableHeader.operator]: 'Điều hành viên',
    },
}

export default bookingsLocale
