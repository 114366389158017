import { useEffect, useState } from 'react'

import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import { ConstructionOutlined } from '@mui/icons-material'

import ViewContext from 'app/_shared/view-context'

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

const GridCallComing = props => {
    const [gridApi, setGridApi] = useState(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)
    const [gridCellApi, setGridCellApi] = useState(null)

    const [data, setData] = useState([])

    useEffect(() => {
        if (
            props.newCallItem !== '' &&
            props.newCallItem != null &&
            gridApi != null
        ){
            gridApi.applyTransaction({
                add: [props.newCallItem],
                addIndex: 0,
            })

            let rows = gridApi.getRenderedNodes()
            if (rows) props.setCountPhoneNumber(rows.length)
        }
    }, [props.newCallItem])

    useEffect(() => {
        if (props.removePhoneNumber != null && gridApi != null) {
            let rowNode = gridApi.getRowNode(props.removePhoneNumber)
            if (rowNode) {
                gridApi.applyTransaction({
                    remove: [rowNode],
                })
                setTimeout(() => {
                    let rows = gridApi.getRenderedNodes()
                    console.log(rows)
                    if (rows) props.setCountPhoneNumber(rows.length)
                }, 500)
            }
        }
    }, [props.removePhoneNumber])

    const onGridReady = params => {
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
        setGridCellApi(params.cellApi)
    }

    function actionCellRenderer(params) {
        let eGui = document.createElement('div')
        eGui.innerHTML = `
            <button  
                data-action="cancel" style="color:red"
            >
                &nbsp;&nbsp;x  
            </button>`
        return eGui
    }

    const viewDispatch = useViewDispatch()

    const sendPhoneNumber = data => {
        viewDispatch(ViewActions.sendPhoneNumber(data))
    }

    const _onCellClicked = params => {
        // console.log('click icon close: ')
        // console.log(params)

        if (params.column.colId === 'cancel') {
            //if (params.event.target.dataset.action === 'cancel') {
            params.api.applyTransaction({
                remove: [params.node.data],
                //remove: [item]
            })

            setTimeout(() => {
                let rows = gridApi.getRenderedNodes()
                if (rows) props.setCountPhoneNumber(rows.length)
            }, 300)
            //}

            sendPhoneNumber({
                id: params.data.id,
                line: params.data.line,
                phoneNumber: params.data.phone_number,
                callTime: params.data.time,
                customerId: params.data.c_id,
                marketingId: params.data.m_id,
                coordinators: params.data.coordinators,
                deleted: true,
            })
        } else {
            //console.log('row click to send phone: ')
            sendPhoneNumber({
                id: params.data.id,
                line: params.data.line,
                phoneNumber: params.data.phone_number,
                callTime: params.data.time,
                customerId: params.data.c_id,
                marketingId: params.data.m_id,
                coordinators: params.data.coordinators,
            })
        }
    }

    const _onRowClicked = params => {
        // if (params.column.colId === 'cancel')
        //     return
        // useViewDispatch()(ViewActions.sendPhoneNumber({
        //     id: callId,
        //     line: line,
        //     phoneNumber: phoneNumber,
        //     callTime: callTime,
        //     customerId: customer_id,
        //     marketingId: marketing_id,
        //     coordinators: coordinators,
        // }))
    }

    const renderCustomerType = params => {
        let s = 'T.Đài'
        switch (parseInt(params.value)) {
            case -1:
                s = '<span style = "color: #6eb4f5">T.Đài</span>'
                break
            case 0:
                s = '<span style = "color: #6eb4f5">T.Đài</span>'
                break
            case 1:
                s = '<span style = "color: green">Lẻ</span>'
                break
            case 2:
                s = '<span style = "color: #6eb4f5">C.Ty</span>'
                break
            case 3:
                s = '<span style = "color: red">T.Năng</span>'
                break
            case 4:
                s = '<span style = "color: red">VIP</span>'
                break
            case 5:
                s = '<span style = "color: red">T.Thị</span>'
                break
        }
        return s
    }

    //console.log('grid call coming render...')
    return (
        <div
            id="myGrid"
            style={{
                // height: '100%',
                width: '100%',
                position: 'absolute',
                right: 0,
                bottom: 0,
                flex: 1,
                backgrounColor: 'white',
            }}
            className="ag-theme-alpine waiting-calls"
        >
            <AgGridReact
                rowData={data}
                defaultColDef={{
                    flex: 1,
                    minWidth: 30,
                    editable: false,
                    resizable: true,
                    wraptext: false,
                    autoHeight: false,
                }}
                onGridReady={onGridReady}
                onCellClicked={_onCellClicked}
                rowHeight={25}
                getRowNodeId={data => data.id}
                onRowClicked={_onRowClicked}
            >
                <AgGridColumn headerName="Id" field="id" hide={true} />
                <AgGridColumn
                    headerName="Số ĐT"
                    field="phone_number"
                    width={100}
                    minWidth={100}
                    cellStyle={() => {
                        return {
                            fontWeight: 'bold',
                        }
                    }}
                />
                <AgGridColumn
                    headerName="L.Sử"
                    field="time_history"
                    width={30}
                    minWidth={30}
                />
                <AgGridColumn
                    headerName="T.Gian"
                    field="time"
                    width={40}
                    minWidth={40}
                />
                <AgGridColumn
                    headerName="Kênh"
                    field="line"
                    width={30}
                    minWidth={30}
                />
                <AgGridColumn
                    headerName="KH"
                    field="customer_type"
                    width={40}
                    minWidth={40}
                    cellRenderer={renderCustomerType}
                />
                <AgGridColumn
                    headerName="Hủy"
                    field="cancel"
                    cellRenderer={actionCellRenderer}
                    editable={false}
                    minWidth={30}
                    width={20}
                    cellStyle={() => {
                        return {
                            // display: 'flex',
                            // alignItems: 'center',
                            // justifyContent: 'center'
                        }
                    }}
                />
            </AgGridReact>
        </div>
    )
}

export default GridCallComing
