const NewBookingActionTypes = {
    setRndConfig: 'SET_RND_CONFIG',

    setHandlingTripInfoToDefault: 'SET_HANDLING_TRIP_INFO_TO_DEFAULT',
    setAddressField: 'SET_ADDRESS_FIELD',
    setEstimateData: 'SET_ESTIMATE_DATA',
    setFocusingEl: 'SET_FOCUSING_EL',
    setPhoneNumber: 'SET_PHONE_NUMBER',
    setOnlyOneCoor: 'SET_ONLY_ONE_COOR',
    setServices: 'SET_SERVICES',

    setPickupCoordinates: 'SET_PICKUP_COORDINATES',

    setPickupTime: 'SET_PICKUP_TIME',
    setNote: 'SET_NOTE',
    setDriverAssigned: 'SET_DRIVER_ASSIGNED',
}

export default NewBookingActionTypes
