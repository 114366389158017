import {
    Button,
    createTheme,
    InputAdornment,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import newBookingLocaleKeys from 'app/new-booking/i18n/newBookingLocaleKeys'
import { useTrans } from 'helpers/useTranslation'
import React from 'react'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiFormControlLabel: {
            styleOverrides: { root: { marginLeft: 0, marginRight: 8 } },
        },
        MuiRadio: { styleOverrides: { root: { padding: 2 } } },
        MuiTypography: { styleOverrides: { root: { fontSize: 12 } } },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: '#212121',
                    padding: '0px 0px 0px 0.125rem !important',
                    borderRadius: '0.125rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    padding: '0.125rem !important',
                    color: 'white',
                    fontSize: '12px',
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: { color: '#edab25', paddingRight: 4 },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    textTransform: 'capitalize',
                    minWidth: '36px',
                    fontWeight: 'normal',
                    paddingLeft: 2,
                    paddingRight: 2,
                },
            },
        },
    },
})
const Promotion = () => {
    const t = useTrans()

    return (
        <ThemeProvider theme={theme}>
            <TextField
                placeholder={t(newBookingLocaleKeys.amountPlaceholder)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    color: '#edab25',
                                }}
                            >
                                %
                            </Typography>
                        </InputAdornment>
                    ),
                }}
            />
            <TextField
                placeholder={t(newBookingLocaleKeys.promotionPlaceholder)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button variant="text" onClick={e => {}}>
                                Apply
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
        </ThemeProvider>
    )
}

export default Promotion
