import {
    createTheme,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
    Autocomplete,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'

import UserContainerContext from 'app/_shared/user-container-context'
import { useEffect } from 'react'

import { useNewBookingContext, useNewBookingDispatch } from '../context'
import actions from '../context/actions'
import { DesignServicesRounded } from '@mui/icons-material'

import { CollectionsOutlined } from '@mui/icons-material'

import { useRecoilState } from 'recoil'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { useTrans } from 'helpers/useTranslation'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'

const { useUserContainerContext, UserContainerActions } = UserContainerContext

const theme = createTheme({
    palette: {
        primary: {
            main: '#edab25',
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: { root: { marginLeft: 0, marginRight: 8 } },
        },
        MuiRadio: { styleOverrides: { root: { padding: 2 } } },
        MuiTypography: { styleOverrides: { root: { fontSize: 12 } } },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: '#ffffff !important',
                    height: '34px',
                    borderRadius: '4px',
                    border: '1px solid #e6e9ec',
                    padding: '0px',
                    '&.Mui-disabled': {
                        backgroundColor: '#393939',
                    },
                },

                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '34px !important',
                    padding: '0.125rem !important',
                    color: '#181918 !important',
                    fontSize: '14px !important',
                    marginTop: '-12px'
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: { padding: '0.125rem' },
                endAdornment: { right: '0px !important' },
            },
        },
    },
})

const dispatchType = {
    auto: { name: 'Auto', value: 'auto' },
    assign: { name: 'Assign', value: 'assign' },
}

const DispatchDriver = ({ onChange }) => {
    const t = useTrans()

    const [{ drivers }] = useUserContainerContext()

    // console.log('list drivers: ')
    // console.log(drivers)

    const [driversReady, setDriversReady] = useState(null)

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    // let listDriversReady = Object.entries(driverLocations['_data']).reduce((arr, item) => {
    //     if (item['1']['1'].is_online && item['1']['1'].driver_status == 0) {
    //         let driverTemp = {
    //             label: item['1']['1'].driver_id + '__' + item['1']['1'].phone_number,
    //             id: item['1']['1'].driver_id
    //         }
    //         arr.push(driverTemp)
    //     }
    //     return arr
    // }, [])

    useEffect(() => {
        let listDriversReady = drivers.reduce((arr, item) => {
            //console.log(item)
            //if (item.is_online && item.status == 0) {
            let driverTemp = {
                label: item.vh_code
                    ? item.full_name + ' | ' + item.vh_code
                    : item.full_name,
                id: item.driver_id,
            }
            arr.push(item)
            //}
            return arr
        }, [])

        //setDriversReady(listDriversReady)
        setDriversReady(drivers)
    }, [drivers])

    const [
        {
            handlingTrip: {
                info: { driverAssigned },
                metadata: { hasOnlyOneCoor },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const [_dispatchType, _setDispatchType] = useState(dispatchType.auto.value)
    const [selectedOptions, setSelectedOptions] = useState('')

    const _handleChange = e => {
        _setDispatchType(e.target.value)
        setSelectedOptions(null)
        if (e.target.value === 'auto') {
            dispatch(actions.tripInfo.setDriverAssigned(null))
            dispatch(actions.tripInfo.setOnlyOneCoor(hasOnlyOneCoor))
        } else {
            dispatch(actions.tripInfo.setDriverAssigned(null))
        }
    }

    const _handleAssingChanged = e => {
        e.preventDefault();
        // console.log(e.target)
        // console.log(e.target.name)
        _setDispatchType(e.target.name)
    }

    const handleSelectOption = (e, value, reason, detail) => {
        // console.log('driver assigned value: ')
        // console.log(value)
        //setSelectedOptions(value ? (value.vh_code ? value.full_name + '|' + value.vh_code : value.full_name) : null)
        setSelectedOptions(value)
        dispatch(
            actions.tripInfo.setDriverAssigned(value ? value.driver_id : null)
        )
        dispatch(actions.tripInfo.setOnlyOneCoor(true))
    }

    useEffect(() => {
        _setDispatchType(dispatchType.auto.value)
    }, [isNewBookingOpen])

    const renderColor = option => {
        if (option.is_online) {
            if (option.status == 0) return 'green'
            return 'orange'
        }
        return 'gray'
    }

    return (
        <div className="w-full flex flex-col dispatcher">
            <p className='title'>Điều chuyến</p>

            <ThemeProvider theme={theme}>
                {/* <FormControl>
                    <RadioGroup
                        row
                        defaultValue={dispatchType.auto.value}
                        value={_dispatchType}
                        onChange={_handleChange}
                    >
                        {Object.keys(dispatchType).map(dispatchKey => (
                            <FormControlLabel
                                key={dispatchKey}
                                value={dispatchType[dispatchKey].value}
                                control={<Radio size="small" />}
                                label={t(
                                    dispatchType[dispatchKey].name === 'Auto'
                                        ? newBookingLocaleKeys.dispatchAuto
                                        : newBookingLocaleKeys.dispatchAssign
                                )}
                            />
                        ))}
                    </RadioGroup>
                </FormControl> */}
                <div className={_dispatchType === 'assign' ? 'options assign' : 'options'}>
                    <div>
                        <button name='auto' onClick={_handleAssingChanged}>Tự động</button>
                        <button name='assign' onClick={_handleAssingChanged}>Chỉ định</button>
                    </div>
                    {/* <TextField
                        sx={{ mt: 1 / 4 }}
                        disabled={_dispatchType === dispatchType.auto.value}
                    /> */}
                    <div>
                        <Autocomplete
                            //open={true}
                            disablePortal
                            id="combo_box_drivers"
                            options={_dispatchType === dispatchType.auto.value ? [] : driversReady}
                            //options={[]}
                            sx={{ width: '100%' }}
                            value={selectedOptions}
                            //onInputChange={handleInputChange}
                            disabled={_dispatchType === dispatchType.auto.value}
                            onChange={handleSelectOption}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    placeholder='Chọn mã số xe, biển số xe'
                                    //sx={{ mt: 1 / 4 }}
                                    disabled={_dispatchType === dispatchType.auto.value}
                                />
                            )}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={option =>
                                option.vh_code
                                    ? option.full_name + ' | ' + option.vh_code
                                    : option.full_name || ""
                            }
                            renderOption={(props, option) => {
                                props.key = option.driver_id
                                return (
                                    <li
                                        {...props}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            borderBottom: '1px solid lightgray',
                                        }}
                                    >
                                        <div>
                                            <p>{option.full_name}</p>
                                            <p>{option.vh_code}</p>
                                        </div>
                                        <span
                                            style={{
                                                backgroundColor: renderColor(option),
                                                width: '12px',
                                                height: '12px',
                                                borderRadius: '50%',
                                                float: 'right',
                                            }}
                                        ></span>
                                    </li>
                                )
                            }}
                        />
                    </div>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default DispatchDriver
