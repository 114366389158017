import axios from 'axios'
import {
    getLocalStorage,
    localStorageKeys,
    removeLocalStorage,
} from 'services/localStorage'
import { useDispatchMainApp } from 'app/_shared/main-app-context/MainAppContext'
import setUser from 'app/_shared/main-app-context/main-app-actions/setUser'

// Default config options
const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
}

// Create instance
const fetcher = axios.create(defaultOptions)

// Set the AUTH token for any request
fetcher.interceptors.request.use(function (config) {
    const token = getLocalStorage(localStorageKeys.token)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})

// handle request errors (optional)
fetcher.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error.response) {
            return Promise.resolve(error.response)
        }
    }
)

export default fetcher
