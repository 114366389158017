import SidebarReducers from './SidebarReducers'
import SwitchPopupReducers from './SwitchPopupReducers'
import PhoneNumberReducers from './PhoneNumberReducers'
import TripsReducer from './TripsReducer'
import UserSettingsReducer from './UserSettingsReducers'

const ViewReducers = {
    ...SidebarReducers,
    ...SwitchPopupReducers,
    ...PhoneNumberReducers,
    ...TripsReducer,
    ...UserSettingsReducer,
}

export default ViewReducers
