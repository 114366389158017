import { bookingApiPrefix } from 'api'
import httpStatus from 'api/httpStatus'
import fetcher from 'services/fetcher'

export const loginUser = async ({ username, password }) => {
    const res = await fetcher.post(`${bookingApiPrefix}/auth/login`, {
        username,
        password,
    })
    if (res && res.status === httpStatus.OK) return res.data
    return false
}

export const loginCode = {
    USER_NOT_FOUND: 'USER_NOT_FOUND',
    WRONG_PASSWORD: 'WRONG_PASSWORD',
}
