import HashMap from 'hashmap'
import UserContainerActionTypes from '../UserContainerActionTypes'

const addAllTrip = trips => {
    const newTrips = new HashMap()
    const keys = Object.keys(trips)
    keys.forEach(key => {
        newTrips.set(key, trips[key])
    })
    return {
        type: UserContainerActionTypes.addAllTrip,
        payload: { trips: newTrips },
    }
}

const tripsActions = { addAllTrip }

export default tripsActions
