import { EmddiMap } from '@emddi/react-map'
import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import L from 'leaflet'
import Lr from 'leaflet-rotatedmarker'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
    addressMarkerTypes,
    getAddressMarker,
    latLngFromString,
    getMarkerIcon,
} from 'utils/maps'

import { getJourneyApi } from 'api/trip'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

const MapTracking = ({ flagMaximumSize }) => {
    const tripDetail = useRecoilValue(tripDetailState)

    const [map, setMap] = useState(null)

    const { center_lat: centerLat, center_lng: centerLng } = getLocalStorage(
        localStorageKeys.user
    )
    const center = [+centerLat, +centerLng]

    let pickupMarker = useRef(
        L.marker([0, 0], {
            //icon: getAddressMarker(addressMarkerTypes.start),
            icon: getMarkerIcon(12, 48),
            rotationAngle: 0,
            rotationOrigin: 'center center',
        })
    )
    let destinationMarker = useRef(
        L.marker([0, 0], {
            icon: getAddressMarker(addressMarkerTypes.end),
            rotationAngle: 0,
            rotationOrigin: 'center center',
        })
    )

    let directionPolylinesComming = useRef(null)
    let directionPolylinesOnTrip = useRef(null)

    useEffect(() => {
        if (tripDetail.open && map) {
            if (directionPolylinesComming.current)
                directionPolylinesComming.current.remove()
            if (directionPolylinesOnTrip.current)
                directionPolylinesOnTrip.current.remove()

            let startPoint, endPoint
            if (!!tripDetail.data.start_point) {
                startPoint = latLngFromString(tripDetail.data.start_point)
                pickupMarker.current.setLatLng(startPoint).addTo(map)
                //console.log(startPoint)
            }
            if (!!tripDetail.data.end_point) {
                endPoint = latLngFromString(tripDetail.data.end_point)
                destinationMarker.current.setLatLng(endPoint).addTo(map)
                //console.log(endPoint)
            }

            if (startPoint && endPoint) {
                let bounds = L.latLngBounds(startPoint, endPoint)
                map.flyToBounds(bounds, { animate: false, padding: [3, 3] })
            } else if (startPoint) {
                map.flyTo(startPoint, undefined, { animate: false })
            } else if (endPoint) {
                map.flyTo(endPoint, undefined, { animate: false })
            }

            getJourneyApi(tripDetail.data.booking_id, tripDetail.data.id)
                .then(response => {
                    //console.log(response)
                    //coming
                    let comingTrackings = []
                    if (response.comingTrackings.length > 0) {
                        comingTrackings = response.comingTrackings.map(
                            (item, index) => {
                                let arrTemp = []
                                arrTemp[0] = item.tracking.lat
                                arrTemp[1] = item.tracking.lng
                                return arrTemp
                            }
                        )
                        const carStartMarker = L.marker(
                            [comingTrackings[0][0], comingTrackings[0][1]],
                            {
                                icon: getMarkerIcon(11, 32),
                                riseOnHover: true,
                                //rotationAngle: orientation,
                                rotationOrigin: 'center center',
                            }
                        )
                        carStartMarker.addTo(map)

                        // const passengerMarker = L.marker([response.ontripTrackings[0].tracking.lat, response.ontripTrackings[0].tracking.lng], {
                        //     icon: getMarkerIcon(12, 48),
                        //     riseOnHover: true,
                        //     rotationOrigin: 'center center',
                        // })
                        // passengerMarker.addTo(map)

                        if (response.ontripTrackings.length == 0) {
                            const carComingMarker = L.marker(
                                [
                                    comingTrackings[
                                        comingTrackings.length - 1
                                    ][0],
                                    comingTrackings[
                                        comingTrackings.length - 1
                                    ][1],
                                ],
                                {
                                    icon: getMarkerIcon(2, 16),
                                    riseOnHover: true,
                                    rotationAngle: response.orientation,
                                    rotationOrigin: 'center center',
                                }
                            )
                            carComingMarker.addTo(map)
                            directionPolylinesComming.current = L.polyline(
                                comingTrackings,
                                {
                                    color: '#f5a623',
                                }
                            ).addTo(map)
                        } else {
                            comingTrackings.push([
                                response.ontripTrackings[0].tracking.lat,
                                response.ontripTrackings[0].tracking.lng,
                            ])
                            directionPolylinesComming.current = L.polyline(
                                comingTrackings,
                                {
                                    color: '#f5a623',
                                }
                            ).addTo(map)
                        }

                        map.flyToBounds(
                            directionPolylinesComming.current.getBounds(),
                            {
                                animate: false,
                                padding: [3, 3],
                            }
                        )
                    }

                    //on trip
                    let ontripTrackings = []
                    if (response.ontripTrackings.length > 0) {
                        ontripTrackings = response.ontripTrackings.map(
                            (item, index) => {
                                let arrTemp = []
                                arrTemp[0] = item.tracking.lat
                                arrTemp[1] = item.tracking.lng
                                return arrTemp
                            }
                        )

                        // console.log('on trip trackings: ')
                        // console.log(ontripTrackings)

                        directionPolylinesOnTrip.current = L.polyline(
                            ontripTrackings,
                            {
                                //color: '#66BBEB',
                                color: '#24ACF2',
                            }
                        ).addTo(map)

                        if (tripDetail.data.status_booking == 1) {
                            const carOnTripMarker = L.marker(
                                [
                                    ontripTrackings[
                                        ontripTrackings.length - 1
                                    ][0],
                                    ontripTrackings[
                                        ontripTrackings.length - 1
                                    ][1],
                                ],
                                {
                                    icon: getMarkerIcon(3, 16),
                                    riseOnHover: true,
                                    rotationAngle: response.orientation,
                                    rotationOrigin: 'center center',
                                }
                            )
                            carOnTripMarker.addTo(map)
                        } else if (tripDetail.data.status_booking == 2) {
                            const carOnTripMarker = L.marker(
                                [
                                    ontripTrackings[
                                        ontripTrackings.length - 1
                                    ][0],
                                    ontripTrackings[
                                        ontripTrackings.length - 1
                                    ][1],
                                ],
                                {
                                    icon: getMarkerIcon(5, 16),
                                    //icon: getMarkerIcon(3, 16),
                                    riseOnHover: true,
                                    rotationAngle: response.orientation,
                                    rotationOrigin: 'center center',
                                }
                            )
                            carOnTripMarker.addTo(map)
                        }

                        map.flyToBounds(
                            directionPolylinesOnTrip.current.getBounds(),
                            {
                                animate: false,
                                padding: [3, 3],
                            }
                        )
                    }

                    if (
                        response.comingTrackings.length > 0 &&
                        response.ontripTrackings.length > 0
                    ) {
                        let arrTemp = [...comingTrackings, ...ontripTrackings]
                        let polyline = L.polyline(arrTemp)
                        map.flyToBounds(polyline.getBounds(), {
                            animate: false,
                            padding: [3, 3],
                        })
                    }
                })
                .catch(err => console.log(err))
        }
    }, [tripDetail.data, map])

    const whenMapCreated = m => {
        if(!map) setMap(m)
    }

    useEffect(() => {
        map?.invalidateSize()
    }, [flagMaximumSize])

    return (
        <div className={flagMaximumSize ? "MapTracking zoomout" : "MapTracking"}>
            <div className="MapContainer">
                <EmddiMap
                    divId="map-tracking"
                    center={center}
                    whenCreated={whenMapCreated}
                />
            </div>
        </div>
    )
}

export default MapTracking