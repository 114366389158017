import {
    EmddiFaIcon,
    EmddiMaterialIcon,
} from 'app/_shared/EmddiIcon/components'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { isSidebarOpenState } from 'app/_shared/recoil/sidebar/atom'
import UserContainerContext from 'app/_shared/user-container-context'
import ViewContext from 'app/_shared/view-context'
import classNames from 'classnames'
import { useTrans } from 'helpers/useTranslation'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { localStorageKeys, setLocalStorage, removeLocalStorage } from 'services/localStorage'
import userContainerLocaleKeys from '../i18n/userContainerLocaleKeys'
import sideBarMenus from '../static/sideBarMenus'
import { useDispatchMainApp } from 'app/_shared/main-app-context/MainAppContext'
import setUser from 'app/_shared/main-app-context/main-app-actions/setUser'

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

const SideBar = props => {
    const t = useTrans()
    const location = useLocation()

    const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState)

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    const _clickAddBooking = () => {
        setIsNewBookingOpen(!isNewBookingOpen)
    }

    const path = location.pathname.split('/')[1]

    const _clickToggle = () => {
        setLocalStorage(localStorageKeys.sideBarOpen, !isSidebarOpen)
        setIsSidebarOpen(!isSidebarOpen)
    }

    const [{ fullListTripsOpen }] = useViewContext()

    const viewDispatch = useViewDispatch()

    const _clickToggleFullListTrips = () => {
        viewDispatch(ViewActions.toggleFullListTrips())
        //viewDispatch(ViewActions.toggleCardTableGroup())
    }

    const handleClickLinkItem = () => {
        //viewDispatch(ViewActions.toggleCardTableGroup(false))
        viewDispatch(ViewActions.toggleFullListTrips(false))
        //dispatch(ViewActions.switchPopup(true))
    }

    const _renderMenus = () =>
        sideBarMenus.map((menu, idx) => {
            const menuPath = menu.route.split('/')[1]
            const isSelected = menuPath === path
            const title = t(menu.title)

            return (
                <Link key={idx} to={menu.route} onClick={handleClickLinkItem}>
                    <button
                        data-tip={title}
                        className={classNames('Menu', {
                            CollapsedMenu: !isSidebarOpen,
                            SelectedMenu:
                                isSelected &&
                                !isNewBookingOpen &&
                                !fullListTripsOpen,
                        })}
                    >
                        <span
                            className={classNames('MenuIcon', {
                                Toggle: !isSidebarOpen,
                            })}
                        >
                            <EmddiMaterialIcon
                                name={menu.icon}
                                className="text-xl outlined-none"
                            />
                        </span>
                        <span
                            className={classNames('MenuText', {
                                hidden: !isSidebarOpen,
                            })}
                        >
                            {title}
                        </span>
                    </button>
                </Link>
            )
        })

    const menus = _renderMenus()
    
    const mainAppDispatch = useDispatchMainApp()

    const _clickLogout = () => {
        mainAppDispatch(setUser({}))
        clearLocalStorageKeys()
        window.location.replace('/login')
    }

    const clearLocalStorageKeys = () => {
        removeLocalStorage(localStorageKeys.user)
        removeLocalStorage(localStorageKeys.userSettings)
        removeLocalStorage(localStorageKeys.socketToken)
        removeLocalStorage(localStorageKeys.services)
        removeLocalStorage(localStorageKeys.token)
        removeLocalStorage(localStorageKeys.sideBarOpen)
    }
    
    return (
        <div
            className={classNames(
                isSidebarOpen ? 'SideBarOpenWidth' : 'SideBarWidth',
                'transition-all SideBar'
            )}
        >   
            <div className="Logo">
                <img src="/assets/icons/logo/emddi_logo.svg"  alt="logo" />
            </div>
            <div className="Menus">
                <div
                    className={classNames('Menu ToggleIcon', {
                        ToggleIconCollapsed: !isSidebarOpen,
                    })}
                    onClick={_clickToggle}
                >
                    <EmddiFaIcon
                        name={
                            isSidebarOpen
                                ? 'FaAngleDoubleLeft'
                                : 'FaAngleDoubleRight'
                        }
                        className="text-xl ToggleMenuIcon"
                    />
                    {!!isSidebarOpen && (
                        <div className="ml-2">
                            <div className="font-bold">EMDDI BOOKING 2.0</div>
                            {/* <div>
                                {t(
                                    userContainerLocaleKeys.sideBar
                                        .clickToToggle
                                )}
                            </div> */}
                        </div>
                    )}
                </div>
                <button
                    className={classNames('Menu NewBookingButton', {
                        CollapsedMenu: !isSidebarOpen,
                        SelectedMenu: isNewBookingOpen,
                    })}
                    onClick={_clickAddBooking}
                >
                    <span
                        className={classNames('MenuIcon', {
                            Toggle: !isSidebarOpen,
                        })}
                    >
                        <EmddiMaterialIcon
                            name={
                                isNewBookingOpen
                                    ? 'MdAddCircle'
                                    : 'MdAddCircleOutline'
                            }
                            className="text-xl outlined-none"
                            // size={32}
                        />
                    </span>
                    <span
                        className={classNames('MenuText', {
                            hidden: !isSidebarOpen,
                        })}
                    >
                        {t(userContainerLocaleKeys.newBooking)}
                    </span>
                </button>
                {/* Button toggle  */}
                <button
                    className={classNames('Menu NewBookingButton', {
                        CollapsedMenu: !isSidebarOpen,
                        SelectedMenu: fullListTripsOpen && !isNewBookingOpen,
                    })}
                    onClick={_clickToggleFullListTrips}
                >
                    <span
                        className={classNames('MenuIcon', {
                            Toggle: !isSidebarOpen,
                        })}
                    >
                        <EmddiMaterialIcon
                            name="MdOutlineDirectionsCar"
                            className="text-xl outlined-none"
                        />
                    </span>
                    <span
                        className={classNames('MenuText', {
                            hidden: !isSidebarOpen,
                        })}
                    >
                        {t(userContainerLocaleKeys.sideBar.trips)}
                    </span>
                </button>

                {/* Menu sidebar */}
                {menus}

                <button
                    className={classNames('Menu NewBookingButton', {
                        CollapsedMenu: !isSidebarOpen,
                        SelectedMenu: fullListTripsOpen && !isNewBookingOpen,
                    })}
                    onClick={_clickLogout}
                >
                    <span
                        className={classNames('MenuIcon', {
                            Toggle: !isSidebarOpen,
                        })}
                    >
                        <EmddiMaterialIcon
                            name="MdLogout"
                            className="text-xl outlined-none"
                        />
                    </span>
                    <span
                        className={classNames('MenuText', {
                            hidden: !isSidebarOpen,
                        })}
                    >
                        {t(userContainerLocaleKeys.sideBar.logout)}
                    </span>
                </button>
            </div>
        </div>
    )
}

SideBar.propTypes = {}

export default SideBar
