import { EmddiMaterialIcon } from 'app/_shared/EmddiIcon/components'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './scss/_CheckBox.scss'

//const DEFAULT_COLOR_SELECTED = 'rgb(25, 118, 210)'
const DEFAULT_COLOR_SELECTED = '#219737'

const CheckBox = props => {
    const { name, checked, label, color, size, className, onToggle } = props
    const [_checked, _setChecked] = useState(
        typeof checked === 'undefined' ? null : checked
    )

    useEffect(() => {
        _setChecked(checked)
    }, [checked])

    const _onClick = () => {
        _setChecked(!_checked)
        if (onToggle) onToggle(!_checked, name)
    }

    return (
        <div className={classNames('CheckBox', className)} onClick={_onClick}>
            <div className="CheckBoxLabel">{label}</div>
            <div style={{ width: size || 20 }}>
                <EmddiMaterialIcon
                    name={
                        _checked
                            ? name === 0
                                ? 'MdOutlineCheckBox'
                                : 'MdCheckBox'
                            : 'MdCheckBoxOutlineBlank'
                    }
                    color={className ? color : DEFAULT_COLOR_SELECTED}
                    //color={DEFAULT_COLOR_SELECTED}
                    size={size || 20}
                />
            </div>
        </div>
    )
}

export default CheckBox
