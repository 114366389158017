import NewBookingActionTypes from '../actionTypes'
import mapPickerReducers from './mapPickerReducers'
import rndReducers from './rndReducers'
import tripInfoReducers from './tripInfoReducers'

const reducers = (state, action) => {
    const { type, payload } = action

    switch (type) {
        // Rnd
        case NewBookingActionTypes.setRndConfig:
            return rndReducers.setRndConfig(state, payload)

        // Trip Info
        case NewBookingActionTypes.setHandlingTripInfoToDefault:
            return tripInfoReducers.setHandlingTripInfoToDefault(state, payload)
        case NewBookingActionTypes.setAddressField:
            return tripInfoReducers.setAddressField(state, payload)
        case NewBookingActionTypes.setEstimateData:
            return tripInfoReducers.setEstimateData(state, payload)
        case NewBookingActionTypes.setFocusingEl:
            return tripInfoReducers.setFocusingEl(state, payload)
        case NewBookingActionTypes.setPhoneNumber:
            return tripInfoReducers.setPhoneNumber(state, payload)
        case NewBookingActionTypes.setOnlyOneCoor:
            return tripInfoReducers.setOnlyOneCoor(state, payload)
        case NewBookingActionTypes.setServices:
            return tripInfoReducers.setServices(state, payload)
        case NewBookingActionTypes.setPickupTime:
            return tripInfoReducers.setPickupTime(state, payload)
        case NewBookingActionTypes.setNote:
            return tripInfoReducers.setNote(state, payload)
        case NewBookingActionTypes.setDriverAssigned:
            return tripInfoReducers.setDriverAssigned(state, payload)
        // Map Picker
        case NewBookingActionTypes.setPickupCoordinates:
            return mapPickerReducers.setPickupCoordinates(state, payload)
    }

    return state
}

export default reducers
