import {
    createTheme,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import newBookingLocaleKeys from 'app/new-booking/i18n/newBookingLocaleKeys'
import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import { useTrans } from 'helpers/useTranslation'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ENTER } from 'static/keyboard-code'
import { REG_PHONE_NUMBER } from '../../../static/regex'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiFormControlLabel: {
            styleOverrides: { root: { marginLeft: 0, marginRight: 8 } },
        },
        MuiRadio: { styleOverrides: { root: { padding: 2 } } },
        MuiTypography: { styleOverrides: { root: { fontSize: 12 } } },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #e6e9ec',
                    height: '40px',
                    position: 'relative',
                    paddingLeft: '6px'
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    color: '#181918',
                    fontSize: '16px',
                    fontWeight: '400',
                    paddingLeft: '8px'
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: { color: '#edab25', paddingRight: 4 },
            },
        },
    },
})

const customerType = {
    individual: { name: 'Indiv.', value: 'individual' },
    corporation: { name: 'Corp.', value: 'corporation' },
}

const CustomerInfo = () => {
    const t = useTrans()

    const [_customerType, _setCustomerType] = useState(
        customerType.individual.value
    )

    const _inputRef = useRef()

    const tripDetail = useRecoilValue(tripDetailState)

    let _highlightCustomerRole = !!tripDetail.data?.marketing_id
    let _customerRole = tripDetail.data?.marketing_id ? 'MKT' : ''
    
    const _handleChangePhoneNumberField = () => {

    }

    const _handleKeyDownPhoneNumberField = () => {

    }

    return (
        <ThemeProvider theme={theme}>
            <div className="flex flex-row customer-info">
                <TextField
                    //sx={{ mt: 1 / 4 }}
                    name="phoneNumber"
                    value={tripDetail.data?.customer_phone}
                    inputRef={_inputRef}
                    placeholder={t(newBookingLocaleKeys.phoneNumberPlaceholder)}
                    onChange={_handleChangePhoneNumberField}
                    onKeyDown={_handleKeyDownPhoneNumberField}
                    onFocus={() => {}}
                    InputProps={{
                        readOnly: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src='assets/icons/phone.svg' />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: _highlightCustomerRole
                                            ? '#219737'
                                            : 'grey',
                                        position: 'absolute',
                                        right: '4px'
                                    }}
                                >
                                    {_customerRole}
                                </Typography>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    name="fullName"
                    placeholder={t(newBookingLocaleKeys.fullNamePlaceholder)}
                    value={tripDetail.data?.customer_name}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </div>
            {/* <TextField
                    name="email"
                    placeholder={t(newBookingLocaleKeys.emailPlaceholder)}
                /> */}
        </ThemeProvider>
    )
}

export default CustomerInfo
