import {bookingApiPrefix} from 'api'
import fetcher from 'services/fetcher'

export const updateCmdSbApi = async dataUpdate => {
    return await fetcher.post(
        `${bookingApiPrefix}/trip/updating/command-switchboard`,
        {
            ...dataUpdate,
        }
    )
}
