import WaitingCallsTable from './waitingCalls/WaitingCallTable'

const WaitingCalls = props => {
    return (
        <div className="WaitingCalls">
            <WaitingCallsTable />
        </div>
    )
}

export default WaitingCalls
