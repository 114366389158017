import { localStorageKeys, setLocalStorage } from 'services/localStorage'
import ViewActionTypes from '../constants/ViewActionTypes'

const setUserSettings = data => {
    setLocalStorage(localStorageKeys.userSettings, data)
    return {
        type: ViewActionTypes.SET_USER_SETTINGS,
        payload: data,
    }
}

const UserSettingsActions = {
    setUserSettings: setUserSettings,
}

export default UserSettingsActions
