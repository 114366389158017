import mainAppInitState from './mainAppInitState'
import MainAppActionTypes from './MainAppActionTypes'
import setUser from './main-app-reducers/setUser'

const MainAppReducer = (state, action) => {
    const { type, payload = {} } = action
    const _state = { ...(state || mainAppInitState) }

    if (type === MainAppActionTypes.setUser)
        return setUser(_state, { type, payload })

    return state
}

export default MainAppReducer
