import {
    Autocomplete,
    CircularProgress,
    createTheme,
    Paper,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material'
import { placeDetailApi, searchPlaceApi } from 'api/trip'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import classNames from 'classnames'
import {
    formatDistance,
    getTime,
    secondsToMinutes,
    differenceInSeconds,
} from 'date-fns'
import { enUS, vi } from 'date-fns/esm/locale'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { ENTER, TAB } from 'static/keyboard-code'
import { useNewBookingContext, useNewBookingDispatch } from '../context'
import actions from '../context/actions'
import { keyboardFlow } from '../context/initialState'
import { bookingStatusColor, bookingStatusName } from 'utils/Booking'
import { useSnackbar } from 'notistack'
import {searchLocationShortcutApi} from "../../../api/location";
import { getLocalStorage, localStorageKeys } from 'services/localStorage'

let debounceTimer = false

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: { padding: '0.125rem' },
                endAdornment: { right: '0px !important' },
            },
        },
        
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    background: '#ffffff',
                    padding: '0px 1rem 0px 0.125rem !important',
                    borderRadius: '4px',
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    border: '0px !important'
                },
                input: {
                    height: '1.5rem',
                    padding: '0.125rem !important',
                    color: '#333537',
                    fontSize: '18px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    fontFamily: 'Roboto'
                },
            },
        },
        MuiSvgIcon: { styleOverrides: { root: { color: 'white' } } },
    },
})

let _highlightingOption = null
const searchTypes = { all: 'all', elastic: 'elastic', google: 'google', database: 'database' }

const AddressField = ({
    name,
    debounce,
    placeholder,
    title,
    keyFlow,
    className,
    histories,
    externalOptions,
    flagQuery,
    ...props
}) => {
    const { i18n } = useTranslation()

    const { enqueueSnackbar } = useSnackbar()

    const [isNewBookingOpen] = useRecoilState(isNewBookingOpenState)

    const { user_service: {region_id: regionId } }= getLocalStorage(localStorageKeys.user)

    const [
        {
            handlingTrip: {
                info: { phoneNumber },
                metadata: { focusingEl },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const [_selectedOptions, _setSelectedOptions] = useState(null)
    const [_inputValue, _setInputValue] = useState('')

    const [_options, _setOptions] = useState([])
    const [_loading, _setLoading] = useState(false)

    const [_doCallApi, _setDoCallApi] = useState(false)
    const [_searchType, _setSearchType] = useState(searchTypes.google)

    let _inputRef = useRef()

    useEffect(() => {
        let active = true

        if (!_doCallApi) {
            return undefined
        }

        ;(async () => {
            const places = await searchPlaceApi(_inputValue, _searchType)
            if (active) {
                _setLoading(false)
                _setOptions(places)
                _setDoCallApi(false)
            }
        })()

        return () => {
            active = false
        }
    }, [_inputValue, _doCallApi])

    useEffect(() => {
        // console.log('focusing El: ' + focusingEl)
        // console.log('keyFlow: ' + keyFlow)
        if (!isNewBookingOpen) {
            _setSelectedOptions(null)
            _setOptions([])
            _setSearchType(searchTypes.google)
            _setInputValue('')
        } else {
            if (keyFlow === focusingEl) {
                setTimeout(() => {
                    _inputRef.current?.focus()
                }, 300)
            } else {
                _inputRef.current?.blur()
            }
        }
    }, [isNewBookingOpen, focusingEl])

    const renderStatus = item => {
        let type = item.type
        let statusCoordinator = item.status
        let statusBooking = item.status_b
        if (item.historyID)
            return {
                color: 'black',
                statusString: '------',
            }
        switch (type) {
            case 0:
                switch (statusCoordinator) {
                    case 0:
                        return {
                            color: bookingStatusColor[
                                bookingStatusName.dispatching
                            ],
                            statusString: 'Tìm xe',
                        }
                    case 1:
                        if (statusBooking == 3) {
                            return {
                                color: 'orange',
                                statusString: 'Thấy xe',
                            }
                        } else {
                            switch (statusBooking) {
                                case 0:
                                    return {
                                        color: '#f5a623',
                                        statusString: 'Đón khách',
                                    }
                                case 1:
                                    return {
                                        color: '#00759A',
                                        statusString: 'Chở khách',
                                    }
                                case 2:
                                    return {
                                        color: bookingStatusColor[
                                            bookingStatusName.finished
                                        ],
                                        statusString: 'Hoàn thành',
                                    }
                                case -3:
                                    return {
                                        color: '#A50F14',
                                        statusString: 'TĐ hủy',
                                    }
                                case -4:
                                    return {
                                        color: 'red',
                                        statusString: 'LX hủy',
                                    }
                                case -5:
                                    return {
                                        color: 'red',
                                        statusString: 'KH hủy',
                                    }
                                default:
                                    return ''
                            }
                        }
                    case -1:
                        return {
                            color: 'red',
                            statusString: 'Không xe',
                        }
                    case -2:
                        return {
                            color: 'red',
                            statusString: 'Lỗi',
                        }
                    case -3:
                        return {
                            color: '#A50F14',
                            statusString: 'TĐ hủy',
                        }
                    case -4:
                        return {
                            color: 'red',
                            statusString: 'LX hủy',
                        }
                    case -5:
                        return {
                            color: 'red',
                            statusString: 'KH hủy',
                        }
                    default:
                        return ''
                }
            case 1:
                break
            case 8:
                return {
                    color: bookingStatusColor[bookingStatusName.pending],
                    statusString: 'đặt hẹn',
                }
        }
    }

    useEffect(() => {
        if (histories) {
            if (histories.length > 0 && histories[0].created_at) {
                let formattedData = histories.map(item => {
                    const dt = item.created_at.split(' ')
                    const t = dt[0].split(':')
                    const d = dt[1].split('-')

                    const callTime = new Date(d[2], d[1] - 1, d[0], t[0], t[1])

                    const now = new Date()

                    item.descriptionTime = formatDistance(callTime, now, {
                        locale: i18n.language === 'vi' ? vi : enUS,
                        addSuffix: true,
                    })
                        .replace('trước', '')
                        .replace('phút', "'")
                        .replace('khoảng', '')

                    let timeDistance = secondsToMinutes(
                        differenceInSeconds(now, callTime)
                    )

                    if (timeDistance >= 0 && timeDistance <= 30)
                        item.warning = true
                    else item.warning = false

                    item.renderStatus = renderStatus(item)
                    return item
                })
                _setOptions(formattedData)
            } else {
                _setOptions(histories)
            }
        }
    }, [histories, i18n.language])

    useEffect(() => {
        if (externalOptions && externalOptions.length > 0) {
            _setOptions(externalOptions)
            _setSelectedOptions(externalOptions[0])
            _searchPlaceDetailWhenSelected(externalOptions[0])
        }
    }, [externalOptions])

    const _handleOnFocus = () => {
        dispatch(actions.tripInfo.setFocusingEl(keyFlow))
    }

    const _handleInputChange = (e, value, reason) => {
        if (flagQuery == false) {
            _setInputValue(value)
            if (name === 'note') {
                dispatch(actions.tripInfo.setNote(value))
            }
        } else {
            if (reason === 'input' && !!value) {
                _setInputValue(value)
                if (debounceTimer) clearTimeout(debounceTimer)
                debounceTimer = setTimeout(() => {
                    _setDoCallApi(true)
                    _setLoading(true)
                }, debounce)

                if (_selectedOptions) {
                    // dispatch(
                    //     actions.tripInfo.setAddressField(name, {
                    //         ..._selectedOptions,
                    //         description: value,
                    //     })
                    // )
                }
            } else _setInputValue(value)
        }
    }
    const _handleSelectOption = async (e, value, reason, detail) => {
        if (reason === 'clear') {
            _setSelectedOptions(value)
            dispatch(actions.tripInfo.setAddressField(name, null))
        } else {
            if (reason === 'selectOption') {
                if (value.descriptionTime && value.start_address == null) {
                    _setSelectedOptions(null)
                    _setInputValue('')
                    return enqueueSnackbar(
                        `Điểm đón không được để trống, vui lòng chọn lại`,
                        {
                            variant: 'warning',
                        }
                    )
                } else {
                    _setSelectedOptions(value)
                    _searchPlaceDetailWhenSelected(detail.option)
                }
            }
        }
    }

    const _handleOnCloseOptions = (_, reason) => {
        // if (reason === 'selectOption' && setFocusEl)
        // setFocusEl(keyboardFlow.createTripBtn)
    }

    const _handleKeyDown = e => {
        if (e.key === TAB) {
            if (
                _highlightingOption &&
                Object.keys(_highlightingOption).length > 0
            ) {
                _setSelectedOptions(_highlightingOption)
                _searchPlaceDetailWhenSelected(_highlightingOption)
            }
        }
        if (e.key === ENTER) {
            if (phoneNumber?.length >= 10)
                dispatch(
                    actions.tripInfo.setFocusingEl(keyboardFlow.createTripBtn)
                )
            else
                dispatch(
                    actions.tripInfo.setFocusingEl(keyboardFlow.phoneNumber)
                )
        }
        if (e.ctrlKey && e.altKey && !e.repeat) {
            _searchType === searchTypes.elastic ? _setSearchType(searchTypes.google) : _setSearchType(searchTypes.elastic)
            _setDoCallApi(true)
        }
        if (e.ctrlKey && e.metaKey) {
            _searchType === searchTypes.elastic ? _setSearchType(searchTypes.google) : _setSearchType(searchTypes.elastic)
            _setDoCallApi(true)
        }
    }

    const _searchPlaceDetailWhenSelected = async option => {
        let returnAddress = option
        if (!(option.emddiId && option.coords)) {
            if (option.type === searchTypes.database) {
                returnAddress = {
                    coords: {lat: option.lat, lng: option.lng},
                    description: option.description,
                }
            } else {
                if (option.coordinates) {
                    let arrTemp = option.coordinates.split(' ')
                    let coords = { lat: arrTemp[0], lng: arrTemp[1] }
                    returnAddress = {...returnAddress, coords: coords,}
                } else {
                    if (option.emddiId) {
                        const placeDetail = await placeDetailApi(returnAddress.emddiId)
                        if (placeDetail) {returnAddress = { ...returnAddress, ...placeDetail }}
                    } else {
                        if (option.start_address) {
                            const places = await searchPlaceApi(option.start_address)
                            if (places.length > 0) {
                                const placeDetail = await placeDetailApi(places[0].emddiId)
                                if (placeDetail) {returnAddress = {...returnAddress, ...placeDetail,}}
                            }
                        }
                    }
                }
            }

        }
        dispatch(actions.tripInfo.setAddressField(name, returnAddress))
        _setSelectedOptions(returnAddress)
    }

    return (
        <div className={classNames('AddressWrapper', className)}>
            {title && <label htmlFor={props.name}>{title}</label>}
            <ThemeProvider theme={theme}>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                        if (option.id) {
                            return option.id === value.id
                        }
                        if (option.emddiId) {
                            return option.emddiId === value.emddiId
                        }
                    }}
                    getOptionLabel={option => option.description || ''}
                    // getOptionDisabled={option =>
                    //     option.coordinates === null ||
                    //     option.start_address === null
                    // }
                    filterOptions={x => x}
                    options={_options}
                    loading={_loading}
                    value={_selectedOptions}
                    inputValue={_inputValue}
                    onInputChange={_handleInputChange}
                    onChange={_handleSelectOption}
                    onHighlightChange={(e, o) =>
                        (_highlightingOption = { ...o })
                    }
                    onFocus={_handleOnFocus}
                    onClose={_handleOnCloseOptions}
                    onKeyDown={_handleKeyDown}
                    clearOnBlur={false}
                    openOnFocus
                    selectOnFocus
                    autoHighlight
                    freeSolo
                    renderInput={params => (
                        <TextField
                            sx={{ padding: 0 }}
                            {...params}
                            placeholder={placeholder}
                            inputRef={_inputRef}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {_loading ? (
                                            <CircularProgress size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                                startAdornment: (
                                    name === 'note' ? <img src='assets/icons/n-edit.svg' /> : ''
                                )
                            }}
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            sx={{
                                minWidth: 'fit-content',
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                    renderOption={(props, option, state) => {
                        props.key = option.id || option.emddiId
                        return (
                            <li
                                {...props}
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                            >
                                <Tooltip
                                    //title={option.description}
                                    title=""
                                    placement="right"
                                    enterDelay={1000}
                                >
                                    <Typography
                                        noWrap
                                        sx={{
                                            fontSize: 14,
                                            maxWidth: 300,
                                            width: 250,
                                            marginRight: 1,
                                        }}
                                    >
                                        {option.description}
                                    </Typography>
                                </Tooltip>
                                {option.renderStatus && (
                                    <div
                                        style={{
                                            width: 180,
                                            fontWeight: 'normal',
                                            display: 'flex',
                                            justifyContent: '',
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: option.warning
                                                    ? 'red'
                                                    : 'black',
                                            }}
                                        >
                                            {option.descriptionTime}&nbsp;
                                        </span>
                                        {option.start_address &&
                                            option.renderStatus.statusString !==
                                                '------' && (
                                                <>
                                                    <span>|&nbsp;</span>
                                                    <span
                                                        style={{
                                                            color: option
                                                                .renderStatus
                                                                .color,
                                                        }}
                                                    >
                                                        {
                                                            option.renderStatus
                                                                .statusString
                                                        }
                                                        &nbsp;
                                                    </span>
                                                </>
                                            )}

                                        {option.vh_code && (
                                            <>
                                                <span>|&nbsp;</span>
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    {option.vh_code}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                )}
                            </li>
                        )
                    }}
                    {...props}
                />
            </ThemeProvider>
        </div>
    )
}

export default AddressField
