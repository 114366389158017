import { AddCircleOutlined, RemoveCircleOutlined } from '@mui/icons-material'
import {
    createTheme,
    IconButton,
    MenuItem,
    Select as SelectMui,
    TextField,
    ThemeProvider,
} from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE, TAB } from 'static/keyboard-code'
import { useNewBookingContext } from '../context'
import actions from '../context/actions'
import { keyboardFlow } from '../context/initialState'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    background: '#ffffff',
                    paddingLeft: '8px',
                    //borderRadius: '0.125rem',
                    // paddingTop: 0,
                    // paddingBottom: 0,
                    padding: '0px !important',
                    outline: 'none',
                    
                },
                notchedOutline: {
                    border: 'none',
                    outline: 'none'
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: '#868686',
                    right: 0,
                },
                nativeInput: {
                    
                },
                select: {
                    padding: '0px',
                    paddingLeft: '8px'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '34px !important',
                    // padding: '0px !important',
                    color: '#181918',
                    fontSize: '14px',
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: 'fit-content',
                    height: '34px !important',
                    border: 'none !important',
                    marginLeft: '0px !important',
                    borderRadius: '0px !important'
                },
                notchedOutline: {
                    border: 'none',
                    outline: 'none'
                }
            }
        }
    },
})

const Services = ({
    className,
    name,
    title,
    handleNumberOfService,
    ...props
}) => {
    const [
        {
            allService,
            defaultService,
            handlingTrip: {
                info: { services },
                metadata: { focusingEl, hasOnlyOneCoor },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const [_isMultiService, _setIsMultiService] = useState(
        () => services.length > 1
    )

    useEffect(() => {
        if (services.length > 1) _setIsMultiService(true)
        else _setIsMultiService(false)
    }, [services])

    useEffect(() => {
        let sumQuantity = services.reduce(
            (sum, service) => sum + service.quantity,
            0
        )
        if (sumQuantity > 1 && hasOnlyOneCoor)
            dispatch(actions.tripInfo.setOnlyOneCoor(false))
        else if (sumQuantity === 1 && !hasOnlyOneCoor)
            dispatch(actions.tripInfo.setOnlyOneCoor(true))
    }, [services])

    const _handleChangeService = (newValue, index, key) => {
        let changingItem = services[index]
        if (changingItem) {
            let newServices = [...services]
            changingItem[key] = newValue
            newServices[index] = changingItem
            dispatch(
                actions.tripInfo.setEstimateData({
                    estimated: false,
                })
            )
            dispatch(actions.tripInfo.setServices(newServices))
        }
    }

    const _handleNumberOfService = index => {
        _setIsMultiService(!_isMultiService)
        // handleNumberOfService(_isMultiService ? 'Remove' : 'Add')
        if (!_isMultiService) {
            let addService = { ...defaultService }
            let selectedService = services.reduce((arr, service) => {
                arr.push(service.service)
                return arr
            }, [])
            for (let i = 0; i < allService.length; i++) {
                if (!selectedService.includes(allService[i].service_id)) {
                    addService.service = allService[i].service_id
                    break
                }
            }
            dispatch(actions.tripInfo.setServices([...services, addService]))
        } else {
            const newServices = [...services]
            newServices.splice(index, 1)
            dispatch(actions.tripInfo.setServices(newServices))
        }
    }

    return (
        <div className={classNames('flex flex-col gap-1', className)}>
            <div className="flex justify-between">
                {title && (
                    <label
                        htmlFor={name}
                        style={{
                            width: props.labelWidth ? props.labelWidth : 'auto',
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            height: '16px',
                            marginTop: '1rem'
                        }}
                    >
                        {title}
                    </label>
                )}
            </div>
            <ThemeProvider theme={theme}>
                {services?.map((item, index) => (
                    <Service
                        options={allService}
                        data={item}
                        key={index}
                        index={index}
                        handleChange={_handleChangeService}
                        handleNumberOfService={_handleNumberOfService}
                        isMultiService={_isMultiService}
                    />
                ))}
            </ThemeProvider>
        </div>
    )
}

export default Services

const serviceModel = { serviceId: 'service', quantity: 'quantity' }

const Service = ({
    options,
    data,
    handleChange,
    index,
    isMultiService,
    handleNumberOfService,
}) => {
    const [_open, _setOpen] = useState(false)
    const [
        {
            handlingTrip: {
                info: { phoneNumber },
                metadata: { focusingEl },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const setFocusEl = () => {
        if (phoneNumber?.length >= 10) {
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.createTripBtn))
        } else {
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.phoneNumber))
        }
    }

    const _handleClick = e => {
        if (_open) {
            _setOpen(false)
            setFocusEl()
        } else {
            _setOpen(true)
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.selectSv))
        }
    }

    const _handleKeyDownService = e => {
        switch (e.key) {
            case TAB:
                _setOpen(!_open)
                // setFocusEl()
                break
            case ENTER:
                _setOpen(false)
                setFocusEl()
                break
            case ESCAPE:
                _setOpen(false)
                setFocusEl()
                break

            default:
                break
        }
    }

    const _handleChangeQuantity = e => {
        const { value } = e.target
        let reg = new RegExp(/^[1-3]{1}\b/)
        if (value.trim().match(reg)) {
            _submitQuantityChange(Number(value))
        }else _submitQuantityChange('')
    }

    const _onBlur = e => {
        if (e.target.value === '' || e.target.value === null) _submitQuantityChange(1)
    }

    const _handleKeyDownQuantity = e => {
        let currentValue = data[serviceModel.quantity]
        switch (e.key) {
            case ARROW_UP:
                e.preventDefault()
                _increaseQuantity()
                break
            case ARROW_DOWN:
                e.preventDefault()
                _decreaseQuantity()
                break
        }
    }

    const _handleScrollQuantity = e => {
        if (e.deltaY > 0) _decreaseQuantity()
        else if (e.deltaY < 0) _increaseQuantity()
    }

    const _increaseQuantity = () => {
        const currentValue = data[serviceModel.quantity]
        if (currentValue < 3) {
            _submitQuantityChange(Number(currentValue) + 1)
        }
    }

    const _decreaseQuantity = () => {
        const currentValue = data[serviceModel.quantity]
        if (currentValue > 1) {
            _submitQuantityChange(currentValue - 1)
        }
    }

    const _submitQuantityChange = value => {
        handleChange(value, index, serviceModel.quantity)
    }

    return (
        <div className="flex w-full justify-between services">
            <SelectMui
                open={_open}
                value={data[serviceModel.serviceId]}
                defaultValue={18}
                //disabled
                onChange={e =>
                    handleChange(e.target.value, index, serviceModel.serviceId)
                }
                onFocus={() => {
                    dispatch(
                        actions.tripInfo.setFocusingEl(keyboardFlow.selectSv)
                    )
                    _setOpen(true)
                }}
                onBlur={() => {
                    _setOpen(false)
                    setFocusEl()
                }}
                onClick={_handleClick}
                className="flex-grow"
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onKeyDown={_handleKeyDownService}
                        sx={{
                            fontSize: 14,
                            paddingLeft: 1.5,
                            paddingRight: 1,
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                        }}
                        value={option.service_id}
                    >
                        {option.vi_name}
                    </MenuItem>
                ))}
            </SelectMui>
            
            <div className='services-quantity'>
                <img src='assets/icons/sub-quantity.svg' onClick={() => _decreaseQuantity()}/>
                <input
                    className='quantity'
                    value={data[serviceModel.quantity]}
                    onChange={e => _handleChangeQuantity(e)}
                    onKeyDown={_handleKeyDownQuantity}
                    size="1" 
                    maxLength="1"
                    //onWheel={_handleScrollQuantity}
                    onBlur={_onBlur}
                />
                
                <img src='assets/icons/add-quantity.svg' onClick={() => _increaseQuantity()}/>
                <span 
                    style={{
                        color:isMultiService ? 'red' : '#219737', 
                        fontSize:'14px', 
                        fontWeight:'500', 
                        width:'80px',
                        cursor: 'pointer',
                        paddingLeft: '10px'
                    }}
                    onClick={() => handleNumberOfService(index)}
                >
                    {isMultiService ? 'Xóa' : 'Thêm'}
                </span>
            </div>
        </div>
    )
}
