const sendPhoneNumber = (state, payload) => {
    return {
        dataPhoneNumber: payload,
    }
}

const PhoneNumberReducers = {
    sendPhoneNumber,
}

export default PhoneNumberReducers
