import { EmddiMaterialIcon } from 'app/_shared/EmddiIcon/components'
import { isPreviewTripsOpenState } from 'app/_shared/recoil/preview-trips/atom'
import { useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import FilterTrip from './FilterTrip'
import GridPreviewTrips from './GridPreviewTrips'
//import Trips from './Trips'

const PreviewTrips = props => {
    const [isPreviewTripsOpen, setIsPreviewTripsOpen] = useRecoilState(
        isPreviewTripsOpenState
    )

    const [_queryCar, _setQueryCar] = useState('')

    let typingTimeout = useRef(null)

    const _onToggle = () => {
        setIsPreviewTripsOpen(!isPreviewTripsOpen)
    }

    const _onQueryCarChange = e => {
        if (typingTimeout.current) clearTimeout(typingTimeout)
        typingTimeout.current = setTimeout(() => {
            _setQueryCar(e.target.value)
        }, 500)
    }

    //console.log('preview trips rendered...')

    return (
        <div className="PreviewTrips">
            <h1>Danh sách rút gọn</h1>
            <FilterTrip />
            <div style={{display: 'flex'}}>
                <img src='assets/icons/icon_search.svg' />
                <input
                    className="SearchInput"
                    placeholder="Tìm kiếm"
                    onChange={_onQueryCarChange}
                />
            </div>
            <button onClick={_onToggle} className="ToggleBtn">
                <EmddiMaterialIcon
                    name={
                        isPreviewTripsOpen ? 'MdChevronRight' : 'MdChevronLeft'
                    }
                    className="text-2xl"
                />
            </button>
            {/* <Trips 
                queryCar={_queryCar} 
            /> */}
            <GridPreviewTrips />
        </div>
    )
}

export default PreviewTrips
