import classNames from 'classnames'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { dateNow } from 'utils/time'
import './scss/_DatePicker.scss'

const pickerStyles = {
    fromDate: {
        backgroundColor: '#edab25',
    },
    betweenDates: {
        backgroundColor: '#ffdc5a69',
    },
    toDate: {
        backgroundColor: '#edab25',
    },
    customRangeButtons: {
        color: 'white',
        backgroundColor: '#424242',
        border: '1px solid transparent',
    },
    border: '1px transparent',
    customRangeSelected: { color: 'black', backgroundColor: '#edab25' },
}

const DatePicker = props => {
    const { className, ...inputProps } = props

    const [_startDate, _setStartDate] = useState(
        moment(dateNow().toISOString().slice(0, -13) + '06:00:00')
    )
    const [_endDate, _setEndDate] = useState(
        moment(_startDate).add(1, 'days').subtract(1, 'seconds')
    )

    let _ranges = useMemo(() => {
        return {
            'Today Only': [moment(_startDate), moment(_endDate)],
            'Yesterday Only': [
                moment(_startDate).subtract(1, 'days'),
                moment(_endDate).subtract(1, 'days'),
            ],
            '3 Days': [
                moment(_startDate).subtract(2, 'days'),
                moment(_endDate),
            ],
            '1 Week': [
                moment(_startDate).subtract(7, 'days'),
                moment(_endDate),
            ],
            '2 Weeks': [
                moment(_startDate).subtract(14, 'days'),
                moment(_endDate),
            ],
            '1 Month': [
                moment(_startDate).subtract(1, 'months'),
                moment(_endDate),
            ],
        }
        //eslint-disable-next-line
    }, [])
    let _local = useMemo(() => {
        return {
            format: 'DD-MM-YYYY HH:mm',
            sundayFirst: false,
        }
    }, [])
    let _maxDate = useMemo(() => {
        return moment(dateNow().toISOString().slice(0, -13) + '00:00:00')
            .add(1, 'days')
            .subtract(1, 'seconds')
    }, [])

    const applyCallback = (start, end) => {
        _setStartDate(start)
        _setEndDate(end)
        props.onChange(start, end)
    }

    return (
        <div className="DatePickerWrapper">
            <DateTimeRangeContainer
                years={[2015, 2099]}
                ranges={_ranges}
                start={_startDate}
                end={_endDate}
                local={_local}
                maxDate={_maxDate}
                applyCallback={applyCallback}
                style={pickerStyles}
                darkMode
                //disabled
            >
                <input
                    className={classNames('DatePickerInput', className)}
                    type="text"
                    placeholder="From date - To date"
                    value={`${_startDate.format(
                        'DD-MM-YYYY HH:mm'
                    )} - ${_endDate.format('DD-MM-YYYY HH:mm')}`}
                    onChange={() => {}}
                    readOnly
                    {...inputProps}
                />
            </DateTimeRangeContainer>
        </div>
    )
}

export default DatePicker
