import Select from 'app/_shared/components/Select'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { useViewContext } from 'app/_shared/view-context/ViewContext'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useRecoilState } from 'recoil'
import { useNewBookingContext } from '../context'
import actions from '../context/actions'
import { keyboardFlow } from '../context/initialState'
import { addressFieldNames } from '../context/reducers/tripInfoReducers'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'
import ActionButtons from './ActionButtons'
import AddressField from './AddressField'
import CustomerInfo from './CustomerInfo'
import DispatchDriver from './DispatchDriver'
import EstimateInfo from './EstimateInfo'
import PickupAddress from './PickupAddress'
import PickupTimeField from './PickupTimeField'
import Promotion from './Promotion'
import Services from './Services'

//import UserContainerContext from 'app/_shared/user-container-context'

//const { useUserContainerContext, UserContainerActions } = UserContainerContext

const BookingInfo = props => {
    const { processCall } = props

    // console.log('process call: ')

    const t = useTrans()

    const [{ usingSwitchboard }] = useViewContext()
    const [
        {
            allService,
            defaultService,
            handlingTrip: {
                info: {
                    phoneNumber,
                    address: { pickup, destination },
                },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    // console.log('booking info rerender...')

    const defaultTripInfo = useMemo(() => ({ phoneNumber: '' }), [])

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    const [_tripInfo, _setTripInfo] = useState(defaultTripInfo)

    const [_userPreviousAddress, _setUserPreviousAddress] = useState([])

    useEffect(() => {
        if (!processCall) return
        dispatch(actions.tripInfo.setPhoneNumber(processCall.phoneNumber))
        const prevAddressAsOptions = processCall.coordinators.map(trip => {
            return {
                ...trip,
                description: trip.start_address || trip.created_at,
            }
        })
        _setUserPreviousAddress(prevAddressAsOptions)
        dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.pickupAdd))
    }, [processCall])

    // set customer history trip
    useEffect(() => {
        if (isNewBookingOpen) {
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.pickupAdd))
            if (processCall) {
                dispatch(
                    actions.tripInfo.setPhoneNumber(processCall.phoneNumber)
                )
                const prevAddressAsOptions = processCall.coordinators.map(
                    trip => {
                        return {
                            ...trip,
                            description: trip.start_address || trip.created_at,
                        }
                    }
                )
                _setUserPreviousAddress(prevAddressAsOptions)
            } else {
                dispatch(
                    actions.tripInfo.setFocusingEl(keyboardFlow.phoneNumber)
                )
            }
        } else {
            dispatch(actions.tripInfo.setHandlingTripInfoToDefault())
            dispatch(actions.tripInfo.setServices([defaultService]))
            _setUserPreviousAddress([])
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.none))
        }
    }, [isNewBookingOpen])

    const _setHistories = locations => {
        // console.log('set history...')
        // console.log(locations)
        
        if (locations.length > 0)_setUserPreviousAddress(locations)

        dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.pickupAdd))
    }

    return (
        <div
            className={
                usingSwitchboard ? 'BookingInfo Switchboard' : 'BookingInfo'
            }
        >
            <form className='FormBooking' autoComplete='off'>
                <div className='Heading'>
                    {t(newBookingLocaleKeys.title)}
                </div>
                <div className='Content'>
                    <CustomerInfo
                        marketingId={processCall?.marketingId}
                        customerName={processCall?.customerName}
                        setHistories={_setHistories}
                    />
                    <div className='address'>
                        <div className='start-point'>
                            <img src='assets/icons/markers/marker_start.svg' />
                            <PickupAddress
                                histories={_userPreviousAddress}
                            />
                        </div>
                        <div className='end-point'>
                            <img src='assets/icons/markers/marker_end.svg' />
                            <AddressField
                                name={addressFieldNames.destination}
                                debounce={500}
                                keyFlow={keyboardFlow.destinationAdd}
                                placeholder={t(
                                    newBookingLocaleKeys.destinationAddressPlaceholder
                                )}
                            />
                        </div>
                    </div>
                    <AddressField
                        className="note"
                        title={t(newBookingLocaleKeys.noteField)}
                        name={addressFieldNames.note}
                        debounce={500}
                        keyFlow={keyboardFlow.note}
                        placeholder={t(
                            newBookingLocaleKeys.noteField
                        )}
                        flagQuery={false}
                    />
                    <Services
                        title={t(newBookingLocaleKeys.serviceField)}
                        name="service"
                    />

                    <PickupTimeField
                        title={t(
                            newBookingLocaleKeys.pickupTimeField
                        )}
                        name="pickupTime"
                        onChange={time => {
                        }}
                    />

                    <DispatchDriver />
                    <div className='customer-payment'>
                        <div className='group-button customer'>
                            <div className='title'>
                                <img src= 'assets/icons/customer.svg'/>
                                <span>Khách hàng</span>
                            </div>
                            <div className='btn-content'>
                                <button onClick={e => e.preventDefault()}>Cá nhân</button>
                                <button onClick={e => e.preventDefault()}>Công ty</button>
                            </div>
                        </div>
                        <div className='group-button payment'>
                            <div className='title'>
                                <span>Thanh toán</span>
                            </div>
                            <div className='btn-content'>
                                <button onClick={e => e.preventDefault()}>Tiền mặt</button>
                                <button onClick={e => e.preventDefault()}>Thẻ</button>
                            </div>
                        </div>
                    </div>
                    <div className='coupon'>
                        <div className='title'>
                            <img src='assets/icons/coupon.svg'/>
                            <span>Tip-Khuyến mại</span>
                        </div>
                        <div className='content'>
                            <div className='left-content'>
                                <input placeholder='Số lượng'/>
                                <span>%</span>
                            </div>
                            <div className='right-content'>
                                <input placeholder='Mã khuyến mại'/>
                                <span>Áp dụng</span>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="">
                        {t(newBookingLocaleKeys.tipPromoHeader)}
                    </div>
                        <Promotion />
                    <div className="">
                        {t(newBookingLocaleKeys.paymentHeader)}
                    </div>
                    <Select
                        name="payment"
                        defaultValue="1"
                        options={[
                            { name: 'Cash', value: '1' },
                            { name: 'Card', value: '2' },
                        ]}
                    />
                    */}
                            
                    <EstimateInfo usingSwitchboard={usingSwitchboard}/> 

                    <ActionButtons
                        processCall={processCall}
                        removePhoneNumber={props.removePhoneNumber}
                    />
                </div>
            </form>
        </div>
    )
}

BookingInfo.propTypes = {}

export default BookingInfo
