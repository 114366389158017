import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useContext, useEffect, useState } from 'react'
import coordinators from '../data'
import { bookingStatusColor, bookingStatusName } from 'utils/Booking'
import useTrips from 'app/_shared/trips-context'
import ViewContext from 'app/_shared/view-context'
import { DocumentScanner } from '@mui/icons-material'
import { BOOKING_FINISHED_PAGE_SIZE } from '../constant'
import { useRef } from 'react'
import { resolveConfig } from 'prettier'
import { localStorageKeys, getLocalStorage } from 'services/localStorage'

const { useViewContext, ViewAction, useViewDispatch } = ViewContext

//init services
const listServices = getLocalStorage(localStorageKeys.services) ? getLocalStorage(localStorageKeys.services) : [];
const arrServices = listServices.map((item, index) => {
    let service = {
        id: item.service_id,
        name: item.vi_name,
        checked: true
    }
    return service
})

const GridBookingFinished = () => {
    const [gridApi, setGridApi] = useState(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)
    const [gridCellApi, setGridCellApi] = useState(null)

    const [finishedTrips, setFinishedTrips] = useState([])

    const [state, actions] = useTrips()

    const [{ usingSwitchboard }] = useViewContext()

    const viewDispatch = useViewDispatch()
    
    const [flagLoadMoreData, setFlagLoadMoreData] = useState(false)

    useEffect(() => {
        setFinishedTrips([...state.tripsFinished])
        countTimesScrollRef.current = 0
    }, [state.tripsFinished])

    const onGridReady = params => {
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
        setGridCellApi(params.cellApi)
    }

    const onBtStopEditing = () => {
        gridApi.stopEditing()
    }

    const onBtStartEditing = (key, char, pinned) => {
        gridApi.setFocusedCell(1, 'lastName', pinned)
        gridApi.startEditingCell({
            rowIndex: 1,
            colKey: 'lastName',
            rowPinned: pinned,
            keyPress: key,
            charPress: char,
        })
    }

    const onBtNextCell = () => {
        gridApi.tabToNextCell()
    }

    const onBtPreviousCell = () => {
        gridApi.tabToPreviousCell()
    }

    const onCellKeyDown = e => {
        let colId = e.column.colId

        if (e.event.code === 'ArrowUp') {
            let rowTopIndex = e.node.rowIndex - 1

            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowTopIndex, colId)
                gridApi.startEditingCell({
                    rowIndex: rowTopIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            } else {
            }
        } else if (e.event.code === 'ArrowDown') {
            let rowDownIndex = e.node.rowIndex + 1
            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowDownIndex, colId)

                gridApi.startEditingCell({
                    rowIndex: rowDownIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            }
        } else if (e.event.code === 'ArrowRight') {
        } else if (e.event.code === 'ArrowLeft') {
        }
    }

    const onCellKeyPress = e => {
        var keyPressed = e.event.key
        if (keyPressed === 's') {
            var rowNode = e.node
            var newSelection = !rowNode.selected
            rowNode.setSelected(newSelection)
        }
    }
    const cellEditingStarted = e => {}

    const colDefs = [
        {
            headerName: '',
        },
    ]

    const renderBookingId = params => {
        return (
            '<p style=";font-size:14px;font-weight:600;color: #219737">' +
                params.value +
            '</p>' +
            '<p style="font-weight:400;color:#616161;font-size:12px;letter-spacing:.3px">' +
                params.data.created_at +
            '</p>' 
        )
    }

    const convertPhoneNumber = phone => {
        if (!phone) return ''
        return phone.substring(0, 4) + '.' + phone.substring(4, 7) + '.' + phone.substring(7);
    }

    const renderCustomerPhone = params => {
        if (
            params.data.customer_name === 'Khách tổng đài' ||
            params.data.customer_name === 'Khách Tổng Đài'
        ) {
            return (
                '<p style="font-weight:400;color:#616161;font-size:14px;letter-spacing:.3px">' +
                    convertPhoneNumber(params.value) +
                '</p>' 
            )
        } else {
            if (params.value !== null && params.value !== '') {
                if (usingSwitchboard == false) {
                    return (
                        '<p style="font-weight:500;color:#282828;font-size:14px">' +
                            params.data.customer_name +
                        '</p>' +
                        '<p style="font-weight:400;color:#616161;font-size:14px;letter-spacing:.3px">' +
                            convertPhoneNumber(params.value) +
                        '</p>' 
                    )
                }else {
                    return (
                        '<span style="font-style:;font-weight:normal">' +
                            params.value +
                        '</span>'
                    )
                }
            } else {
                return (
                    '<span style="font-style:;font-weight:normal">' +
                        params.data.customer_name +
                    '</span>'
                )
            }
        }
    }

    const createReasonCancelled = (reasonCancelled, element) => {
        if (reasonCancelled !== null && reasonCancelled !== '') {
            let pCancelled = document.createElement('p')
            pCancelled.classList.add('p-reason-cancelled')
            pCancelled.appendChild(document.createTextNode(reasonCancelled))
            element.appendChild(pCancelled)
            if (reasonCancelled.length >= 20) {
                const linkDetail2 = document.createElement('a')
                linkDetail2.appendChild(document.createTextNode('Xem'))
                linkDetail2.classList.add('a-reason-cancelled')
                pCancelled.appendChild(linkDetail2)
                const pReasonContainer = document.createElement('p')
                pReasonContainer.classList.add('p-reason-cancelled-container')
                pReasonContainer.append(pCancelled, linkDetail2)
                element.append(pReasonContainer)
            }else element.appendChild(pCancelled)
        }
    }

    const renderStatus = params => {// render statrus

        const element = document.createElement('div')
        element.classList.add('parent-icon-status')
        const imageElement = document.createElement('img')
        const textElenment = document.createElement('span')
        element.appendChild(imageElement);
        element.appendChild(textElenment);

        let status = params.data.status

        let statusBooking = params.data.status_booking

        let cancel = params.data.cancel

        let element2 = document.createElement('div')
        element2.classList.add('div-status')

        if (params.data.type == 10 || params.data.type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        return '<span style="color:#f5a623">Đang đi đón khách</span>'
                    } else if (cancel == 1) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));

                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                        //return '<span style="color:">Khách hàng hủy chuyến</span>'
                    } else if (cancel == 2) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));
                        
                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                        //return '<span style="color:red" class="DriverCancelBlink">Lái xe hủy chuyến</span>'
                    } else if (cancel == 3) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));
                        
                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                        //return '<span style="color:">Tổng đài hủy chuyến</span>'
                    } else {
                        return `<span style="color:${
                            bookingStatusColor[bookingStatusName.dispatching]
                        }">Đang tìm xe</span>`
                    }
                case 1:
                    return '<span style="color:#00759A">Đang chở khách</span>'
                case 2:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    imageElement.classList.add('finished-booking');
                    textElenment.appendChild(document.createTextNode('Hoàn thành'));

                    element2.appendChild(element)
                    return element2;
                case 3:
                    return '<span style="color:orange">Đã thấy xe</span>'
                case -1:
                    return '<span style="color:red">Chưa có xe nhận đón</span>'
                default:
                    return '<span>---</span>'
            }
        } else if (params.data.type == 0) {
            switch (status) {
                case 0:
                    // return `<span style="color:${
                    //     bookingStatusColor[bookingStatusName.dispatching]
                    // }">Đang tìm xe</span>`
                case 1:
                    if (statusBooking == 3) {
                        //return '<span style="color:orange">Đã thấy xe<span>'
                    } else {
                        switch (statusBooking) {
                            case 0:
                                //return '<span style="color:#f5a623">Đang đi đón khách<span>'
                            case 1:
                                //return '<span style="color:#00759A">Đang chở khách</span>'
                            case 2:
                                imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                                imageElement.classList.add('finished-booking');
                                textElenment.appendChild(document.createTextNode('Hoàn thành'));

                                element2.appendChild(element)
                                return element2;
                            case -3:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));
                                
                                element2.appendChild(element)
                                createReasonCancelled(params.data.reason_cancel, element2)
                                return element2;
                            case -4:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));
                                
                                element2.appendChild(element)
                                createReasonCancelled(params.data.reason_cancel, element2)
                                return element2;
                            case -5:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));

                                element2.appendChild(element)
                                createReasonCancelled(params.data.reason_cancel, element2)
                                return element2;
                            default:
                                return '<span>---</span>'
                        }
                    }
                case -1:
                    imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                    textElenment.appendChild(document.createTextNode('Chưa có xe nhận'));

                    element2.appendChild(element)
                    return element2;
                case -2:
                    imageElement.src = 'assets/icons/Loi.svg';
                    textElenment.appendChild(document.createTextNode('Lỗi'));

                    element2.appendChild(element)
                    return element2;
                case -3:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2;
                case -4:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2;
                case -5:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2;
                default:
                    //return '<span>---</span>'
            }
        } else if (params.data.type == 1) {
            let s = ''
            if (
                statusBooking == 3 ||
                statusBooking == 4 ||
                statusBooking == 5
            ) {
                s = '<span style = "color: green;">---</span>'
                return s
            } else {
                switch (parseInt(status)) {
                    case 1:
                        if (params.data.driver_id != '') {
                            s = '<span style="color:blue;">Hoàn thành</span>'
                        } else {
                            s = '<span style="color:green;">---</span>'
                        }
                        break
                    case 0:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -1:
                        s = '<span style="color:red;">Không thấy xe</span>'
                        break
                    case -2:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -3:
                        s = '<span style="color:red;">Quản trị hủy</span>'
                        break
                    case -4:
                        s =
                            '<span class = "DriverCancelBlink" style="color:red;">Lái xe hủy chuyến</span>'
                        break
                    case 5:
                        s =
                            '<span style="color:red;">Đã X.Lý, Không Đ.Xe</span>'
                        break
                    case -5:
                        s = '<span style="color:red;">Khách hủy</span>'
                        break
                    case 6:
                        s = '<span style="color:red;">Khách hẹn</span>'
                        break
                    default:
                        s = '<span style="color:green;">---</span>'
                        break
                }

                if (params.data.cmd_sb == 3 || params.data.cmd_cd == 'k') {
                    return '<span style="color:red;">Không thấy xe</span>'
                }
                return s
            }

            return element
        } else if (params.data.type == 8) {
            imageElement.src = 'assets/icons/booking_reservation.svg';
            textElenment.appendChild(document.createTextNode('Đặt hẹn'));

            element2.appendChild(element)
            if (params.data.status == -3) createReasonCancelled('TĐ hủy', element2)
            return element2
        }
    }

    const renderStartAddress = params => {
        const element = document.createElement('div')
        element.classList.add('parent-div-address')

        const divStartAddress = document.createElement('div')
        divStartAddress.classList.add('div-address')
        const imgStart = document.createElement('img')
        imgStart.src = "assets/icons/markers/marker_start.svg"
        const textStart = document.createElement('span')
        textStart.appendChild(document.createTextNode(params.value))
        divStartAddress.appendChild(imgStart)
        divStartAddress.appendChild(textStart)
        
        if (params.value.length >= 51) {
            const linkDetail = document.createElement('a')
            linkDetail.appendChild(document.createTextNode('Chi tiết'))
            divStartAddress.appendChild(linkDetail)
        }

        element.appendChild(divStartAddress)
        
        if (usingSwitchboard == false) {
            if (params.value != null && params.value != '') {
                if (
                    params.data.end_address != null &&
                    params.data.end_address !== ''
                ) {
                    const divEndAddress = document.createElement('div')
                    divEndAddress.classList.add('div-address')
                    const imgEnd = document.createElement('img')
                    imgEnd.src = "assets/icons/markers/marker_end.svg"
                    const textEnd = document.createElement('span')
                    textEnd.appendChild(document.createTextNode(params.data.end_address))
                    divEndAddress.appendChild(imgEnd)
                    divEndAddress.appendChild(textEnd)

                    if (params.data.end_address.length >= 51) {
                        const linkDetail2 = document.createElement('a')
                        linkDetail2.appendChild(document.createTextNode('Chi tiết'))
                        divEndAddress.appendChild(linkDetail2)
                    }

                    element.appendChild(divEndAddress)
                }
            } else {
                return ''
            }
            return element
        } else {
            return params.value
        }
    }

    const renderDriverPhone = params => {
        if (params.value && params.value !== null && params.value !== '') {
            let arrTemp = params.value.split('-')
            if (arrTemp.length == 2) {
                return arrTemp[0] + '</br>' + arrTemp[1]
            }
        }
        return ''
    }

    const renderCreatedAt = params => {
        if (params.value.indexOf(' ') > -1) {
            let arrTemp = params.value.split(' ')
            return arrTemp[0] + '</br>' + arrTemp[1]
        } else {
            return params.value
        }
    }

    const renderOperator = userId => {
        let operator = state.users.filter(user => user.id == userId)
        if (operator.length == 1) return operator[0].name
        else return ''
    }

    const renderService = params => {
        // render service function
        if (usingSwitchboard == false) {
            if (params.value != null && params.value !== '') {
                let divService = document.createElement('div')
                let serviceName = document.createElement('p')
                serviceName.classList.add('service-name')
                divService.appendChild(serviceName)

                if (params.value.toString().indexOf(';') > -1) {
                    let arrTemp = params.value.split(';')
                    if (arrTemp.length == 3) {
                        let service = arrServices.find(
                            sr => sr.id == parseInt(arrTemp[0].trim())
                        )
                        if (service) {
                            serviceName.appendChild(document.createTextNode(service.name))
                            let carInfo = document.createElement('p')
                            carInfo.classList.add('car-info')
                            divService.appendChild(carInfo)
                            carInfo.appendChild(document.createTextNode(arrTemp[1] + ' (' + arrTemp[2] +')'))
                            if (params.data.driver_phone_render) {
                                let arrStr = params.data.driver_phone_render.split('-')
                                let driverName = document.createElement('p')
                                driverName.classList.add('driver-name')
                                driverName.appendChild(document.createTextNode(arrStr[0]))
                                divService.appendChild(driverName)
                                let driverPhone = document.createElement('p')
                                driverPhone.classList.add('driver-phone')
                                driverPhone.appendChild(document.createTextNode(arrStr[1]))
                                divService.appendChild(driverPhone)
                            }
                            return divService
                        }
                    }
                }else {
                    let service = arrServices.filter(
                        service => service.id == params.value
                    )
                    if (service.length > 0) serviceName.appendChild(document.createTextNode(service[0].name))
                    return divService
                }
            }
        } else {
            if (params.value != null && params.value !== '') {
                let serviceRender = ''
                if (params.value.toString().indexOf(';') > -1) {
                    let arrTemp = params.value.split(';')
                    if (arrTemp.length == 3) {
                        let service = arrServices.find(
                            sr => sr.id == parseInt(arrTemp[0].trim())
                        )
                        if (service) serviceRender = service.name
                    }
                    return serviceRender;
                }
                let service = arrServices.filter(
                    service => service.id == params.value
                )
                if (service.length == 1) serviceRender = service[0].name
                return serviceRender
            }
        }
    }

    const renderIconStatus = params => {
        let status = params.value
        let statusBooking = params.data.status_booking
        switch (status) {
            case 0:
                return `<div style="width:15px;height:15px;background-color:${
                    bookingStatusColor[bookingStatusName.dispatching]
                };border-radius:4px"></div>`
            case 1:
                if (statusBooking == 3) {
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DriverAccepted]
                    };border-radius:4px"></div>`
                } else {
                    switch (statusBooking) {
                        case 0:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[
                                    bookingStatusName.DriverOnTheWay
                                ]
                            };border-radius:4px"></div>`
                        case 1:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[
                                    bookingStatusName.PassengerOnBoard
                                ]
                            };border-radius:4px"></div>`
                        case 2:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[bookingStatusName.finished]
                            };border-radius:4px"></div>`
                        case -3:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[bookingStatusName.AddCancel]
                            };border-radius:4px"></div>`
                        case -4:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[
                                    bookingStatusName.DriverCancel
                                ]
                            };border-radius:4px"></div>`
                        case -5:
                            return `<div style="width:15px;height:15px;background-color:${
                                bookingStatusColor[
                                    bookingStatusName.CustomerCancel
                                ]
                            };border-radius:4px"></div>`
                        default:
                            return `<div style="width:15px;height:15px;background-color:black;border-radius:4px"></div>`
                    }
                }
            case -1:
                return `<div style="width:15px;height:15px;background-color:${
                    bookingStatusColor[bookingStatusName.waitingForProcess]
                };border-radius:4px"></div>`
            case -2:
                return `<div style="width:15px;height:15px;background-color:red;border-radius:4px"></div>`
            case -3:
                return `<div style="width:15px;height:15px;background-color:${
                    bookingStatusColor[bookingStatusName.AddCancel]
                };border-radius:4px"></div>`
            case -4:
                return `<div style="width:15px;height:15px;background-color:${
                    bookingStatusColor[bookingStatusName.DriverCancel]
                };border-radius:4px"></div>`
            case -5:
                return `<div style="width:15px;height:15px;background-color:${
                    bookingStatusColor[bookingStatusName.CustomerCancel]
                };border-radius:4px"></div>`
            default:
                return `<div style="width:15px;height:15px;background-color:black;border-radius:4px"></div>`
        }
    }

    const renderBookingFrom = params => {
        switch (params.data.type) {
            case 10:
                return 'App KH'
            case 11:
                return 'Khách vẫy'
            default:
                return 'Tổng đài'
        }
    }

    const convertTotalPrice = totalPrice => {
        totalPrice = totalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.')
        totalPrice = totalPrice.substring(0, totalPrice.length - 3)
        return totalPrice
    }

    const renderTotalPrice = params => {
        if (params.data.total_price) {
            let divPrice = document.createElement('div')
            let pTotalPrice = document.createElement('p')
            pTotalPrice.appendChild(document.createTextNode(convertTotalPrice(params.data.total_price) + ' '))
            let uPrice = document.createElement('u')
            uPrice.appendChild(document.createTextNode('đ'))
            pTotalPrice.appendChild(uPrice)
            pTotalPrice.classList.add('p-total-price')
            divPrice.appendChild(pTotalPrice)
            if (params.data.distance >= 0) {
                let pDistance = document.createElement('p')
                pDistance.appendChild(document.createTextNode(params.data.distance + ' km'))
                pDistance.classList.add('p-distance')
                divPrice.appendChild(pDistance)
            }
            return divPrice
        }
        return ''
    }

    const renderOtherInfo = params => {
        let divBookingMode = document.createElement('div')
        let lbBookingMode = document.createElement('label')
        lbBookingMode.appendChild(document.createTextNode('Điều xe: '))
        lbBookingMode.classList.add('label-other-info')
        let bookingMode = document.createElement('span')
        bookingMode.appendChild(document.createTextNode(params.data.check_sb == 0 ? 'Tự động' : 'Chỉ định'))
        bookingMode.classList.add('content-other-info')
        divBookingMode.appendChild(lbBookingMode)
        divBookingMode.appendChild(bookingMode)

        let divBookinFrom = document.createElement('div')
        let lbBookingFrom = document.createElement('label')
        lbBookingFrom.appendChild(document.createTextNode('Nguồn: '))
        lbBookingFrom.classList.add('label-other-info')
        let bookingFrom = document.createElement('span')
        bookingFrom.appendChild(document.createTextNode(params.data.check_sb == 0 ? 'App khách hàng' : 'khách tổng đài'))
        bookingFrom.classList.add('content-other-info')
        divBookinFrom.appendChild(lbBookingFrom)
        divBookinFrom.appendChild(bookingFrom)

        let divOperator = document.createElement('div')
        let lbOperator = document.createElement('label')
        lbOperator.appendChild(document.createTextNode('Người điều: '))
        lbOperator.classList.add('label-other-info')
        let operator = document.createElement('span')
        operator.appendChild(document.createTextNode(renderOperator(params.data.user_id)))
        operator.classList.add('content-other-info')
        divOperator.appendChild(lbOperator)
        divOperator.appendChild(operator)

        let divOtherInfo = document.createElement('div')
        divOtherInfo.append(divBookingMode, divBookinFrom, divOperator)
        return divOtherInfo
    }

    const renderNote = params => {
        if (params.value != null && params.value !== '') {
            let divNote = document.createElement('div')
            divNote.classList.add('div-note')
            let pNote = document.createElement('p');
            pNote.classList.add('note-content')
            pNote.appendChild(document.createTextNode(params.value))
            divNote.appendChild(pNote)
            if (params.value.length >= 113) {
                let linkDetail = document.createElement('a')
                linkDetail.appendChild(document.createTextNode('Chi tiết'))
                linkDetail.classList.add('link-detail-note')
                divNote.appendChild(linkDetail)
            }
            return divNote
        }else return ''
    }

    const countTimesScrollRef = useRef(0)
    const endOfRecordsRef = useRef(false)
    return (
        <div className="grid-wrapper">
            <div
                id="myGridFinished"
                style={{
                    height: '100%',
                    width: '100%',
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    rowData={finishedTrips}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 30,
                        //editable: true,
                        resizable: true,
                        wrapText: true,
                        autoHeight: true,
                    }}
                    onGridReady={onGridReady}
                    onCellKeyDown={onCellKeyDown}
                    onCellKeyPress={onCellKeyPress}
                    cellEditingStarted={cellEditingStarted}
                    enableCellTextSelection={true}
                    getRowNodeId={data => data.id}
                    onBodyScroll={async function (params) {
                        const grid = document.getElementById('myGridFinished')
                        const gridBody = grid.querySelector('.ag-body-viewport')
                        const scrollPos = gridBody.offsetHeight + params.top
                        const scrollDiff = gridBody.scrollHeight - scrollPos

                        if (
                            scrollDiff == 0 &&
                            params.top != -1 &&
                            !endOfRecordsRef.current
                        ) {
                            setFlagLoadMoreData(true)

                            countTimesScrollRef.current += 1

                            let trips = await actions.getTripsFinished(
                                {
                                    ...state.filterFormFinished,
                                    start:
                                        BOOKING_FINISHED_PAGE_SIZE *
                                            countTimesScrollRef.current +
                                        1,
                                    limit: BOOKING_FINISHED_PAGE_SIZE,
                                },
                                true
                            )

                            if (trips.length > 0) {
                                for (let i = 0; i < trips.length; i++) {
                                    await new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            gridApi.applyTransaction({
                                                add: [trips[i]],
                                                addIndex:
                                                    BOOKING_FINISHED_PAGE_SIZE *
                                                        countTimesScrollRef.current +
                                                    i,
                                            })
                                            resolve()
                                        }, 200)
                                    })
                                }
                                endOfRecordsRef.current = false
                            } else endOfRecordsRef.current = true
                        }
                    }}
                >
                    {/* <AgGridColumn
                        headerName=""
                        field="status"
                        maxWidth={40}
                        minWidth={30}
                        cellRenderer={renderIconStatus}
                        cellStyle={() => {
                            return {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 'auto',
                                height: '100%',
                                marginRight: '20px',
                            }
                        }}
                    /> */}
                    <AgGridColumn
                        headerName="Id"
                        field="id"
                        maxWidth={170}
                        minWidth={90}
                        cellRenderer={renderBookingId}
                    />
                    <AgGridColumn
                        headerName="Thời gian"
                        field="created_at"
                        minWidth={110}
                        cellRenderer={renderCreatedAt}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="KHÁCH HÀNG"
                        field="customer_phone"
                        cellRenderer={renderCustomerPhone}
                        minWidth={120}
                    />
                    <AgGridColumn
                        headerName="ĐIỂM ĐÓN TRẢ"
                        field="start_address"
                        minWidth={350}
                        maxWidth={350}
                        cellRenderer={renderStartAddress}
                        editable={true}
                    />
                    <AgGridColumn
                        headerName="Điểm trả"
                        field="end_address"
                        hide={true}
                        editable={true}
                    />
                    <AgGridColumn
                        headerName="Điểm trả"
                        field="end_point"
                        hide={true}
                        editable={true}
                    />
                    <AgGridColumn
                        minWidth={170}
                        headerName="TRẠNG THÁI"
                        field="status"
                        cellRenderer={renderStatus}
                        cellStyle={{ 
                            whiteSpace: 'wrap', 
                            color: 'red',
                            lineHeight: '20px' 
                        }}
                    />
                    <AgGridColumn
                        headerName="LOẠI XE/TÀI XẾ"
                        field="service_render"
                        cellRenderer={renderService}
                        minWidth={usingSwitchboard ? 100 : 150}
                        cellStyle={{ lineHeight: '20px' }}
                    />
                    <AgGridColumn
                        minWidth={120}
                        headerName="Tài xế"
                        field="driver_phone"
                        hide={usingSwitchboard ? true : true}
                    />
                    <AgGridColumn
                        minWidth={120}
                        headerName="Tài xế"
                        field="driver_phone_render"
                        hide={usingSwitchboard ? true : true}
                    />
                    <AgGridColumn
                        minWidth={120}
                        maxWidth={120}
                        headerName="Tiền cước"
                        field="total_price"
                        cellRenderer={renderTotalPrice}
                    />
                    <AgGridColumn
                        headerName="TT KHÁC"
                        field="note"
                        editable={true}
                        minWidth={usingSwitchboard ? 130 : 150}
                        cellRenderer={renderOtherInfo}
                        cellStyle={{lineHeight: '24px'}}
                    />
                    <AgGridColumn
                        headerName="GHI CHÚ"
                        field="note"
                        editable={true}
                        minWidth={usingSwitchboard ? 130 : 250}
                        maxWidth={250}
                        cellRenderer={renderNote}
                        //cellClass={ params => { return ['note-content'] }}
                    />
                    <AgGridColumn
                        headerName="LD H.Chuyến"
                        field="reason_cancel"
                        minWidth={130}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="Đ.Xe"
                        field="check_sb"
                        minWidth={80}
                        maxWidth={80}
                        hide={usingSwitchboard ? true : true}
                        cellRenderer={params => {
                            if (params.value == 0) {
                                return 'Tự động'
                            } else if (params.value == 1) {
                                return 'Chỉ định'
                            } else {
                                return 'Tự động'
                            }
                        }}
                    />
                    <AgGridColumn
                        headerName="Nguồn"
                        field="type"
                        cellRenderer={renderBookingFrom}
                        hide={usingSwitchboard ? true : true}
                        minWidth={80}
                        maxWidth={80}
                    />
                    <AgGridColumn
                        headerName="Ng.Điều"
                        field="user_id"
                        minWidth={100}
                        maxWidth={100}
                        cellRenderer={renderOperator}
                        hide={usingSwitchboard ? true : true}
                    />
                </AgGridReact>
            </div>
        </div>
    )
}

export default GridBookingFinished
