import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useEffect, useState, useRef } from 'react'

import iconRefresh from '../../../static/images/refresh.png'

import iconRemove from '../../../static/images/close.png'

import iconSwitch from '../../../static/images/switch.png'

import ViewContext from 'app/_shared/view-context'

//import { createSocketConnection } from 'services/socket'

import { bookingStatusColor, bookingStatusName } from 'utils/Booking'
import { set } from 'date-fns/esm'

import useTrips from 'app/_shared/trips-context'

import socketClient from 'socket.io-client'
import { useRowSelection } from 'react-data-grid'
import { CreditCardOffRounded, PartyModeSharp } from '@mui/icons-material'

import moment from 'moment'

import configBooking from '../ConfigBooking'

import { updateCmdSbApi } from 'api/trip/update/updateTripSwitchboard'

import { updateCoorAddCancelApi } from 'api/trip/update/updateCoorAddCancelApi'

import { localStorageKeys, getLocalStorage } from 'services/localStorage'

import FormDialog from './FormDialog'

import updateTypeCoordinatorApi from 'api/trip/update/updateTypeCoordinatorApi'

import { useSnackbar } from 'notistack'

import { editBookingTypes, rebookingTypes } from '../constant'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import { updateTripInfo } from 'api/trip/update/updateTripActivity'

import { estimateTripApi } from 'api/trip'

import UserContainerContext from 'app/_shared/user-container-context'
import MenuContext from './MenuContext'

import { MdRadioButtonChecked } from 'react-icons/md'
import { FaEllipsisH } from 'react-icons/fa'
import { element } from 'prop-types'
import MenuActions from './MenuActions'
import ModalConfirmCancel from './ModalConfirmCancel'

import { isSidebarOpenState } from 'app/_shared/recoil/sidebar/atom'
import ModalDetail from './ModalDetail'
import actions from 'app/new-booking/context/actions'

//import '../scss/_RadioStatus.scss'

const { useUserContainerContext, UserContainerActions } = UserContainerContext

const { user_id: userId } = getLocalStorage(localStorageKeys.user)

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

class RefreshAction {
    init(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-refresh'>
                <img src="${iconRefresh}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-refresh')
        this.eventClickListener = () => {}
        this.eButton.addEventListener('click', this.eventClickListener)

        if (
            (params.data.type == 0 &&
                (params.data.status == -1 || params.data.status == -4)) ||
            params.data.type == 8
        ) {
            this.eButton.style.display = 'initial'
        } else {
            this.eButton.style.display = 'none'
        }
    }

    getGui() {
        return this.eGui
    }
}

class RemoveAction {
    init(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-cancel'>
                <img src="${iconRemove}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-cancel')
        this.eventClickListener = () => {
            //cancelBooking(params.api, params.data, socket, null)
        }
        this.eButton.addEventListener('click', this.eventClickListener)
        switch (params.data.type) {
            case false:
                if (
                    params.data.status == 0 ||
                    params.data.status == -4 ||
                    params.data.status == -1 ||
                    (params.data.status == 1 &&
                        (params.data.status_booking == 3 ||
                            params.data.status_booking == 0))
                ) {
                    this.eButton.style.display = 'initial'
                } else {
                    this.eButton.style.display = 'none'
                }
                break
            default:
                this.eButton.style.display = 'initial'
                break
        }
    }

    getGui() {
        return this.eGui
    }
}

const updateCoorAddCancel = (gridApi, data, cmdCd, reasonCancel) => {
    let rowNode = gridApi.getRowNode(data.id)
    rowNode.setDataValue('status', -3)
    rowNode.setDataValue('reason_cancel', data.reason_cancel)

    setTimeout(() => {
        let rowNode = gridApi.getRowNode(data.id)
        gridApi.applyTransaction({
            remove: [rowNode],
        })
    }, 1000)

    let dataUpdate = {
        id: data.id,
        status: -3,
        cmd_cd: cmdCd, //chuyển màn hình finished
        user_id: userId,
        type: data.type,
        reason_cancel: data.reason_cancel,
    }

    //update database
    updateCoorAddCancelApi(dataUpdate)
    .then(res => {
        console.log('data response cancelled: ', res.data);
    })
    .catch(err => console.log(err))
}

const cancelTripWhenFindingDriver = (gridApi, data, socket) => {
    let rowNode = gridApi.getRowNode(data.id)
    rowNode.setDataValue('status', -3)
    rowNode.setDataValue('reason_cancel', data.reason_cancel)

    let dataCancel = {
        coordinator_id: data.id,
        other_reason: data.reason_cancel,
    }
    socket.emit('cancel-trip-bb', dataCancel, function () {})

    setTimeout(() => {
        let rowNode = gridApi.getRowNode(data.id)
        gridApi.applyTransaction({
            remove: [rowNode],
        })
    }, 1500)
    //updateCoorAddCancel(gridApi, data)
}

const rebookingDriverCancel = async (record, socket) => {
    let arrCoor = record.start_point.split(' ')
    let lat = arrCoor[0]
    let lng = arrCoor[1]
    let dataBooking = {
        type: 1000,
        coordinator_id: record.id,
        service_id: record.service_id,
        user_id: record.user_id,
        customer_id: record.customer_id,
        start_address: record.start_address,
        start_point: lat + ' ' + lng,
        waypoints: [
            {
                x: lat,
                y: lng,
                place_id: '',
                geocode: record.start_address,
            },
        ],
        note: record.note,
        driver_id: record.driverAssigned,
    }
    if (record.end_point != null && record.end_point != '') {
        let arrTemp = record.end_point.split(' ')
        let lat = arrTemp[0]
        let lgn = arrTemp[1]
        dataBooking.waypoints[1] = {
            x: lat,
            y: lng,
            place_id: '',
            geocode: record.end_address,
        }

        //Estimated
        let w1 = {
            lat: dataBooking.waypoints[0].x,
            lng: dataBooking.waypoints[0].y,
        }
        let w2 = {
            lat: dataBooking.waypoints[1].x,
            lng: dataBooking.waypoints[1].y,
        }
        const estimateData = {
            service: record.service_id,
            waypoints: [w1, w2],
        }
        const estimatedResponse = await estimateTripApi(estimateData)
        if (estimatedResponse) {
            dataBooking = {
                ...dataBooking,
                distance: estimatedResponse.distance,
                total_price: estimatedResponse.price,
                estimated_time: estimatedResponse.duration,
            }
        }
    }
    socket.emit('booking', dataBooking, function () {})
}

const rebookingReservation = async (record, socket) => {
    //Function rebooking reservation
    let arrCoor = record.start_point.split(' ')
    let lat = arrCoor[0]
    let lng = arrCoor[1]
    let dataBooking = {
        type: 1000,
        coordinator_id: record.id,
        service_id: record.service_id,
        user_id: record.user_id,
        customer_id: record.customer_id,
        start_address: record.start_address,
        start_point: lat + ' ' + lng,
        waypoints: [
            {
                x: lat,
                y: lng,
                place_id: '',
                geocode: record.start_address,
            },
        ],
        note: record.note,
    }
    if (record.end_point != null && record.end_point != '') {
        let arrTemp = record.end_point.split(' ')
        let lat = arrTemp[0]
        let lgn = arrTemp[1]
        dataBooking.waypoints[1] = {
            x: lat,
            y: lng,
            place_id: '',
            geocode: record.end_address,
        }

        //Estimated
        let w1 = {
            lat: dataBooking.waypoints[0].x,
            lng: dataBooking.waypoints[0].y,
        }
        let w2 = {
            lat: dataBooking.waypoints[1].x,
            lng: dataBooking.waypoints[1].y,
        }
        const estimateData = {
            service: record.service_id,
            waypoints: [w1, w2],
        }
        const estimatedResponse = await estimateTripApi(estimateData)
        if (estimatedResponse) {
            dataBooking = {
                ...dataBooking,
                distance: estimatedResponse.distance,
                total_price: estimatedResponse.price,
                estimated_time: estimatedResponse.duration,
            }
        }
    }
}

const updateTypeAndRebookingReservation = async (gridApi, record, socket) => {
    let dataUpdate = {
        id: record.id,
        user_id: record.user_id,
        status: 0,
        note: record.note,
        type_update: rebookingTypes.REBOOKING_DRIVER_CANCEL,
    }
    const resUpdate = await updateTypeCoordinatorApi(dataUpdate)

    if (resUpdate.success == true) {
        let rowNode = gridApi.getRowNode(record.id)
        if (rowNode) {
            rowNode.setDataValue('list_vh', '')
            rowNode.setDataValue('vh_code', '')
            rowNode.setDataValue('list_arrived', '')
            rowNode.setDataValue('status', 0)
            rowNode.setDataValue('note', record.note)
            rowNode.setDataValue('reason_cancel', '')
            rowNode.setDataValue('service_render', '')
            rowNode.setDataValue('driver_phone_render', '')

            rebookingReservation(record, socket)

            let dataEditBooking = {
                user_id: record.user_id,
                editbooking_type: editBookingTypes.REBOOKING_RESERVATION, //Emit rebooking reservation to an other partner
                coordinator_id: record.id,
            }
            socket.emit('edit-booking', dataEditBooking, function () {})
        }
    } else {
        // enqueueSnackbar('Có lỗi xảy ra, vui lòng thử lại', {
        //     variant: 'warning'
        // })
    }
}

const updateTypeAndRebookingCancelled = async (gridApi, record, socket) => {
    let dataUpdate = {
        id: record.id,
        user_id: record.user_id,
        status: 0,
        note: record.note,
        type_update: rebookingTypes.REBOOKING_DRIVER_CANCEL,
    }
    const resUpdate = await updateTypeCoordinatorApi(dataUpdate)
    if (resUpdate.success == true) {
        let rowNode = gridApi.getRowNode(record.id)
        if (rowNode) {
            if (record.status == -1 || record.status == -2) {
                rowNode.setDataValue('status', 0)
                //rebooking(socket, {coordinator_id: record.id})
            } else {
                rowNode.setDataValue('list_vh', '')
                rowNode.setDataValue('vh_code', '')
                rowNode.setDataValue('list_arrived', '')
                rowNode.setDataValue('status', 0)
                rowNode.setDataValue('note', record.note)
                rowNode.setDataValue('reason_cancel', '')
                rowNode.setDataValue('service_render', '')
                rowNode.setDataValue('driver_phone_render', '')

                rebookingDriverCancel(record, socket)

                let dataEditBooking = {
                    user_id: record.user_id,
                    editbooking_type: editBookingTypes.REBOOKING_DRIVER_CANCEL, //Emit rebooking when  driver cancel to an other partner
                    coordinator_id: record.id,
                }
                socket.emit('edit-booking', dataEditBooking, function () {})
            }
        }
    } else {
        // enqueueSnackbar('Có lỗi xảy ra, vui lòng thử lại', {
        //     variant: 'warning'
        // })
    }
}

const cancelTrip = (gridApi, data, socket) => {
    // cancel trip function
    // console.log('cancel trip function: ')
    // console.log(data)
    if (data.flagRebooking) {
        let dataCancel = {
            coordinator_id: data.id,
            other_reason: data.reason_cancel,
        }
        socket.emit('cancel-trip', dataCancel, function () {})
        setTimeout(() => {
            updateTypeAndRebookingCancelled(gridApi, data, socket)
        }, 1000)
    } else {
        let rowNode = gridApi.getRowNode(data.id)
        rowNode.setDataValue('status', -3)
        rowNode.setDataValue('reason_cancel', data.reason_cancel)

        let dataCancel = {
            coordinator_id: data.id,
            other_reason: data.reason_cancel,
        }
        socket.emit('cancel-trip', dataCancel, function () {})

        setTimeout(() => {
            gridApi.applyTransaction({
                remove: [rowNode],
            })
        }, 1500)
    }
}

const cancelBooking = (gridApi, data, socket, reasonCancel) => {
    //cancel booking function
    data.reason_cancel = reasonCancel

    // console.log('data cancel: ')
    // console.log(data)

    switch (data.type) {
        case 10:
            if (data.status == -4) {
                updateCoorAddCancel(gridApi, data, 'out') //out coor from active trips
            } else {
                if (
                    data.status_booking == -1 ||
                    (data.status_booking == 0 && data.cancel == 2)
                ) {
                    updateCoorAddCancel(
                        gridApi,
                        data,
                        data.status_booking == -1 ? '' : 'out'
                    )
                } else {
                    if (data.status == 0) {
                        cancelTripWhenFindingDriver(gridApi, data, socket)
                    } else cancelTrip(gridApi, data, socket)
                }
            }
            break
        case 0:
            if (data.status == -4 || data.status == -1 || data.status == -2) {
                updateCoorAddCancel(gridApi, data, 'out')
            } else {
                if (data.status == 0) {
                    cancelTripWhenFindingDriver(gridApi, data, socket)
                } else cancelTrip(gridApi, data, socket)
            }
            break
        case 8:
            updateCoorAddCancel(gridApi, data, 'out')
            break
    }
}

class TransferSwitchboardAction {
    init(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-switchboard'>
                <img src="${iconSwitch}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-switchboard')
        this.eventClickListener = () => {}
        this.eButton.addEventListener('click', this.eventClickListener)

        switch (params.data.type) {
            case 0:
                if (
                    params.data.status == -1 ||
                    params.data.status == -4 ||
                    params.data.status == -2 ||
                    params.data.status == -3
                ) {
                    this.eButton.style.display = 'initial'
                } else {
                    this.eButton.style.display = 'none'
                }
                break
            default:
                this.eButton.style.display = 'initial'
                break
        }
    }

    getGui() {
        return this.eGui
    }
}

//init services
const listServices = getLocalStorage(localStorageKeys.services) ? getLocalStorage(localStorageKeys.services) : [];
const arrServices = listServices.map((item, index) => {
    let service = {
        id: item.service_id,
        name: item.vi_name,
        checked: true
    }
    return service
})

const GridBookingActive = props => {
    // grid booking active
    const [gridApi, setGridApi] = useState(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)
    const [gridCellApi, setGridCellApi] = useState(null)

    const [state, actions] = useTrips()

    const [activeTrips, setActiveTrips] = useState([])

    const [flagCopyText, setFlagCopyText] = useState(false)

    //use recoil
    const [tripDetail, setTripDetail] = useRecoilState(tripDetailState)

    const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState)

    const { services: services } = getLocalStorage(localStorageKeys.services)

    useEffect(() => {
        setActiveTrips(state.trips)
    }, [state.filterForm])

    //Insert new trips into active grid
    useEffect(() => {
        if (!state.newTrips.length) return
        if (Array.isArray(state.newTrips) && state.newTrips[0].type == 1000) {
            let data = [...state.newTrips]
            if (data[0].user_id == userId) {
                let trips = data.map(trip => {
                    let item = {
                        booking_id: '',
                        cancel: 0,
                        check_sb: trip.driver_id ? 1 : 0,
                        cmd_cd: null,
                        cmd_sb: null,
                        count_call: 0,
                        created_at_original: trip.createdAt,
                        created_at: trip.createdAt
                            ? moment(
                                  trip.createdAt.toLocaleString()
                              ).format('HH:mm:ss DD-MM-yyyy')
                            : moment(new Date()).format(
                                  'HH:mm:ss DD-MM-yyyy'
                              ),
                        customer_id: trip.customer_id,
                        customer_name: trip.customer_name
                            ? trip.customer_name
                            : 'Khách tổng đài',
                        customer_phone: trip.phoneNumber,
                        deleted: 0,
                        driver_id: trip.driver_id,
                        driver_phone: '',
                        end_address: trip.waypoints && trip.waypoints.length >= 2 ? trip.waypoints[1].geocode : '',
                        end_point: trip.waypoints && trip.waypoints.length >= 2
                            ? trip.waypoints[1].x + ' ' + trip.waypoints[1].y
                            : '',
                        id: trip.coordinator_id,
                        is_grp: '0',
                        line: '0',
                        list_arrived: trip.driver_id,
                        list_vh: trip.driver_id,
                        marketing_id: null,
                        note: trip.note,
                        number_vh: '1',
                        reason_cancel: '',
                        service_id: trip.service_id,
                        service_render: trip.service_id,
                        start_address: trip?.start_address || '',
                        start_point: trip.start_point,
                        status: trip.coordinatorType == 8 ? -1 : 0,
                        status_booking: '',
                        switchboard: '',
                        type: trip.coordinatorType
                            ? trip.coordinatorType
                            : 0,
                        type_booking: '',
                        user_id: trip.user_id ? trip.user_id : '',
                        vh_code: null,
                    }
                    return item
                })

                if (gridApi) {
                    for (let i = 0; i < trips.length; i++) {
                        gridApi.applyTransaction({
                            add: [trips[i]],
                            addIndex: 0,
                        })
                    }
                }

                actions.addMultipleTrips([...trips])
            }
        } 
    }, [state.newTrips])

    const viewDispatch = useViewDispatch()

    const [
        {
            userId,
            socket,
            usingSwitchboard,
            showDialogCancel,
            determineCopyingText,
            updateTripInfo,
        },
    ] = useViewContext()

    //itit socket into trips-context for rebooking reservation
    useEffect(() => {
        actions.setSocket(socket)
    }, [socket])

    const [coor, setCoor] = useState(null)

    //const { enqueueSnackbar } = useSnackbar()

    function setupContextMenu() {// event right click
        var eBody = document.querySelector('.ag-body')
        document.addEventListener('contextmenu', function (event) {
            //event.preventDefault();
        })
    }

    const onGridReady = params => {
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
        setGridCellApi(params.cellApi)

        //socket on events to update gird view
        if (socket) {
            socket.removeAllListeners()
            socketOnEvents(params.api)
        }

        setupContextMenu()
    }

    const updateStatusBooking = (agGridApi, updateType, data) => {// update status booking
        data.updateType = updateType
        switch (updateType) {
            case 'booking':
                let rowNode = agGridApi.getRowNode(data.coordinator_id)
                if (rowNode) {
                    if (data.status == 1) {
                        rowNode.setDataValue('status_booking', 3)
                    }
                    rowNode.setDataValue('status', data.status)
                    if (data.driver) {
                        let serviceString =
                            rowNode.data.service_id +
                            ';' +
                            data.vh_code +
                            ';' +
                            data.driver.license_plate
                        rowNode.setDataValue('service_render', serviceString)

                        let driverString =
                            data.driver.full_name +
                            '-' +
                            data.driver.phone_number

                        rowNode.setDataValue(
                            'driver_phone',
                            data.driver.phone_number
                        )
                        rowNode.setDataValue(
                            'driver_phone_render',
                            driverString
                        )
                    }

                    data.status_booking = 3
                    actions.updateTrips(data)

                    setTimeout(() => {
                        if (rowNode.data.status_booking == 3) {
                            rowNode.setDataValue('status_booking', 0)
                            data.status_booking = 0
                            actions.updateTrips(data)
                        }
                    }, 10000)
                }
                break
            case 'start-ji':
                let rowNode2 = agGridApi.getRowNode(data.coordinator_id)
                if (rowNode2) {
                    rowNode2.setDataValue('status_booking', 1)
                    actions.updateTrips(data)
                }
                break
            case 'stop-journey':
                let rowNode3 = agGridApi.getRowNode(data.coordinator_id)
                if (rowNode3) {
                    rowNode3.setDataValue('status_booking', 2)

                    actions.updateTrips(data)

                    //Add finished to the gridfinishedtrips
                    actions.updateFinishedTrips(rowNode3.data)

                    //Send coordinator to GridBookingFinished
                    //viewDispatch(ViewActions.sendFinishedTrip(rowNode3.data))

                    setTimeout(function () {
                        agGridApi.applyTransaction({
                            remove: [rowNode3],
                        })
                        actions.removeTrip(data.coordinator_id)
                    }, 600)
                }
                break
            case 'cancel-trip':
                let rowNode4 = agGridApi.getRowNode(data.data.coordinator_id)
                if (rowNode4) {
                    let dataCancel = {
                        updateType: 'cancel-trip',
                        status: '',
                        status_booking: '',
                        reason_cancel: '',
                    }
                    switch (data.data.code) {
                        case 1002: //Driver cancel
                            rowNode4.setDataValue('status', -4)
                            rowNode4.setDataValue('status_booking', -4)
                            rowNode4.setDataValue(
                                'reason_cancel',
                                data.data.reason_cancel
                            )
                            dataCancel.status = -4
                            dataCancel.status_booking = -4
                            dataCancel.reason_cancel = data.data.reason_cancel
                            break
                        case 1003: //Admin cancel
                            rowNode4.setDataValue('status', -3)
                            rowNode4.setDataValue('status_booking', -3)
                            rowNode4.setDataValue(
                                'reason_cancel',
                                data.data.reason_cancel
                            )
                            dataCancel.status = -3
                            dataCancel.status_booking = -3
                            dataCancel.reason_cancel = data.data.reason_cancel
                            break
                        case 1004: //Customer cancel
                            rowNode4.setDataValue('status', -5)
                            rowNode4.setDataValue('status_booking', -5)
                            rowNode4.setDataValue(
                                'status_booking',
                                data.data.reason_cancel
                            )
                            dataCancel.status = -5
                            dataCancel.status_booking = -5
                            dataCancel.reason_cancel = data.data.reason_cancel
                            break
                    }
                    actions.updateTrips(dataCancel)
                }
                break
        }
    }

    const addCoordinatorBookingByAppCustomer = (agGridApi, data) => {
        let record = {
            booking_id: data.booking_id,
            customer_id: data.customer_id,
            customer_phone: data.c_phone,
            start_address: data.waypoints[0].geocode,
            end_address: data.waypoints[1].geocode,
            start_point: data.waypoints[0].x + ' ' + data.waypoints[0].y,
            end_point: data.waypoints[1].x + ' ' + data.waypoints[1].y,
            customer_name: data.c_name,
            service_id: data.service_id,
            service_render: data.service_id,
            driver_phone: '',
            created_at: data.created_at,
            note: data.note,
            type: 10,
            type_booking: 1,
            status: 0,
            status_booking: 0,
            cancel: -1,
        }

        agGridApi.applyTransaction({
            add: [record],
            addIndex: 0,
        })

        actions.addTrips(record)
    }

    const updateStatusCoorBookingAppCustomer = (agGridApi, data) => {
        //console.log(data)
        let rows = agGridApi.getRenderedNodes()
        // console.log(rows)
        if (rows) {
            let row = rows.find(item => item.data.booking_id == data.booking_id)
            // console.log('row find: ')
            // console.log(row)
            if (!row) return
            //let row = agGridApi.getRowNode(data.booking_id)

            switch (data.status) {
                case 'SUCCESS':
                    row.setDataValue('status_booking', 3)
                    row.setDataValue('status', 1)

                    let serviceString =
                        row.data.service_id +
                        ';' +
                        data.vh_code +
                        ';' +
                        data.license_plate
                    row.setDataValue('service_render', serviceString)

                    let driverString =
                        data.d_name + '-' + '0' + data.d_phone.substring(3)

                    row.setDataValue('driver_phone', driverString)

                    data.type = 'SUCCESS'
                    data.status = 1
                    data.status_booking = 3
                    actions.updateTrips(data)

                    setTimeout(() => {
                        if (row.data.status_booking == 3) {
                            row.setDataValue('status_booking', 0)
                            row.setDataValue('cancel', 0)
                            row.setDataValue('status', 5)
                            data.status_booking = 0
                            data.cancel = 0
                            actions.updateTrips(data)
                        }
                    }, 6000)
                    break
                case 'START':
                    row.setDataValue('status_booking', 1)
                    row.setDataValue('status', 5) //status temp to update UI

                    data.type = 'START'
                    data.status_booking = 1
                    actions.updateTrips(data)
                    break
                case 'STOP':
                    row.setDataValue('status_booking', 2)
                    row.setDataValue('status', 2)
                    data.type = 'STOP'
                    data.status_booking = 2
                    data.status = 2
                    actions.updateTrips(data)

                    setTimeout(function () {
                        agGridApi.applyTransaction({
                            remove: [row],
                        })
                    }, 1000)

                    break
                case 'FAIL':
                    row.setDataValue('status_booking', -1)
                    row.setDataValue('status', -1)

                    data.type = 'FAIL'
                    data.status_booking = -1
                    data.status = -1
                    actions.updateTrips(data)

                    break
                case 'CCANCEL':
                    // console.log('customer cancel event data: ')
                    // console.log(data)
                    row.setDataValue('cancel', 1)
                    row.setDataValue('status', -5)
                    row.setDataValue('reason_cancel', data.reason)

                    data.type = 'CCANCEL'
                    data.cancel = 1
                    data.reason_cancel = data.reason
                    data.status = -5
                    actions.updateTrips(data)

                    setTimeout(function () {
                        agGridApi.applyTransaction({
                            remove: [row],
                        })
                    }, 1000)

                    break
                case 'DCANCEL':
                    // console.log('driver cancel event data: ')
                    // console.log(data)
                    row.setDataValue('cancel', 2)
                    row.setDataValue('status', -4)
                    row.setDataValue('reason_cancel', data.reason)

                    data.type = 'CCANCEL'
                    data.cancel = 2
                    data.reason_cancel = data.reason
                    data.status = -4
                    actions.updateTrips(data)

                    break
                case 'ACANCEL':
                    // console.log('admin cancel event data: ')
                    // console.log(data)
                    row.setDataValue('cancel', 3)
                    row.setDataValue('status', -3)

                    data.type = 'CCANCEL'
                    data.cancel = 3
                    data.status = -3
                    actions.updateTrips(data)

                    setTimeout(function () {
                        agGridApi.applyTransaction({
                            remove: [row],
                        })
                    }, 1000)

                    break
                default:
                    break
            }
        }
    }

    const socketOnEvents = agGridApi => {
        //socket on events

        if (!socket) return

        socket.on('update-cmd', function (data) {
            // console.log('data cmd update on grid active booking: ')
            // console.log(data)
            let rowNode = agGridApi.getRowNode(data.coordinator_id)

            if (rowNode) {
                if (data.deleted == 1) {
                    agGridApi.applyTransaction({
                        remove: [rowNode],
                    })
                } else {
                    if (data.cmd_sb) rowNode.setDataValue('cmd_sb', data.cmd_sb)
                    if (data.list_vh)
                        rowNode.setDataValue('list_vh', data.list_vh)
                    if (data.vh_code)
                        rowNode.setDataValue('vh_code', data.vh_code)
                    if (data.list_arrived)
                        rowNode.setDataValue('list_arrived', data.list_arrived)
                    if (data.status) rowNode.setDataValue('status', data.status)
                    if (data.note) rowNode.setDataValue('note', data.note)
                }
            }
        })

        socket.on('connect', function () {
            //console.log('Socket  grid active booking connected...')
        })

        //App center switchboard event on-booking
        socket.on('booking', function (data) {
            console.log('data on booking: ', data)
            updateStatusBooking(agGridApi, 'booking', data)
        })

        socket.on('booking-driver', function (data) {
            //Show drivers closest
            // console.log('data booking-driver: ')
            // console.log(data)
            //updateStatusBooking(agGridApi, 'booking', data)
        })

        socket.on('start-ji', function (data) {
            updateStatusBooking(agGridApi, 'start-ji', data)
        })

        socket.on('stop-journey', function (data) {
            updateStatusBooking(agGridApi, 'stop-journey', data)
        })

        socket.on('cancel-trip', function (data) {
            updateStatusBooking(agGridApi, 'cancel-trip', data)
        })

        socket.on('edit-booking', function (data) {
            //on edit-booking
            if (data instanceof Array && data[0].type == 1000) {
                if (data[0].user_id == userId) {
                    let trips = data.map(trip => {
                        let item = {
                            booking_id: '',
                            cancel: 0,
                            check_sb: trip.driver_id ? 1 : 0,
                            cmd_cd: null,
                            cmd_sb: null,
                            count_call: 0,
                            created_at_original: trip.createdAt,
                            created_at: trip.createdAt
                                ? moment(
                                      trip.createdAt.toLocaleString()
                                  ).format('HH:mm:ss DD-MM-yyyy')
                                : moment(new Date()).format(
                                      'HH:mm:ss DD-MM-yyyy'
                                  ),
                            customer_id: trip.customer_id,
                            customer_name: trip.customer_name
                                ? trip.customer_name
                                : 'Khách tổng đài',
                            customer_phone: trip.phoneNumber,
                            deleted: 0,
                            driver_id: trip.driver_id,
                            driver_phone: '',
                            end_address: trip.waypoints && trip.waypoints.length >= 2 ? trip.waypoints[1].geocode : '',
                            end_point: trip.waypoints && trip.waypoints.length >= 2
                                ? trip.waypoints[1].x + ' ' + trip.waypoints[1].y
                                : '',
                            id: trip.coordinator_id,
                            is_grp: '0',
                            line: '0',
                            list_arrived: trip.driver_id,
                            list_vh: trip.driver_id,
                            marketing_id: null,
                            note: trip.note,
                            number_vh: '1',
                            reason_cancel: '',
                            service_id: trip.service_id,
                            service_render: trip.service_id,
                            start_address: trip?.start_address || '',
                            start_point: trip.start_point,
                            status: trip.coordinatorType == 8 ? -1 : 0,
                            status_booking: '',
                            switchboard: '',
                            type: trip.coordinatorType
                                ? trip.coordinatorType
                                : 0,
                            type_booking: '',
                            user_id: trip.user_id ? trip.user_id : '',
                            vh_code: null,
                        }
                        return item
                    })

                    if (agGridApi) {
                        for (let i = 0; i < trips.length; i++) {
                            agGridApi.applyTransaction({
                                add: [trips[i]],
                                addIndex: 0,
                            })
                        }
                    }

                    actions.addMultipleTrips([...trips])
                }
            } else {
                switch (data.editbooking_type) {
                    case editBookingTypes.REBOOKING_CAR_NOT_FOUND:
                        if (data.user_id !== userId) {
                            let rowNode = agGridApi.getRowNode(
                                data.coordinator_id
                            )
                            if (!rowNode) return
                            rowNode.setDataValue('status', 0)
                        }
                        break
                    case editBookingTypes.REBOOKING_DRIVER_CANCEL:
                        if (data.user_id !== userId) {
                            let rowNode = agGridApi.getRowNode(
                                data.coordinator_id
                            )
                            if (!rowNode) return
                            rowNode.setDataValue('list_vh', '')
                            rowNode.setDataValue('vh_code', '')
                            rowNode.setDataValue('list_arrived', '')
                            rowNode.setDataValue('status', 0)
                            rowNode.setDataValue('note', data.note)
                            rowNode.setDataValue('reason_cancel', '')
                            rowNode.setDataValue('service_render', '')
                            rowNode.setDataValue('driver_phone_render', '')
                        }
                        break
                    case editBookingTypes.UPDATE_TRIP_INFO:
                        // console.log('data update trip info: ')
                        // console.log(data)

                        agGridApi.clearFocusedCell()
                        agGridApi.deselectAll()

                        let rowNode = agGridApi.getRowNode(data.coordinator_id)

                        if (!rowNode) return

                        rowNode.setDataValue('start_point', data.start_point)
                        rowNode.setDataValue('end_address', data.end_address)
                        rowNode.setDataValue('end_point', data.end_point)
                        rowNode.setDataValue('note', data.note)
                        rowNode.setDataValue('user_id', data.user_id)

                        rowNode.setDataValue(
                            'start_address',
                            data.start_address + ' '
                        )
                        rowNode.setDataValue(
                            'start_address',
                            data.start_address
                        )

                        if (data.booking_time)
                            rowNode.setDataValue(
                                'created_at',
                                moment(data.booking_time).format(
                                    'HH:mm:ss DD-MM-yyyy'
                                )
                            )
                        if (data.list_arrived)
                            rowNode.setDataValue(
                                'list_arrived',
                                data.list_arrived
                            )
                        else {
                            if (rowNode.data.list_arrived)
                                rowNode.setDataValue('list_arrived', '')
                        }
                        if (data.check_sb)
                            rowNode.setDataValue('check_sb', data.check_sb)
                        else {
                            if (rowNode.data.check_sb == 1)
                                rowNode.setDataValue('check_sb', 0)
                        }

                        if (data.flagRebooking && data.user_id == userId) {
                            let dataRebooking = {
                                ...rowNode.data,
                                flagRebooking: data.flagRebooking,
                                driverAssigned: data.driverAssigned,
                                reason_cancel: 'Tổng đài hủy chuyến',
                                createNewBooking: data.createNewBooking,
                            }
                            rebookingOnActiveGrid(
                                agGridApi,
                                dataRebooking,
                                socket
                            )
                        }

                        break
                    case editBookingTypes.TRIP_DETAIL_CANCEL:
                        if (data.user_id == userId) {
                            cancelBooking(
                                agGridApi,
                                data,
                                socket,
                                data.reason_cancel
                            )
                        } else {
                            let rowNode = agGridApi.getRowNode(
                                data.coordinator_id
                            )
                            if (!rowNode) return
                            rowNode.setDataValue('status', -3)
                            rowNode.setDataValue('note', data.node)
                        }
                        actions.removeTrip(data.id)
                        break
                    case editBookingTypes.REBOOKING_RESERVATION:
                        let rowNodeReservation = agGridApi.getRowNode(
                            data.coordinator_id
                        )
                        if (!rowNodeReservation) return
                        rowNodeReservation.setDataValue('type', 0)
                        rowNodeReservation.setDataValue('status', 0)
                        //console.log(rowNodeReservation)
                        break
                }
            }
        })

        socket.on('check-sbg', function (data) {
            // console.log('check-sbg on active: ')
            // console.log(data)

            let record = {
                id: data.coordinator_id,
                customer_phone: data.customer_phone,
                line: data.line,
                start_address: data.start_address,
                start_point: data.start_point,
                number_vh: data.number_vh,
                created_at: data.call_time,
                user_id: data.user_id,
                user_name: data.user_name,
                type: data.type,
                customer_name: data.customer_name,
                service_id: data.service_id,
                service_render: data.service_id,
                status: data.status,
                status_booking: data.status_booking,
                count_call: data.count_call,
                check_sb: 0, // define coordinator has transfered switchboard
                note: data.noteReasonCancelled,
                area_code: data.area,
            }

            // agGridApi.applyTransaction({
            //     add: [record],
            //     addIndex: 0,
            // })

            actions.addTrips(record)

            actions.addSwitchboardTrip(record)
        })

        //Event app customer
        socket.on('app-booking', function (data) {
            // console.log('app-booking data: ')
            // console.log(data)
            if (agGridApi && agGridApi.getModel()) {
                let rows = agGridApi.getModel().gridOptionsWrapper.gridOptions.rowData
                for (let i = 0; i < rows.length; i++) {
                    if (
                        rows[i].type == 10 &&
                        rows[i].status_booking == -1 &&
                        rows[i].customer_id === data.customer_id
                    ) {
                        deleteAppCustomerNoCarPickup(
                            rows[i].id,
                            rows[i].booking_id
                        )
                        agGridApi.applyTransaction({
                            remove: [rows[i]],
                        })
                    }
                    break
                }

                if (data.waypoints[1]) {
                    addCoordinatorBookingByAppCustomer(agGridApi, data)
                }
            }
        })

        socket.on('app-booking-status', function (data) {
            //console.log(data)
            if (data.status !== 'REBOOKING')
                updateStatusCoorBookingAppCustomer(agGridApi, data)
        })

        socket.on('app-booking-cancel', function (data) {
            //console.log(data)
            updateStatusCoorBookingAppCustomer(agGridApi, data)
        })

        socket.on('free-booking-status', function (data) {
            //console.log(data)
            //updateStatusCoorBookingAppCustomer(agGridApi, data)
            let record = {
                booking_id: data.booking_id,
                customer_phone: data.c_phone,
                start_address: '',
                end_address: '',
                customer_name: data.c_name,
                service_id: data.service_id,
                service_render:
                    data.service_id +
                    ';' +
                    data.vh_code +
                    ';' +
                    data.license_plate,
                created_at: data.created_at,
                driver_phone: data.d_name + '-0' + data.d_phone.substring(3),
                type: 11,
                type_booking: 4,
                note: data.note,
            }

            agGridApi.applyTransaction({
                add: [record],
                addIndex: 0,
            })
        })

        // return () => {
        //     socket.removeAllListeners()
        //     socket.close()
        // }
    }

    const deleteAppCustomerNoCarPickup = (coordinatorId, bookingId) => {
        // console.log('coordinatorId: ' + coordinatorId)
        // console.log('bookingId: ' + bookingId)
        let dataUpdate = {
            id: coordinatorId,
            booking_id: bookingId,
            deleted: 1, //Delete coord
        }
        updateCoorAddCancelApi(dataUpdate)
    }

    const onBtStopEditing = () => {
        gridApi.stopEditing()
    }

    const onBtStartEditing = (key, char, pinned) => {
        gridApi.setFocusedCell(1, 'lastName', pinned)
        gridApi.startEditingCell({
            rowIndex: 1,
            colKey: 'lastName',
            rowPinned: pinned,
            keyPress: key,
            charPress: char,
        })
    }

    const onBtNextCell = () => {
        gridApi.tabToNextCell()
    }

    const onBtPreviousCell = () => {
        gridApi.tabToPreviousCell()
    }

    const onBtWhich = () => {
        var cellDefs = gridApi.getEditingCells()
        if (cellDefs.length > 0) {
            var cellDef = cellDefs[0]
            // console.log(
            // 'editing cell is: row = ' +
            //     cellDef.rowIndex +
            //     ', col = ' +
            //     cellDef.column.getId() +
            //     ', floating = ' +
            //     cellDef.rowPinned
            // );
        } else {
            //console.log('no cells are editing');
        }
    }
    const onCellKeyDown = e => {
        let colId = e.column.colId

        if (e.event.code === 'ArrowUp') {
            let rowTopIndex = e.node.rowIndex - 1

            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowTopIndex, colId)
                gridApi.startEditingCell({
                    rowIndex: rowTopIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            } else {
            }
        } else if (e.event.code === 'ArrowDown') {
            let rowDownIndex = e.node.rowIndex + 1
            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowDownIndex, colId)

                gridApi.startEditingCell({
                    rowIndex: rowDownIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            }
        } else if (e.event.code === 'ArrowRight') {
            // if (colId === 'start_address') {
            //     let rowIndex = e.node.rowIndex
            //     if (gridApi.getEditingCells().length > 0 ) {
            //         gridApi.setFocusedCell(rowIndex, 'note')
            //         gridApi.startEditingCell({
            //             rowIndex: rowIndex,
            //             colKey: 'note',
            //             keyPress: null,
            //             charPress: null
            //         })
            //     }
            // }else {
            //     onBtNextCell();
            // }
            //onBtNextCell()
        } else if (e.event.code === 'ArrowLeft') {
            //onBtPreviousCell()
        } else if (e.event.code === 'Enter') {
        }
    }

    const updateCmdCd = (column, data) => {
        // console.log('colId: ' + column)

        // console.log('data cmd sb: ')
        // console.log(data)

        let cmdCoordinator = data.cmd_cd
        let note = data.note
        let status = null
        switch (column) {
            case 'cmd_cd':
                if (cmdCoordinator === 'o') {
                    status = -1
                }
                if (
                    cmdCoordinator === '8' ||
                    cmdCoordinator === 'z' ||
                    cmdCoordinator === 'u'
                ) {
                    status = 5 //Trượt khách
                }
                if (cmdCoordinator === '3') {
                    status = -1
                }
                break
            case 'note':
                break
        }

        let dateUpdate = {
            coordinator_id: data.id,
            cmd_cd: data.cmd_cd,
            status: status,
            note: data.note,
        }

        //socket.emit('cmd-coordinator', dateUpdate, function() {})

        updateCmdSbApi(dateUpdate)
            .then(res => {
                socket.emit('cmd-coordinator', dateUpdate, function () {})
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onCellKeyPress = e => {
        var keyCode = e.event.code
        let colId = e.column.colId

        if (keyCode === 'Enter') {
            var rowNode = e.node
            updateCmdCd(colId, rowNode.data)
        }
    }

    const cellEditingStarted = e => {
        //return false
    }

    const [countTime, setCountTime] = useState(0)

    const [flagCopyTextOnCell, setCopyTextOnCell] = useState(false)

    let count = 0
    let interval

    const _onCellMouseDown = e => {
        interval = setInterval(() => {
            count += 10
            if (count > 30) {
                setFlagCopyText(true)
                clearInterval(interval)
            }
        }, 50)
    }

    const _onCellMouseOut = e => {}

    const openTripDetailWindow = (params) => {
        if (determineCopyingText) {
            if (count <= 100)
                setTripDetail({ open: true, data: { ...params.data } })
            clearInterval(interval)
            count = 0
        } else {
            setTripDetail({ open: true, data: { ...params.data } })
        }
    }

    const _onRowClicked = params => {
        // on row clicked
        if (params.event.target.toString() !== '[object HTMLImageElement]') {
            if (determineCopyingText) {
                if (count <= 30)
                    setTripDetail({ open: true, data: { ...params.data } })
                clearInterval(interval)
                count = 0
            } else {
                setTripDetail({ open: true, data: { ...params.data } })
            }
        }
    }

    const _onRowDoubleClicked = params => {
        openTripDetailWindow(params)
    }

    const _onCellClicked = params => {}

    const colDefs = [
        {
            headerName: '',
        },
    ]

    function renderCmdSb(params) {
        let string = ''
        switch (params.value) {
            case '1':
                // statements_1
                string = '<span style = "color:black"><b>Mời khách</b></span>'
                break
            case '2':
                // statements_1
                string =
                    '<span style = "color:#DD5145"><b>Không xe lần 1</b></span>'
                break
            case '22':
                // statements_1
                string =
                    '<span style = "color:#DD5145"><b>XL khách lần 1</b></span>'
                break
            case '3':
                // statements_1
                string = '<span style = "color:red"><b>Không xe</b></span>'
                break
            case '33':
                // statements_1
                string = '<span style = "color:red"><b>XL khách</b></span>'
                break
            case '4':
                // statements_1
                string = '<span style = "color:blue"><b>Hỏi lại ĐC</b></span>'
                break
            case '44':
                // statements_1
                string = '<span style = "color:blue"><b>Kiểm tra</b></span>'
                break
            case '5':
                // statements_1
                string = '<span style = "color:green"><b>Giữ khách</b></span>'
                break
            case '6':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Kiểm tra khách</b></span>'
                break
            case 'g':
                // statements_1
                string =
                    '<span style = "color:green"><b>Xe gặp khách</b></span>'
                break
            case 'd':
                // statements_1
                string = '<span style = "color:"><b>Xe đến điểm</b></span>'
                break
            case 'n':
                // statements_1
                string = '<span style = "color:blue"><b>Xe nhận</b></span>'
                break
            case 'l':
                // statements_1
                string = '<span style = "color:blue"><b>Gọi lại</b></span>'
                break
            case 't':
                // statements_1
                string = '<span style = "color:blue"><b>Cuốc trượt</b></span>'
                break
            case 'h':
                // statements_1
                string = '<span style = "color:blue"><b>Cuốc hoãn</b></span>'
                break
            case 'o':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Cuốc không xe</b></span>'
                break
            case 'm':
                // statements_1
                string = '<span style = "color:blue"><b>Cuộc hỏi đàm</b></span>'
                break
            case 'x':
                // statements_1
                string = '<span style = "color:blue"><b>Xe đến</b></span>'
                break
            case 'c':
                // statements_1
                string = '<span style = "color:blue"><b>Gọi taxi</b></span>'
                break
            case '444':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Kiểm tra lần 2</b></span>'
                break
            default:
                // statements_def
                //string = '<span>---</span>'
                return params.value
        }
        return string
    }
    function renderCmdCd(params) {
        let string = ''
        switch (params.value) {
            case '1':
                // statements_1
                string = '<span style = "color:black"><b>Đã mời</b></span>'
                break
            case '11':
                // statements_2
                string = '<span style = "color:red"><b>Đã mời lần 2</b></span>'
                break
            case '2':
                // statements_3
                string = '<span style = "color:green"><b>Gặp xe</b></span>'
                break
            case '3':
                // statements_4
                string = '<span style = "color:red"><b>Đã XL khách</b></span>'
                break
            case '4':
                // statements_5
                string = '<span style = "color:orange"><b>Máy bận</b></span>'
                break
            case '5':
                // statements_6
                string =
                    '<span style = "color:#DD5145"><b>Không LL được</b></span>'
                break
            case '55':
                // statements_6
                string = '<span style = "color:blue"><b>Đã giữ</b></span>'
                break
            case '6':
                // statements_7
                string =
                    '<span style = "color:orange"><b>Không nghe máy</b></span>'
                break
            case '66':
                // statements_8
                string = '<span style = "color:red"><b>K.Nghe lần 2</b></span>'
                break
            case '7':
                // statements_7
                string =
                    '<span style = "color:black"><b>Không nói gì</b></span>'
                break
            case '8':
                // statements_13
                string =
                    '<span style = "color:#FF5733"><b>Khách hoãn xe</b></span>'
                break
            case '9':
                // statements_13
                string = '<span style = "color:#FF5733"><b>Gọi hộ</b></span>'
                break
            case 't':
                // statements_9
                string =
                    '<span style = "color:#FF5733"><b>Trượt khách</b></span>'
                break
            case 'o':
                // statements_10
                string = '<span style = "color:red"><b>Không thấy xe</b></span>'
                break
            case 'l':
                // statements_11
                string =
                    '<span style = "color:green"><b>Khách gọi lại</b></span>'
                break
            case '0':
                // statements_12
                string = '<span style = "color:blue"><b>Nhắn chờ</b></span>'
                break
            case 'z':
                // statements_14
                string =
                    '<span style = "color:#FF5733"><b>Trượt khách</b></span>'
                break
            case 'u':
                // statements_15
                string =
                    '<span style = "color:#FF5733"><b>Khách từ chối</b></span>'
                break
            case 'm':
                // statements_15
                string = '<span style = "color:#FF5733"><b>Mất sóng</b></span>'
                break
            case 'd':
                // statements_15
                string =
                    '<span style = "color:green"><b>Đang gọi khách</b></span>'
                break
            case 'k':
                // statements_15
                string = '<span style = "color:green"><b>Khách đặt</b></span>'
                break
            default:
                // statements_def
                //string = '<span>---</span>'
                return params.value
                break
        }
        return string
    }

    const createReasonCancelled = (reasonCancelled, element) => {
        if (reasonCancelled !== null && reasonCancelled !== '') {
            let pCancelled = document.createElement('p')
            pCancelled.classList.add('p-reason-cancelled')
            pCancelled.appendChild(document.createTextNode(reasonCancelled))
            element.appendChild(pCancelled)
            if (reasonCancelled.length >= 20) {
                const linkDetail2 = document.createElement('a')
                linkDetail2.appendChild(document.createTextNode('Xem'))
                linkDetail2.classList.add('a-reason-cancelled')
                linkDetail2.onclick = (e) => {
                    setPosition({ x: e.clientX, y: e.clientY })
                    setFlagHiddenModalDetail(false)
                    setModalDetailType('reason')
                    setDataDetail({ reasonCancelled: reasonCancelled })
                }
                pCancelled.appendChild(linkDetail2)
                const pReasonContainer = document.createElement('p')
                pReasonContainer.classList.add('p-reason-cancelled-container')
                pReasonContainer.append(pCancelled, linkDetail2)
                element.append(pReasonContainer)
            }else element.appendChild(pCancelled)
        }
    }

    const renderStatus = params => {// render status
        const element = document.createElement('div')
        element.classList.add('parent-icon-status')
        const imageElement = document.createElement('img')
        const textElenment = document.createElement('span')
        element.appendChild(imageElement);
        element.appendChild(textElenment);

        let status = params.data.status

        let statusBooking = params.data.status_booking

        let cancel = params.data.cancel

        let element2 = document.createElement('div')
        element2.classList.add('div-status')

        if (params.data.type == 10 || params.data.type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        imageElement.src = 'assets/icons/Dang_don_khach.svg';
                        textElenment.appendChild(document.createTextNode('Đang đi đón khách'));
                        
                        element2.appendChild(element)
                        return element2;
                    } else if (cancel == 1) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));

                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                    } else if (cancel == 2) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));

                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                    } else if (cancel == 3) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));

                        element2.appendChild(element)
                        createReasonCancelled(params.data.reason_cancel, element2)
                        return element2;
                    } else {
                        imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                        textElenment.appendChild(document.createTextNode('Đang tìm xe'));

                        element2.appendChild(element)
                        return element2;
                    }
                case 1:
                    imageElement.src = 'assets/icons/Dang_cho_khach.svg';
                    textElenment.appendChild(document.createTextNode('Đang chở khách'));
                    
                    element2.appendChild(element)
                    return element2;
                case 2:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    imageElement.classList.add('finished-booking');
                    textElenment.appendChild(document.createTextNode('Hoàn thành'));

                    element2.appendChild(element)
                    return element2;
                case 3:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    imageElement.classList.add('car-found')
                    textElenment.appendChild(document.createTextNode('Đã thấy xe'));

                    element2.appendChild(element)
                    return element2;
                case -1:
                    imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                    textElenment.appendChild(document.createTextNode('Chưa có xe nhận'));
                    
                    element2.appendChild(element)
                    return element2;
                default:
                    return '<span>---</span>'
            }
        } else if (params.data.type == 0) {
            switch (status) {
                case 0:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    textElenment.appendChild(document.createTextNode('Đang tìm xe'));

                    element2.appendChild(element)
                    return element2;
                case 1:
                    if (statusBooking == 3) {
                        imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                        imageElement.classList.add('car-found')
                        textElenment.appendChild(document.createTextNode('Đã thấy xe'));

                        element2.appendChild(element)
                        return element2;
                    } else {
                        switch (statusBooking) {
                            case 0:
                                imageElement.src = 'assets/icons/Dang_don_khach.svg';
                                textElenment.appendChild(document.createTextNode('Đang đi đón khách'));

                                element2.appendChild(element)
                                return element2;
                            case 1:
                                imageElement.src = 'assets/icons/Dang_cho_khach.svg';
                                textElenment.appendChild(document.createTextNode('Đang chở khách'));
                                                        
                                element2.appendChild(element)
                                return element2;
                            case 2:
                                imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                                imageElement.classList.add('finished-booking');
                                textElenment.appendChild(document.createTextNode('Hoàn thành'));

                                element2.appendChild(element)
                                return element2;
                            case -3:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'))

                                element2.appendChild(element)
                                return element2;
                            case -4:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'))

                                element2.appendChild(element)
                                createReasonCancelled(params.data.reason_cancel, element2)
                                return element2;
                            case -5:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'))

                                element2.appendChild(element)
                                createReasonCancelled(params.data.reason_cancel, element2)
                                return element2
                            default:
                                return '<span>---</span>'
                        }
                    }
                case -1:
                    imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                    textElenment.appendChild(document.createTextNode('Chưa có xe nhận'))

                    element2.appendChild(element)
                    return element2
                case -2:
                    imageElement.src = 'assets/icons/Loi.svg';
                    textElenment.appendChild(document.createTextNode('Lỗi'))

                    element2.appendChild(element)
                    return element2
                case -3:
                    imageElement.src = 'assets/icons/huy_chuyen.svg'
                    textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'))

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2
                case -4:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'))

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2
                case -5:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'))

                    element2.appendChild(element)
                    createReasonCancelled(params.data.reason_cancel, element2)
                    return element2
                default:
                    return '<span>---</span>'
            }
            //return element
        } else if (params.data.type == 1) {
            let s = ''
            if (
                statusBooking == 3 ||
                statusBooking == 4 ||
                statusBooking == 5
            ) {
                s = '<span style = "color: green;">---</span>'
                return s
            } else {
                switch (parseInt(status)) {
                    case 1:
                        if (params.data.driver_id != '') {
                            s = '<span style="color:blue;">Hoàn thành</span>'
                        } else {
                            s = '<span style="color:green;">---</span>'
                        }
                        break
                    case 0:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -1:
                        s = '<span style="color:red;">Không thấy xe</span>'
                        break
                    case -2:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -3:
                        s = '<span style="color:red;">Quản trị hủy</span>'
                        break
                    case -4:
                        s =
                            '<span class = "DriverCancelBlink" style="color:red;">Lái xe hủy chuyến</span>'
                        break
                    case 5:
                        s =
                            '<span style="color:red;">Đã X.Lý, Không Đ.Xe</span>'
                        break
                    case -5:
                        s = '<span style="color:red;">Khách hủy</span>'
                        break
                    case 6:
                        s = '<span style="color:red;">Khách hẹn</span>'
                        break
                    default:
                        s = '<span style="color:green;">---</span>'
                        break
                }

                if (params.data.cmd_sb == 3 || params.data.cmd_cd == 'k') {
                    return '<span style="color:red;">Không thấy xe</span>'
                }
                return s
            }

            return element
        } else if (params.data.type == 8) {
            imageElement.src = 'assets/icons/booking_reservation.svg';
            textElenment.appendChild(document.createTextNode('Đặt hẹn'));

            element2.appendChild(element)
            return element2;
        }
    }

    const renderStartAddress = params => {// render start address
        const element = document.createElement('div')
        element.classList.add('parent-div-address')

        const divStartAddress = document.createElement('div')
        divStartAddress.classList.add('div-address')
        const imgStart = document.createElement('img')
        imgStart.src = "assets/icons/markers/marker_start.svg"
        const textStart = document.createElement('span')
        textStart.appendChild(document.createTextNode(params.value))
        divStartAddress.appendChild(imgStart)
        divStartAddress.appendChild(textStart)
        
        if (params.value.length >= 51) {
            const linkDetail = document.createElement('a')
            linkDetail.appendChild(document.createTextNode('Chi tiết'))
            linkDetail.onclick=(e) => {
                setPosition({ x: e.clientX, y: e.clientY })
                setFlagHiddenModalDetail(false)
                setModalDetailType('address')
                setDataDetail({ startAddress: params.data.start_address, endAddress: params.data.end_address })
            }
            divStartAddress.appendChild(linkDetail)
        }

        element.appendChild(divStartAddress)
        
        if (usingSwitchboard == false) {
            if (params.value != null && params.value != '') {
                if (
                    params.data.end_address != null &&
                    params.data.end_address !== ''
                ) {
                    //return (params.value + '.' + '</br>' + params.data.end_address)
                    //console.log('Length end address: ' + params.data.end_address.length)
                    const divEndAddress = document.createElement('div')
                    divEndAddress.classList.add('div-address')
                    const imgEnd = document.createElement('img')
                    imgEnd.src = "assets/icons/markers/marker_end.svg"
                    const textEnd = document.createElement('span')
                    textEnd.appendChild(document.createTextNode(params.data.end_address))
                    divEndAddress.appendChild(imgEnd)
                    divEndAddress.appendChild(textEnd)

                    if (params.data.end_address.length >= 51) {
                        const linkDetail2 = document.createElement('a')
                        linkDetail2.appendChild(document.createTextNode('Chi tiết'))
                        linkDetail2.onclick=(e) => {
                            setPosition({ x: e.clientX, y: e.clientY })
                            setFlagHiddenModalDetail(false)
                            setModalDetailType('address')
                            setDataDetail({ startAddress: params.data.start_address, endAddress: params.data.end_address })
                        }
                        divEndAddress.appendChild(linkDetail2)
                    }

                    element.appendChild(divEndAddress)
                }
            } else {
                return ''
            }
            return element
        } else {
            return params.value
        }
    }

    const renderDriverPhone = params => {
        if (usingSwitchboard == false) {
            if (params.value && params.value !== null && params.value !== '') {
                let arrTemp = params.value.split('-')
                if (arrTemp.length == 2) {
                    return arrTemp[0] + '</br>' + arrTemp[1]
                }
            }
            return ''
        } else {
            if (params.value && params.value !== null && params.value !== '') {
                let arrTemp = params.value.split('-')
                if (arrTemp.length == 2) {
                    return arrTemp[0] + '-' + arrTemp[1]
                }
            }
            return ''
        }
    }

    const renderCreatedAt = params => {
        if (usingSwitchboard == false) {
            if (params.value.indexOf(' ') > -1) {
                let arrTemp = params.value.split(' ')
                return arrTemp[0] + '</br>' + arrTemp[1]
            } else {
                return params.value
            }
        } else {
            return params.value
        }
    }

    const renderOperator = userId => {
        let operator = state.users.filter(user => user.id == userId)
        if (operator.length == 1) return operator[0].name
        else return ''
    }

    const getTypeBookingFrom = typeCoordinator => {
        if (typeCoordinator != 10 && typeCoordinator != 11) return 'Tổng đài';
        if (typeCoordinator == 10) return 'App KH';
        if (typeCoordinator == 11) return 'Khách vẫy';
        return '';
    }
    const renderOtherInfo = params => {
        let divBookingMode = document.createElement('div')
        let lbBookingMode = document.createElement('label')
        lbBookingMode.appendChild(document.createTextNode('Điều xe: '))
        lbBookingMode.classList.add('label-other-info')
        let bookingMode = document.createElement('span')
        bookingMode.appendChild(document.createTextNode(params.data.check_sb == 0 ? 'Tự động' : 'Chỉ định'))
        bookingMode.classList.add('content-other-info')
        divBookingMode.appendChild(lbBookingMode)
        divBookingMode.appendChild(bookingMode)

        let divTypeFrom = document.createElement('div')
        let lbTypeFrom= document.createElement('label')
        lbTypeFrom.appendChild(document.createTextNode('Chuyến: '))
        lbTypeFrom.classList.add('label-other-info')
        let typeFrom = document.createElement('span')
        typeFrom.appendChild(document.createTextNode(params.data.type == 1 ? 'Đàm' : params.data.type == 0 ? 'Tự Động(App)' : ''))
        typeFrom.classList.add('content-other-info')
        divTypeFrom.appendChild(lbTypeFrom)
        divTypeFrom.appendChild(typeFrom)

        let divBookinFrom = document.createElement('div')
        let lbBookingFrom = document.createElement('label')
        lbBookingFrom.appendChild(document.createTextNode('Nguồn: '))
        lbBookingFrom.classList.add('label-other-info')
        let bookingFrom = document.createElement('span')
        let textBookingFrom = getTypeBookingFrom(params.data.type);
        bookingFrom.appendChild(document.createTextNode(textBookingFrom))
        bookingFrom.classList.add('content-other-info')
        divBookinFrom.appendChild(lbBookingFrom)
        divBookinFrom.appendChild(bookingFrom)

        let divOperator = document.createElement('div')
        let lbOperator = document.createElement('label')
        lbOperator.appendChild(document.createTextNode('Người điều: '))
        lbOperator.classList.add('label-other-info')
        let operator = document.createElement('span')
        operator.appendChild(document.createTextNode(renderOperator(params.data.user_id)))
        operator.classList.add('content-other-info')
        divOperator.appendChild(lbOperator)
        divOperator.appendChild(operator)

        let divOtherInfo = document.createElement('div')
        divOtherInfo.className = 'div-other'
        divOtherInfo.append(divBookingMode, divTypeFrom, divBookinFrom, divOperator)
        return divOtherInfo
    }

    const renderNote = params => {
        if (params.value != null && params.value !== '') {
            let divNote = document.createElement('div')
            divNote.classList.add('div-note')
            let pNote = document.createElement('p');
            pNote.classList.add('note-content')
            pNote.appendChild(document.createTextNode(params.value))
            divNote.appendChild(pNote)
            if (params.value.length >= 113) {
                let linkDetail = document.createElement('a')
                linkDetail.appendChild(document.createTextNode('Chi tiết'))
                linkDetail.classList.add('link-detail-note')
                linkDetail.onclick = (e) => {
                    setPosition({ x: e.clientX, y: e.clientY })
                    setFlagHiddenModalDetail(false)
                    setModalDetailType('reason')
                    setDataDetail({ note: params.data.note })
                }
                divNote.appendChild(linkDetail)
            }
            return divNote
        }else return ''
    }

    const renderService = params => {
        // render service function
        if (usingSwitchboard == false) {
            if (params.value) {
                let divService = document.createElement('div')
                let serviceName = document.createElement('p')
                serviceName.classList.add('service-name')
                divService.appendChild(serviceName)

                if (params.value.toString().indexOf(';') > -1) {
                    let arrTemp = params.value.split(';')
                    if (arrTemp.length == 3) {
                        let service = arrServices.find(
                            sr => sr.id == parseInt(arrTemp[0].trim())
                        )
                        if (service) {
                            serviceName.appendChild(document.createTextNode(service.name))
                            let carInfo = document.createElement('p')
                            carInfo.classList.add('car-info')
                            divService.appendChild(carInfo)
                            carInfo.appendChild(document.createTextNode(arrTemp[1] + ' (' + arrTemp[2] +')'))
                            if (params.data.driver_phone_render) {
                                let arrStr = params.data.driver_phone_render.split('-')
                                let driverName = document.createElement('p')
                                driverName.classList.add('driver-name')
                                driverName.appendChild(document.createTextNode(arrStr[0]))
                                divService.appendChild(driverName)
                                let driverPhone = document.createElement('p')
                                driverPhone.classList.add('driver-phone')
                                driverPhone.appendChild(document.createTextNode(arrStr[1]))
                                divService.appendChild(driverPhone)
                            }
                            return divService
                        }
                    }
                }else {
                    let service = arrServices.filter(
                        service => service.id == params.value
                    )
                    if (service.length > 0) serviceName.appendChild(document.createTextNode(service[0].name))
                    return divService
                }
            }
        } else {
            if (params.value) {
                let serviceRender = ''
                if (params.value.toString().indexOf(';') > -1) {
                    let arrTemp = params.value.split(';')
                    if (arrTemp.length == 3) {
                        let service = arrServices.find(
                            sr => sr.id == parseInt(arrTemp[0].trim())
                        )
                        if (service) serviceRender = service.name
                    }
                } else {
                    let service = arrServices.filter(
                        service => service.id == params.value
                    )
                    if (service.length == 1) serviceRender = service[0].name
                    return serviceRender
                }
                return serviceRender
            }
        }
    }

    const renderIconStatus = params => {
        let status = params.value
        let statusBooking = params.data.status_booking
        let type = params.data.type
        let cancel = params.data.cancel

        if (type == 0) {
            switch (status) {
                case 0:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.dispatching]
                    };border-radius:4px"></div>`
                case 1:
                    if (statusBooking == 3) {
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.FoundDriver]
                        };border-radius:4px"></div>`
                    } else {
                        switch (statusBooking) {
                            case 0:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DriverOnTheWay
                                    ]
                                };border-radius:4px"></div>`
                            case 1:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.PassengerOnBoard
                                    ]
                                };border-radius:4px"></div>`
                            case 2:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.finished
                                    ]
                                };border-radius:4px"></div>`
                            case -3:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DriverCancel
                                    ]
                                };border-radius:4px"></div>`
                            case -4:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                };border-radius:4px"></div>`
                            case -5:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                };border-radius:4px"></div>`
                            default:
                                return `<div style="width:15px;height:15px;background-color:green;border-radius:4px"></div>`
                        }
                    }
                case -1:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.waitingForProcess]
                    };border-radius:4px"></div>`
                case -2:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.error]
                    };border-radius:4px"></div>`
                case -3:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -4:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -5:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                default:
                    return `<div style="width:15px;height:15px;background-color:black;border-radius:4px"></div>`
            }
        } else if (type == 1) {
            switch (parseInt(status)) {
                case 1:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.finished]
                    };border-radius:4px"></div>`
                case 0:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.dispatching]
                    };border-radius:4px"></div>`
                case -1:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.waitingForProcess]
                    };border-radius:4px"></div>`
                case -2:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.error]
                    };border-radius:4px"></div>`
                case -3:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -4:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case 5:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -5:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case 6:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.default]
                    };border-radius:4px"></div>`
                default:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.default]
                    };border-radius:4px"></div>`
            }
        } else if (type == 10 || type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        //Đang đi đón khách
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.DriverOnTheWay]
                        };border-radius:4px"></div>`
                    } else if (cancel == 1) {
                        //KH Hủy;
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.CustomerCancel]
                        };border-radius:4px"></div>`
                    } else if (cancel == 2) {
                        //TX Hủy;
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.DriverCancel]
                        };border-radius:4px"></div>`
                    } else if (cancel == 3) {
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.AddCancel]
                        };border-radius:4px"></div>`
                        //TX Hủy;
                    } else {
                        //Đang tìm xe
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.dispatching]
                        };border-radius:4px"></div>`
                    }
                    break
                case 1:
                    //Đang chở khách
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.PassengerOnBoard]
                    };border-radius:4px"></div>`
                case 2:
                    //Hoàn thành
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case 3:
                    //Đã thấy xe
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.FoundDriver]
                    };border-radius:4px"></div>`
                case -1:
                    //Không thấy xe
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.waitingForProcess]
                    };border-radius:4px"></div>`
                default:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.finished]
                    };border-radius:4px"></div>`
            }
        } else if (type == 8) {
            return `<div style="width:15px;height:15px;background-color:${
                bookingStatusColor[bookingStatusName.pending]
            };border-radius:4px"></div>`
        }
    }

    const renderBookingFrom = params => {
        switch (params.data.type) {
            case 10:
                return 'App KH'
            case 11:
                return 'Khách vẫy'
            default:
                return 'Tổng đài'
        }
    }

    const renderBookingId = params => {
        return (
            '<p style=";font-size:14px;font-weight:600;color: #219737">' +
                params.value +
            '</p>' +
            '<p style="font-weight:400;color:#616161;font-size:12px;letter-spacing:.3px">' +
                params.data.created_at +
            '</p>' 
        )
    }

    const convertPhoneNumber = phone => {
        if (!phone) return ''
        return phone.substring(0, 4) + '.' + phone.substring(4, 7) + '.' + phone.substring(7);
    }

    const renderCustomerPhone = params => {
        if (
            params.data.customer_name === 'Khách tổng đài' ||
            params.data.customer_name === 'Khách Tổng Đài'
        ) {
            return (
                '<p style="font-weight:500;color:#282828;font-size:14px">' +
                    params.data.customer_name +
                '</p>' +
                '<p style="font-weight:400;color:#616161;font-size:14px;letter-spacing:.3px">' +
                    convertPhoneNumber(params.value) +
                '</p>' 
            )
        } else {
            if (params.value !== null && params.value !== '') {
                if (usingSwitchboard == false) {
                    return (
                        '<p style="font-weight:500;color:#282828;font-size:14px">' +
                            params.data.customer_name +
                        '</p>' +
                        '<p style="font-weight:400;color:#616161;font-size:14px;letter-spacing:.3px">' +
                            convertPhoneNumber(params.value) +
                        '</p>' 
                    )
                }else {
                    return (
                        '<span style="font-style:;font-weight:normal">' +
                            params.value +
                        '</span>'
                    )
                }
            } else {
                return (
                    '<span style="font-style:;font-weight:normal">' +
                        params.data.customer_name +
                    '</span>'
                )
            }
        }
    }

    //console.log('grid active booking rerender...')

    function onCellValueChanged(params) {
        //console.log('Callback onCellValueChanged:', params);
    }

    function onPasteStart(params) {
        //console.log('Callback onPasteStart:', params);
    }

    function onPasteEnd(params) {
        //console.log('Callback onPasteEnd:', params);
    }

    function BtnCancel() {}

    BtnCancel.prototype.init = function (params) {
        this.params = params

        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-cancel'>
                <img src="${iconRemove}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-cancel')
        this.eventClickListener = e => {
            e.preventDefault()

            setOpenDialog(false)

            if (!showDialogCancel)
                cancelBooking(
                    params.api,
                    params.data,
                    socket,
                    params.data.reason_cancel
                )
            else {
                setCoor(params.data)
                setOpenDialog(true)
            }

            //Remove the trip on trips context
            actions.removeTrip(params.data.id)
        }
        this.eButton.addEventListener('click', this.eventClickListener)
        switch (params.data.type) {
            case false:
                if (
                    params.data.status == 0 ||
                    params.data.status == -4 ||
                    params.data.status == -1 ||
                    (params.data.status == 1 &&
                        (params.data.status_booking == 3 ||
                            params.data.status_booking == 0))
                ) {
                    this.eButton.style.display = 'initial'
                } else {
                    this.eButton.style.display = 'none'
                }
                break
            default:
                this.eButton.style.display = 'initial'
                break
        }
    }

    BtnCancel.prototype.getGui = function () {
        return this.eGui
    }

    //Rebooing on active grid
    const rebooking = function (socket, dataRebooking) {// rebooking function
        // console.log('data rebooking: ')
        // console.log(dataRebooking)

        dataRebooking.type = 1001
        socket.emit('booking', dataRebooking, function (data) {})
    }

    const rebookingOnActiveGrid = async (gridApi, record, socket) => {
        //rebooking on grid active
        switch (record.type) {
            case 0:
                if (
                    record.status == -1 ||
                    record.status == -4 ||
                    record.status == -2
                ) {
                    let dataUpdate = {
                        id: record.id,
                        user_id: userId,
                        status: 0,
                        type_update:
                            record.status == -1
                                ? rebookingTypes.REBOOKING_CAR_NOT_FOUND
                                : rebookingTypes.REBOOKING_DRIVER_CANCEL,
                    }

                    const resUpdate = await updateTypeCoordinatorApi(dataUpdate)
                    if (resUpdate.success == true) {
                        let rowNode = gridApi.getRowNode(record.id)

                        if (rowNode) {
                            if (
                                (record.status == -1 || record.status == -2) &&
                                !record.createNewBooking
                            ) {
                                rowNode.setDataValue('status', 0)
                                rebooking(socket, {
                                    coordinator_id: record.id,
                                    driver_id: record.driverAssigned,
                                })
                            } else {
                                rowNode.setDataValue('list_vh', '')
                                rowNode.setDataValue('vh_code', '')
                                rowNode.setDataValue('list_arrived', '')
                                rowNode.setDataValue('status', 0)
                                rowNode.setDataValue('note', record.note)
                                rowNode.setDataValue('reason_cancel', '')
                                rowNode.setDataValue('service_render', '')
                                rowNode.setDataValue('driver_phone_render', '')

                                rebookingDriverCancel(record, socket)

                                let dataEditBooking = {
                                    user_id: userId,
                                    editbooking_type:
                                        editBookingTypes.REBOOKING_DRIVER_CANCEL, //Emit rebooking when  driver cancel to an other partner
                                    coordinator_id: record.id,
                                    note: record.note,
                                }
                                socket.emit(
                                    'edit-booking',
                                    dataEditBooking,
                                    function () {}
                                )
                            }
                        }
                    } else {
                        // enqueueSnackbar('Có lỗi xảy ra, vui lòng thử lại', {
                        //     variant: 'warning'
                        // })
                    }
                } else {
                    //must cancel before rebooking
                    if (
                        record.status == 0 ||
                        (record.status == 1 &&
                            (record.status_booking == 3 ||
                                record.status_booking == 1 ||
                                record.status_booking == 0))
                    ) {
                        cancelBooking(
                            gridApi,
                            record,
                            socket,
                            'Tổng đài hủy chuyến'
                        )
                    }
                }
                break
            case 1:
                break
            case 8:
                cancelBooking(gridApi, record, socket, record.reason_cancel)
                break
            case 10:
                break
        }
    }

    function BtnRebooking() {}

    BtnRebooking.prototype.init = function (params) {
        this.params = params

        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-refresh'>
                <img src="${iconRefresh}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-refresh')

        this.eventClickListener = e => {
            //setOpenDialog(false)
            e.preventDefault()

            rebookingOnActiveGrid(params.api, params.data, socket)
        }
        this.eButton.addEventListener('click', this.eventClickListener)

        if (
            (params.data.type == 0 &&
                (params.data.status == -1 || params.data.status == -4)) ||
            params.data.type == 8
        ) {
            this.eButton.style.display = 'initial'
        } else {
            if (
                params.data.type == 10 &&
                (params.data.status_booking == -1 || params.data.cancel == 2)
            ) {
                this.eButton.style.display = 'initial'
            } else this.eButton.style.display = 'none'
        }
    }

    BtnRebooking.prototype.getGui = function () {
        return this.eGui
    }

    function showIconReBooking (record)  {
        return (record.type == 0 && (record.status == -1 || record.status == -4))
    }

    function showIconCancel (record) {
        return (record.type == 0 && record.status != -3 && record.status != -4 && record.status != -5)
    }


    // Btn action
    function BtnAction () {}
    // render action
    BtnAction.prototype.init = function(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-options'>
                <img src="assets/icons/icon_options.svg"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-options')
        this.eventClickListener = (e) => {
            //console.log(params.data)
            setPosition({
                x: e.clientX,
                y: e.clientY
            })
            setFlagHiddenMenuAction(false)

            setDataAction({
                ...params.data
            })
        }

        this.eButton.addEventListener('click', this.eventClickListener)
    }
    
    BtnAction.prototype.getGui = function() {
        return this.eGui
    }

    const [openDialog, setOpenDialog] = useState(false)
    const [reasonCancel, setReasonCancel] = useState('')

    const _onCloseDialog = () => {
        setOpenDialog(false)
    }

    const _onClickButtonYesDialog = () => {
        cancelBooking(gridApi, coor, socket, reasonCancel)
        setOpenDialog(false)
        setReasonCancel('')
    }

    const _getReasonCancel = e => {
        setReasonCancel(e.target.value)
    }

    const _onKeyDownInputReasonCancel = e => {
        if (e.key.toLowerCase() === 'enter') {
            cancelBooking(gridApi, coor, socket, reasonCancel)
            setOpenDialog(false)
            setReasonCancel('')
        }
    }

    const [position, setPosition] = useState({x: 0, y: 0})
    const [flagHiddenMenuContext, setFlagHiddenMenuContext] = useState(true)
    
    const _onCellContextMenu = params => {// open menu context
        setPosition({
            x: params.event.clientX,
            y: params.event.clientY
        })
        //setFlagHiddenMenuContext(false)
    }

    const _clickOutSideMenuContext = () => {
        setFlagHiddenMenuContext(true)
    }

    const [flagHiddenMenuAction, setFlagHiddenMenuAction] = useState(true)
    const [flagHiddenModalDetail, setFlagHiddenModalDetail] = useState(true)
    const [modalDetailType, setModalDetailType] = useState('')
    const [dataDetail, setDataDetail] = useState({})
    
    const [dataAction, setDataAction] = useState({
    })

    const _clickOutSideMenuActions = () => {
        setFlagHiddenMenuAction(true)
    }

    const _rebooking = () => {
        rebookingOnActiveGrid(gridApi, dataAction, socket)
        setFlagHiddenMenuAction(true)
    }

    const [flagHiddenModalConfirm, setFlagHiddenModalConfirm] = useState(true)
    const _showModalConfirm = () => {
        setFlagHiddenModalConfirm(false)
        setFlagHiddenMenuAction(true)
    }
    const _hideModalConfirm = () => {
        setFlagHiddenModalConfirm(true)
    }

    const _confirmCancelled = (reasonCancelled) => {
        cancelBooking(gridApi, dataAction, socket, reasonCancelled)

        setFlagHiddenModalConfirm(true)

        //Remove the trip on trips context
        setTimeout(() => {
            actions.updateTrips({
                updateType: 'cancel-trip',
                ...dataAction,
                status: -3
            })
        }, 1000)
    }

    const _closeModalDetail = () => {
        setFlagHiddenModalDetail(true)
    }

    return (
        <div className="grid-wrapper">
            <div
                id="myGrid"
                style={{
                    height: '100%',
                    width: '100%',
                }}
                className={
                    usingSwitchboard
                        ? 'ag-theme-alpine ag-switchboard'
                        : 'ag-theme-alpine'
                }
            >
                <AgGridReact // Grid view
                    rowData={activeTrips}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 30,
                        editable: true,
                        resizable: true,
                        wrapText: usingSwitchboard ? false : true,
                        autoHeight: true,
                    }}
                    //pinnedTopRowData={getPinnedTopData()}
                    //pinnedBottomRowData={getPinnedBottomData()}
                    onGridReady={onGridReady}
                    onCellKeyDown={onCellKeyDown}
                    onCellKeyPress={onCellKeyPress}
                    cellEditingStarted={cellEditingStarted}
                    onCellMouseDown={_onCellMouseDown}
                    onCellMouseOut={_onCellMouseOut}
                    getRowNodeId={data => data.id}
                    enableCellTextSelection={true}
                    /*--- Enterprise version ---*/
                    //enableRangeSelection={true}
                    //allowContextMenuWithControlKey={true}
                    //getContextMenuItems={getContextMenuItems}
                    //

                    //rowSelection={'multiple'}

                    //onRowClicked={_onRowClicked}
                    onRowDoubleClicked={_onRowDoubleClicked}
                    onCellClicked={_onCellClicked}
                    onCellContextMenu={_onCellContextMenu}

                    // onCellValueChanged={onCellValueChanged}
                    // onPasteStart={onPasteStart}
                    // onPasteEnd={onPasteEnd}

                    //suppressCellSelection={true}
                >
                    <AgGridColumn
                        headerName=""
                        field="status"
                        maxWidth={40}
                        minWidth={30}
                        cellRenderer={renderIconStatus}
                        cellStyle={() => {
                            return {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 'auto',
                                height: '100%',
                                marginRight: '20px',
                            }
                        }}
                        hide={usingSwitchboard ? true : true}
                    />
                    <AgGridColumn
                        headerName="ID"
                        field="id"
                        maxWidth={170}
                        minWidth={90}
                        hide={usingSwitchboard ? true : false}
                        cellRenderer={renderBookingId}
                        cellStyle={() => {
                            return {
                                // wordWrap: 'normal',
                                // whiteSpace: 'nowrap'
                            }
                        }}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName="Thời gian"
                        field="created_at"
                        minWidth={110}
                        cellRenderer={renderCreatedAt}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="KHÁCH HÀNG"
                        field="customer_phone"
                        cellRenderer={renderCustomerPhone}
                        minWidth={115}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName="ĐIỂM ĐÓN TRẢ"
                        field="start_address"
                        minWidth={usingSwitchboard ? 200 : 350}
                        maxWidth={350}
                        cellRenderer={renderStartAddress}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName="Điểm đón"
                        field="start_point"
                        hide={true}
                        editable={true}
                    />
                    <AgGridColumn
                        headerName="Điểm trả"
                        field="end_address"
                        hide={true}
                        editable={true}
                    />
                    <AgGridColumn
                        headerName="Điểm trả"
                        field="end_point"
                        hide={true}
                        editable={true}
                    />
                    <AgGridColumn
                        minWidth={170}
                        headerName="TRẠNG THÁI"
                        field="status"
                        cellRenderer={renderStatus}
                        cellStyle={{ 
                            whiteSpace: 'wrap', 
                            color: 'red', 
                            lineHeight: '20px'
                        }}
                        editable={false}
                    />
                    <AgGridColumn
                        headerName="LOẠI XE/TÀI XẾ"
                        field="service_render"
                        cellRenderer={renderService}
                        minWidth={usingSwitchboard ? 100 : 150}
                        cellStyle={{ lineHeight: '20px' }}
                        editable={false}
                    />
                    <AgGridColumn
                        minWidth={120}
                        headerName="Tài xế"
                        field="driver_phone"
                        hide={true}
                    />
                    <AgGridColumn
                        minWidth={120}
                        headerName="Tài xế"
                        field="driver_phone_render"
                        //cellRenderer={renderDriverPhone}
                        hide={usingSwitchboard ? true : true}
                    />
                    <AgGridColumn
                        headerName="Xe nh.điểm"
                        field="list_vh"
                        editable={false}
                        //cellRenderer={renderService}
                        minWidth={110}
                        hide={usingSwitchboard ? false : true}
                    />
                    <AgGridColumn
                        minWidth={100}
                        headerName="Xe đ.điểm"
                        field="list_arrived"
                        editable={false}
                        cellRenderer={params => {
                            return params.value
                        }}
                        hide={usingSwitchboard ? false : true}
                    />
                    <AgGridColumn
                        minWidth={80}
                        headerName="Xe đón"
                        field="vh_code"
                        editable={false}
                        cellRenderer={params => {
                            return params.value
                        }}
                        hide={usingSwitchboard ? false : true}
                    />
                    <AgGridColumn
                        minWidth={140}
                        headerName="L ĐTV"
                        field="cmd_cd"
                        editable={true}
                        cellRenderer={renderCmdCd}
                        hide={usingSwitchboard ? false : true}
                    />
                    <AgGridColumn
                        minWidth={80}
                        headerName="L ĐHV"
                        field="cmd_sb"
                        cellRenderer={renderCmdSb}
                        hide={usingSwitchboard ? false : true}
                    />
                    
                    <AgGridColumn
                        minWidth={167}
                        headerName="Trạng thái booking"
                        field="status_booking"
                        hide={true}
                    />
                    <AgGridColumn
                        minWidth={167}
                        headerName="Hủy App KH"
                        field="cancel"
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="Đ`lại"
                        field="Điều lại"
                        minWidth={70}
                        maxWidth={70}
                        cellRenderer={BtnRebooking}
                        cellStyle={() => {
                            return {
                                border: 'none',
                            }
                        }}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="Hủy"
                        field="Hủy"
                        minWidth={70}
                        maxWidth={70}
                        cellRenderer={BtnCancel}
                        cellStyle={() => {
                            return {
                                border: 'none',
                            }
                        }}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="C.Đàm"
                        field="C.Đàm"
                        minWidth={80}
                        maxWidth={80}
                        cellRenderer={TransferSwitchboardAction}
                        cellStyle={() => {
                            return {
                                border: 'none',
                            }
                        }}
                        hide={usingSwitchboard ? false : true}
                    />
                    <AgGridColumn
                        headerName="LD H.Chuyến"
                        field="reason_cancel"
                        //minWidth={120}
                        minWidth={usingSwitchboard ? 110 : 130}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="TT KHÁC"
                        field="note"
                        editable={false}
                        minWidth={usingSwitchboard ? 130 : 150}
                        cellRenderer={renderOtherInfo}
                        cellStyle={{lineHeight: '24px'}}
                    />
                    <AgGridColumn
                        headerName="GHI CHÚ"
                        field="note"
                        editable={false}
                        minWidth={usingSwitchboard ? 130 : 250}
                        maxWidth={250}
                        cellRenderer={renderNote}
                        //cellClass={ params => { return ['note-content'] }}
                    />
                    <AgGridColumn
                        headerName="HĐ"// action coloumn
                        editable={true}
                        minWidth={50}
                        maxWidth={50}
                        cellRenderer={BtnAction}
                    />
                    <AgGridColumn
                        headerName="Đ.Xe" //Booking assign driver
                        field="check_sb"
                        minWidth={80}
                        maxWidth={80}
                        hide={usingSwitchboard ? true : true}
                        cellRenderer={params => {
                            if (params.value == 0) {
                                return 'Tự động'
                            } else if (params.value == 1) {
                                return 'Chỉ định'
                            } else {
                                return 'Tự động'
                            }
                        }}
                    />
                    <AgGridColumn
                        headerName="Nguồn"
                        field="type"
                        cellRenderer={renderBookingFrom}
                        hide={usingSwitchboard ? true : true}
                        minWidth={80}
                        maxWidth={80}
                    />
                    <AgGridColumn
                        headerName="Ng.Điều"
                        field="user_id"
                        minWidth={100}
                        maxWidth={100}
                        cellRenderer={renderOperator}
                        hide={usingSwitchboard ? true : true}
                    />
                </AgGridReact>
            </div>
            <FormDialog
                dialogTitle="Hủy chuyến"
                dialogContentText="Bạn có chắc chắn muốn hủy chuyến không ?"
                openDialog={openDialog && showDialogCancel}
                handleCloseDialog={_onCloseDialog}
                handleClickButtonYesDialog={_onClickButtonYesDialog}
                getReasonCancel={_getReasonCancel}
                onKeyDown={_onKeyDownInputReasonCancel}
            />
            <MenuContext 
                x={position.x} 
                y={position.y} 
                hidden={flagHiddenMenuContext}
                clickOutSideMenuContext={_clickOutSideMenuContext}
            />
            <MenuActions 
                x={position.x} 
                y={position.y} 
                hidden={flagHiddenMenuAction}
                clickOutSideMenuContext={_clickOutSideMenuActions}
                showModalConfirm={_showModalConfirm}
                rebooking={_rebooking}
                data={dataAction}
            />
            <ModalConfirmCancel 
                className={!isSidebarOpen? 'modal-sidebarclose' : ''}
                hidden={flagHiddenModalConfirm}
                hideModalConfirm={_hideModalConfirm}
                confirmCancelled={_confirmCancelled}
            />

            <ModalDetail
                type={modalDetailType}
                x={position.x} 
                y={position.y} 
                data={dataDetail}
                hidden={flagHiddenModalDetail}
                closeModalDetail={_closeModalDetail}
            />
        </div>
    )
}

export default GridBookingActive

function getPinnedTopData() {
    return [
        {
            firstName: '##',
            lastName: '##',
            gender: '##',
            address: '##',
            mood: '##',
            country: '##',
        },
    ]
}
function getPinnedBottomData() {
    return [
        {
            firstName: '##',
            lastName: '##',
            gender: '##',
            address: '##',
            mood: '##',
            country: '##',
        },
    ]
}
function getCharCodeFromEvent(event) {
    event = event || window.event
    return typeof event.which === 'undefined' ? event.keyCode : event.which
}
function isCharNumeric(charStr) {
    return !!/\d/.test(charStr)
}
function isKeyPressedNumeric(event) {
    var charCode = getCharCodeFromEvent(event)
    var charStr = String.fromCharCode(charCode)
    return isCharNumeric(charStr)
}
