export const dateNow = () => {
    return converTZ(new Date())
}

export const converTZ = date => {
    let timeZone = 7

    let newDate = new Date(
        date.toISOString().replace(/T/, ' ').replace(/\..+/, '')
    )

    newDate.setHours(date.getHours() + timeZone)

    return newDate
}
