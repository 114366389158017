import {isNewBookingOpenState} from 'app/_shared/recoil/new-booking/atom'
import classNames from 'classnames'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Rnd} from 'react-rnd'
import {useRecoilState} from 'recoil'
import {
    getLocalStorage,
    localStorageKeys,
    setLocalStorage,
} from 'services/localStorage'
import {NewBookingContextProvider, useNewBookingContext} from '../context'
import actions from '../context/actions'
import {defaultRndConfig} from '../context/initialState'
import '../scss/NewBookingContainer.scss'
import NewBooking from './NewBooking'

import ViewContext from 'app/_shared/view-context'
import {Slide, Zoom} from '@mui/material'

const {useViewContext} = ViewContext

const NewBookingContainer = () => {
    const [{usingSwitchboard}] = useViewContext()

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    useEffect(() => {
        if (usingSwitchboard) setIsNewBookingOpen(true)
    }, [usingSwitchboard])

    return (
        <Slide
            in={usingSwitchboard ? true : isNewBookingOpen}
            //in={true}
        >
            <div
                className={classNames('NewBookingContainer', {
                    NewBookingContainerHide: usingSwitchboard ? false : !isNewBookingOpen
                })}
            >
                <NewBookingContextProvider>
                    <DragAndDrop />
                </NewBookingContextProvider>
            </div>
        </Slide>
    )
}

export default NewBookingContainer

const DragAndDrop = () => {
    const [{rndConfig}, dispatch] = useNewBookingContext()
    const [{usingSwitchboard}] = useViewContext()

    const onDragStop = (event, data) => {
        const newConfig = {x: data.x, y: data.y}
        dispatch(actions.rnd.setRndConfig(newConfig))
    }

    const onResizeStop = (e, direction, ref) => {
        const wh = {
            width: clearCssPx(ref.style.width),
            height: clearCssPx(ref.style.height),
        }
        const xy = computePosition(direction, wh, rndConfig)

        dispatch(actions.rnd.setRndConfig({...wh, ...xy}))
    }

    return (
        <Rnd
            className="Rnd"
            size={usingSwitchboard ? {width: 535, height: 445} : rndConfig}
            position={rndConfig}
            bounds="parent"
            minWidth={usingSwitchboard ? 535 : defaultRndConfig.width}
            minHeight={usingSwitchboard ? 445 : defaultRndConfig.height}
            //dragHandleClassName="Title"
            dragHandleClassName="NewBooking"
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
        >
            <NewBooking/>
        </Rnd>
    )
}

const computePosition = (d, n, o) => {
    let x = o.x
    let y = o.y
    switch (d) {
        case 'topLeft':
            x = o.x - (n.width - o.width)
            y = o.y - (n.height - o.height)
            break
        case 'topRight':
            y = o.y - (n.height - o.height)
            break
        case 'bottomLeft':
            x = o.x - (n.width - o.width)
            break
        case 'top':
            y = o.y - (n.height - o.height)
            break
        case 'left':
            x = o.x - (n.width - o.width)
            break
    }
    return {x, y}
}

const clearCssPx = s => parseInt(s.replace('px', ''))
