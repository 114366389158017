import { DateTimePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { Button, createTheme, TextField, ThemeProvider } from '@mui/material'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNewBookingContext, useNewBookingDispatch } from '../context'
import actions from '../context/actions'
import moment from 'moment'
import { useSnackbar } from 'notistack'

const theme = createTheme({
    palette: {
        primary: {
            main: '#edab25',
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                    fontSize: '14px',
                    height: '34px',
                    //background: '#212121',
                    background: '#ffffff',
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: '8px',
                    borderRadius: '4px',
                    width: '50% !important',
                    minWith: '0',
                    border: '1px solid #e6e9ec',
                    outline: 'none',
                    ':focus': {},
                },
                input: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    width: '100%',
                    border: '0px !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                    //padding: '0.125rem',
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    color: '#181918',
                    fontSize: '14px',
                    fontWeight: '400',
                    height: '34px',
                    width: '100% important',
                    border: '0px !important'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    textTransform: 'capitalize',
                    minWidth: '22px',
                    fontWeight: '400',
                    paddingLeft: 2,
                    paddingRight: 2,
                    border: '0px !important',
                    position: 'absolute',
                    right: '4px',
                    fontFamily: 'Roboto',
                    fontSize: '14px',

                },
            },
        },
    },
})

const PickupTimeField = props => {
    const { title, className, onChange } = props

    const [
        {
            handlingTrip: {
                info: { pickupTime },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const [_value, _setValue] = useState(pickupTime)
    const [_open, _setOpen] = useState(false)
    const nowPlaceholder = !_value ? 'Chọn thời gian đón' : null

    const { enqueueSnackbar } = useSnackbar()

    const _onChange = newValue => {
        if (newValue) {
            _setValue(newValue)

            if (newValue != null) {
                let tiemTemp = moment(newValue).valueOf()
                dispatch(actions.tripInfo.setPickupTime(newValue))
                dispatch(actions.tripInfo.setOnlyOneCoor(true))
            }
        }
    }

    const _onClose = value => {
        _setOpen(false)
    }

    useEffect(() => {
        _setValue(pickupTime)
    }, [pickupTime])

    return (
        <div className={classNames('PickUpTimeWrapper', className)}>
            {title && <label htmlFor={props.name}>{title}</label>}
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        open={_open}
                        value={_value}
                        ampm={false}
                        minutesStep={1}
                        disableOpenPicker
                        disableMaskedInput
                        inputFormat={`HH:mm:00 dd/MM/yyyy`}
                        onClose={_onClose}
                        onChange={_onChange}
                        InputProps={{
                            endAdornment: (
                                <Button
                                    variant="text"
                                    tabIndex={-1}
                                    onClick={e => {
                                        e.stopPropagation()
                                        _setValue(null)
                                    }}
                                >
                                    <img src='assets/icons/calendar.svg' />
                                </Button>
                            ),
                            onClick: () => {
                                _setOpen(true)
                            },
                        }}
                        renderInput={props => (
                            <TextField
                                {...props}
                                inputProps={{
                                    ...props.inputProps,
                                    placeholder: nowPlaceholder,
                                }}
                            />
                        )}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    )
}

export default PickupTimeField
