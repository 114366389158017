const prefix = 'com.emddi.booking'

export const localStorageKeys = {
    sideBarOpen: 'sideBarOpen',
    user: 'user',
    token: 'token',
    socketToken: 'socketToken',
    services: 'services',
    newBookingRndCfg: 'nb-rnd-cfg',
    userSettings: 'userSettings',
}

const _safeParse = value => {
    try {
        return JSON.parse(value || false)
    } catch (e) {
        return null
    }
}

export const getLocalStorage = key => {
    const fullKey = `${prefix}.${key}`

    const value = localStorage.getItem(fullKey)

    return _safeParse(value)
}

export const setLocalStorage = (key, value) => {
    const fullKey = `${prefix}.${key}`
    const vValue = JSON.stringify(value)

    return localStorage.setItem(fullKey, vValue)
}

export const removeLocalStorage = key => {
    return localStorage.removeItem(`${prefix}.${key}`)
}
