import userContainerLocaleKeys from './userContainerLocaleKeys'

const userContainerLocale = {
    en: {
        [userContainerLocaleKeys.newBooking]: 'New booking',
        [userContainerLocaleKeys.sideBar.vehicleMap]: 'Map',
        [userContainerLocaleKeys.sideBar.trips]: 'Trips',
        [userContainerLocaleKeys.sideBar.clickToToggle]: 'Click to toggle',
        [userContainerLocaleKeys.waitingCalls.waitingCallList]: 'Waiting Calls',
        [userContainerLocaleKeys.waitingCalls.phoneNumber]: 'Phone',
        [userContainerLocaleKeys.waitingCalls.history]: 'History',
        [userContainerLocaleKeys.waitingCalls.time]: 'Time',
        [userContainerLocaleKeys.waitingCalls.channel]: 'Channel',
        [userContainerLocaleKeys.waitingCalls.customer]: 'Customer',
        [userContainerLocaleKeys.waitingCalls.skip]: 'Skip',
        [userContainerLocaleKeys.sideBar.setting]: 'Setting',
        [userContainerLocaleKeys.sideBar.logout]: 'Logout',
        [userContainerLocaleKeys.sideBar.quickSearch]: 'Abbreviated Coordinates',
        [userContainerLocaleKeys.sideBar.grantRole]: 'Permission Coordinator',
    },

    vi: {
        [userContainerLocaleKeys.newBooking]: 'Tạo chuyến',
        [userContainerLocaleKeys.sideBar.vehicleMap]: 'Bản đồ xe',
        [userContainerLocaleKeys.sideBar.trips]: 'Danh sách chuyến',
        [userContainerLocaleKeys.sideBar.clickToToggle]: 'Nhấn để đóng',
        [userContainerLocaleKeys.sideBar.quickSearch]: 'Toạ độ viết tắt',
        [userContainerLocaleKeys.sideBar.grantRole]: 'Phân Quyền Điều Xe',
        [userContainerLocaleKeys.waitingCalls.waitingCallList]: 'Danh sách cuộc gọi chờ',
        [userContainerLocaleKeys.waitingCalls.phoneNumber]: 'Số ĐT',
        [userContainerLocaleKeys.waitingCalls.history]: 'L.Sử',
        [userContainerLocaleKeys.waitingCalls.time]: 'T.Gian',
        [userContainerLocaleKeys.waitingCalls.channel]: 'Kênh',
        [userContainerLocaleKeys.waitingCalls.customer]: 'KH',
        [userContainerLocaleKeys.waitingCalls.skip]: 'Hoãn',
        [userContainerLocaleKeys.sideBar.setting]: 'Cài đặt',
        [userContainerLocaleKeys.sideBar.logout]: 'Đăng xuất',
    },
}

export default userContainerLocale
