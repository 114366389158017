import ViewActionTypes from '../constants/ViewActionTypes'

const switchPopup = targertState => {
    return {
        type: ViewActionTypes.SWITCH_POPUP,
        payload: {
            switchPopupState: targertState,
        },
    }
}

const SwitchPopupActions = {
    switchPopup,
}

export default SwitchPopupActions
