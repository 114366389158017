import { getUserSettingsApi } from 'api/settings/getUserSettings'
import { setUserSettings } from 'api/settings/setUserSettings'
import ViewActions from 'app/_shared/view-context/actions'
import {
    useViewContext,
    useViewDispatch,
} from 'app/_shared/view-context/ViewContext'
import { useState, useEffect } from 'react'

const Setting = () => {
    const [loading, setLoading] = useState(true)
    const [copied, setCopied] = useState(false)
    const [config, setConfig] = useState({
        line: 0,
        reservationSearchingMinutes: 0,
        emddiSocketToken: '',
    })
    const viewDispatch = useViewDispatch()
    const [viewContextState] = useViewContext()
    useEffect(() => {
        const fetch = async () => {
            try {
                const {
                    data: { success, data, message },
                } = await getUserSettingsApi()
                if (!success) return alert(message)

                setConfig(data?.config)
            } catch (e) {
                console.error(e)
            } finally {
                setLoading(false)
            }
        }

        fetch()
    }, [])

    const copyJwt = async () => {
        setCopied(true)
        await navigator.clipboard.writeText(config.emddiSocketToken)
        setTimeout(() => setCopied(false), 699)
    }

    const onChange = name => e =>
        setConfig(v => ({ ...v, [name]: e.target.value }))

    const onSubmit = async e => {
        e.preventDefault()
        if (loading) return

        setLoading(true)
        const { success, message } = await setUserSettings(config)
        setLoading(false)
        if (!success) alert(message)
        viewDispatch(ViewActions.setUserSettings(config))
    }

    return (
        <div className="Setting">
            <div className='Title'>
                <h3>CÀI ĐẶT</h3>
            </div>
            <form className="SettingForm" disable={loading} onSubmit={onSubmit}>
                <div className="InputWrapper">
                    <label>EMDDI socket JWT</label>
                    <div className="JwtInput">
                        <input value={config.emddiSocketToken} disabled></input>
                        <button onClick={copyJwt} className="" type="button">
                            {copied ? 'Copied' : 'Copy'}
                        </button>
                    </div>
                </div>
                <div className="InputWrapper">
                    <label>Line</label>
                    <input value={config.line} onChange={onChange('line')} />
                </div>
                <div className="InputWrapper">
                    <label>Reservation searching in minutes</label>
                    <input
                        value={config.reservationSearchingMinutes}
                        onChange={onChange('reservationSearchingMinutes')}
                    />
                </div>
                <div className="SubmitWrapper">
                    <button type="submit">{loading ? '...' : 'Save'}</button>
                </div>
            </form>
        </div>
    )
}

export default Setting
