import L from 'leaflet'
import {tripStatus, tripStatusName} from './trips'

export const getMarkerIcon = (status, iconSize = 24) => {
    let image = 'Unavailable.png'

    switch (status) {
        case tripStatus[tripStatusName.vacant]:
            image = 'Vacant.png'
            break
        case tripStatus[tripStatusName.dispatching]:
            image = 'Dispatching.png'
            break
        case tripStatus[tripStatusName.driverOnTheWay]:
            image = 'Driver-on-the-way.png'
            break
        case tripStatus[tripStatusName.pob]:
            image = 'POB.png'
            break
        case tripStatus[tripStatusName.arrivedAndWaiting]:
            image = 'Arrived-and-waiting.png'
            break
        case tripStatus[tripStatusName.droppedOff]:
            image = 'Dropped-Off.png'
            break
        case tripStatus[tripStatusName.waitingForProcess]:
            image = 'Waiting-for-process.png'
            break
        case tripStatus[tripStatusName.unavailable]:
            image = 'Unavailable.png'
            break
        case tripStatus[tripStatusName.startTrip]:
            image = 'marker-start.png'
            break
        case tripStatus[tripStatusName.streetView]:
            image = 'street-view-red-48.png'
            break
        default:
            image = 'Unavailable.png'
    }

    const iconWidth = iconSize

    const icon = L.icon({
        iconUrl: `${process.env.PUBLIC_URL}/assets/map/marker/${image}`,
        iconSize:
            status !== 11 && status !== 12
                ? [iconWidth, iconWidth * 2]
                : [iconWidth, iconWidth],
        iconAnchor: [iconWidth / 2, iconWidth],
    })

    return icon
}

export const addressMarkerTypes = { start: 'start-marker', end: 'end-marker' }

export const getAddressMarker = type => {
    const iconHeight = 40
    const iconWidth = 40
    const icon = L.icon({
        iconUrl: `${process.env.PUBLIC_URL}/assets/map/marker/${type}.png`,
        iconSize: [iconWidth, iconHeight],
        iconAnchor: [iconWidth / 2, iconHeight],
    })

    return icon
}

export const pickUpAddress = () => {
    const iconHeight = 40
    const iconWidth = 40
    return L.icon({
        iconUrl: `${process.env.PUBLIC_URL}/assets/map/marker/pick-marker.png`,
        iconSize: [iconWidth, iconHeight],
        iconAnchor: [iconWidth / 2, iconHeight],
    })
}

export const latLngFromString = string => {
    const array = string.split(' ')
    return L.latLng(array[0], array[1])
}
