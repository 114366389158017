import { bookingApiPrefix } from 'api'
import httpStatus from 'api/httpStatus'
import fetcher from 'services/fetcher'

export const getListService = async () => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/service`)
        let options = []
        if (res) {
            if (res.status && res.status === httpStatus.OK) {
                if (res.data.success) {
                    // options = res.data.data.services.filter(
                    //     service => service.group_service_id === 1
                    // )
                    options = res.data.data.services
                }
            } else {
                if (res.status === httpStatus.UNAUTHORIZED) {
                    // logout user
                }
            }
        }
        return options
    } catch (error) {
        console.log(error)
    }
}

export const getListUsers = async () => {
    try {
        const response = await fetcher.get(`${bookingApiPrefix}/office/user`)
        if (
            response &&
            response.status === httpStatus.OK &&
            response.data.success
        ) {
            let listUsers = response.data.data.users
            //return listUsers.reduce((arr, user) => [...arr, {id: user.user_id, username: user.user_name, checked: true}], [])
            return listUsers.map(({ user_id, user_name }) => ({
                id: user_id,
                name: user_name,
                checked: false
            }))
        }
        return []
    } catch (error) {
        console.log(error)
    }
}

export const getListDriversApi = async () => {
    try {
        const response = await fetcher.get(`${bookingApiPrefix}/driver`)
        if (
            response &&
            response.status === httpStatus.OK &&
            response.data.success
        ) {
            let listDrivers = response.data.data.drivers
            // console.log('drivers on index service: ')
            // console.log(listDrivers)

            return listDrivers.reduce((arr, driver) => {
                //console.log(driver)
                let item = {
                    driver_id: driver.driver_id,
                    is_online: driver.is_online,
                    status: driver.status,
                    full_name: driver.full_name,
                    phone_number: '0' + driver.phone_number.substring(3),
                    vh_code: driver.onlineVehicle
                        ? (driver.onlineVehicle.vehicle.code === "" ? null: driver.onlineVehicle.vehicle.code)
                        : null,
                    location: driver.location,
                    orientation: driver.orientation,
                }
                arr.push(item)
                return arr
            }, [])

            // const numberCarOnlines = listDrivers.reduce((total, driver) =>
            //     driver.is_online ? (total + 1) : total
            // , 0)

            // console.log('number car online: ' + numberCarOnlines)
        }
        return []
    } catch (error) {
        console.log(error)
    }
}

export const sendNotify = async data => {
    try {
        let url = `${bookingApiPrefix}/trip/notify`
        url = url + '?phone=' + data.phone + '&info=' + data.info
        url =
            url +
            '&user_id=' +
            data.user_id +
            '&office_id=' +
            data.office_id +
            '&c_phone=' +
            data.c_phone

        const response = await fetcher.post(url)

        if (response && response.data.success) {
            return response.data.success
        } else {
            return false
        }
    } catch (error) {
        console.log(error)
    }
}

export const getPhoneNumberHistoriesApi = async phoneNumber => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/customer/history`, {
            timeout: 2000,
            params: {
                phoneNumber: phoneNumber,
            },
        })
        if (!(res && res.data)) return { customer_name: '', histories: [] }
        return {customer_name: res.data.data.customer_name, histories: res.data.data.history}
    } catch (err) {
        return { customer_name: '', histories: [] }
    }
}

export const getListServiceByOffice = async () => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/user/user-service/get`)

        if (res.data.success) {
            return res.data.data
        }
    } catch (error) {
        console.log(error)
    }
}

export const updateService = async data => {

    const res = await fetcher.post(
        `${bookingApiPrefix}/service/update`,
        {...data}
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.success
            }
        }
    }
    return false
}

