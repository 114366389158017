import {bookingApiPrefix} from 'api'
import httpStatus from 'api/httpStatus'
import fetcher from 'services/fetcher'


export const searchLocationShortcutApi = async (shortcut, regionId, limit) => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/location/search`, {
            params: {
                item: shortcut,
                region: regionId,
                limit: limit
            },
        })

        if (res.data.success) {
            return res.data.data
        }
    }
    catch (err) {
        console.log(err)
    }
}
export const getAllLocationShortcutApi = async (page, region) => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/location/all`, {
            params: {
                page: page,
                region: region
            },
        })

        if (res.data.success) {
            return res.data.data
        }
    }
    catch (err) {
        console.log(err)
    }
}

export const getAllRegion = async (officeId) => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/region/all`, {
            params: {
                officeId,
            },
        })

        if (res.data.success) {
            return res.data.data
        }
    }
    catch (err) {
        console.log(err)
    }
}

export const createLocation = async location => {
    let data = { ...location }
    const res = await fetcher.post(
        `${bookingApiPrefix}/location/create`,
        data
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.success
            }
        }
    }
    return false
}

export const deleteLocation = async data => {

    const res = await fetcher.put(
        `${bookingApiPrefix}/location/delete`,
        {id : data}
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.success
            }
        }
    }
    return false
}

export const getOffice = async () => {

    const res = await fetcher.get(`${bookingApiPrefix}/office/all`)
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.data
            }
        }
    }
    return false
}

export const createRegion = async region => {
    let data = { ...region }
    const res = await fetcher.post(
        `${bookingApiPrefix}/region/create`,
        data
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data
            }
        }
    }
    return false
}

export const addLocationToElastic = async region => {
    let data = { ...region }
    const res = await fetcher.post(
        `${bookingApiPrefix}/location/elastic/create`,
        data
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.data
            }
        }
    }
    return false
}

export const addRegionToUserRegion = async data => {

    const res = await fetcher.post(
        `${bookingApiPrefix}/location/user-region/create`,
        {...data}
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.success
            }
        }
    }
    return false
}
export const addRegionToUserService = async data => {

    const res = await fetcher.post(
        `${bookingApiPrefix}/user/user-service/region/add`,
        {...data}
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return res.data.success
            }
        }
    }
    return false
}

