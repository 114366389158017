const bookingsKeys = {
    tabHeader: {
        active: 'bookingsKeys.tabHeader.active',
        finished: 'bookingsKeys.tabHeader.finished',
    },
    filter: {
        searchPlaceholder: 'bookingsKeys.filter.searchPlaceholder',

        localOrAffiliates: 'bookingsKeys.filter.localOrAffiliates',
        bookingType: 'bookingsKeys.filter.bookingType',
        status: 'bookingsKeys.filter.status',
        operator: 'bookingsKeys.filter.operator',
        carType: 'bookingsKeys.filter.carType',
        bookingFrom: 'bookingsKeys.filter.bookingFrom',
        paymentMethod: 'bookingsKeys.filter.paymentMethod',
        customerType: 'bookingsKeys.filter.customerType',
    },

    tableHeader: {
        bookingId: 'bookingsKeys.tableHeader.bookingId',
        address: 'bookingsKeys.tableHeader.address',
        vehicle: 'bookingsKeys.tableHeader.vehicle',
        driver: 'bookingsKeys.tableHeader.driver',
        passenger: 'bookingsKeys.tableHeader.passenger',
        payment: 'bookingsKeys.tableHeader.payment',
        note: 'bookingsKeys.tableHeader.note',
        operator: 'bookingsKeys.tableHeader.operator',
    },
}

export default bookingsKeys
