import ActiveTripsAPI from 'api/active-trips'

import socketClient from 'socket.io-client'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

const { user_id: userId, unit_id: officeId } = getLocalStorage(
    localStorageKeys.user
)

const initSocket = () => {
    const SOCKET_HOST_DEV = 'http://sdev.emddi.com:80'

    const SOCKET_TOKEN = getLocalStorage(localStorageKeys.socketToken)

    //const SOCKET_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwMSwibmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBlbWRkaS5jb20iLCJjb250ZW50IjoiYWRtaW4gY29vcmRpbmF0b3IiLCJ0eXBlIjoxMDAwLCJpYXQiOjE1MTI0NDQ5ODh9.IyLv0V1Y-zMqcOb7ndUauMRyJPqEb63ulV0I0ma6CVY'
    
    const user = getLocalStorage(localStorageKeys.user)

    if (!user || JSON.stringify(user) === '{}') return

    const {
        hosts,
        user_id,
        user_service: { switchboard },
        unit_id: office_id,
    } = user

    console.log('host: ', hosts);

    const SOCKET_HOST =
        hosts.length > 0 ? 'https://' + hosts[0] : SOCKET_HOST_DEV

    const client = socketClient(SOCKET_HOST, {
        query: {
            token: SOCKET_TOKEN,
            type: 1000,
            office_id: office_id,
            user_id: user_id,
            switchboard: switchboard,
        },
    })

    client.on('connect', () => {
        console.log('Socket client connected');
    })

    return client
}

const ViewInitState = {
    userId: userId,
    officeId: officeId,
    fullListTripsOpen: false,
    checkedSwitchPopup: false,
    cardTableGroupOpen: false,
    dataPhoneNumber: null,
    socket: initSocket(),
    dataFilter: {
        fromDate: '',
        toDate: '',
        status: '',
    },
    finishedTrip: null,
    dataFilterSwitchboard: {
        fromDate: '',
        toDate: '',
        status: '',
    },
    //Switch using switchboard coordinator
    usingSwitchboard: false,
    showDialogCancel: true,
    determineCopyingText: true,
    updateTripInfo: null,
}

export default ViewInitState
