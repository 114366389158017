import { bookingApiPrefix } from 'api'
import httpStatus from 'api/httpStatus'
import fetcher from 'services/fetcher'

export const updateTripInfoApi = async dataUpdate => {
    try {
        const res = await fetcher.post(
            `${bookingApiPrefix}/trip/coordinator/${dataUpdate.coordinator_id}`,
            {
                ...dataUpdate,
            }
        )
        return res
    } catch (error) {
        console.log(error)
    }
}
