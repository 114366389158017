import { useEffect, useRef } from "react"

const MenuContext = (props) => {

    const menuRef = useRef(null)

    useEffect(() => {
        document.addEventListener('mousedown', _clickOutSideMenuContext);        
    }, [])

    const _clickOutSideMenuContext = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            props.clickOutSideMenuContext()
        }
    }

    return (
        <div 
            className={props.hidden ? "menu-context hide" : "menu-context"}
            style={{position:'fixed', width: '200px', height: '220px', top: props.y, left: props.x}}
            ref={menuRef}
        >
            <h3>
                Gửi tin nhắn cho tài xế
            </h3>
            <div className="menu-item">
                <p>Khách hủy</p>
            </div>
            <div className="menu-item">
                <p>Không nghe máy</p>
            </div>
            <h3>
                Lệnh tổng đài
            </h3>
            <div className="menu-item">
                <p>Không xe</p>
            </div>
            <div className="menu-item">
                <p>Gọi lại</p>
            </div>
        </div>
    )
}

export default MenuContext;