import { bookingApiPrefix } from 'api'
import httpStatus from 'api/httpStatus'
import fetcher from 'services/fetcher'

export const searchPlaceApi = async (query, searchType) => {
    if (!query) return []
    const res = await fetcher.get(`${bookingApiPrefix}/trip/place/search`, {
        params: {
            place: query,
            type: searchType,
        },
    })
    let options = []
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                options = [...res.data.data.places]
            }
        } else {
            if (res.status === httpStatus.UNAUTHORIZED) {
                // logout user
            }
        }
    }
    return options
}

export const geocodeApi = async latLng => {
    if (!latLng) return []
    const res = await fetcher.get(`${bookingApiPrefix}/trip/place/geocode`, {
        params: {
            location: latLng,
        },
    })
    let options = []
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                options = res.data.data.locations.map(location => ({
                    ...location,
                    description: location.formatted_address,
                    coords: location.geometry.location,
                }))
            }
        } else {
            if (res.status === httpStatus.UNAUTHORIZED) {
                // logout user
            }
        }
    }
    return options
}

export const placeDetailApi = async emddiId => {
    if (!emddiId) return null
    const res = await fetcher.get(`${bookingApiPrefix}/trip/place/detail`, {
        params: {
            emddiId,
        },
    })
    let details = null
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                details = res.data.data
            }
        } else {
            if (res.status === httpStatus.UNAUTHORIZED) {
                // logout user
            }
        }
    }
    return details
}

export const createTripApi = async tripData => {
    let data = { ...tripData }

    const res = await fetcher.post(`${bookingApiPrefix}/trip`, data)
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                return { ...res.data.data }
            }
        } else {
            if (res.status === httpStatus.UNAUTHORIZED) {
                // logout user
            }
        }
    }
    return false
}

export const estimateTripApi = async tripInfo => {
    const res = await fetcher.post(
        `${bookingApiPrefix}/trip/estimate`,
        tripInfo
    )
    if (res) {
        if (res.status === httpStatus.OK) {
            if (res.data.success) {
                const estimated = res.data.data
                return estimated
            }
        } else {
            if (res.status === httpStatus.UNAUTHORIZED) {
                // logout user
            }
        }
    }
    return false
}

export const getJourneyApi = async (bookingId, coorId) => {
    try {
        const res = await fetcher.get(`${bookingApiPrefix}/trip/journey`, {
            params: {
                bookingId: bookingId,
                coordinatorId: coorId,
            },
        })

        if (res.data.success) {
            return {
                comingTrackings: res.data.data.comingRoute,
                ontripTrackings: res.data.data.onTripRoute,
                driverOrientation: res.data.data.driverStatus
                    ? res.data.data.driverStatus.orientation
                    : null,
            }
        }
    } catch (err) {
        console.log(err)
    }
}
