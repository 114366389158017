import UserContainerActionTypes from '../UserContainerActionTypes'

const setTotalDrivers = totalDrivers => {
    return {
        type: UserContainerActionTypes.setTotalDrivers,
        payload: { totalDrivers },
    }
}

const toggleFilterTripCollapse = targetState => {
    return {
        type: UserContainerActionTypes.toggleFilterTripCollapse,
        payload: { collapseState: targetState },
    }
}

const tripStatusFilter = {
    setTotalDrivers,
    toggleFilterTripCollapse,
}

export default tripStatusFilter
