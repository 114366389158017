import Nav from 'app/user-container/components/Nav'
import SideBar from 'app/user-container/components/SideBar'
import UserContainerContext from 'app/_shared/user-container-context'
import { ViewContextProvider } from 'app/_shared/view-context/ViewContext'
import { lazy, Suspense, useEffect, useState } from 'react'
import { createSocketConnection } from 'services/socket'
import { KEEP_ALIVE } from 'services/socket/events'
import '../scss/UserContainer.scss'
import PageContent from './PageContent'
import RightPanel from './RightPanel'

const { UserContainerContextProvider } = UserContainerContext

const WaitingCalls = lazy(() => import('./WaitingCalls'))
const PreviewTripsContainer = lazy(() =>
    import('app/preview-trips/components/PreviewTripsContainer')
)

const UserContainer = props => {
    const pageProps = {}

    //const [{checkedSwitchPopup }] = useViewContext()

    return (
        <UserContainerContextProvider>
            <div className="UserContainer">
                <ViewContextProvider>
                    {/* <Nav /> */}
                    <div className="flex">
                        <SideBar open={true} />
                        <PageContent pageProps={pageProps} />
                        {/* <div className="CardSetupBooking">
                            <CardCallComing />
                            <CardSetupBooking />
                        </div> */}

                        {/* <CardTableGroup>
                            <CardCallComing />
                            <CardSetupBooking />
                        </CardTableGroup> */}
                    </div>
                    <Suspense fallback={<span>...</span>}>
                        <RightPanel />
                    </Suspense>

                    <Suspense fallback={<span>...</span>}>
                        <WaitingCalls />
                    </Suspense>
                </ViewContextProvider>
            </div>
        </UserContainerContextProvider>
    )
}

export default UserContainer
