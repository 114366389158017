import './scss/_CustomButton.scss'

const CustomButton = ({ type, text, primary, outline, large, onClick }) => {
    return (
        <button className={"custom-button" + (primary ?  " primary" : (outline ? " outline" : "")) + (large ? " large" : "")}
            type={type}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default CustomButton