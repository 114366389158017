import {
    createTheme,
    MenuItem,
    Select as SelectMui,
    ThemeProvider,
} from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './scss/_Select.scss'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    background: '#ffffff !important',
                    paddingLeft: '0.25rem',
                    border: '1px solid #e6e9ec',
                    borderRadius: '0.125rem',
                    paddingTop: 0,
                    paddingBottom: 0,
                },

                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    //color: 'white',
                    color: '#8d8d8d',
                    right: 0,
                },
                nativeInput: {
                    color: '#363636 !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    padding: '0.125rem !important',
                    //color: 'white',
                    color: '#363636 !important',
                    fontSize: '12px',
                },
            },
        },
    },
})

const Select = ({
    className,
    defaultValue,
    name,
    value,
    title,
    displayField,
    valueField,
    options,
    onChange,
    ...props
}) => {
    const [_value, _setValue] = useState(defaultValue || '')

    useEffect(() => {
        if (value) _setValue(value)
        else _setValue(defaultValue || '')
    }, [value])

    const _handleChange = e => {
        console.log(e.target.value);
        _setValue(e.target.value)
        onChange && onChange(e.target.value || null)
    }

    useEffect(() => {
        _setValue(options[0].value)
    }, [])

    return (
        <div
            className={classNames('SelectWrapper', className)}
            //style={{width: props.width ? props.width  : 'auto'}}
        >
            {title && (
                <label
                    htmlFor={name}
                    style={{
                        width: props.labelWidth ? props.labelWidth : 'auto',
                    }}
                >
                    {title}
                </label>
            )}
            <ThemeProvider theme={theme}>
                <SelectMui
                    id={name}
                    value={_value}
                    defaultValue={defaultValue || ''}
                    onChange={_handleChange}
                    {...props}
                    disabled
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={index}
                            sx={{
                                fontSize: 14,
                                paddingLeft: 1.5,
                                paddingRight: 1,
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                            }}
                            value={option[valueField || 'value']}
                        >
                            {option[displayField || 'name']}
                        </MenuItem>
                    ))}
                </SelectMui>
            </ThemeProvider>
        </div>
    )
}

export default Select
