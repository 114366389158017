import { createTripApi } from 'api/trip'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { useViewContext } from 'app/_shared/view-context/ViewContext'
import classNames from 'classnames'
import { useTrans } from 'helpers/useTranslation'
import { useSnackbar } from 'notistack'
import { useEffect, useRef, useState } from 'react'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useRecoilState } from 'recoil'
import { EDIT_BOOKING, NEW_BOOKING } from 'services/socket/events'
import { useNewBookingContext } from '../context'
import { keyboardFlow } from '../context/initialState'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'

import useTrips from 'app/_shared/trips-context'

import { estimateTripApi } from 'api/trip'

import moment from 'moment'

import updateTypeCoordinatorApi from 'api/trip/update/updateTypeCoordinatorApi'

import { updateTripInfoApi } from 'api/trip/update/updateTripActivity'

import { rebookingTypes, editBookingTypes } from 'app/bookings/constant'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

var socket = null

const extractWaypoint = address => {
    let waypoint = {}
    let latLngString = ''
    if (address.coords) {
        waypoint.x = address.coords.lat
        waypoint.y = address.coords.lng
        latLngString = address.coords.lat + ' ' + address.coords.lng
    } else if (address.coordinates) {
        const coords = address.coordinates.split(' ')
        waypoint.x = coords[0]
        waypoint.y = coords[1]
        latLngString = coords[0] + ' ' + coords[1]
    } else {
        waypoint.x = 0
        waypoint.y = 0
    }
    waypoint.geocode = address.description
    waypoint.place_id = address.placeId || ''

    return { waypoint, latLngString }
}

const ActionButtons = ({ processCall, removePhoneNumber }) => {
    const t = useTrans()

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    const [{ socket }] = useViewContext()

    const [
        {
            handlingTrip: {
                info: {
                    phoneNumber,
                    address: { pickup, destination },
                    estimatedData,
                    services,
                    pickupTime,
                    note,
                    driverAssigned,
                },
                metadata: {
                    canCreateTrip,
                    errorMessage,
                    estimated,
                    focusingEl,
                    hasOnlyOneCoor,
                    userId,
                },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const [state, actions] = useTrips()
    //const [{ handlingTrip }] = useNewBookingContext()

    const [_isTripFromSwitchBoard, _setIsTripFromSwitchBoard] = useState(
        !!processCall
    )
    const _createBtnRef = useRef()
    const _btnSwitchboardRef = useRef()

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (focusingEl === keyboardFlow.createTripBtn) {
            _createBtnRef.current?.focus()
        }
    }, [focusingEl])

    useEffect(() => {
        socket?.on(NEW_BOOKING, data => {
            //console.log(data)
        })
    }, [socket])

    useEffect(() => {
        // check phone number change from processCall
        if (
            _isTripFromSwitchBoard &&
            phoneNumber.length >= 10 &&
            processCall.phoneNumber !== phoneNumber
        ) {
            _setIsTripFromSwitchBoard(false)
        }
    }, [phoneNumber, _isTripFromSwitchBoard])

    useEffect(() => {
        _setIsTripFromSwitchBoard(!!processCall)
    }, [processCall])

    const _handleCancelTrip = (e) => {
        if (e) e.preventDefault()
        setIsNewBookingOpen(false)
    }

    const _handleCreateTrip = (flagBookingSwitchboard = false) => async (e) => {
        e.preventDefault()
        
        if (canCreateTrip) {
            let socketBookingData = {
                user_id: userId,
                note: note,
                distance: null,
                total_price: null,
                estimated_time: null,
                coordinator_id: null,
                customer_id: null,
                type: 1000,
                //c_version: 11,
                driver_id: driverAssigned,
            }
            socketBookingData.service_id = services[0].service || null

            let waypoints = []

            //let waypointsCreateTrip = []

            if (pickup) {
                let startWaypoint = extractWaypoint(pickup)
                waypoints.push(startWaypoint.waypoint)
                socketBookingData.start_address = pickup.description
                socketBookingData.start_point = startWaypoint.latLngString
                if (destination) {
                    let endWaypoint = extractWaypoint(destination)
                    waypoints.push(endWaypoint.waypoint)
                    socketBookingData.end_address = destination.description
                    socketBookingData.end_point = endWaypoint.latLngString
                }
            }
            socketBookingData.waypoints = waypoints
            if (estimated) {
                socketBookingData = {
                    ...socketBookingData,
                    distance: estimatedData.distance,
                    total_price: estimatedData.price,
                    estimated_time: estimatedData.duration,
                }
            }
            if (hasOnlyOneCoor) {
                if (_isTripFromSwitchBoard && processCall) {
                    if (pickupTime != null || flagBookingSwitchboard == true) {
                        //booking reservation
                        if (
                            Math.floor(
                                (moment(pickupTime).valueOf() -
                                    moment(new Date()).valueOf()) /
                                    60000
                            ) < 15
                        ) {
                            return enqueueSnackbar(
                                `Thời gian chuyến đặt hẹn phải > thời gian hiện tại 15'`,
                                {
                                    variant: 'warning',
                                }
                            )
                        }
                        let createTripData = {
                            type: flagBookingSwitchboard ? 1 : 8,
                            coordinator_id: processCall ? processCall.id : null,
                            phone: phoneNumber,
                            services: services,
                            waypoints: 
                                // pickup.emddiId ? 
                                // [
                                //       {
                                //           emddiId: pickup.emddiId,
                                //           placeId: pickup.placeId,
                                //           description: pickup.description
                                //       },
                                //   ]
                                // : 
                                [
                                    {
                                        lat: waypoints[0].x,
                                        lng: waypoints[0].y,
                                        description: pickup.description,
                                        placeId: '',
                                    },
                                ],
                            //reservationTime: Math.floor(moment(pickupTime).valueOf() / 100000) * 100000,

                            reservationTime: flagBookingSwitchboard ? null : moment(pickupTime).valueOf(),

                            note: note
                                ? note
                                : (flagBookingSwitchboard ? null : moment(pickupTime).format(
                                      'HH:mm:ss DD/MM/yyyy'
                                  ) +
                                  '-' +
                                  t(newBookingLocaleKeys.noteBookingAssigned)),

                            check_sb: driverAssigned ? 1 : null,

                            list_arrived: driverAssigned
                                ? driverAssigned
                                : null,
                        }
                        if (destination) {
                            createTripData.waypoints.push({
                                // emddiId: destination.emddiId,
                                // placeId: destination.placeId,
                                // description: destination.description,

                                at: destination.coords.lat,
                                lng: destination.coords.lng,
                                description: pickup.description,
                                placeId: '',
                            })
                        }

                        createTripApi(createTripData)
                            .then(newTrips => {
                                if (newTrips?.coordinator?.length > 0) {
                                    socketBookingData = {
                                        ...socketBookingData,
                                        coordinator_id: processCall.id,
                                        customer_id: processCall.customerId,
                                        customer_name: processCall.customerName,
                                        coordinatorType: flagBookingSwitchboard ? 1 : 8,
                                    }
                                    _emitEditBooking([
                                        {
                                            ...socketBookingData,
                                            phoneNumber: phoneNumber,
                                            createdAt:
                                                newTrips.coordinator[0]
                                                    .created_at,
                                            note: note
                                                ? note
                                                : newTrips.coordinator[0].note,
                                        },
                                    ])
                                    if (flagBookingSwitchboard == true) {
                                        _emitCheckSb({
                                            ...socketBookingData,
                                            phoneNumber: phoneNumber,
                                            createdAt:
                                                newTrips.coordinator[0]
                                                    .created_at,
                                            note: note
                                                ? note
                                                : newTrips.coordinator[0].note,
                                        })
                                    }else {
                                        //Create interval booking reservation
                                        actions.createIntervalReservation(
                                            newTrips.coordinator[0]
                                        )
                                    }
                                    //
                                }
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    } else {
                        socketBookingData = {
                            ...socketBookingData,
                            coordinator_id: processCall.id,
                            customer_id: processCall.customerId,
                            customer_name: processCall.customerName,
                        }
                        _emitEditBooking(
                            [
                                {
                                    ...socketBookingData,
                                    phoneNumber: phoneNumber,
                                    createdAt: processCall.callTime,
                                },
                            ],
                            () => _emitCreateBooking(socketBookingData)
                        )
                    }
                } else if (!_isTripFromSwitchBoard) {
                    let createTripData = {
                        phone: phoneNumber,
                        services: services,
                        waypoints: 
                            // pickup.emddiId ? 
                            // [
                            //       {
                            //           emddiId: pickup.emddiId,
                            //           placeId: pickup.placeId,
                            //           description: pickup.description
                            //       },
                            //   ]
                            // : 
                            [
                                {
                                    lat: waypoints[0].x,
                                    lng: waypoints[0].y,
                                    description: pickup.description,
                                    placeId: '',
                                },
                            ],
                        //pickupTime: pickupTime ? Math.floor(moment(pickupTime).valueOf() / 100000) * 100000 : null,
                        pickupTime: pickupTime
                            ? moment(pickupTime).valueOf()
                            : null,
                        note: note
                            ? note
                            : pickupTime
                            ? moment(pickupTime).format('HH:mm:ss DD/MM/yyyy') +
                              '-' +
                              t(newBookingLocaleKeys.noteBookingAssigned)
                            : '',
                        check_sb: driverAssigned ? 1 : null,
                        list_arrived: driverAssigned ? driverAssigned : null,
                    }

                    //Booking reservation
                    if (pickupTime) createTripData.type = 8

                    //Booking switchboard
                    if (flagBookingSwitchboard == true) createTripData.type = 1

                    if (destination) {
                        createTripData.waypoints.push({
                            // emddiId: destination.emddiId,
                            // placeId: destination.placeId,
                            lat: destination.coords.lat,
                            lng: destination.coords.lng,
                            description: destination.description,
                            placeId: '',
                        })
                    }
                    createTripApi(createTripData)
                        .then(newTrips => {
                            // console.log('customer info: ')
                            // console.log(newTrips)
                            if (newTrips?.coordinator?.length > 0) {
                                socketBookingData = {
                                    ...socketBookingData,
                                    coordinator_id: newTrips.coordinator[0].id,
                                    customer_id: newTrips.customer.customer_id,
                                    customer_name: newTrips.customer.full_name
                                }
                                _emitEditBooking(
                                    [
                                        {
                                            ...socketBookingData,
                                            phoneNumber: phoneNumber,
                                            createdAt:
                                                newTrips.coordinator[0]
                                                    .created_at,
                                            coordinatorType: pickupTime ? 8 : 0,
                                            note: note
                                                ? note
                                                : newTrips.coordinator[0].note
                                        }
                                    ],
                                    (!pickupTime && flagBookingSwitchboard == false)
                                        ? () =>
                                              _emitCreateBooking(
                                                  socketBookingData
                                              )
                                        : ( pickupTime ? actions.createIntervalReservation(
                                              newTrips.coordinator[0]
                                        ) : _emitCheckSb({
                                            ...socketBookingData,
                                            phoneNumber: phoneNumber,
                                            createdAt:newTrips.coordinator[0].created_at,
                                            coordinatorType: pickupTime ? 8 : 0,
                                            note: note
                                                ? note
                                                : newTrips.coordinator[0].note
                                        })
                                    )
                                )
                            }
                        })
                        .catch(error => {})
                }
            } else {
                let createTripData = {
                    phone: phoneNumber,
                    services: services,
                    waypoints: 
                    // pickup.emddiId ? 
                    // [
                    //     {
                    //         emddiId: pickup.emddiId,
                    //         placeId: pickup.placeId,
                    //     },
                    // ]
                    // : 
                    [
                        {
                            lat: waypoints[0].x,
                            lng: waypoints[0].y,
                            description: pickup.description,
                            placeId: '',
                        },
                    ],
                    note: note,
                }
                if (_isTripFromSwitchBoard && processCall) {
                    createTripData.coordinator = processCall.id
                }
                if (destination) {
                    createTripData.waypoints.push({
                        // emddiId: destination.emddiId,
                        // placeId: destination.placeId,
                        lat: destination.coords.lat,
                        lng: destination.coords.lng,
                        description: destination.description,
                        placeId: '',
                    })
                }
                createTripApi(createTripData)
                    .then(newTrips => {
                        if (newTrips?.coordinator?.length > 0) {
                            let list_coordinator = newTrips.coordinator.reduce(
                                (arr, coord) => {
                                    //console.log(coord)
                                    arr.push(coord.id)
                                    return arr
                                },
                                []
                            )
                            socketBookingData = {
                                ...socketBookingData,
                                coordinator_id: newTrips.coordinator[0].id,
                                customer_id: newTrips.customer.customer_id,
                                customer_name: processCall.customerName,
                                list_coordinator,
                            }
                            let baseTripData = { ...socketBookingData }
                            delete baseTripData.coordinator_id
                            delete baseTripData.service_id
                            let editBookingData = newTrips.coordinator.map(
                                trip => {
                                    return {
                                        ...baseTripData,
                                        phoneNumber: phoneNumber,
                                        coordinator_id: trip.id,
                                        service_id: trip.service_id,
                                        createdAt: trip.created_at,
                                    }
                                }
                            )
                            if (flagBookingSwitchboard == false) {
                                _emitEditBooking(editBookingData, () =>
                                    _emitCreateBooking(socketBookingData)
                                )
                            }else {
                                _emitCheckSb(editBookingData)
                            }
                        }
                    })
                    .catch(error => {})
            }
        } else {
            enqueueSnackbar(errorMessage || 'Điền đầy đủ thông tin cần thiết', {
                variant: 'warning',
            })
        }
    }

    //Booking switchboard(F4)
    const _emitCheckSb = data => {
        // console.log('Booking switchboard: ' + swithboard)
        // console.log(data)
        const { user_service: { swithboard } } = getLocalStorage(localStorageKeys.user)
        socket?.emit('check-sb', data)
    }

    const _emitCreateBooking = data => {
        socket?.emit(NEW_BOOKING, data, () => {
            enqueueSnackbar('Đặt chuyến thành công', {
                variant: 'info',
            })
            //setIsNewBookingOpen(false)

            // console.log('data booking actions buttons: ')
            // console.log(data)
            if (data.driver_id && _isTripFromSwitchBoard) {
                //update driver assigned
                let dataUpdate = {
                    type_update: 0,
                    coordinator_id: data.coordinator_id,
                    check_sb: 1,
                    list_arrived: data.driver_id,
                }
                updateTripInfoApi(dataUpdate)
            }
        })
    }

    const _emitEditBooking = (data, callback) => {
        // emit edit booking function
        setIsNewBookingOpen(false)

        actions.updateNewTripsCreated(data);

        removePhoneNumber(data[0].coordinator_id)

        socket?.emit(EDIT_BOOKING, data)

        callback?.()
    }

    return (
        <div className="ActionBtns light-mode">
             <button className="Button CancelBtn" onClick={_handleCancelTrip}>
                {t(newBookingLocaleKeys.btnCancel)}
            </button>
            <button
                className={classNames('Button CreateBtn', {
                    Disabled: !canCreateTrip,
                    Focused: focusingEl === keyboardFlow.createTripBtn,
                })}
                ref={_createBtnRef}
                onClick={_handleCreateTrip(false)}
            >
                {t(newBookingLocaleKeys.btnCreate)}
            </button>
             {/* Btn booking switchboard */}

            { <button
                className={classNames('Button CreateBtn', {
                    Disabled: !canCreateTrip,
                    Focused: focusingEl === keyboardFlow.createTripBtn,
                })}
                ref={_btnSwitchboardRef}
                onClick={_handleCreateTrip(true)}
            >
                {t(newBookingLocaleKeys.btnCreateSwitchboard)}
            </button> }
           
           
            <KeyboardEventHandler
                handleKeys={['f2', 'f4', 'Esc']}
                handleFocusableElements
                onKeyEvent={(key, e) => {
                    if (isNewBookingOpen) {
                        if (key === 'f2') 
                            _createBtnRef.current.click()
                        if (key === 'f4') 
                            _btnSwitchboardRef.current.click()
                        if (key === 'Esc')
                            _handleCancelTrip()
                    }
                }}
            />
        </div>
    )
}

export default ActionButtons
