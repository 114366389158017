import classNames from "classnames/bind";
import styles from "../scss/_ListAccount.scss";
import React, {useEffect, useState} from "react";
import {ENTER} from "../../../static/keyboard-code";
import ListBox from "./ListBox";

const cx = classNames.bind(styles)

const ListAccount = ({userData, officeId, officeName, onReload, onClick}) => {

    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingList, setLoadingList] = useState(false);
    const [userDatas, setUserDatas] = useState(userData)

    useEffect( () => {
        setLoadingList(false)
        setUserDatas(userData)
    },[userData])

    useEffect( () => {
        setLoading(false)
    },[userDatas])

    const searchUsers = (item) => {
        if (userData.length > 0) {
            return userData.filter(user =>
                user.full_name.toLowerCase().includes(item.toLowerCase()) ||
                user.user_name.toLowerCase().includes(item.toLowerCase())
            );
        } else {
            return [];
        }
    };
    const _handleSearch = () => {
        const result = searchUsers(inputValue)
        setUserDatas(result)
        setLoading(true)
    }

    const _handleReload = (e) => {
        onReload(e)
        setLoadingList(true)
    }
    const _handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const _handleClear = () => {
        setInputValue('')
    };
    const _handleKeyDown = e => {
        if (e.key === ENTER) {
            setLoading(true)
        }
    };
    const _handleSelectOffice = (e) => {}
    const _handleCellClick = (e) => {
        const selectUser = userDatas.find(user => user.user_id == e.user_id)
        onClick(selectUser)
    }

    return (
        <div className={cx('ListAccount__Wrapper')}>
            <div className={cx('Title')}>
                <h3 className={cx('title-text')}>
                    Danh sách tài khoản
                </h3>
            </div>
            <div className={cx('ListAccount__Action')}>
                <div className="search-box">
                    <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-3"
                        htmlFor="Search">
                        Tìm Kiếm:
                    </label>
                    <input className={cx('grant-role-input')} onFocus={() => setLoading(false)} value={inputValue || ''}
                           onChange={_handleInputChange} onKeyDown={_handleKeyDown} type="text"></input>
                    <div className='action-search-input'>
                        {loading && <span className={cx('icon-loading')}> <img src="assets/icons/loading.png"
                                                                               alt="loading"/></span>}
                        {!loading && <button className={cx('search-btn')} onClick={_handleSearch}>
                            <img src="assets/icons/search-btn.png" alt="search"/>
                        </button>}
                        {!!inputValue && <button className={cx('clear-btn')} onClick={_handleClear}><img
                            src="assets/icons/clear.png" alt="search"/></button>}
                    </div>
                </div>
                <div className="select-entity">
                    <div className="w-full">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mr-6"
                            htmlFor="grid-state">
                            Đơn Vị:
                        </label>
                        <select
                            className={cx('entity-select-box')} value={officeId || ''} onChange={_handleSelectOffice}>
                            <option value={officeId || ''} defaultValue=''>{officeName || ''}
                            </option>
                        </select>
                    </div>
                </div>
                {loadingList && <span className={cx('loading-overlay')}> <img src="assets/icons/loading.png" alt="loading"/></span>}
            </div>
            { userDatas && <ListBox data={userDatas} onReload={_handleReload} onClick={_handleCellClick}/>}
        </div>
    )
}

export default ListAccount