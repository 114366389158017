const setPickupCoordinates = (state, payload) => ({
    ...state,
    handlingTrip: {
        ...state.handlingTrip,
        metadata: {
            ...state.handlingTrip.metadata,
            mapPickerCoordinates: {
                ...state.handlingTrip.metadata.mapPickerCoordinates,
                pickup: payload,
            },
        },
    },
})

const mapPickerReducers = { setPickupCoordinates }

export default mapPickerReducers
