import { useEffect, useRef } from "react"

const MenuActions = (props) => {
    const menuRef = useRef(null)

    useEffect(() => {
        document.addEventListener('mousedown', _clickOutSideMenuContext);        
    }, [])

    const _clickOutSideMenuContext = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) && e.target.toString() !== '[object HTMLImageElement]') {
            props.clickOutSideMenuContext()
        }
    }

    return (
        <div 
            className={props.hidden ? "menu-actions hide" : "menu-actions"}
            style={{position:'fixed', width: '108px', height: '68px', top: props.y, left: props.x - 108}}
            ref={menuRef}
        >
            <div className="item-action"
                onClick={props.rebooking}
            >
                <img src="assets/icons/icon_rebooking.svg" />
                <span>Điều lại</span>
            </div>
            <div className="divide"></div>
            <div className="item-action"
                onClick={props.showModalConfirm}
            >
                <img src="assets/icons/icon_cancel.svg" />
                <span>Hủy</span>
            </div>
        </div>
    )
}

export default MenuActions;