import NewBookingActionTypes from '../actionTypes'

const setRndConfig = newConfig => {
    Object.keys(newConfig).forEach(key => {
        newConfig[key] = !!newConfig[key] ? newConfig[key] : 0
    })

    return {
        type: NewBookingActionTypes.setRndConfig,
        payload: {
            newConfig,
        },
    }
}

const rnd = { setRndConfig }

export default rnd
