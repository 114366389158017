import previewTripsKeys from 'app/preview-trips/i18n/previewTripsKeys'
import DatePicker from 'app/_shared/components/DatePicker'
import Input from 'app/_shared/components/Input'
import Select from 'app/_shared/components/Select'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPrimitiveDot } from 'react-icons/go'
import { tripStatusColor, tripStatus } from 'utils/trips'
import bookingsKeys from '../i18n/bookingsKeys'
import MultipleSelectCheckmarks from './MultipleSelecCheckmarks'

//import { bookingStatusColor, bookingStatusName } from '../../../utils/Booking'

import { bookingStatusColor, bookingStatusName } from 'utils/Booking'

import ViewContext from 'app/_shared/view-context'

import moment from 'moment'

import { getListService, getListUsers } from 'api/service'

import useTrips from 'app/_shared/trips-context'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import SearchIcon from '@mui/icons-material/Search'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'

import { alpha, styled } from '@mui/material/styles'

import OutlinedInput from '@mui/material/OutlinedInput';

const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'green',
      },
    },
  });

const CssOulinedInput = styled(OutlinedInput)({
    '&.MuiOutlinedInput-root': {
        padding: '1px 6px !important',
        fontSize: '.8rem !important',
        height: '26px',
        
        '& input': {
            height: '26px',
            width: '80px',
            color: '#000000',
            fontSize: '14px',
            fontWeight: '400',
            padding: '0',
            marginLeft: '-8px',
            lineHeight: '30px'
        },

        '& fieldset': {
            borderColor: 'green',
            borderRadius: '15px',
        },
        '&:hover fieldset': {
            borderColor: 'green',
        },

        '& .MuiInputAdornment-root': {
            cursor: 'pointer !important',
            '& img': {
                width: '12px !important',
                height: '12px !important'
            },
            '& p' : {
                fontSize: '.8rem',
                color: '#676767',
                fontWeight: '400'
            }
        }
    }
})

const optionsBookingStatus = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
        color: 'white',
        img_url: 'assets/icons/All.svg'
    },
    {
        id: 1,
        name: 'Đặt hẹn',
        checked: true,
        color: bookingStatusColor[bookingStatusName.pending],
        img_url: 'assets/icons/Dat_hen.svg'
    },
    {
        id: 2,
        name: 'Đang tìm xe',
        checked: true,
        color: bookingStatusColor[bookingStatusName.dispatching],
        img_url: 'assets/icons/Dang_tim_xe.svg'
    },
    {
        id: 3,
        name: 'Chưa có xe nhận đón',
        checked: true,
        color: bookingStatusColor[bookingStatusName.waitingForProcess],
        img_url: 'assets/icons/Chua_co_xe_nhan.svg'
    },
    {
        id: 4,
        name: 'Nhận chuyến đặt hẹn',
        checked: true,
        color: bookingStatusColor[bookingStatusName.confirmReservation],
        img_url: 'assets/icons/Da_nhan_dat_hen.svg'
    },
    {
        id: 5,
        name: 'Đang đi đón khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.DriverOnTheWay],
        img_url: 'assets/icons/Dang_don_khach.svg'
    },
    {
        id: 6,
        name: 'Đến điểm đang chờ khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.ArrivedAndWaiting],
        img_url: 'assets/icons/Da_den_diem_cho.svg'
    },
    {
        id: 7,
        name: 'Đang chở khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.PassengerOnBoard],
        img_url: 'assets/icons/Dang_cho_khach.svg'
    },
    {
        id: 8,
        name: 'Lái xe hủy chuyến',
        checked: true,
        color: bookingStatusColor[bookingStatusName.DriverCancel],
        img_url: 'assets/icons/huy_chuyen.svg'
    },

    // 'Đặt hẹn',
    // 'Đang tìm xe',
    // 'Chưa có xe nhận đón',
    // 'Nhận chuyến đặt hẹn',
    // 'Đang đi đón khách',
    // 'Đến điểm đang chờ khách',
    // 'Đang chở khách',
    // 'Lái xe hủy chuyến'
]

const optionsBookingType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Đi ngay',
        checked: true,
    },
    {
        id: 2,
        name: 'Đặt hẹn',
        checked: true,
    },
]

const optionsCarType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Taxi bất kỳ',
        checked: true,
    },
    {
        id: 2,
        name: 'Taxi 4 chỗ lớn',
        checked: true,
    },
    {
        id: 3,
        name: 'Taxi 7 chỗ',
        checked: true,
    },
    {
        id: 4,
        name: 'Emddi Fixed',
        checked: true,
    },
]

const optionsBookingFrom = [
    {
        //id: 'ALL',
        id: 0,
        name: 'Tất cả',
        checked: false,
    },
    {
        id: 'CUSTOMER_APP',
        //id: 1,
        name: 'App KH',
        checked: true,
    },
    {
        id: 'TEL_OPERATOR',
        //id: 2,
        name: 'Tổng đài',
        checked: true,
    },
    {
        id: 'GUEST',
        //id: 3,
        name: 'Khách vẫy',
        checked: false,
    },
]

const optionsOperator = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Admin Emddi',
        checked: true,
    },
    {
        id: 2,
        name: 'dieuxe1',
        checked: true,
    },
    {
        id: 3,
        name: 'Cong2k',
        checked: true,
    },
]

const optionsCustomerType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Khách VIP',
        checked: true,
    },
    {
        id: 2,
        name: 'Non VIP',
        checked: true,
    },
]

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

//init services
const listServices = getLocalStorage(localStorageKeys.services) ? getLocalStorage(localStorageKeys.services) : [];
// const arrServices = listServices.map(({ service_id, vi_name }) => ({
//     id: service_id,
//     name: vi_name,
//     checked: true,
// }))

const arrServices = listServices.map((item, index) => {
    let service = {
        id: item.service_id,
        name: item.vi_name,
        checked: true
    }
    return service
})

//console.log(arrServices);

const services = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    ...arrServices,
]

const FilterBooking = () => {
    const {user_id : userId } = getLocalStorage(localStorageKeys.user).user_service
    const { full_name: userName } = getLocalStorage(localStorageKeys.user)

    const t = useTrans()
    const { i18n } = useTranslation()

    const [{ usingSwitchboard }] = useViewContext()

    const [displayFilter, setDisplayFilter] = useState(
        usingSwitchboard ? false : true
    )

    const [status, setStatus] = useState('')

    const [formDate, setFormDate] = useState(moment(new Date()).unix() * 1000)

    const [toDate, setToDate] = useState(moment(new Date()).unix() * 1000)

    const [searching, setSearching] = useState('')
    const [typeSearching, setTypeSearching] = useState(5)

    const [users, setUsers] = useState([
        {
            id: '0',
            name: '',
            checked: false,
        },
    ])

    /* show list filters seleted */
    const objectFilterType = {
        OPERATOR: 'OPERATOR',
        STATUS: 'STATUS',
        BOOKING_FROM: 'BOOGKING_FROM',
        CAR_SERVICE: 'CAR_SERVICE',
        CUSTOMER_TYPE: 'CUSTOMER_TYPE',
        BOOKING_TYPE: 'BOOKING_TYPE'
    }

    const [arrObjFilter, setArrayObjFilter] = useState([
        {
            filterType: objectFilterType.OPERATOR,
            label: 'Người điều:',
            isActive: true,
            listItemsSelected: [{
                id: userId,
                name: userName,
                checked: true
            }],
            dataOptions: [...users],
        },
        {
            filterType: objectFilterType.STATUS,
            label: 'Trạng thái:',
            isActive: false,
            listItemsSelected: [],
            dataOptions: [...optionsBookingStatus],
        },
        {
            filterType: objectFilterType.BOOKING_FROM,
            label: 'Nguồn cuốc:',
            isActive: true,
            listItemsSelected: [
                {
                    id: 'CUSTOMER_APP',
                    name: 'App KH',
                    checked: true,
                },
                {
                    id: 'TEL_OPERATOR',
                    name: 'Tổng đài',
                    checked: true,
                },
            ],
            dataOptions: [...optionsBookingFrom]
        },
        {
            filterType: objectFilterType.CAR_SERVICE,
            label: 'Loại xe:',
            isActive: false,
            listItemsSelected: [],
            dataOptions: [...optionsCarType]
        },
        {
            filterType: objectFilterType.CUSTOMER_TYPE,
            label: 'Loại KH:',
            isActive: false,
            listItemsSelected: [],
            dataOptions: [...optionsCustomerType]
        },
        {
            filterType: objectFilterType.BOOKING_TYPE,
            label: 'Loại chuyến:',
            isActive: false,
            listItemsSelected: [],
            dataOptions: [...optionsBookingType]
        },

    ])

    let timeTeporary = new Date().toISOString().substring(0, 10) + ' 06:00:00'

    let startDateTime = moment(timeTeporary).utc().format('YYYY-MM-DD HH:mm:ss')

    let endDateTime = moment(timeTeporary)
        .add(1, 'days')
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

    const [dataFilter, setDataFiler] = useState({
        fromDate: null,
        toDate: null,
        startDate: startDateTime,
        endDate: endDateTime,
        status: '',
        users: userId, //operators
        types: '', //type booking(booking from),
        services: '',
        searching: null,
        typeSearching: null,
    })

    //react-sweet-state context
    const [state, actions] = useTrips()

    const _onDateChange = (start, end) => {
        setDataFiler({
            ...dataFilter,
            fromDate: moment(start).unix() * 1000,
            toDate: moment(end).unix() * 1000,
            startDate: moment(start).utc().format('YYYY-MM-dd HH:mm:ss'),
            endDate: moment(end).utc().format('YYYY-MM-dd HH:mm:ss'),
        })
    }

    const clearFilter = typeFilter => {
        let arrTemp = arrObjFilter.map((obj, index) => 
            obj.filterType === typeFilter? { ...obj, isActive: false} : obj
        )
        setArrayObjFilter(arrTemp)
    }

    const _onStatusChange = objectStatus => {
        if (checkAllNotSelected(objectStatus)) {
            actions.setTrips([])
            actions.setFilterForm({})

            clearFilter(objectFilterType.STATUS)
        } else {
            if (checkAllSelected(objectStatus)) {
                setDataFiler({ ...dataFilter, status: '' })

                clearFilter(objectFilterType.STATUS)
            } else {
                let tempStatus = ''
                if (objectStatus[1] == true) tempStatus = 'RESERVATION_CREATED'
                if (objectStatus[2] == true) tempStatus += ',' + 'FINDING'
                if (objectStatus[3] == true) tempStatus += ',' + 'TIMEOUT'
                if (objectStatus[4] == true)
                    tempStatus += ',' + 'RESERVATION_APPROVED'
                if (objectStatus[5] == true) tempStatus += ',' + 'COMING'
                if (objectStatus[6] == true)
                    tempStatus += ',' + 'PICKUP_ARRIVED'
                if (objectStatus[7] == true) tempStatus += ',' + 'ON_JOURNEY'
                if (objectStatus[8] == true) tempStatus += ',' + 'CANCELED'

                if (tempStatus.indexOf(',') == 0)
                    tempStatus = tempStatus.substring(1)
                setDataFiler({ ...dataFilter, status: tempStatus })

                //Add status input to the list filter
                let arrStatus = []
                for (const item in objectStatus) {
                    if (objectStatus[item]) {
                        let status = optionsBookingStatus.find(status => status.id == item)
                        if (status) {
                            status.checked = true
                            arrStatus.push(status)
                        }
                    }
                }
                let arrTemp = arrObjFilter.map((obj, index) => 
                    obj.filterType === objectFilterType.STATUS ? { ...obj, isActive: true, listItemsSelected: [...arrStatus]} : obj
                )
                setArrayObjFilter([...arrTemp])
            }
        }
    }

    const _onServiceChange = objectServices => {
        if (checkAllNotSelected(objectServices)) {
            actions.setTrips([])
            actions.setFilterForm({})

            clearFilter(objectFilterType.CAR_SERVICE)
        } else {
            if (checkAllSelected(objectServices)) {
                setDataFiler({ ...dataFilter, services: '' })

                clearFilter(objectFilterType.CAR_SERVICE)
            } else {
                let servicesFilter = Object.keys(objectServices).reduce(
                    (listServices, k) =>
                        objectServices[k]
                            ? k + ',' + listServices
                            : listServices,
                    ''
                )

                servicesFilter = servicesFilter.substring(
                    0,
                    servicesFilter.length - 1
                )
                setDataFiler({ ...dataFilter, services: servicesFilter })

                let arrServices = []
                for (const item in objectServices) {
                    if (objectServices[item]) {
                        let service = services.find(sv => sv.id == item)
                        if (service) {
                            service.checked = true
                            arrServices.push(service)
                        }
                    }
                }
                let arrTemp = arrObjFilter.map((obj, index) => 
                    obj.filterType === objectFilterType.CAR_SERVICE ? { ...obj, isActive: true, listItemsSelected: [...arrServices]} : obj
                )
                setArrayObjFilter([...arrTemp])
            }
        }
    }

    const _onOperatorChange = objectOperator => {
        if (checkAllNotSelected(objectOperator)) {
            actions.setTrips([])
            actions.setFilterForm({})

            clearFilter(objectFilterType.OPERATOR)
        } else {
            if (checkAllSelected(objectOperator)) {
                setDataFiler({ ...dataFilter, users: '' })

                clearFilter(objectFilterType.OPERATOR)
            } else {
                let usersFilter = Object.keys(objectOperator).reduce(
                    (listUsers, k) =>
                        objectOperator[k] ? k + ',' + listUsers : listUsers,
                    ''
                )
                usersFilter = usersFilter.substring(0, usersFilter.length - 1)
                setDataFiler({ ...dataFilter, users: usersFilter })
                
                //Add operators to the list filter
                let operators = []
                for (const item in objectOperator) {
                    if (Object.hasOwnProperty.call(objectOperator, item)) {
                        if (objectOperator[item]) {
                            let operator = users.find(user => user.id == item)
                            operator.checked = true
                            operators.push(operator)
                        }
                    }
                }

                let arrTemp = arrObjFilter.map((obj, index) => 
                    obj.filterType === objectFilterType.OPERATOR ? { ...obj, isActive: true, listItemsSelected: [...operators]} : obj
                )

                setArrayObjFilter([...arrTemp])
            }
        }
    }

    const _onCustomerTypeChange = () => {}

    const _onBookingFromChange = objectBookingFrom => {
        if (checkAllNotSelected(objectBookingFrom)) {
            actions.setTrips([])
            actions.setFilterForm({})

            clearFilter(objectFilterType.BOOKING_FROM)
        } else {
            if (checkAllSelected(objectBookingFrom)) {
                setDataFiler({ ...dataFilter, types: '' })

                clearFilter(objectFilterType.BOOKING_FROM)
            } else {
                let bookingFrom = Object.keys(objectBookingFrom).reduce(
                    (listTypesBooking, k) =>
                        objectBookingFrom[k]
                            ? k + ',' + listTypesBooking
                            : listTypesBooking,
                    ''
                )
                bookingFrom = bookingFrom.substring(0, bookingFrom.length - 1)
                setDataFiler({ ...dataFilter, types: bookingFrom })

                //Add BookingFrom to the list filter
                let arrBookingForm = []
                for (const item in objectBookingFrom) {
                    if (objectBookingFrom[item]) {
                        let bookingFromItem = optionsBookingFrom.find(bookingForm => bookingForm.id == item)
                        if (bookingFromItem) {
                            arrBookingForm.checked = true
                            arrBookingForm.push(bookingFromItem)
                        }
                    }
                }

                let arrTemp = arrObjFilter.map((obj, index) => 
                    obj.filterType === objectFilterType.BOOKING_FROM ? { ...obj, isActive: true, listItemsSelected: [...arrBookingForm]} : obj
                )
                setArrayObjFilter([...arrTemp])
            }
        }
    }

    const checkAllSelected = object => {
        for (let key in object) {
            if (!object[key]) return false
        }
        return true
    }
    const checkAllNotSelected = object => {
        for (let key in object) {
            if (object[key]) return false
        }
        return true
    }

    //Using actions from react-sweet-sate context to fetch & share data
    useEffect(() => {
        actions.getTrips(dataFilter)
    }, [dataFilter])

    useEffect(() => {
        ;(async () => {
            // const listServices = await getListService()
            // const arrServices = listServices.map(({ service_id, vi_name }) => ({
            //     id: service_id,
            //     name: vi_name,
            //     checked: true,
            // }))
            // setServices([
            //     {
            //         id: 0,
            //         name: 'Tất cả',
            //         checked: true,
            //     },
            //     ...arrServices,
            // ])
            // actions.setServices([...arrServices])

            const listUsers = await getListUsers()

            const listUsers2 = listUsers.map((user, index) => {
                if (user.id == userId) return { ... user, checked: true}
                else return user
            })
            setUsers([
                {
                    id: 0,
                    name: 'Tất cả',
                    checked: false,
                },
                ...listUsers2,
            ])
            actions.setUsers([...listUsers])
        })()
    }, [])

    const handleClickCollapse = () => {
        setDisplayFilter(!displayFilter)
    }

    const handleClickSearchIcon = () => {
        setDataFiler({
            ...dataFilter,
            searching: searching,
            typeSearching: typeSearching,
        })
    }

    const _onChangeInput = e => {
        let value = e.target.value.trim()

        let typeSearching = 5
        if (
            (value.length == 16 || value.length == 8 || value.length == 6) &&
            value.replace(/[0-9]/g, '') === ''
        ) {
            typeSearching = 1
        } else if (value.length == 4) {
            typeSearching = 2
        } else if (value.length == 10 && value.replace(/[0-9]/g, '') === '') {
            typeSearching = 3
            if (value.indexOf('0') == 0 && value.length == 10)
                value = '+84' + value.substring(1)
        } else if (
            value.replace(/[0-9]/g, '') !== '' &&
            value.length >= 2 &&
            value.length <= 12
        ) {
            let flagSearchByUser = false
            let userId = null
            for (let i = 0; i < state.users.length; i++) {
                if (state.users[i].name === value) {
                    userId = state.users[i].id
                    flagSearchByUser = true
                    break
                }
            }
            if (flagSearchByUser == true) {
                typeSearching = 4
                value = userId
            }
        }

        setSearching(value)
        setTypeSearching(typeSearching)
    }

    const _onKeyDownInput = e => {
        if (e.key.toLowerCase() === 'enter') {
            setDataFiler({
                ...dataFilter,
                searching: searching,
                typeSearching: typeSearching,
            })
        }
    }

    const _clearSearching = () => {
        setDataFiler({
            ...dataFilter,
            searching: null,
            typeSearching: null,
        })
    }

    const _forwardRef = useRef(null)

    
    const checkFilterIsActive = filterType => {
        for(let i = 0; i <  arrObjFilter.length; i ++) {
            if (arrObjFilter[i].filterType === filterType) return arrObjFilter[i].isActive
        }
        return false
    }

    return (
        <div className={displayFilter ? 'Filter' : 'Filter Hide'}>
            {/* {displayFilter ? (
                <ExpandLessIcon
                    className="IconCollapseUp"
                    onClick={handleClickCollapse}
                />
            ) : (
                <ExpandMoreIcon
                    className="IconCollapseDown"
                    onClick={handleClickCollapse}
                />
            )} */}
            <div className="Row Active First">
                <Input
                    className="search-input"
                    placeholder={t(bookingsKeys.filter.searchPlaceholder)}
                    onChange={_onChangeInput}
                    onKeyDown={_onKeyDownInput}
                    forwardRef={_forwardRef}
                    clearSearching={_clearSearching}
                />
                <MultipleSelectCheckmarks
                    //width={135}
                    //title="Loại chuyến"
                    label="Loại chuyến"
                    options={optionsBookingType}
                    disabled={true}
                />
                {/* <DatePicker className="w-64" onChange={_onDateChange} /> */}
                <MultipleSelectCheckmarks
                    // width={230}
                    //title="Trạng thái"
                    label="Trạng thái"
                    options={optionsBookingStatus}
                    onChange={_onStatusChange}
                    name='status'
                    isActive={checkFilterIsActive(objectFilterType.STATUS)}
                />
                <MultipleSelectCheckmarks
                    // width={160}
                    //title="Loại xe"
                    label="Loại xe"
                    options={services}
                    onChange={_onServiceChange}
                    isActive={checkFilterIsActive(objectFilterType.CAR_SERVICE)}
                />
                <MultipleSelectCheckmarks
                    // width={150}
                    //title="Người điều"
                    label="Người điều"
                    options={users}
                    onChange={_onOperatorChange}
                    isActive={checkFilterIsActive(objectFilterType.OPERATOR)}
                />
                <MultipleSelectCheckmarks
                    // width={130}
                    //title="Loại KH"
                    label="Loại KH"
                    options={optionsCustomerType}
                    onChange={_onCustomerTypeChange}
                    disabled={true}
                />
                <MultipleSelectCheckmarks
                    // width={135}
                    //title="Nguồn cuốc"
                    label="Nguồn cuốc"
                    options={optionsBookingFrom}
                    onChange={_onBookingFromChange}
                    isActive={checkFilterIsActive(objectFilterType.BOOKING_FROM)}
                />
                
                {/* <SearchIcon
                    className="search-icon"
                    onClick={handleClickSearchIcon}
                /> */}
            </div>
            <div className="Row Second">
                {arrObjFilter.map((item, index) => {
                    if (item.isActive) {
                        return (
                            <CssOulinedInput
                                key={index}
                                value={item.listItemsSelected.length > 1 ? item.listItemsSelected.length + ' đang chọn' : item.listItemsSelected[0].name}
                                startAdornment = { 
                                    <InputAdornment position="start">
                                        {item.label}:
                                    </InputAdornment>
                                }
                                endAdornment = { 
                                    <InputAdornment position="end" onClick={() => {
                                            let arrTemp = arrObjFilter.map((obj, index) => 
                                                item.filterType === obj.filterType ? { ...obj, isActive: false } : obj
                                            )
                                            setArrayObjFilter([
                                                ...arrTemp
                                            ])
                                        }}
                                    >
                                        <img src='assets/icons/icon_close_2.svg' />
                                    </InputAdornment>
                                }
                            />
                        )
                    }
                })}
                
            </div>
        </div>
    )
}

export default FilterBooking
