const ViewActionTypes = {
    TOGGLE_FULL_LIST_TRIPS: 'TOGGLE_FULL_LIST_TRIPS',
    SWITCH_POPUP: 'SWITCH_POPUP',
    TOGGLE_CARD_TABLE_GROUP: 'TOGGLE_CARD_TABLE_GROUP',
    SEND_PHONE_NUMBER: 'SEND_PHONE_NUMBER',
    ADD_ACTIVE_TRIP: 'ADD_ACTIVE_TRIP',
    SET_FILTER_FORM: 'SET_FILTER_FORM',
    SEND_FINISHED_TRIP: 'SEND_FINISHED_TRIP',
    UPDATE_TRIP_INFO: 'UPDATE_TRIP_INFO',
    SET_USER_SETTINGS: 'SET_USER_SETTINGS',
}

export default ViewActionTypes
