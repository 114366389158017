import Select from 'app/_shared/components/Select'
import { EmddiHeroIcon } from 'app/_shared/EmddiIcon/components'
import UserContainerContext from 'app/_shared/user-container-context'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useMemo } from 'react'
import previewTripsKeys from '../i18n/previewTripsKeys'
import StatusFilter from './StatusFilter'
const { useUserContainerContext, UserContainerActions } = UserContainerContext

const FilterTrip = () => {
    const t = useTrans()

    const [
        {
            previewTrips: { totalDrivers, filterTripCollapsed },
        },
        dispatch,
    ] = useUserContainerContext()

   
    // console.log(useUserContainerContext())

    // useEffect(() => {
    //     console.log('state context changed...')
    // }, [useUserContainerContext()])
   

    //const filterTripCollapsed = false


    const offices = useMemo(() => {
        return [
            { value: 0, name: 'Đơn vị' },
            { value: 1, name: 'Emddi' },
            { value: 2, name: 'Taxi Group' },
        ]
    }, [])

    const services = useMemo(() => {
        return [
            { value: 0, name: 'Dịch vụ' },
            { value: 1, name: 'Serivce 1' },
            { value: 2, name: 'Serivce 2' },
        ]
    }, [])

    const _onToggle = () => {
        dispatch(
            UserContainerActions.toggleFilterTripCollapse(!filterTripCollapsed)
        )
    }

    return (
        <>
            {!filterTripCollapsed && (
                <div className="FilterWrapper">
                    <div className="TripFilter pb-4">
                        <Select options={offices} className="mr-2 w-1/2" defaultValue={1}/>
                        <Select options={services} className="ml-2 w-1/2" defaultValue={0}/>
                    </div>
                    <div className="TripFilter">
                        <div className="TotalDriver">
                            <div className="TotalText">
                                {t(previewTripsKeys.filter.totalDriver)}
                            </div>
                            {/* <div className="TotalCount">{totalDrivers}</div> */}
                        </div>
                        <StatusFilter />
                    </div>
                </div>
            )}

            <div className="FilterToggle" onClick={_onToggle}>
                <EmddiHeroIcon
                    name={filterTripCollapsed ? 'HiChevronDown' : 'HiChevronUp'}
                    className="text-xl"
                />
            </div>
        </>
    )
}

export default FilterTrip
