import { element } from 'prop-types'
import React, { useEffect, useState } from 'react'

const Tabs = ({ children, active = 0 }) => {
    const [activeTab, setActiveTab] = useState(active)
    const [tabsData, setTabsData] = useState([])

    useEffect(() => {
        let data = []

        React.Children.forEach(children, element => {
            if (!React.isValidElement(element)) return
            const {
                props: { tab, children },
            } = element
            data.push({ tab, children })
        })

        setTabsData(data)
    }, [children])

    return (
        <div className="w-100 tabs">
            <ul className="nav nav-tabs">
                {tabsData.map(({ tab }, index) => {
                    return (
                        <li
                            key={index}
                            className={`nav-link ${
                                index === activeTab ? 'active' : ''
                            }`}
                        >
                            <a href="#" onClick={() => setActiveTab(index)}>
                                {tab}
                            </a>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content">
                {tabsData[activeTab] && tabsData[activeTab].children}
            </div>
        </div>
    )
}

const TabPane = ({ children }) => {
    return { children }
}

Tabs.TabPane = TabPane

export default Tabs
