const onBooking = (state, payload) => {
    return {}
}

const setFilterForm = (state, payload) => {
    return {
        dataFilter: payload,
    }
}

const sendFinishedTrip = (state, payload) => {
    return {
        finishedTrip: payload,
    }
}

const updateTripInfo = (state, payload) => {
    return {
        updateTripInfo: payload,
    }
}

const TripsReducer = {
    setFilterForm,
    sendFinishedTrip,
    updateTripInfo,
}

export default TripsReducer
