import { localStorageKeys, setLocalStorage } from 'services/localStorage'

const toggleFullListTrips = (state, payload) => {
    const { openFullListTripsState } = payload

    return {
        ...state,
        fullListTripsOpen:
            openFullListTripsState === undefined
                ? !state.fullListTripsOpen
                : !!openFullListTripsState,
    }
}

const sidebarReducers = { toggleFullListTrips }

export default sidebarReducers
