import PreviewTripsContainer from 'app/preview-trips/components/PreviewTripsContainer'
import { isPreviewTripsOpenState } from 'app/_shared/recoil/preview-trips/atom'
import classNames from 'classnames'
import React from 'react'
import { Route, Switch, useLocation } from 'react-router'
import { useRecoilState } from 'recoil'
import WaitingCalls from './WaitingCalls'
import '../scss/UserContainer.scss'

const RightPanel = () => {
    const location = useLocation()

    const [isPreviewTripsOpen] = useRecoilState(isPreviewTripsOpenState)

    const currentPageIsMap = location.pathname.split('/')[1] === 'map'

    return (
        <div
            className={classNames('RightPanelContainer', {
                ShowPreviewTrips: currentPageIsMap && !isPreviewTripsOpen,
            })}
        >   
            {/* <WaitingCalls /> */}
            <Switch>
                <Route
                    exact
                    path="/map"
                    component={() => <PreviewTripsContainer />}
                />
            </Switch>
        </div>
    )
}

export default RightPanel
