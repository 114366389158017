import classNames from 'classnames'
import './scss/_Input.scss'
import { MdClose } from 'react-icons/md'
import { MdSearch } from 'react-icons/md'
import { useState } from 'react'

const Input = props => {
    const {
        title,
        className,
        inputClassName,
        forwardRef,
        initValue,
        placeholder,
        searchIcon,
        ...inputProps
    } = props

    const [value, setValue] = useState(initValue || '')
    const [flagShowIconClose, setFlagShowIconClose] = useState(false)
    const [flagShowIconSearch, setFlagShowIconSearch] = useState(searchIcon ? true : false)

    const handleClickIconClose = e => {
        setValue('')
        setFlagShowIconClose(false)

        forwardRef.current.focus()

        props.clearSearching()
    }

    const _onChange = e => {
        if (e.target.value !== '' && e.target.value != null)
            setFlagShowIconClose(true)
        else setFlagShowIconClose(false)

        setValue(e.target.value)
        props.onChange(e)
    }

    const _onKeyDown = e => {
        props?.onKeyDown && props.onKeyDown(e)
    }

    const _onFocus = e => {
        if (searchIcon) setFlagShowIconSearch(false)
    }

    const _onBlur = e => {
        if (searchIcon) {
            value ? setFlagShowIconSearch(false) : setFlagShowIconSearch(true)
        }
    }

    return (
        <div
            className={classNames('InputWrapper', className)}
            style={{ position: 'relative', zIndex: 1 }}
        >
            {title && <label htmlFor={props.name}>{title}</label>}
            <input
                spellCheck="false"
                ref={forwardRef}
                className={classNames(inputClassName, 'Input')}
                //{...inputProps}
                value={value}
                onChange={_onChange}
                onKeyDown={_onKeyDown}
                placeholder={placeholder}
                onFocus={_onFocus}
                onBlur={_onBlur}
            />
            {flagShowIconSearch && (
                <MdSearch 
                    style={{
                        color: '#616161',
                        position: 'absolute',
                        top: 6,
                        right: 20,
                        cursor: 'pointer',
                        zIndex: 0,
                        fontSize: '1rem'
                    }}
                />
            )}
            {flagShowIconClose && (
                <MdClose
                    style={{
                        color: 'orange',
                        position: 'absolute',
                        top: 8,
                        right: 5,
                        cursor: 'pointer',
                        zIndex: 0,
                    }}
                    onClick={handleClickIconClose}
                />
            )}
        </div>
    )
}

export default Input
