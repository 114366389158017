import { LoadingButton } from '@mui/lab'
import { loginCode, loginUser } from 'api/login'
import Input from 'app/_shared/components/Input'
import setUser from 'app/_shared/main-app-context/main-app-actions/setUser'
import { useDispatchMainApp } from 'app/_shared/main-app-context/MainAppContext'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { localStorageKeys, setLocalStorage } from 'services/localStorage'

const Login = props => {
    const dispatch = useDispatchMainApp()
    const { enqueueSnackbar } = useSnackbar()

    const _usernameRef = useRef()
    const _passwordRef = useRef()
    const [_submitting, _setSubmitting] = useState(false)

    const _login = async e => {
        e.preventDefault()
        _setSubmitting(true)

        const user = {
            username: _usernameRef.current.value,
            password: _passwordRef.current.value,
        }

        loginUser(user).then(res => {
            if (res) {
                if (res.success) {
                    //console.log(res.data.services)
                    setLocalStorage(localStorageKeys.token, res.data.token)
                    setLocalStorage(
                        localStorageKeys.socketToken,
                        res.data.emddiSocketToken
                    )
                    setLocalStorage(
                        localStorageKeys.services,
                        res.data.services
                    )

                    dispatch(setUser(res.data.user))

                    window.location.replace('/')
                    // setTimeout(() => {
                    //     dispatch(setUser(res.data.user))
                    // }, 200)
                } else {
                    enqueueSnackbar(res.message, {
                        variant: 'warning',
                    })
                    if (res.code === loginCode.USER_NOT_FOUND)
                        _usernameRef.current.focus()
                    if (res.code === loginCode.WRONG_PASSWORD)
                        _passwordRef.current.focus()
                    _setSubmitting(false)
                }
            }
        })
    }

    const handleEnter = e => {
        if (e.key.toLowerCase() === 'enter') {
            const form = e.target.form
            const index = [...form].indexOf(e.target)
            form.elements[index + 1].focus()
            e.preventDefault()
        }
    }

    const [value, setValue] = useState('')

    const _onChange = e => {
        setValue(e.target.value)
    }

    return (
        <div className="LoginContainer">
            <form className="FormLogin" onSubmit={_login}>
                <Input
                    inputClassName="LoginInput"
                    title="Username"
                    name="username"
                    forwardRef={_usernameRef}
                    onKeyDown={handleEnter}
                    onChange={_onChange}
                    value={value}
                />
                <Input
                    inputClassName="LoginInput"
                    title="Password"
                    type="password"
                    name="password"
                    forwardRef={_passwordRef}
                    onKeyDown={handleEnter}
                    onChange={_onChange}
                    value={value}
                />
                <LoadingButton
                    className={classNames('LoginButton', {
                        Loading: _submitting,
                    })}
                    type="submit"
                    loading={_submitting}
                >
                    Login
                </LoadingButton>
            </form>
        </div>
    )
}

export default Login
