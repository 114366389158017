import Login from './Login'
import '../scss/index.scss'

const LoginContainer = props => {
    return (
        <>
            <Login {...props} />
        </>
    )
}

export default LoginContainer
