import { useEffect, useState } from "react";
import { reasonCancelledName, reasonCancelledContent } from "utils/trips";
import CheckboxReason from "./CheckboxReason";

const ModalConfirmCancel = (props) => {
    const [reasonCancelled, setReasonCancelled] = useState('')

    const _checkboxChange = ({ name, checked }) => {
        if (checked) {
            let objTemp = {}
            for (const radioCheck in objectReasonChecked) {
                objTemp[radioCheck] = radioCheck !== name ? false : true
            }

            setObjectReasonChecked({...objTemp})

            if (name !== reasonCancelledName.otherReason) setReasonCancelled(reasonCancelledContent[name])
            else setReasonCancelled('')
        }else {
            let objTemp = {}
            for (const radioCheck in objectReasonChecked) {
                objTemp[radioCheck] = false
            }
            setObjectReasonChecked({...objTemp})
        }
    }

    const [objectReasonChecked, setObjectReasonChecked] = useState({
        [reasonCancelledName.unableContact]: false,
        [reasonCancelledName.driverCancel]: false,
        [reasonCancelledName.customerCancel]: false,
        [reasonCancelledName.wrong]: false,
        [reasonCancelledName.otherReason]: false,
    })

    const _textareaChange = e => {
        if (objectReasonChecked[reasonCancelledName.otherReason])
            setReasonCancelled(e.target.value)
    }

    useEffect(() => {
        // Reset modal confirm
        setReasonCancelled('')
        let objTemp = {}
        for (const radioCheck in objectReasonChecked) {
            objTemp[radioCheck] = false
        }
        setObjectReasonChecked({...objTemp})
    }, [props.hidden])
    return(
        <div className={"confirm-cancel " + props.className + (!props.hidden ? 'show' : '')}>
            <div className="content">
                <h3>Xác nhận hủy chuyến</h3>
                <div className="reason-cancel">
                    <CheckboxReason 
                        checked={objectReasonChecked[reasonCancelledName.unableContact]}
                        name={reasonCancelledName.unableContact}
                        reasonCancelled={reasonCancelledContent[reasonCancelledName.unableContact]}
                        checkboxChange={_checkboxChange}
                    />
                    <CheckboxReason 
                        checked={objectReasonChecked[reasonCancelledName.driverCancel]}
                        name={reasonCancelledName.driverCancel}
                        reasonCancelled={reasonCancelledContent[reasonCancelledName.driverCancel]}
                        checkboxChange={_checkboxChange}
                    />
                    <CheckboxReason 
                        checked={objectReasonChecked[reasonCancelledName.customerCancel]}
                        name={reasonCancelledName.customerCancel}
                        reasonCancelled={reasonCancelledContent[reasonCancelledName.customerCancel]}
                        checkboxChange={_checkboxChange}
                    />
                    <CheckboxReason 
                        checked={objectReasonChecked[reasonCancelledName.wrong]}
                        name={reasonCancelledName.wrong}
                        reasonCancelled={reasonCancelledContent[reasonCancelledName.wrong]}
                        checkboxChange={_checkboxChange}
                    />
                    <CheckboxReason 
                        checked={objectReasonChecked[reasonCancelledName.otherReason]}
                        name={reasonCancelledName.otherReason}
                        reasonCancelled={reasonCancelledContent[reasonCancelledName.otherReason]}
                        checkboxChange={_checkboxChange}
                    />
                    {
                        objectReasonChecked[reasonCancelledName.otherReason] &&
                        <textarea 
                            className="textarea"
                            placeholder="Nhập lý do hủy chuyến"
                            onChange={_textareaChange}
                        />
                    }
                </div>
                <div className="btn-group">
                    <button onClick={props.hideModalConfirm}>Hủy</button>
                    <button onClick={() => props.confirmCancelled(reasonCancelled)}>Xác nhận</button>
                </div>
            </div>
        </div>
    )
}

export default ModalConfirmCancel