import classNames from "classnames/bind";
import styles from "../scss/_Detail.scss";
import React, {useEffect, useState} from "react";
import {getAllRegion,} from "../../../api/location";
import {getListServiceByOffice, updateService} from "../../../api/service";
import {useSnackbar} from "notistack";
import {updateUser} from "../../../api/user";

const cx = classNames.bind(styles)
const Detail = ({user, officeId, onReload}) => {
    const [regionData, setRegionData] = useState([]);
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState('');
    const [userFullName, setUserFullName] = useState('');
    const [userService, setUserService] = useState([]);
    const [oldUserService, setOldUserService] = useState([]);
    const [listService, setListService] = useState([]);
    const [switchBoard, setSwitchBoard] = useState(null);
    const [regionId, setRegionId] = useState(null);
    const [reload, setReload] = useState(1);
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        (async () => {
            const data = await getAllRegion(officeId);
            setRegionData(data);
        })()

    }, [officeId])

    useEffect(() => {
        (async () => {
            const data = await getListServiceByOffice();
            setListService(data);
        })()
    }, [])
    useEffect(() => {
        (async () => {
            const data = await getListServiceByOffice();
            setListService(data);
        })()
    }, [reload])

    useEffect(() => {
        if (user) {
            setUserId(user.user_id)
            setUserName(user.user_name)
            setUserFullName(user.full_name)
            setUserService(user['user_service.list_service'] ? user['user_service.list_service'].map(id => parseInt(id)) : []);
            setOldUserService(user['user_service.list_service'] ? user['user_service.list_service'].map(id => parseInt(id)) : []);
            setSwitchBoard(user['user_service.switchboard'])
            setRegionId(user['user_service.region_id'])
        }
    }, [user])

    const _handleSelectRegion = e => {
        setRegionId(e.target.value)
    }

    const _handleFullNameChange = e => {
        setUserFullName(e.target.value)
    }

    const _handleCheckboxChange = serviceId => {
        if (userService.includes(serviceId)) {
            setUserService(userService.filter(id => id !== serviceId));
        } else {
            setUserService([...userService, serviceId]);
        }
    }

    const _handleSelectAll = () => {
        const allServiceIds = listService.map(service => service.service_id);

        if (userService.length === allServiceIds.length) {
            setUserService(oldUserService);
        } else {
            setUserService(allServiceIds);
        }
    }

    const _handleSelectSwitchBoard = e => {
        setSwitchBoard(e.target.value)
    }
    const _handleUpdateUser = () => {
        const service = {userId, regionId, switchBoard, listService: userService}
        const user = {userId, userFullName}

        if (Object.values(service).includes(null)) {
            return enqueueSnackbar('Vui lòng chọn đầy đủ thông tin!', {
                variant: 'warning',
            })
        } else {
            if (Object.values(user).includes(null)) {
                return enqueueSnackbar('Vui lòng chọn đầy đủ thông tin!', {
                    variant: 'warning',
                })
            } else {
                Promise.all([updateService(service), updateUser(user)])
                    .then(response => {
                        if (response.every(value => value === true)) {
                            setReload(reload + 1)
                            onReload()
                            return enqueueSnackbar('Update Thành Công!', {variant: 'success'})
                        } else {
                            return enqueueSnackbar('Update Thất Bại!', {variant: 'warning'})
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
        }
    }

    return (<div className={cx('Detail__Wrapper')}>
        <div className={cx('Title')}>
            <h3 className={cx('title-text')}>
                Thông Tin Chi Tiết
            </h3>
        </div>
        <div className={cx('Detail__Content')}>
            <div className={cx('content-box')}>
                <table className="table-region">
                    <tbody>
                    <tr>
                        <td className="label-table">Vùng:</td>
                        <td>
                            <select className="modal-region-select" value={regionId || ''}
                                    onChange={_handleSelectRegion}>
                                {regionData
                                    ? regionData.map((region) =>
                                        (<option key={region.region_id}
                                                 value={region.region_id}
                                        >{region.name}
                                        </option>))
                                    : ''}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td className="label-table">Tên Đăng Nhập:</td>
                        <td>
                            <input className={cx('modal-region-input')} type="text" value={userName || ''}
                                   disabled/>
                        </td>
                    </tr>
                    <tr>
                        <td className="label-table">Họ Tên:</td>
                        <td>
                            <input className={cx('modal-region-input')} type="text" value={userFullName || ''}
                                   onChange={_handleFullNameChange}/>
                        </td>
                    </tr>
                    <tr>
                        <td className="label-table">Tổng Đài Số:</td>
                        <td>
                            <select className="modal-region-select" onChange={_handleSelectSwitchBoard}>
                                <option value={null}>Chọn số tổng đài</option>
                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className={cx('btn-box')}>
                    <button className="custom-button danger" onClick={_handleUpdateUser}>Cập Nhật</button>
                </div>
            </div>
            <div className={cx('content-service')}>
                <div className={cx('checkbox-grid')}>
                    <label className={cx('checkbox-grid-item all')}>
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4 text-indigo-600 cursor-pointer"
                            onChange={_handleSelectAll}
                            checked={userService.length === listService.length}
                        />
                        <span className="ml-2 text-gray-700">CHỌN TẤT CẢ</span>
                    </label>
                    {listService.map((service) => (
                        <label key={service.service_id} className={cx('checkbox-grid-item')}>
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4 text-indigo-600 cursor-pointer"
                                onChange={() => _handleCheckboxChange(service.service_id)}
                                checked={userService.includes(service.service_id)}
                            />
                            <span className="ml-2 text-gray-700">{service.vi_name}</span>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    </div>)
}
export default Detail