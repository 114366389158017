import userContainerLocaleKeys from '../i18n/userContainerLocaleKeys'

const sideBarMenus = [
    {
        route: '/grant-role',
        title: userContainerLocaleKeys.sideBar.grantRole,
        icon: 'MdOutlineSupervisorAccount',
    },
    {
        route: '/map',
        title: userContainerLocaleKeys.sideBar.vehicleMap,
        icon: 'MdOutlineMap',
    },
    {
        route: '/quicksearch',
        title: userContainerLocaleKeys.sideBar.quickSearch,
        icon: 'MdBookmarks'
    },
    {
        route: '/setting',
        title: userContainerLocaleKeys.sideBar.setting,
        icon: 'MdOutlineSettings',
    },
    // {
    //     route: '/logout',
    //     title: userContainerLocaleKeys.sideBar.logout,
    //     icon: 'MdLogout',
    // },
    
    // {
    //     route: '/booking',
    //     title: userContainerLocaleKeys.sideBar.trips,
    //     icon: 'MdOutlineDirectionsCar',
    // },
]

export default sideBarMenus
