import { coordinatorStatus } from './coordinator'

export const tripStatusName = {
    vacant: 'VACANT',
    dispatching: 'DISPATCHING',
    driverOnTheWay: 'DRIVER_ON_THE_WAY',
    pob: 'POB',
    arrivedAndWaiting: 'ARRIVED_AND_WAITING',
    droppedOff: 'DROPPED_OFF',
    waitingForProcess: 'WAITING_FOR_PROCESS',
    unavailable: 'UNAVAILABLE',
    carNotFound: 'CAR_NOT_FOUND',
    error: 'ERROR',
    pending: 'PENDING',
    startTrip: 'START_TRIP',
    streetView: 'STREET_VIEW',
}

export const tripStatus = {
    [tripStatusName.vacant]: 0,
    [tripStatusName.dispatching]: 1,
    [tripStatusName.driverOnTheWay]: 2,
    [tripStatusName.pob]: 3,
    [tripStatusName.arrivedAndWaiting]: 4,
    [tripStatusName.droppedOff]: 5,
    [tripStatusName.waitingForProcess]: 6,
    [tripStatusName.unavailable]: 7,
    [tripStatusName.carNotFound]: 8,
    [tripStatusName.error]: 9,
    [tripStatusName.pending]: 10,
    [tripStatusName.startTrip]: 11,
    [tripStatusName.streetView]: 12,
}

export const tripStatusColor = {
    [tripStatusName.vacant]: '#4bd948',
    //[tripStatusName.dispatching]: '#b04aff',
    [tripStatusName.dispatching]: '#00953D', //Đổi sang màu xanh lá
    //[tripStatusName.driverOnTheWay]: '#fff500',
    [tripStatusName.driverOnTheWay]: '#EF5816',
    [tripStatusName.pob]: '#00759a',
    [tripStatusName.arrivedAndWaiting]: '#f5a623',
    //[tripStatusName.droppedOff]: '#ff382c',
    //[tripStatusName.droppedOff]: '#43B8FF',
    [tripStatusName.droppedOff]: '#006CC8',
    //[tripStatusName.waitingForProcess]: '#ff00b8',
    [tripStatusName.waitingForProcess]: '#ff382c',
    [tripStatusName.unavailable]: '#cccccc',
    [tripStatusName.carNotFound]: 'red',
    [tripStatusName.error]: 'red',
    [tripStatusName.pending]: 'red',
}

const oldBookingStatus = {
    //error: -1,
    error: -2, //Lỗi
    carNotFound: -1, //Không thấy xe
    comming: 0,
    journey: 1,
    endTrip: 2,
}

export const getTripStatusByBookingStatus = status => {
    //Chưa có trạng thái tài hủy, khách hủy, TĐ hủy
    switch (status) {
        case oldBookingStatus.error:
            return tripStatusName.unavailable
        case oldBookingStatus.carNotFound:
            return tripStatusName.carNotFound
        case oldBookingStatus.comming:
            return tripStatusName.driverOnTheWay
        case oldBookingStatus.journey:
            return tripStatusName.pob
        case oldBookingStatus.endTrip:
            return tripStatusName.droppedOff
        default:
            return false
    }
}

export const getTripStatusByCoordinatorStatus = status => {
    switch (status) {
        case coordinatorStatus.searching:
            return tripStatusName.dispatching
        case coordinatorStatus.hadFound:
            return tripStatusName.driverOnTheWay
        case coordinatorStatus.error:
            return tripStatusName.error
        default:
            return false
    }
}

export const mapOldStatus = trip => {
    if (trip.type === 8) {
        return tripStatusName.pending
    } else {
        let status = getTripStatusByBookingStatus(
            trip.status_booking || trip.booking?.status
        )

        if (!status) {
            status = getTripStatusByCoordinatorStatus(trip.status)
        }

        return status
    }
}

export const mapDriverStatus = status => {
    switch (status) {
        case tripStatus[tripStatusName.vacant]:
            return tripStatusName.vacant
        case tripStatus[tripStatusName.dispatching]:
            return tripStatusName.dispatching
        case tripStatus[tripStatusName.driverOnTheWay]:
            return tripStatusName.driverOnTheWay
        case tripStatus[tripStatusName.pob]:
            return tripStatusName.pob
        case tripStatus[tripStatusName.arrivedAndWaiting]:
            return tripStatusName.arrivedAndWaiting
        case tripStatus[tripStatusName.droppedOff]:
            return tripStatusName.droppedOff
        case tripStatus[tripStatusName.waitingForProcess]:
            return tripStatusName.waitingForProcess
        case tripStatus[tripStatusName.unavailable]:
            return tripStatusName.unavailable
    }
    return false
}

export const reasonCancelledName = {
    unableContact: 'UNABLE_CONTACT',
    driverCancel: 'DRIVER_CANCEL',
    customerCancel: 'CUSTOMER_CANCEL',
    wrong: 'WRONG',
    otherReason: 'ORTHER_REASON'
}

export const reasonCancelledContent  = {
    [reasonCancelledName.unableContact]: 'Không liên lạc được với khách hàng',
    [reasonCancelledName.driverCancel]: 'Tài xế hủy',
    [reasonCancelledName.customerCancel]: 'Khách hủy',
    [reasonCancelledName.wrong]: 'Nhầm',
    [reasonCancelledName.otherReason]: 'Lý do khác',
}


