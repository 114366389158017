const setUser = (state, action) => {
    const { payload: args } = action
    const { user } = args

    return {
        ...state,
        user,
    }
}

export default setUser
