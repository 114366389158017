const UserContainerActionTypes = {
    setSingleFilterTripStatus: 'SET_SINGLE_FILTER_TRIP_STATUS',
    setAllFilterTripStatus: 'SET_ALL_FILTER_TRIP_STATUS',
    setTotalDrivers: 'SET_TOTAL_DRIVERS',
    toggleFilterTripCollapse: 'TOGGLE_FILTER_TRIP_COLLAPSE',

    addAllTrip: 'ADD_ALL_TRIP',

    toggleFullListTrips: 'TOGGLE_FULL_LIST_TRIPS',

    setListDrivers: 'SET_LIST_DRIVER',
    updateListDrivers: 'UPDATE_LIST_DRIVER',
    setIsTheFirstDisplayCarMarker: 'SET_IS_THE_FIRST_DISPLAY_CAR_MARKER',
}

export default UserContainerActionTypes
