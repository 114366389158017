import React from 'react'
import ReactDOM from 'react-dom'
import App from 'app/main-app/components/App'
import { BrowserRouter } from 'react-router-dom'
import 'scss/index.scss'
import reportWebVitals from './reportWebVitals'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import appLocale from 'app/main-app/i18n/appLocale'
import userContainerLocale from 'app/user-container/i18n/userContainerLocale'
import newBookingLocale from 'app/new-booking/i18n/newBookingLocale'
import bookingsLocale from 'app/bookings/i18n/bookingsLocale'
import LngDetector from 'i18next-browser-languagedetector'
import ReactTooltip from 'react-tooltip'
import previewTripsLocale from 'app/preview-trips/i18n/previewTripsLocale'
import '@emddi/react-map/dist/index.css'
import { SnackbarProvider } from 'notistack'
import { RecoilRoot } from 'recoil'
import tripDetailLocale from 'app/trip-detail/i18n/tripDetailLocale'

i18n.use(initReactI18next)
    .use(LngDetector)
    .init({
        resources: {
            en: {
                translation: {
                    ...appLocale.en,
                    ...userContainerLocale.en,
                    ...newBookingLocale.en,
                    ...previewTripsLocale.en,
                    ...bookingsLocale.en,
                    ...tripDetailLocale.en,
                },
            },
            vi: {
                translation: {
                    ...appLocale.vi,
                    ...userContainerLocale.vi,
                    ...newBookingLocale.vi,
                    ...previewTripsLocale.vi,
                    ...bookingsLocale.vi,
                    ...tripDetailLocale.vi,
                },
            },
        },
        fallbackLng: 'en',
        lng: 'vi',
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    })

ReactDOM.render(
    <RecoilRoot>
        <BrowserRouter>
            <SnackbarProvider
                dense
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <App />
            </SnackbarProvider>
            <ReactTooltip />
        </BrowserRouter>
    </RecoilRoot>,
    document.getElementById('root')
)

reportWebVitals()
