import PageWrapper from 'app/page-wrapper/components/PageWrapper'
import Bookings from './Bookings'
import '../scss/BookingsContainer.scss'

import ViewContext from 'app/_shared/view-context'
import { useEffect, useState } from 'react'

const { useViewContext } = ViewContext

const BookingsContainer = props => {
    const [{ fullListTripsOpen, usingSwitchboard }] = useViewContext()

    const [flagShowingTrips, setFlagShowingTrips] = useState(fullListTripsOpen)

    useEffect(() => {
        setFlagShowingTrips(fullListTripsOpen)
    }, [fullListTripsOpen])

    useEffect(() => {
        if (usingSwitchboard == true) setFlagShowingTrips(true)
    }, [])

    return (
        <PageWrapper
            title="Trips"
            className={
                !flagShowingTrips
                    ? 'BookingsContainerHidden'
                    : usingSwitchboard
                    ? 'BookingsContainer'
                    : 'BookingsContainerFullHeight'
            }
        >
            <Bookings />
        </PageWrapper>
    )
}

export default BookingsContainer
