import { selector, selectorFamily } from 'recoil'
import { tripStatusFilterState } from './atom'

export const singleTripStatusSelector = selectorFamily({
    key: 'previewTrips.filter.singleTripStatus',
    get:
        name =>
        ({ get }) => {
            const tripStatusFilter = get(tripStatusFilterState)

            return tripStatusFilter[name]
        },
})

export const isAllStatusCheckedSelector = selector({
    key: 'previewTrips.filter.isAllStatusChecked',
    get: ({ get }) => {
        const tripStatusFilter = get(tripStatusFilterState)

        let allChecked = true
        for (const status in tripStatusFilter) {
            if (Object.hasOwnProperty.call(tripStatusFilter, status)) {
                if (!tripStatusFilter[status]) {
                    allChecked = false
                    break
                }
            }
        }

        return allChecked
    },
})
