import classNames from "classnames/bind";
import styles from "../scss/_GrantRole.scss";
import ListAccount from "./ListAccount";
import Detail from "./Detail";
import {useEffect, useState} from "react";
import {getOffice} from "../../../api/location";
import {getUserByOfficeId} from "../../../api/user";

const cx = classNames.bind(styles)
const local = JSON.parse(localStorage.getItem('com.emddi.booking.user'));
const GrantRole = () => {

    const [officeId, setOfficeId] = useState(null);
    const [officeName, setOfficeName] = useState(null);
    const [office, setOffice] = useState([]);
    const [reload, setReload] = useState(1)
    const [userData, setUserData] = useState([]);
    const [user, setUser] = useState(null);

    useEffect(() => {
            setOfficeId(local.office.office_id)
            setOfficeName(local.office.name)
    }, [officeId])

    useEffect( () => {
        (async () => {
            const data = await getUserByOfficeId(officeId);
            setUserData(data)
        })()
    },[officeId, reload])

    useEffect(() => {
        (async () => {
            const data = await getOffice();
            setOffice(data)
        })()

    }, [reload])

    const _handleReload = () => {
        setReload(reload + 1)
    }

    const _handeCellClick = (e) => {
       setUser(e)
    }
    return (
        <div className={cx('GrantRole')}>
            <div className={cx('GrantRole-Header')}>
            <h1> Phân Quyền Điều Xe </h1>
            </div>
            <div className={cx('GrantRole__wrapper')}>
                <ListAccount userData={userData} officeList={office} officeId={officeId} officeName={officeName} onReload={_handleReload} onClick={_handeCellClick} />
                <Detail user={user} officeId={officeId} officeName={officeName} onReload={_handleReload}/>
            </div>
        </div>
    )
}

export default GrantRole
