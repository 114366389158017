import fetcher from 'services/fetcher'
import { bookingApiPrefix } from 'api'
const updateTpyeCoordinatorApi = async dataUpdate => {
    try {
        const res = await fetcher.post(
            `${bookingApiPrefix}/trip/updating/type`,
            {
                ...dataUpdate,
            }
        )

        // console.log('response update type coor: ')
        // console.log(res)
        return res.data
    } catch (error) {
        console.log(error)
    }
}

export default updateTpyeCoordinatorApi
