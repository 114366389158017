const toggleFullListTrips = (state, payload) => {
    const { fullListTripsOpenState } = payload

    const newListTripsOpenState =
        fullListTripsOpenState === undefined
            ? !state.fullListTripsOpen
            : fullListTripsOpenState

    return {
        //...state,
        fullListTripsOpen: newListTripsOpenState,
    }
}

const toggleCardTableGroup = (state, payload) => {
    const { cardTableGroupOpenState } = payload

    const newCardTableGroupOpenState =
        cardTableGroupOpenState === undefined
            ? !state.cardTableGroupOpen
            : cardTableGroupOpenState

    return {
        //...state,
        cardTableGroupOpen: newCardTableGroupOpenState,
    }
}

const SidebarReducers = {
    toggleFullListTrips,
    toggleCardTableGroup,
}

export default SidebarReducers
