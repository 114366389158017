import HashMap from 'hashmap'
import { getLocalStorage, localStorageKeys } from 'services/localStorage'
import { tripStatusName } from 'utils/trips'

const userContainerInitState = {
    vehicleMap: { driverLocations: new HashMap(), focusingDriver: null },
    previewTrips: {
        filterTripCollapsed: false,
        totalDrivers: 0,
    },
    fullListTripsOpen: false,
    drivers: [],
    driverUpdate: null,
}

export default userContainerInitState
