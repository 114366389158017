import '../scss/PreviewTripsContainer.scss'
import PreviewTrips from './PreviewTrips'

const  PreviewTripsContainer = props => {
    return (
        <div className="PreviewTripContainner">
            <PreviewTrips {...props} />
        </div>
    )
}

export default PreviewTripsContainer
