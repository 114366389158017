import UserContainerActionTypes from '../UserContainerActionTypes'

const toggleFullListTrips = tartgetState => {
    return {
        type: UserContainerActionTypes.toggleFullListTrips,
        payload: {
            fullListTripsState: tartgetState,
        },
    }
}

const sidebarActions = { toggleFullListTrips }

export default sidebarActions
