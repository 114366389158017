const setListDriver = (state, payload) => {
    return {
        drivers: [...payload],
    }
}

const setIsTheFirstDisplayCarMarker = (state, payload) => {
    return {
        isTheFirstDisplayCarMarker: payload,
    }
}

const updateListDriver = (state, payload) => {
    // let drivers = [...state.drivers]
    // drivers = drivers.map(item => {
    //     if (item.driver_id === payload.driver_id) {
    //         return {
    //             ...item,
    //             status: payload.status,
    //             is_online: payload.is_online == 0 ? false : true,
    //         }
    //     }
    //     return item
    // })

    return {
        //drivers: drivers,
        driverUpdate: payload,
    }
}

const driversReducer = {
    setListDriver,
    updateListDriver,
    setIsTheFirstDisplayCarMarker,
}

export default driversReducer
