import { bookingApiPrefix } from 'api'
import fetcher from 'services/fetcher'
import { getLocalStorage, localStorageKeys } from 'services/localStorage'

export const getUserSettings = async () => {
    const { user_id: userId } = getLocalStorage(localStorageKeys.user)

    try {
        const response = await fetcher.get(
            `${bookingApiPrefix}/office/settings`,
            {
                params: {
                    user_id: userId,
                },
            }
        )
        //console.log(response.data.data)
    } catch (error) {
        console.log(error)
    }
}

export const setUserSettings = async data => {
    try {
        const { data: resp } = await fetcher.post(
            `${bookingApiPrefix}/office/user/setting`,
            data
        )

        return resp
    } catch (e) {
        return { success: false, message: e.message || e }
    }
}
