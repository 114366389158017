import { localStorageKeys, setLocalStorage } from 'services/localStorage'

const setRndConfig = (state, payload) => {
    const { newConfig } = payload
    const newRndState = { ...state.rndConfig, ...newConfig }

    setLocalStorage(localStorageKeys.newBookingRndCfg, newRndState)

    return {
        ...state,
        rndConfig: newRndState,
    }
}

const rndReducers = { setRndConfig }

export default rndReducers
