import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'

//import Checkbox from '@mui/material/Checkbox'

import CheckBox from 'app/_shared/components/CheckBox'

import { makeStyles } from '@mui/styles'
import { MdTranslate } from 'react-icons/md'
import { borderRadius } from '@mui/system'

const useStyle = makeStyles({
    customSelector: {
        width: '100%',

        '& .MuiInputBase-input': {
            padding: '1.5px 20px !important',
        },

        '& .select': {
            position: 'relative',
            width: '100%',
        },

        '& .MuiPaper-root': {
            minWidth: '283px !important'
        },

        '& .MuiInputLabel-root': {
            webkitTransform: 'translate(14px, 6px) !important',
            transform: 'translate(14px, 6px) !important',
            fontSize: '0.8rem',
            color: '#363636',
        },

        '& .MuiInputLabel-root.Mui-focused': {
            // webkitTransform: 'translate(14px, -8px) !important',
            // transform: 'translate(14px, -8px) !important',
            // fontSize: '0.8rem'

            webkitTransform: 'translate(14px, 6px) !important',
            transform: 'translate(14px, 6px) !important',

            fontSize: '0.8rem',
            color: '#363636 !important',
        },

        '& .MuiFormLabel-filled': {
            transform: 'translate(14px, -10px) !important',
        },

        '& .MuiSelect-select': {
            fontSize: '0.9rem !important',
        },

        '& .CheckBox': {
            width: '100% !important',
        },

        '& .MuiOutlinedInput-root': {
            /*background: '#212121',*/
            borderRadius: '0 !important',
            border: '1px solid #e5e5e5',
        },

        '&.Active': {
            '& .MuiOutlinedInput-root': {
                border: '1px solid #219737',
            },
        },

        '& .MuiOutlinedInput-root:hover': {
            border: '1px solid #219737',
        },

        '& .MuiOutlinedInput-root fieldset': {
            display: 'none',
        },

        '& .MuiFormControl-root': {
            display: 'flex !important',
            flexDirection: 'row !important',
            alignItems: 'center',
            position: 'relative',
            '&:after': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                top: 'calc(50% - 9px)',
                right: '15px',
                width: '11px',
                height: '11px',
                color: '#fff',
                transform: 'rotate(45deg)',
                borderRight: '1px solid #fff',
                borderBottom: '1px solid #fff',
                pointerEvents: 'none',
            },
            '& .label': {
                marginRight: '6px',
            },
        },
    },

    customMenuItem: {
        '&.MuiMenuItem-root': {
            padding: '0 4px !important',
            width: '100% !important',
        },
        '& .MuiCheckbox-root': {
            padding: '4px !important',
        },

        '& .CheckBox': {
            width: '100% !important',
        },
    },
    customSelectPaper: {
        '& .MuiPaper-root': {
            maxHeight: 'auto !important',
            backgroundColor: 'black !important',
            minWidth: '283px !important'
        },
    },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const DEFAULT_COLOR_SELECTED = 'rgb(25, 118, 210)'
//const DEFAULT_COLOR_SELECTED = 'white'
const DEFAULT_WIDTH = 300

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            //   width: 180,
            paddingLeft: 10,
            // paddingRight: 10
        },
    },
}

const MenuPropsStatus = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            //   width: 180,
            minWidth: 283,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 4
        },
    },
}

export default function MultipleSelectCheckmarks(props) {
    // const [isActive, setIsActive] = React.useState(false)

    // const [count, setCount] = React.useState(0)

    const [values, setValues] = React.useState([])

    const [options, setOptions] = React.useState(props.options)

    const [allStatusChecked, setAllStatusChecked] = React.useState(true)

    const [objectStatus, setObjectStatus] = React.useState({})

    const classes = useStyle()

    React.useEffect(() => {
        setOptions(props.options)
    }, [props.options])

    React.useEffect(() => {
        let objectStatus = {}
        for (let i = 1; i < props.options.length; i++) {
            objectStatus[props.options[i].id] = props.options[i].checked
        }
        setObjectStatus({ ...objectStatus })
    }, [props.options])

    React.useEffect(() => {
        let allChecked = true
        for (const status in objectStatus) {
            if (Object.hasOwnProperty.call(objectStatus, status)) {
                if (!objectStatus[status]) {
                    allChecked = false
                    break
                }
            }
        }
        setAllStatusChecked(allChecked)
        // if (!allChecked) {
        //     let countTemp = 0
        //     for (const status in objectStatus) {
        //         if (Object.hasOwnProperty.call(objectStatus, status)) {
        //             if (objectStatus[status]) {
        //                 countTemp ++
        //             }
        //         }
        //     }
        //     setCount(countTemp)

        //     if (countTemp > 0 && countTemp != options.length) setIsActive(true)

        // }
    }, [objectStatus])

    const handleChange = event => {
        const {
            target: { value },
        } = event
        setValues(typeof value === 'string' ? value.split(',') : value)
    }

    const _handleSingleStatusChange = (checked, name) => {
        setObjectStatus({ ...objectStatus, [name]: checked })
        props.onChange({ ...objectStatus, [name]: checked })
    }

    const _handleAllStatusChange = checked => {
        setAllStatusChecked(checked)
        let newObjectStatus = objectStatus
        for (const status in newObjectStatus) {
            if (Object.hasOwnProperty.call(newObjectStatus, status)) {
                newObjectStatus[status] = checked
            }
        }
        setObjectStatus({ ...newObjectStatus })
        props.onChange({ ...newObjectStatus })
    }

    const checkAllSelected = object => {
        for (let key in object) {
            if (!object[key]) return false
        }
        return true
    }

    const checkAllNotSelected = object => {
        for (let key in object) {
            if (object[key]) return false
        }
        return true
    }

    React.useEffect(() => {
        if (props.isActive === false && !checkAllNotSelected(objectStatus)) {
            _handleAllStatusChange(true)
        }
    }, [props.isActive])

    return (
        <div className={classes.customSelector + (props.isActive ? ' Active' : '')}>
            <FormControl
                sx={{
                    m: 0,
                    // width: props.width ? props.width : DEFAULT_WIDTH
                    width: props.width ? props.width : '100%'
                }}
            >
                {/* <span className="label">{props.title}</span> */}
                {/* <span className='all'>Tất cả</span> */}
                <div 
                    className="select" 
                >
                    <InputLabel id="demo-multiple-checkbox-label">
                        {props.label}
                    </InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        disabled={props.disabled}
                        multiple
                        value={[]}
                        onChange={handleChange}
                        input={<OutlinedInput label={props.label} />}
                        //style={{ width: props.width }}
                        style={{ width: '100%' }}
                        // renderValue={(selected) => selected.join(', ')}

                        // renderValue={(selected) =>
                        //     {
                        //         console.log(selected)
                        //         if(selected.indexOf(props.options[0].name) > -1) return props.options[0].name

                        //         let stringValue = ''
                        //         selected.map((name, index) => {
                        //             if (index < selected.length - 1)  stringValue += name + ','
                        //             else stringValue += name
                        //         })
                        //         return stringValue
                        //     }
                        // }

                        MenuProps={props.name === 'status' ? MenuPropsStatus : MenuProps}
                        className={classes.customSelectPaper}
                    >   
                        <div style={{display: 'flex'}}>
                            <img src='/assets/icons/All.svg' />
                            <MenuItem className={classes.customMenuItem}>
                                <CheckBox
                                    checked={allStatusChecked}
                                    label={options[0].name}
                                    color={DEFAULT_COLOR_SELECTED}
                                    size={25}
                                    onToggle={_handleAllStatusChange}
                                    name={options[0].id}
                                />
                            </MenuItem>
                        </div>
                        <div className='divider' style={{
                                height: '1px', 
                                backgroundColor: '#e6e9ec',
                                margin: props.name === 'status' ? '5px 6px 5px 22px' : '5px 6px 5px 0px'
                            }}
                        >
                        </div>

                        {options.map((item, index) => {
                            if (props.name !== 'status') {
                                if (index > 0) {
                                    return (
                                        <div key={item.id}>
                                            <MenuItem
                                                className={classes.customMenuItem}
                                            >
                                                <CheckBox
                                                    checked={objectStatus[item.id]}
                                                    label={item.name}
                                                    color={item.color}
                                                    size={25}
                                                    onToggle={_handleSingleStatusChange}
                                                    name={item.id}
                                                />
                                                
                                            </MenuItem>
                                            {
                                                index % 3 == 0 &&
                                                <div className='divider' style={{
                                                        height: '1px', 
                                                        backgroundColor: '#e6e9ec',
                                                        margin: '5px 6px'
                                                    }}
                                                >
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            }else {
                                if (index > 0) {
                                    return (
                                        <div key={item.id}>
                                            <div style={{display: 'flex'}}>
                                                <img src={item.img_url} style={{width: '16px'}}/>
                                                <MenuItem
                                                    className={classes.customMenuItem}
                                                >
                                                    <CheckBox
                                                        checked={objectStatus[item.id]}
                                                        label={item.name}
                                                        color={item.color}
                                                        size={25}
                                                        onToggle={_handleSingleStatusChange}
                                                        name={item.id}
                                                    />
                                                </MenuItem>
                                            </div>
                                            {
                                                index % 3 == 0 &&
                                                <div className='divider' style={{
                                                        height: '1px', 
                                                        backgroundColor: '#e6e9ec',
                                                        margin: '5px 6px 5px 22px'
                                                    }}
                                                >
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            }
                        })}
                        {   props.name === 'status' &&
                            <div>
                                <div className='divider' 
                                    style={{
                                        height: '1px', 
                                        backgroundColor: '#e6e9ec',
                                        margin: '5px 0px 8px 0px'
                                    }}
                                >
                                </div>
                                <div style={{display: 'flex'}}>
                                    <button style={{
                                            flex:1,
                                            border:'1px solid #bdbdbd',
                                            borderRadius: 4,
                                            padding: '4px 16px',
                                            color: '#181918',
                                            fontSize: 14,
                                            fontWeight: 500
                                        }}>
                                        Hủy
                                    </button>
                                    <button style={{
                                        flex:1,
                                        background: '#219737',
                                        borderRadius: 4,
                                        padding: '4px 16px',
                                        color: '#ffffff',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        marginLeft: 8
                                    }}>
                                     Áp dụng
                                    </button>
                                </div>
                            </div>
                        }
                    </Select>
                    
                </div>
            </FormControl>
        </div>
    )
}
