import { Box, Modal } from '@mui/material'
import previewTripsKeys from 'app/preview-trips/i18n/previewTripsKeys'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import { headerStatusSelector } from 'app/_shared/recoil/trip-detail/selector'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getLocalStorage, localStorageKeys } from 'services/localStorage'
import { mapDriverStatus, mapOldStatus } from 'utils/trips'
import './../scss/TripDetailContainer.scss'
import BookingDetail from './BookingDetail'
import MapTracking from './MapTracking'
import CloseIcon from '@mui/icons-material/Close'

const TripDetailContainer = () => {
    const rndConfig = getLocalStorage(localStorageKeys.newBookingRndCfg)
    const t = useTrans()

    const [tripDetail, setTripDetail] = useRecoilState(tripDetailState)
    //console.log('🚀 ~ TripDetailContainer ~ tripDetail', tripDetail)
    const tripStatusHeader = useRecoilValue(headerStatusSelector)

    const handleClose = (e, r) => {
        e.stopPropagation()
        setTripDetail({ open: false, data: null })
    }

    const _onClickCloseIcon = () => {
        setTripDetail({ open: false, data: null })
        setFlagMaximumSize(false)
    }

    const [flagMaximumSize, setFlagMaximumSize] = useState(true)

    const _handleZoomInOut = () => {
        setFlagMaximumSize(!flagMaximumSize)
    }

    return (
        <>
            {tripDetail.open && (
                <div
                    className={flagMaximumSize ? "TripDetailContainer zoomout" : "TripDetailContainer"}
                >
                    <div className="Body">
                        <BookingDetail 
                            clickCloseIcon={_onClickCloseIcon}
                            handleZoomInOut={_handleZoomInOut}
                            flagMaximumSize={flagMaximumSize}
                        />
                        <MapTracking 
                            flagMaximumSize={flagMaximumSize}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default TripDetailContainer
