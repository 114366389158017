export const rebookingTypes = {
    REBOOKING_CAR_NOT_FOUND: 'REBOOKING_CAR_NOT_FOUND',
    REBOOKING_DRIVER_CANCEL: 'REBOOKING_DRIVER_CANCEL',
    REBOOKING_RESERVATION: 'REBOOKING_RESERVATION',
}

export const editBookingTypes = {
    REBOOKING_CAR_NOT_FOUND: 'REBOOKING_CAR_NOT_FOUND',
    REBOOKING_DRIVER_CANCEL: 'REBOOKING_DRIVER_CANCEL',
    UPDATE_TRIP_INFO: 'UPDATE_TRIP_INFO',
    TRIP_DETAIL_CANCEL: 'TRIP_DETAIL_CANCEL',
    REBOOKING_RESERVATION: 'REBOOKING_RESERVATION',
}

export const BOOKING_FINISHED_PAGE_SIZE = 20
