import { createContext, useContext, useReducer } from 'react'
import initialState from './initialState'
import reducers from './reducers'

const NewBookingContext = createContext(initialState)

export const NewBookingContextProvider = props => {
    const [state, dispatch] = useReducer(reducers, initialState)

    return (
        <NewBookingContext.Provider value={[state, dispatch]}>
            {props.children}
        </NewBookingContext.Provider>
    )
}

export const useNewBookingContext = () => useContext(NewBookingContext)

export const useNewBookingDispatch = () => {
    const [_, dispatch] = useContext(NewBookingContext)

    return dispatch
}
