import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import useTrips from 'app/_shared/trips-context'
import { bookingStatusColor, bookingStatusName } from 'utils/Booking'

const GridPreviewTrips = () => {
    const onGridReady = params => {
        
    }

    const [{ trips }] = useTrips()

    const renderStartAddress = params => {
        if (params.value != null && params.value !== '') {
            let divNote = document.createElement('div')
            divNote.classList.add('div-start-address')
            let pNote = document.createElement('p');
            pNote.classList.add('start-address-content')
            pNote.appendChild(document.createTextNode(params.value))
            divNote.appendChild(pNote)
            // if (params.value.length >= 40) {
            //     let linkDetail = document.createElement('a')
            //     linkDetail.appendChild(document.createTextNode('Chi tiết'))
            //     linkDetail.classList.add('link-detail-start-address')
            //     divNote.appendChild(linkDetail)
            // }
            return divNote
        }else return ''
    }

    const renderDriver = params => {
        if (params.value != null && params.value !== '') {
            let divNote = document.createElement('div')
            divNote.classList.add('div-driver-info')
            let pDriverName = document.createElement('p')
            pDriverName.classList.add('p-driver-name')
            pDriverName.appendChild(document.createTextNode(params.data.driver_name))
            divNote.appendChild(pDriverName)
            let pDriverLicense = document.createElement('p')
            pDriverLicense.classList.add('p-driver-license')
            pDriverLicense.appendChild(document.createTextNode(params.data.vh_code + '-' + params.data.license_plate))
            divNote.appendChild(pDriverLicense)
            return divNote
        }else return ''
    }

    const renderStatus = params => {
        const element = document.createElement('div')
        element.classList.add('parent-icon-status')
        const imageElement = document.createElement('img')
        const textElenment = document.createElement('span')
        element.appendChild(imageElement);
        element.appendChild(textElenment);

        let status = params.data.status

        let statusBooking = params.data.status_booking

        let cancel = params.data.cancel

        if (params.data.type == 10 || params.data.type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        imageElement.src = 'assets/icons/Dang_don_khach.svg';
                        textElenment.appendChild(document.createTextNode('Đang đi đón khách'));
                        return element;
                        //return '<span style="color:#f5a623">Đang đi đón khách</span>'
                    } else if (cancel == 1) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));
                        return element;
                        //return '<span style="color:">Khách hàng hủy chuyến</span>'
                    } else if (cancel == 2) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));
                        return element;
                        //return '<span style="color:red" class="DriverCancelBlink">Lái xe hủy chuyến</span>'
                    } else if (cancel == 3) {
                        imageElement.src = 'assets/icons/huy_chuyen.svg';
                        textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));
                        return element;
                        //return '<span style="color:">Tổng đài hủy chuyến</span>'
                    } else {
                        imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                        textElenment.appendChild(document.createTextNode('Đang tìm xe'));
                        return element;
                        // return `<span style="color:${
                        //     bookingStatusColor[bookingStatusName.dispatching]
                        // }">Đang tìm xe</span>`
                    }
                case 1:
                    imageElement.src = 'assets/icons/Dang_cho_khach.svg';
                    textElenment.appendChild(document.createTextNode('Đang chở khách'));
                    return element;
                    //return '<span style="color:#00759A">Đang chở khách</span>'
                case 2:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    imageElement.classList.add('finished-booking');
                    textElenment.appendChild(document.createTextNode('Hoàn thành'));
                    return element;
                    //return '<span style="color:black">Hoàn thành</span>'
                case 3:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    imageElement.classList.add('car-found')
                    textElenment.appendChild(document.createTextNode('Đã thấy xe'));
                    return element;
                    //return '<span style="color:orange">Đã thấy xe</span>'
                case -1:
                    imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                    textElenment.appendChild(document.createTextNode('Chưa có xe nhận'));
                    return element;
                    //return '<span style="color:red">Chưa có xe nhận đón</span>'
                default:
                    return '<span>---</span>'
            }
        } else if (params.data.type == 0) {
            switch (status) {
                case 0:
                    imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                    textElenment.appendChild(document.createTextNode('Đang tìm xe'));
                    return element;
                    // return `<span style="color:${
                    //     bookingStatusColor[bookingStatusName.dispatching]
                    // }">Đang tìm xe</span>`
                case 1:
                    if (statusBooking == 3) {
                        imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                        imageElement.classList.add('car-found')
                        textElenment.appendChild(document.createTextNode('Đã thấy xe'));
                        return element;
                        //return '<span style="color:orange">Đã thấy xe<span>'
                    } else {
                        switch (statusBooking) {
                            case 0:
                                imageElement.src = 'assets/icons/Dang_don_khach.svg';
                                textElenment.appendChild(document.createTextNode('Đang đi đón khách'));
                                return element;
                                //return '<span style="color:#f5a623">Đang đi đón khách<span>'
                            case 1:
                                imageElement.src = 'assets/icons/Dang_cho_khach.svg';
                                textElenment.appendChild(document.createTextNode('Đang chở khách'));
                                return element;
                                //return '<span style="color:#00759A">Đang chở khách</span>'
                            case 2:
                                imageElement.src = 'assets/icons/Dang_tim_xe.svg';
                                imageElement.classList.add('finished-booking');
                                textElenment.appendChild(document.createTextNode('Hoàn thành'));
                                return element;
                                // return `<span style="color:${
                                //     bookingStatusColor[
                                //         bookingStatusName.finished
                                //     ]
                                // }">Hoàn thành</span>`
                            case -3:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));
                                return element;
                                //return '<span style="color:">Tổng đài hủy chuyến</span>'
                            case -4:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));
                                return element;
                                //return '<span style="color:red">Lái xe hủy chuyến</span>'
                            case -5:
                                imageElement.src = 'assets/icons/huy_chuyen.svg';
                                textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));
                                return element;
                                //return '<span style="color:">Khách hàng hủy chuyến</span>'
                            default:
                                return '<span>---</span>'
                        }
                    }
                case -1:
                    imageElement.src = 'assets/icons/Chua_co_xe_nhan.svg';
                    textElenment.appendChild(document.createTextNode('Chưa có xe nhận'));
                    return element;
                    //return '<span style="color:red">Chưa có xe nhận đón</span>'
                case -2:
                    imageElement.src = 'assets/icons/Loi.svg';
                    textElenment.appendChild(document.createTextNode('Lỗi'));
                    return element;
                    //return '<span style="color:red">Lỗi</span>'
                case -3:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi tổng đài'));
                    return element;
                    //return '<span style="color:">Tổng đài hủy chuyến</span>'
                case -4:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi lái xe'));
                    return element;
                    //return '<span style="color:red">Lái xe hủy chuyến</span>'
                case -5:
                    imageElement.src = 'assets/icons/huy_chuyen.svg';
                    textElenment.appendChild(document.createTextNode('Hủy bởi khách hàng'));
                    return element;
                    //return '<span style="color:red">Khách hàng hủy chuyến</span>'
                default:
                    return '<span>---</span>'
            }
            //return element
        } else if (params.data.type == 1) {
            let s = ''
            if (
                statusBooking == 3 ||
                statusBooking == 4 ||
                statusBooking == 5
            ) {
                s = '<span style = "color: green;">---</span>'
                return s
            } else {
                switch (parseInt(status)) {
                    case 1:
                        if (params.data.driver_id != '') {
                            s = '<span style="color:blue;">Hoàn thành</span>'
                        } else {
                            s = '<span style="color:green;">---</span>'
                        }
                        break
                    case 0:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -1:
                        s = '<span style="color:red;">Không thấy xe</span>'
                        break
                    case -2:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -3:
                        s = '<span style="color:red;">Quản trị hủy</span>'
                        break
                    case -4:
                        s =
                            '<span class = "DriverCancelBlink" style="color:red;">Lái xe hủy chuyến</span>'
                        break
                    case 5:
                        s =
                            '<span style="color:red;">Đã X.Lý, Không Đ.Xe</span>'
                        break
                    case -5:
                        s = '<span style="color:red;">Khách hủy</span>'
                        break
                    case 6:
                        s = '<span style="color:red;">Khách hẹn</span>'
                        break
                    default:
                        s = '<span style="color:green;">---</span>'
                        break
                }

                if (params.data.cmd_sb == 3 || params.data.cmd_cd == 'k') {
                    return '<span style="color:red;">Không thấy xe</span>'
                }
                return s
            }
        } else if (params.data.type == 8) {
            return `<span style="color:${
                bookingStatusColor[bookingStatusName.pending]
            }">Đặt hẹn</span>`
        }
    }
    return (
        <div className="grid-wrapper">
            <div
                id="myGridPreviewTrips"
                style={{
                    height: '100%',
                    width: '100%',
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    rowData={trips}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 30,
                        //editable: true,
                        resizable: true,
                        wrapText: true,
                        autoHeight: true,
                    }}
                    onGridReady={onGridReady}
                    enableCellTextSelection={true}
                    getRowNodeId={data => data.id}
                >
                    <AgGridColumn
                        headerName="ID"
                        field="id"
                        maxWidth={170}
                        minWidth={90}
                        //cellRenderer={renderBookingId}
                        cellStyle={{
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#181918'
                        }}
                    />
                    <AgGridColumn
                        headerName="TÀI XẾ / BIỂN SỐ"
                        field="driver_id"
                        minWidth={150}
                        maxWidth={150}
                        cellRenderer={renderDriver}
                    />
                    <AgGridColumn
                        headerName="TRẠNG THÁI"
                        field="status"
                        cellRenderer={renderStatus}
                        minWidth={170}
                    />
                    <AgGridColumn
                        headerName="ĐIỂM ĐÓN"
                        field="start_address"
                        minWidth={250}
                        maxWidth={250}
                        cellRenderer={renderStartAddress}
                        editable={true}
                    />
                </AgGridReact>
            </div>
        </div>
    )
}

export default GridPreviewTrips