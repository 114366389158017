// case -5:
//       s = '<span style="color:purple;"></span>';
//       break;
//     case -3:
//       s = '<span style="color:purple;"></span>';
//       break;
//     case -4:
//       s = '<span style="color:purple;"></span>';
//       break;
//     case -2:
//       s = '<span style="color:red;"></span>';
//       break;
//     case -1:
//       s = '<span style="color:#ff941c;"></span>';
//       break;
//     case 0:
//       s = '<span style="color:green;"></span>';
//       break;
//     case 1:
//       s = '<span style="color:blue;"></span>';
//       break;
//     // case 2:
//     //     s = '<span style="color:grey;">Đã xử lý nhưng chưa điều được xe</span>';
//     //     break;
//     case 5:
//       s = '<span style="color:grey;"></span>';
//       break;
//     case 6:
//       s = '<span style="color:black;"></span>';
//       break;
//     case 99:
//       s = '<span style="color:brown;"></span>';
//       break;

export const coordinatorStatus = {
    customerCancel: -5, // Khách hàng hủy
    driverCancel: -4, // Tài xế hủy
    switchboardCancel: -3, // Quản trị / Tổng đài hủy
    error: -2, // Lỗi
    notFound: -1, // Không thấy xe
    searching: 0, // Đang tìm xe
    hadFound: 1, // Đã thấy xe
    handledButNotFound: 5, // Đã xử lý nhưng không điều được xe
    temporaryLocked: 6, // Chuyến tạm khóa
    notHandled: 99, // Chưa xử lý
}
