import { AddCircleOutlined, RemoveCircleOutlined } from '@mui/icons-material'
import {
    createTheme,
    IconButton,
    MenuItem,
    Select as SelectMui,
    TextField,
    ThemeProvider,
} from '@mui/material'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { ARROW_DOWN, ARROW_UP, ENTER, ESCAPE, TAB } from 'static/keyboard-code'

import useTrips from 'app/_shared/trips-context'

import { localStorageKeys, getLocalStorage } from 'services/localStorage'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    background: '#ffffff',
                    paddingLeft: '8px',
                    padding: '0 !important',
                    borderRadius: '0.125rem',
                    outline: 'none'
                },
                notchedOutline: {
                    border: 'none',
                    outline: 'none'
                }
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: '#868686',
                    right: 0,
                },
                select: {
                    padding: '0px',
                    paddingLeft: '8px'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    //height: '34px !important',
                    //padding: '0.125rem !important',
                    color: '#181918',
                    fontSize: '14px',
                    fontWeight: '400',
                    display: 'flex',
                    //aligninItems: 'center',
                    '&disabled': {
                        fillColor: 'rgba(255, 255, 255, 0.8) !important',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: 'fit-content',
                    height: '34px !important',
                    border: 'none !important',
                    marginLeft: '0px !important',
                    borderRadius: '0px !important'
                },
                notchedOutline: {
                    border: 'none',
                    outline: 'none'
                }
            }
        }
    },
})

const Services = ({
    className,
    name,
    title,
    handleServiceChange,
    disabled,
    ...props
}) => {
    //Get list services from context
    //const [state, actions] = useTrips()
    const listServices = getLocalStorage(localStorageKeys.services)

    const [service, setService] = useState({
        service: listServices?.length > 0 ? listServices[0].service_id : null,
        quantity: 1,
    })

    const [_isMultiService, _setIsMultiService] = useState(
        () => service.length > 1
    )

    const _handleChangeService = (newValue, index, key) => {
        // console.log(newValue)
        // console.log(index)
        // console.log(key)

        setService({
            ...service,
            [key]: newValue,
        })

        handleServiceChange({
            ...service,
            [key]: newValue,
        })
    }

    const _handleNumberOfService = index => {}

    return (
        <div className={classNames('flex flex-col gap-1', className)}>
            <div className="flex justify-between">
                {title && (
                    <label
                        htmlFor={name}
                        style={{
                            width: props.labelWidth ? props.labelWidth : 'auto',
                            color: '#000000',
                            fontSize: '14px',
                            fontWeight: '500',
                            lineHeight: '16px',
                            height: '16px',
                            marginTop: '1rem'
                        }}
                    >
                        {title}
                    </label>
                )}
            </div>
            <ThemeProvider theme={theme}>
                <Service
                    options={listServices}
                    data={service}
                    key={0}
                    index={0}
                    handleChange={_handleChangeService}
                    handleNumberOfService={_handleNumberOfService}
                    isMultiService={_isMultiService}
                    disabled={disabled}
                />
            </ThemeProvider>
        </div>
    )
}

export default Services

const serviceModel = { serviceId: 'service', quantity: 'quantity' }

const Service = ({
    options,
    data,
    handleChange,
    index,
    isMultiService,
    handleNumberOfService,
    disabled,
}) => {
    const [_open, _setOpen] = useState(false)

    const setFocusEl = () => {}

    const _handleClick = e => {
        if (!disabled) {
            if (_open) {
                _setOpen(false)
                setFocusEl()
            } else {
                _setOpen(true)
            }
        }
    }

    const _handleKeyDownService = e => {
        switch (e.key) {
            case TAB:
                _setOpen(!_open)
                // setFocusEl()
                break
            case ENTER:
                _setOpen(false)
                setFocusEl()
                break
            case ESCAPE:
                _setOpen(false)
                setFocusEl()
                break

            default:
                break
        }
    }

    const _handleChangeQuantity = e => {
        let { value } = e.target

        let reg = new RegExp(/^[1-3]{1}\b/)

        if (value.match(reg)) {
            _submitQuantityChange(Number(value))
        } else {
            if (value === '') {
                _submitQuantityChange(value)
            }
        }

        // let newValue = Number(e.target.value?.toString().slice(-1))
        // newValue = newValue ? newValue : 1

        // if (!isNaN(newValue) && newValue !== data[serviceModel.quantity]) {
        //     _submitQuantityChange(newValue)
        // }
    }

    const _handleKeyDownQuantity = e => {
        let currentValue = data[serviceModel.quantity]
        switch (e.key) {
            case ARROW_UP:
                e.preventDefault()
                _increaseQuantity()
                break
            case ARROW_DOWN:
                e.preventDefault()
                _decreaseQuantity()
                break
        }
    }

    const _handleScrollQuantity = e => {
        if (e.deltaY > 0) _decreaseQuantity()
        else if (e.deltaY < 0) _increaseQuantity()
    }

    const _increaseQuantity = () => {
        const currentValue = data[serviceModel.quantity]
        if (currentValue < 5) {
            _submitQuantityChange(currentValue + 1)
        }
    }

    const _decreaseQuantity = () => {
        const currentValue = data[serviceModel.quantity]
        if (currentValue > 1) {
            _submitQuantityChange(currentValue - 1)
        }
    }

    const _submitQuantityChange = value => {
        handleChange(value, index, serviceModel.quantity)
    }

    const _onBlurQuantity = e => {
        if (e.target.value === '') handleChange(1, index, serviceModel.quantity)
    }

    return (
        <div className="flex w-full justify-between services">
            <SelectMui
                open={_open}
                value={data[serviceModel.serviceId]}
                defaultValue={18}
                disabled={disabled}
                onChange={e =>
                    handleChange(e.target.value, index, serviceModel.serviceId)
                }
                onFocus={() => {
                    //_setOpen(true)
                }}
                onBlur={() => {
                    //console.log('blur service...')
                    _setOpen(false)
                    setFocusEl()
                }}
                onClick={_handleClick}
                className="flex-grow"
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onKeyDown={_handleKeyDownService}
                        sx={{
                            fontSize: 14,
                            paddingLeft: 1.5,
                            paddingRight: 1,
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                        }}
                        value={option.service_id}
                    >
                        {option.vi_name}
                    </MenuItem>
                ))}
            </SelectMui>
            <div className='services-quantity'>
                <img src='assets/icons/sub-quantity.svg' onClick={() => _decreaseQuantity()}/>
                <input
                    className='quantity'
                    value={data[serviceModel.quantity]}
                    onChange={e => _handleChangeQuantity(e)}
                    onKeyDown={_handleKeyDownQuantity}
                    size="1" 
                    maxLength="1"
                    //onWheel={_handleScrollQuantity}
                    onBlur={_onBlurQuantity}
                />
                
                <img src='assets/icons/add-quantity.svg' onClick={() => _increaseQuantity()}/>
                <span 
                    style={{
                        color:isMultiService ? 'red' : '#219737', 
                        fontSize:'14px', 
                        fontWeight:'500', 
                        width:'80px',
                        cursor: 'pointer',
                        paddingLeft: '10px'
                    }}
                    onClick={() => handleNumberOfService(index)}
                >
                    {isMultiService ? 'Xóa' : 'Thêm'}
                </span>
            </div>

            {/* <TextField
                sx={{ marginLeft: 1 }}
                className="w-6"
                value={data[serviceModel.quantity]}
                onChange={e => _handleChangeQuantity(e)}
                onKeyDown={_handleKeyDownQuantity}
                onWheel={_handleScrollQuantity}
                onBlur={_onBlurQuantity}
                disabled={disabled}
            />
            <IconButton
                sx={{
                    padding: 0,
                    paddingLeft: 1 / 2,
                    color: isMultiService ? 'red' : '#edab25',
                    display: 'none',
                }}
                onClick={() => handleNumberOfService(index)}
            >
                {isMultiService ? (
                    <RemoveCircleOutlined
                        sx={{ width: '16px', height: '16px' }}
                    />
                ) : (
                    <AddCircleOutlined sx={{ width: '16px', height: '16px' }} />
                )}
            </IconButton> */}
        </div>
    )
}
