import GrantRole from "./GrantRole";
import classNames from "classnames/bind";
import styles from "../scss/_GrantRole.scss";

const cx = classNames.bind(styles)

const GrantRoleContainer = () => {
    return (
        <div className={cx('GrantRoleContainer')}>
            <GrantRole />
        </div>
    )
}

export default GrantRoleContainer
