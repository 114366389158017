import {
    createTheme,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import { getPhoneNumberHistoriesApi } from 'api/service'
import { useTrans } from 'helpers/useTranslation'
import React, { useEffect, useRef, useState } from 'react'
import { ENTER } from 'static/keyboard-code'
import { REG_PHONE_NUMBER } from '../../../static/regex'
import { useNewBookingContext } from '../context'
import actions from '../context/actions'
import { keyboardFlow } from '../context/initialState'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'
import moment from 'moment'

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiFormControlLabel: {
            styleOverrides: { root: { marginLeft: 0, marginRight: 8 } },
        },
        MuiRadio: { styleOverrides: { root: { padding: 2 } } },
        MuiTypography: { styleOverrides: { root: { fontSize: 12 } } },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    background: '#ffffff',
                    borderRadius: '4px',
                    border: '1px solid #e6e9ec',
                    height: '40px',
                    position: 'relative',
                    paddingLeft: '6px'
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    color: '#181918',
                    fontSize: '16px',
                    fontWeight: '400',
                    //width: '50%',
                    paddingLeft: '8px'
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: { 
                    color: '#edab25', 
                    //color: '#219737', 
                    //paddingRight: 4,
                },
            },
        },
    },
})

const customerType = {
    individual: { name: 'Indiv.', value: 'individual' },
    corporation: { name: 'Corp.', value: 'corporation' },
}

const CustomerInfo = ({ marketingId, customerNameInit, setHistories }) => {
    const t = useTrans()

    const [_customerType, _setCustomerType] = useState(
        customerType.individual.value
    )
    
    const [customerName, setCustomerName] = useState(customerNameInit)

    const [
        {
            handlingTrip: {
                info: { phoneNumber },
                metadata: { focusingEl },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    const _inputRef = useRef()

    useEffect(() => {
        if (focusingEl === keyboardFlow.phoneNumber) {
            _inputRef.current?.focus()
        } else _inputRef.current?.blur()
    }, [focusingEl])

    const _handleChangeCustomerType = e => {
        _setCustomerType(e.target.value)
    }

    const _handleKeyDownPhoneNumberField = e => {
        if (e.key === ENTER) {
            dispatch(actions.tripInfo.setFocusingEl(keyboardFlow.createTripBtn))
            e.stopPropagation()
        }
    }

    const _handleChangePhoneNumberField = e => {
        const phoneNumber = e.target.value || ''
        if (
            phoneNumber.length <= 10 &&
            (REG_PHONE_NUMBER.test(phoneNumber) || phoneNumber === '')
        ) {
            dispatch(actions.tripInfo.setPhoneNumber(e.target.value))

            if (phoneNumber.length == 10) {
                getPhoneNumberHistoriesApi(phoneNumber)
                    .then(res => {
                        let { customer_name: customerName, histories }  = res
                        if (histories.length > 0) {
                            let temp1 = []
                            let temp2 = []
                            histories.forEach(item => {
                                if (!temp1.includes(item.start_address)) {
                                    temp1.push(item.start_address)
                                    temp2.push(item)
                                }
                            })
                            let arrTemp = temp2.map(item => {
                                item.coordinates = item.start_point
                                item.created_at = moment(
                                    item.created_at
                                ).format('HH:mm:ss DD-MM-YYYY')
                                item.description = item.start_address
                                return item
                            })

                            setHistories(arrTemp)
                            setCustomerName(customerName)
                        }else setHistories([])
                    })
                    .catch(err => {
                        setHistories([])
                        console.log(err)
                    })
            }
        }
    }

    let _highlightCustomerRole = !!marketingId
    let _customerRole = marketingId ? 'MKT' : ''

    return (
        <ThemeProvider theme={theme}>
            <div className="flex flex-row customer-info">
                {/* <FormControl>
                    <RadioGroup
                        row
                        defaultValue={customerType.corporation.value}
                        value={_customerType}
                        onChange={_handleChangeCustomerType}
                    >
                        {Object.keys(customerType).map(customerKey => (
                            <FormControlLabel
                                key={customerKey}
                                value={customerType[customerKey].value}
                                control={<Radio size="small" />}
                                label={customerType[customerKey].name}
                            />
                        ))}
                    </RadioGroup>
                </FormControl> */}
               
                <TextField
                    //sx={{ mt: 1 / 4 }}
                    name="phoneNumber"
                    value={phoneNumber}
                    inputRef={_inputRef}
                    placeholder={t(newBookingLocaleKeys.phoneNumberPlaceholder)}
                    onChange={_handleChangePhoneNumberField}
                    onKeyDown={_handleKeyDownPhoneNumberField}
                    onFocus={() =>
                        dispatch(
                            actions.tripInfo.setFocusingEl(
                                keyboardFlow.phoneNumber
                            )
                        )
                    }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <img src='assets/icons/phone.svg' />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        color: _highlightCustomerRole
                                            ? '#219737'
                                            : 'grey',
                                        position: 'absolute',
                                        right: '4px',
                                    }}
                                >
                                    {_customerRole}
                                </Typography>
                                
                            </InputAdornment>
                        ),
                    }}
                >
                    
                </TextField>
            
                <TextField
                    name="fullName"
                    placeholder={t(newBookingLocaleKeys.fullNamePlaceholder)}
                    value={customerName || ''}
                />
                {/* <TextField
                        name="email"
                        placeholder={t(newBookingLocaleKeys.emailPlaceholder)}
                    /> */}
            </div>
        </ThemeProvider>
    )
}

export default CustomerInfo
