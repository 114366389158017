import previewTripsKeys from 'app/preview-trips/i18n/previewTripsKeys'
import DatePicker from 'app/_shared/components/DatePicker'
import Input from 'app/_shared/components/Input'
import Select from 'app/_shared/components/Select'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPrimitiveDot } from 'react-icons/go'
import { tripStatusColor, tripStatus } from 'utils/trips'
import bookingsKeys from '../i18n/bookingsKeys'
import MultipleSelectCheckmarks from './MultipleSelecCheckmarks'
import { bookingStatusColor, bookingStatusName } from '../../../utils/Booking'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import ViewContext from 'app/_shared/view-context'

import useTrips from 'app/_shared/trips-context'

import moment from 'moment'

import { getListService, getListUsers } from 'api/service'

import SearchIcon from '@mui/icons-material/Search'

import { BOOKING_FINISHED_PAGE_SIZE } from '../constant'

const optionsBookingStatus = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
        color: 'white',
    },
    {
        id: 1,
        name: 'Lái xe hủy chuyến',
        checked: true,
        color: bookingStatusColor[bookingStatusName.DriverCancel],
    },
    {
        id: 2,
        name: 'Tổng đài hủy chuyến',
        checked: true,
        color: bookingStatusColor[bookingStatusName.AddCancel],
    },
    {
        id: 3,
        name: 'Khách hàng hủy chuyến',
        checked: true,
        color: bookingStatusColor[bookingStatusName.CustomerCancel],
    },
    {
        id: 4,
        name: 'Chuyến thành công',
        checked: true,
        color: bookingStatusColor[bookingStatusName.finished],
    },

    // 'Đặt hẹn',
    // 'Đang tìm xe',
    // 'Chưa có xe nhận đón',
    // 'Nhận chuyến đặt hẹn',
    // 'Đang đi đón khách',
    // 'Đến điểm đang chờ khách',
    // 'Đang chở khách',
    // 'Lái xe hủy chuyến'
]

const optionsBookingType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Đi ngay',
        checked: true,
    },
    {
        id: 2,
        name: 'Đặt hẹn',
        checked: true,
    },
]

const optionsCarType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Taxi bất kỳ',
        checked: true,
    },
    {
        id: 2,
        name: 'Taxi 4 chỗ lớn',
        checked: true,
    },
    {
        id: 3,
        name: 'Taxi 7 chỗ',
        checked: true,
    },
    {
        id: 4,
        name: 'Emddi Fixed',
        checked: true,
    },
]

const optionsBookingFrom = [
    {
        id: 0,
        name: 'Tất cả',
        checked: <i class="fa fa-bullseye" aria-hidden="true"></i>,
    },
    {
        id: 'CUSTOMER_APP',
        name: 'App KH',
        checked: true,
    },
    {
        id: 'TEL_OPERATOR',
        name: 'Tổng đài',
        checked: true,
    },
    {
        id: 'GUEST',
        name: 'Khách vẫy',
        checked: false,
    },
]

const optionsOperator = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Admin Emddi',
        checked: true,
    },
    {
        id: 2,
        name: 'dieuxe1',
        checked: true,
    },
    {
        id: 3,
        name: 'Cong2k',
        checked: true,
    },
]

const optionsCustomerType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Khách VIP',
        checked: true,
    },
    {
        id: 2,
        name: 'Non VIP',
        checked: true,
    },
]

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

const FilterBookingFinished = () => {
    const t = useTrans()
    const { i18n } = useTranslation()

    const [state, actions] = useTrips()

    const [{ usingSwitchboard }] = useViewContext()

    const [displayFilter, setDisplayFilter] = useState(
        usingSwitchboard ? false : true
    )

    const [services, setServices] = useState([
        {
            id: 0,
            name: '',
            checked: true,
        },
    ])

    const [users, setUsers] = useState([
        {
            id: '',
            username: '',
        },
    ])

    const handleClickCollapse = () => {
        setDisplayFilter(!displayFilter)
    }

    const [formDate, setFormDate] = useState(moment(new Date()).unix() * 1000)

    const [toDate, setToDate] = useState(moment(new Date()).unix() * 1000)

    // const [services, setServices] = useState([
    //     {
    //         id: 0,
    //         name: '',
    //         checked: true
    //     }
    // ])

    // const [users, setUsers] = useState([
    //     {
    //         id: '',
    //         username: ''
    //     }
    // ])

    let timeTeporary = new Date().toISOString().substring(0, 10) + ' 06:00:00'

    //let timeTeporary = moment(new Date()).subtract(1, 'day').toISOString().substring(0, 10) + ' 06:00:00'

    let startDateTime = moment(timeTeporary).utc().format('YYYY-MM-DD HH:mm:ss')

    let endDateTime = moment(timeTeporary)
        .add(1, 'days')
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

    const [searching, setSearching] = useState('')
    const [typeSearching, setTypeSearching] = useState(5)

    const [dataFilter, setDataFiler] = useState({
        fromDate: null,
        toDate: null,
        startDate: startDateTime,
        endDate: endDateTime,
        status: '',
        users: '', //operators
        types: '', //type booking(booking from),
        services: '',
        searching: null,
        typeSearching: null,
        start: 0,
        limit: BOOKING_FINISHED_PAGE_SIZE,
    })

    const _onDateChange = (start, end) => {
        setDataFiler({
            ...dataFilter,
            fromDate: moment(start).unix() * 1000,
            toDate: moment(end).unix() * 1000,
            startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
        })
    }

    const _onStatusChange = objectStatus => {
        if (checkAllNotSelected(objectStatus)) {
            actions.setTripsFinished([])
            actions.setFilterFormFinished({})
        } else {
            if (checkAllSelected(objectStatus)) {
                setDataFiler({ ...dataFilter, status: '' })
            } else {
                let tempStatus = ''
                if (objectStatus[1] == true) tempStatus = 'DRIVER_CANCELLED'
                if (objectStatus[2] == true) tempStatus += ',' + 'ADD_CANCELLED'
                if (objectStatus[3] == true)
                    tempStatus += ',' + 'CUSTOMER_CANCELLED'
                if (objectStatus[4] == true) tempStatus += ',' + 'FINISHED'

                if (tempStatus.indexOf(',') == 0)
                    tempStatus = tempStatus.substring(1)

                setDataFiler({ ...dataFilter, status: tempStatus })
            }
        }
    }

    const _onServiceChange = objectServices => {
        if (checkAllNotSelected(objectServices)) {
            actions.setTripsFinished([])
            actions.setFilterFormFinished({})
        } else {
            if (checkAllSelected(objectServices)) {
                setDataFiler({ ...dataFilter, services: '' })
            } else {
                let servicesFilter = Object.keys(objectServices).reduce(
                    (listServices, k) =>
                        objectServices[k]
                            ? k + ',' + listServices
                            : listServices,
                    ''
                )
                let services = servicesFilter.substring(
                    0,
                    servicesFilter.length - 1
                )

                setDataFiler({ ...dataFilter, services: services })
            }
        }
    }

    const _onOperatorChange = objectOperator => {
        if (checkAllNotSelected(objectOperator)) {
            actions.setTripsFinished([])
            actions.setFilterFormFinished({})
        } else {
            if (checkAllSelected(objectOperator)) {
                setDataFiler({ ...dataFilter, users: '' })
            } else {
                let usersFilter = Object.keys(objectOperator).reduce(
                    (listUsers, k) =>
                        objectOperator[k] ? k + ',' + listUsers : listUsers,
                    ''
                )
                usersFilter = usersFilter.substring(0, usersFilter.length - 1)

                setDataFiler({ ...dataFilter, users: usersFilter })
            }
        }
    }

    const _onCustomerTypeChange = () => {}

    const _onBookingFromChange = objectBookingFrom => {
        if (checkAllNotSelected(objectBookingFrom)) {
            actions.setTripsFinished([])
            actions.setFilterFormFinished({})
        } else {
            if (checkAllSelected(objectBookingFrom)) {
                setDataFiler({ ...dataFilter, types: '' })
            } else {
                let bookingFrom = Object.keys(objectBookingFrom).reduce(
                    (listTypesBooking, k) =>
                        objectBookingFrom[k]
                            ? k + ',' + listTypesBooking
                            : listTypesBooking,
                    ''
                )

                bookingFrom = bookingFrom.substring(0, bookingFrom.length - 1)

                setDataFiler({ ...dataFilter, types: bookingFrom })
            }
        }
    }

    const checkAllSelected = object => {
        for (let key in object) {
            if (!object[key]) return false
        }
        return true
    }
    const checkAllNotSelected = object => {
        for (let key in object) {
            if (object[key]) return false
        }
        return true
    }

    useEffect(() => {
        actions.getTripsFinished({
            ...dataFilter,
            start: 0,
            limit: BOOKING_FINISHED_PAGE_SIZE,
        })
    }, [dataFilter])

    useEffect(() => {
        setUsers([
            {
                id: 0,
                name: 'Tất cả',
                checked: true,
            },
            ...state.users,
        ])
    }, [state.users])

    useEffect(() => {
        setServices([
            {
                id: 0,
                name: 'Tất cả',
                checked: true,
            },
            ...state.services,
        ])
    }, [state.services])

    const handleClickSearchIcon = () => {
        setDataFiler({
            ...dataFilter,
            searching: searching,
            typeSearching: typeSearching,
        })
    }

    const _onChangeInput = e => {
        let value = e.target.value.trim()

        let typeSearching = 5
        if (
            (value.length == 16 || value.length == 8 || value.length == 6) &&
            value.replace(/[0-9]/g, '') === ''
        ) {
            typeSearching = 1
        } else if (value.length == 4) {
            typeSearching = 2
        } else if (value.length == 10 && value.replace(/[0-9]/g, '') === '') {
            typeSearching = 3
            if (value.indexOf('0') == 0 && value.length == 10)
                value = '+84' + value.substring(1)
        } else if (
            value.replace(/[0-9]/g, '') !== '' &&
            value.length >= 2 &&
            value.length <= 12
        ) {
            let flagSearchByUser = false
            let userId = null
            for (let i = 0; i < state.users.length; i++) {
                if (state.users[i].name === value) {
                    userId = state.users[i].id
                    flagSearchByUser = true
                    break
                }
            }
            if (flagSearchByUser == true) {
                typeSearching = 4
                value = userId
            }
        }

        setSearching(value)
        setTypeSearching(typeSearching)
    }

    const _onKeyDownInput = e => {
        if (e.key.toLowerCase() === 'enter') {
            setDataFiler({
                ...dataFilter,
                searching: searching,
                typeSearching: typeSearching,
            })
        }
    }

    const _clearSearching = () => {
        setDataFiler({
            ...dataFilter,
            searching: null,
            typeSearching: null,
        })
    }

    const _forwardRef = useRef(null)

    return (
        <div className={displayFilter ? 'Filter' : 'Filter Hide'}>
            {/* {displayFilter ? (
                <ExpandLessIcon
                    className="IconCollapseUp"
                    onClick={handleClickCollapse}
                />
            ) : (
                <ExpandMoreIcon
                    className="IconCollapseDown"
                    onClick={handleClickCollapse}
                />
            )} */}
            <div className="Row Finished First">
                <Input
                    className="search-input"
                    placeholder={t(bookingsKeys.filter.searchPlaceholder)}
                    onChange={_onChangeInput}
                    onKeyDown={_onKeyDownInput}
                    forwardRef={_forwardRef}
                    clearSearching={_clearSearching}
                />
                <MultipleSelectCheckmarks
                    //width={135}
                    //title="Loại chuyến"
                    label="Loại chuyến"
                    options={optionsBookingType}
                />
                <DatePicker 
                    //className="w-64" 
                    onChange={_onDateChange} 
                />
                <MultipleSelectCheckmarks
                    //width={230}
                    //title="Trạng thái"
                    label="Trạng thái"
                    options={optionsBookingStatus}
                    onChange={_onStatusChange}
                />
                <MultipleSelectCheckmarks
                    //width={160}
                    //title="Loại xe"
                    label="Loại xe"
                    options={services}
                    onChange={_onServiceChange}
                />
                <MultipleSelectCheckmarks
                    //width={150}
                    //title="Người điều"
                    label="Người điều"
                    options={users}
                    onChange={_onOperatorChange}
                />
                <MultipleSelectCheckmarks
                    //width={130}
                    //title="Loại KH"
                    label="Loại KH"
                    options={optionsCustomerType}
                    onChange={_onCustomerTypeChange}
                />
                <MultipleSelectCheckmarks
                    //width={135}
                    //title="Nguồn cuốc"
                    label="Nguồn cuốc"
                    options={optionsBookingFrom}
                    onChange={_onBookingFromChange}
                />
                
                {/* <SearchIcon
                    className="search-icon"
                    onClick={handleClickSearchIcon}
                /> */}
            </div>
            <div className='Row Second'></div>
        </div>
    )
}

export default FilterBookingFinished
