import DefaultPageContainer from 'app/default-page/components/DefaultPageContainer'
import NewBookingContainer from 'app/new-booking/components/NewBookingContainer'
import UserContainerContext from 'app/_shared/user-container-context'
import classNames from 'classnames'
import { lazy, Suspense, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import '../scss/UserContainer.scss'
import Loading from './Loading'

import BookingContainer from '../../bookings/components/BookingsContainer'
import TripDetailContainer from 'app/trip-detail/components/TripDetailContainer'
import SettingContainer from 'app/setting/components/SettingContainer'
//import AbbreviateContainer from "../../abbreviate/components/AbbreviateContainer";
import { getUserSettingsApi } from 'api/settings/getUserSettings'
import { useViewDispatch } from 'app/_shared/view-context/ViewContext'
import ViewActions from 'app/_shared/view-context/actions'
import GrantRoleContainer from "../../grant-role/components/GrantRoleContainer";

const VehicleMapContainer = lazy(() =>
    import('app/vehicle-map/components/VehicleMapContainer')
)

const QuickSearchContainer = lazy(() =>
    import('../../quick-search/components/QuickSearchContainer')
)

const PageContent = props => {
    const viewDispatch = useViewDispatch()

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const {
                    data: { data },
                } = await getUserSettingsApi()

                if (!data?.config) return

                //console.log({ config: data?.config })

                viewDispatch(ViewActions.setUserSettings(data?.config))
            } catch (e) {
                console.error(e)
            }
        }

        fetchSettings()
    }, [])

    const { pageProps } = props

    const _renderPage = Component => routeProps =>
        <Component {...{ ...pageProps, ...routeProps }} />

    const sideBarOpen = true

    return (
        <div
            className={classNames(
                sideBarOpen
                    ? 'MarginWithSideBarOpen'
                    : 'MarginWithNoSideBarOpen',
                'transition-all w-full'
            )}
        >
            <div className="UserContainerBody">
                <Suspense fallback={<Loading />}>
                    <Switch>
                        <Route
                            exact
                            path="/grant-role"
                            render={_renderPage(GrantRoleContainer)}
                        />
                        <Route
                            exact
                            path="/"
                            //component={DefaultPageContainer}
                        >
                            <Redirect to="/map" />
                        </Route>
                        <Route
                            exact
                            path="/map"
                            render={_renderPage(VehicleMapContainer)}
                        />
                        <Route
                            exact
                            path="/quicksearch"
                            render={_renderPage(QuickSearchContainer)}
                        />
                        {/* <Route
                            exact
                            path="/booking"
                            render={_renderPage(BookingsContainer)}
                        />
                        <Redirect to="/" /> */}
                        <Route
                            exact
                            path="/setting"
                            render={_renderPage(SettingContainer)}
                        />
                        <Route
                            exact
                            path="/logout"
                            //component={DefaultPageContainer}
                        >
                            <Redirect to="/login"/>
                        </Route>
                    </Switch>
                </Suspense>

                <NewBookingContainer />
                <BookingContainer />
                <TripDetailContainer />
            </div>
        </div>
    )
}

export default PageContent
