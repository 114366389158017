class Node {
    constructor(data) {
        this.data = data
        this.next = null
    }
}

class CustomQueue {
    constructor() {
        this.head = null
        this.size = 0
    }

    enQueue(data) {
        let node = new Node(data)
        if (!this.head) {
            this.head = node
        } else {
            let temp = this.head
            while (temp.next) {
                temp = temp.next
            }
            temp.next = node
        }
    }

    headQueue(data) {
        let node = new Node(data)
        if (!this.head) {
            this.head = node
        } else {
            node.next = this.head
            this.head = node
        }
        this.size += 1
    }

    deleteNodeById(id) {
        if (this.head) {
            if (this.head.data.id == id) {
                this.head = this.head.next
                this.size -= 1
                return
            } else {
                let preNode = this.head
                let currNode = preNode.next
                while (currNode) {
                    if (currNode.data.id == id) {
                        preNode.next = currNode.next
                        break
                    }
                    preNode = currNode
                    currNode = currNode.next
                }
            }
        }
        return
    }

    print() {
        if (!this.head) console.log('The queue is empty...')
        else {
            let curr = this.head
            while (curr) {
                console.log(curr.data)
                curr = curr.next
            }
        }
    }
}

export default CustomQueue
