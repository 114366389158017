import { EmddiMap } from '@emddi/react-map'
import L from 'leaflet'
import polyUtil from 'polyline-encoded'
import { useEffect, useRef, useState } from 'react'
import { addressMarkerTypes, getAddressMarker, getMarkerIcon } from 'utils/maps'
import { useNewBookingContext } from '../context'
import actions from '../context/actions'
import UserContainerContext from 'app/_shared/user-container-context'
import { useViewContext } from 'app/_shared/view-context/ViewContext'
import { localStorageKeys, getLocalStorage } from 'services/localStorage'
import { getListDriversApi } from 'api/service'

const { useUserContainerContext, UserContainerActions } = UserContainerContext

class MarkerItem {
    constructor(
        driverId,
        fullName,
        isOnline,
        location,
        orientation,
        phoneNumber,
        driverStatus,
        vhCode
    ) {
        this.id = driverId
        this.fullName = fullName
        this.isOnline = isOnline
        this.location = location
        this.orientation = orientation
        this.phoneNumber = phoneNumber
        this.driverStatus = driverStatus
        this.vhCode = vhCode

        this.carMarker = L.marker([location.lat, location.lng], {
            icon: getMarkerIcon(driverStatus, 16),
            riseOnHover: true,
            rotationAngle: orientation,
            rotationOrigin: 'center center',
        })

        const label = `<b>${fullName}</b>`
        // if (vhCode != null && vhCode !== '') {
        //     label = `<b>${vhCode}</b>`
        // } else {
        //     label = `<b>${fullName}</b>`
        // }
        this.infoText = L.divIcon({
            className: 'MarkerInfoText',
            html: label,
        })
        this.textMarker = L.marker([location.lat, location.lng], {
            icon: this.infoText,
        })
    }

    setMap = map => {
        if (map) {
            this.carMarker.addTo(map)
            if (this.textMarker) this.textMarker.addTo(map)
        }
    }

    remove = () => {
        this.carMarker.remove()
        this.textMarker.remove()
    }

    updateState = (location, driverStatus, orientation, isOnline) => {
        if (isOnline == 0) {
            this.carMarker.remove()
            this.textMarker.remove()
            return
        }

        this.carMarker.setIcon(getMarkerIcon(driverStatus, 16))
        this.carMarker.setLatLng([location.lat, location.lng])
        this.carMarker.setRotationAngle(orientation)

        this.textMarker.setLatLng([location.lat, location.lng])
    }
}

class ManageCarMarkers {
    constructor() {
        this.listMarkers = []
    }
    addMarker = (marker, map) => {
        this.listMarkers.push(marker)
        if (map) marker.setMap(map)
    }
    removeMarker = id => {
        let index = this.listMarkers.findIndex(item => item.id === id)
        if (index == -1) return
        this.listMarkers[index].remove()
        this.listMarkers.splice(index, 1)
    }
    removeAllMarkers = () => {
        for (let i = 0; i < this.listMarkers.length; i++) {
            this.listMarkers[i].remove()
        }
    }
    getAllMarker = () => {
        return this.listMarkers
    }
    setMapAllMarkers = map => {
        this.listMarkers.forEach(element => {
            element.setMap(map)
        })
    }
    getMarker = id => {
        return this.listMarkers.find(item => item.id === id)
    }
}

var manageCarMarkers = new ManageCarMarkers()


const MapPicker = props => {
    const { className } = props

    const { center_lat: centerLat, center_lng: centerLng } = getLocalStorage(
        localStorageKeys.user
    )
    const center = [+centerLat, +centerLng]

    const [{ socket }] = useViewContext()

    const [map, setMap] = useState(null)

    let pickupMarker = useRef(
        L.marker([0, 0], {
            icon: getAddressMarker(addressMarkerTypes.start),
            rotationAngle: 0,
            rotationOrigin: 'center center',
        })
    )
    let destinationMarker = useRef(
        L.marker([0, 0], {
            icon: getAddressMarker(addressMarkerTypes.end),

            rotationAngle: 0,
            rotationOrigin: 'center center',
        })
    )
    let directionPolylines = useRef(null)
    let rangeCircle = useRef(
        L.circle([0, 0], {
            color: 'red',
            fillColor: '#f03',
            weight: 1,
            opacity: 0.5,
            fillOpacity: 0.1,
            radius: 2000,
        })
    )

    const [
        {
            rndConfig,
            handlingTrip: {
                info: {
                    address: { pickup, destination },
                    estimatedData,
                },
                metadata: { estimated },
            },
        },
        dispatch,
    ] = useNewBookingContext()
    
    useEffect(() => {
        map?.invalidateSize()
    }, [rndConfig])

    /**
     * Create control estimate
     */
    const estimateControl = L.Control.extend({
        options: {
            position: 'bottomLeft'
        }
    })

    const convertTotalPrice = totalPrice => {
        totalPrice = totalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.')
        totalPrice = totalPrice.substring(0, totalPrice.length - 3)
        return totalPrice
    }

    function updateEstimatedControl(estimatedData) {
        document.getElementById('distance_value').innerHTML = estimatedData.distance !== '-' ? estimatedData.distance.toFixed(1) : estimatedData.distance
        document.getElementById('price_value').innerHTML = estimatedData.price !== '-' ? convertTotalPrice(estimatedData.price) : estimatedData.price
        document.getElementById('duration_value').innerHTML = estimatedData.duration !== '-' ? Math.round(estimatedData.duration) : estimatedData.duration
    }

    L.Control.MyControl = L.Control.extend({
        onAdd: function(map) {
            var divContainer = L.DomUtil.create('div', 'estimate-control');

            let divDistance = document.createElement('div')
            divDistance.classList.add('estimate-content')
            divDistance.classList.add('distance')
            let pDistance = document.createElement('p')
            pDistance.setAttribute('id', 'distance_value')
            pDistance.appendChild(document.createTextNode('00.0'))
            let pDistanceKm = document.createElement('p')
            pDistanceKm.appendChild(document.createTextNode('km'))
            divDistance.append(pDistance, pDistanceKm)

            let divPrice = document.createElement('div')
            divPrice.classList.add('estimate-content')
            divPrice.classList.add('price')
            let pPrice = document.createElement('p')
            pPrice.setAttribute('id', 'price_value')
            pPrice.appendChild(document.createTextNode('000.0'))
            let pCurrency= document.createElement('p')
            pCurrency.appendChild(document.createTextNode('VNĐ'))
            divPrice.append(pPrice, pCurrency)

            let divDuration = document.createElement('div')
            divDuration.classList.add('estimate-content')
            let pDuration = document.createElement('p')
            pDuration.setAttribute('id', 'duration_value')
            pDuration.appendChild(document.createTextNode('00.0'))
            let pMinutes = document.createElement('p')
            pMinutes.appendChild(document.createTextNode('Phút'))
            divDuration.append(pDuration, pMinutes)

            divContainer.append(divDistance, divPrice, divDuration)

            return divContainer;
        },
      
        onRemove: function(map) {
        }
    })
      
    L.Control.myControl = function(opts) {
        return new L.Control.MyControl(opts);
    }

    const flagAddEstimateControlRef = useRef(false)

    useEffect(() => {
        if (map) {
            // Add estimate control
            if (flagAddEstimateControlRef.current == false) {
                L.Control.myControl({
                    position: 'bottomleft'
                }).addTo(map);

                flagAddEstimateControlRef.current = true
            }

            directionPolylines.current?.remove()

            if (pickup) {
                pickupMarker.current.setLatLng([
                    pickup.coords.lat,
                    pickup.coords.lng,
                ])
                pickupMarker.current.addTo(map)
                rangeCircle.current
                    .setLatLng([pickup.coords.lat, pickup.coords.lng])
                    .addTo(map)
            } else {
                pickupMarker.current.remove()
                rangeCircle.current.remove()
            }
            if (destination) {
                destinationMarker.current.setLatLng([
                    destination.coords.lat,
                    destination.coords.lng,
                ])
                destinationMarker.current.addTo(map)
            } else {
                destinationMarker.current.remove()
            }

            if (pickup && destination) {
                let bounds = L.latLngBounds(pickup.coords, destination.coords)
                map.flyToBounds(bounds, { animate: false, padding: [3, 3] })
            } else if (pickup) {
                map.flyTo(pickup.coords, undefined, { animate: false })
            } else if (destination) {
                map.flyTo(destination.coords, undefined, { animate: false })
            }
            map.setZoom(14, { animate: false })

            if (estimated) {
                let latlngs = []
                for (
                    let index = 0;
                    index < estimatedData.steps.length;
                    index++
                ) {
                    const step = estimatedData.steps[index]

                    const decoded = polyUtil.decode(step.polyline.points)
                    //console.log('decoded from google: ', decoded)
                    if (decoded) latlngs.push(decoded)
                }
                // map.stop()
                if (latlngs.length > 1) {
                    directionPolylines.current = L.polyline(latlngs, {
                        color: '#0098EF',
                        // color: '#66BBEB',
                    }).addTo(map)
                    map.flyToBounds(directionPolylines.current.getBounds(), {
                        animate: false,
                        padding: [3, 3],
                    })
                }
            }
            if (flagAddEstimateControlRef.current) updateEstimatedControl(estimatedData)
            // Add estimate control
        }
    }, [pickup, destination, estimated, estimatedData])
    //}, [estimated, estimatedData])

    const [{ drivers, driverUpdate }] = useUserContainerContext()

    useEffect(() => {
        manageCarMarkers.removeAllMarkers()
        drivers.forEach(item => {
            if (
                item.is_online &&
                item.location.lat != 0 &&
                item.location.lng != 0
            ) {
                if (!map) return;
                if (map.getBounds().contains(item.location)) {
                let markerItem = new MarkerItem(
                    item.driver_id,
                    item.full_name,
                    item.is_online,
                    item.location,
                    item.orientation,
                    item.phone_number,
                    item.status,
                    item.vh_code
                )
                manageCarMarkers.addMarker(markerItem, map)
            } else {
                    manageCarMarkers.removeMarker(item.driver_id);
                }
            }
        })
        //manageCarMarkers.setMapAllMarkers(map)
    }, [drivers])

    useEffect(() => {
        if (!driverUpdate) return;

            if (driverUpdate.is_online === 0) {
                manageCarMarkers.removeMarker(driverUpdate.driver_id);
            } else {
                let carMarker = manageCarMarkers.getMarker(driverUpdate.driver_id);
                if (carMarker) {
                    carMarker.updateState(
                        { lat: driverUpdate.x, lng: driverUpdate.y },
                        driverUpdate.status,
                        driverUpdate.orientation,
                        driverUpdate.is_online
                    );
                } else {
                    let item = drivers.find(
                        dr => dr.driver_id === driverUpdate.driver_id
                    );
                    if (!item) return;
                    let markerItem = new MarkerItem(
                        item.driver_id,
                        item.full_name,
                        true,
                        { lat: driverUpdate.x, lng: driverUpdate.y },
                        driverUpdate.orientation,
                        item.phone_number,
                        driverUpdate.status,
                        item.vh_code
                    );
                    manageCarMarkers.addMarker(markerItem, map);
                }
            }
    }, [driverUpdate]);


    useEffect(() => {
        if (!map) return;

        const handleMapChange = () => {
            manageCarMarkers.getAllMarker().forEach(marker => {
                if (
                    map.getBounds().contains(marker.location)
                ) {
                    marker.setMap(map)
                } else {
                    marker.remove()
                }
            })
        };

        map.on('moveend', handleMapChange)
        handleMapChange();

        return () => {
            map.off('moveend', handleMapChange)
        };
    }, [map]);


    useEffect(() => {
        if (map) {
            map.on('click', e => {
                pickupMarker.current
                    .setLatLng([e.latlng.lat, e.latlng.lng])
                    .addTo(map)
                rangeCircle.current
                    .setLatLng([e.latlng.lat, e.latlng.lng])
                    .addTo(map)
                map.flyTo([e.latlng.lat, e.latlng.lng], undefined, {
                    animate: false,
                })
                dispatch(actions.mapPicker.setPickupCoordinates(e.latlng))
            })
        }
    }, [map])

    const whenMapCreated = m => {
        if (!map) setMap(m)
    }

    return (
        <div className={className}>
            <div className="MapContainer">
                <EmddiMap
                    divId="map-picker"
                    center={center}
                    whenCreated={whenMapCreated}
                />
            </div>
        </div>
    )
}

export default MapPicker
