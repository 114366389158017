export const bookingStatusName = {
    pending: 1,
    dispatching: 2,
    waitingForProcess: 3,
    confirmReservation: 4,
    DriverOnTheWay: 5,
    ArrivedAndWaiting: 6,
    PassengerOnBoard: 7,
    DroppedOff: 8,
    DriverCancel: 9,
    finished: 10,
    FoundDriver: 11,
    timeout: 12,
    CustomerCancel: 13,
    AddCancel: 14,
    error: 15,
}

export const bookingStatusColor = {
    [bookingStatusName.pending]: '#50080A',
    [bookingStatusName.pending]: '#A0282D',
    [bookingStatusName.pending]: '#880015',

    //[bookingStatusName.dispatching]: '#4bd948',

    [bookingStatusName.dispatching]: '#00953D',

    // [bookingStatusName.waitingForProcess]: '#b04aff',
    [bookingStatusName.waitingForProcess]: '#FF00B8',

    [bookingStatusName.confirmReservation]: '#73CEDD',
    [bookingStatusName.DriverAccepted]: 'orange',
    [bookingStatusName.DriverOnTheWay]: '#f5a623',
    [bookingStatusName.ArrivedAndWaiting]: '#FD7E00',

    //[bookingStatusName.PassengerOnBoard]: '#ff00b8',
    [bookingStatusName.PassengerOnBoard]: '#00759A',

    //[bookingStatusName.DroppedOff]: '#EE3A30',

    [bookingStatusName.DroppedOff]: 'red',

    //[bookingStatusName.DrivereCancel]: '#185ABD',
    [bookingStatusName.DriverCancel]: 'red',
    //[bookingStatusName.finished]: 'rgba(0, 0, 255, .5)',
    //[bookingStatusName.finished]: '#6EB4F5',
    [bookingStatusName.finished]: '#80B3FF',
    [bookingStatusName.FoundDriver]: 'orange',
    [bookingStatusName.timeout]: 'red',
    [bookingStatusName.AddCancel]: '#A50F14',
    [bookingStatusName.CustomerCancel]: '#FD5329',
    [bookingStatusName.error]: 'red',
    [bookingStatusName.default]: 'black',
}
