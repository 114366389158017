const switchPopup = (state, payload) => {
    const { switchPopupState } = payload

    const newSwitchPopupState =
        switchPopupState === undefined
            ? !state.checkedSwitchPopup
            : switchPopupState
    return {
        ...state,
        checkedSwitchPopup: newSwitchPopupState,
    }
}

const SwitchPopupReducers = {
    switchPopup,
}

export default SwitchPopupReducers
