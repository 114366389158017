import sidebarActions from './toggleSideBar'
import tripsActions from './tripsActions'
import tripStatusFilter from './tripStatusFilter'
import driverActions from './driversActions'

const UserContainerActions = {
    ...sidebarActions,
    ...tripsActions,
    ...tripStatusFilter,
    ...driverActions,
}

export default UserContainerActions
