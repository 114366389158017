import ViewActionTypes from '../constants/ViewActionTypes'

const setFilterForm = filterForm => {
    console.log('filter form on action: ')
    console.log(filterForm)
    return {
        type: ViewActionTypes.SET_FILTER_FORM,
        payload: filterForm,
    }
}

const sendFinishedTrip = record => {
    return {
        type: ViewActionTypes.SEND_FINISHED_TRIP,
        payload: record,
    }
}

const updateTripInfo = record => {
    return {
        type: ViewActionTypes.UPDATE_TRIP_INFO,
        payload: record,
    }
}

const TripsActions = {
    setFilterForm,
    sendFinishedTrip,
    updateTripInfo,
}

export default TripsActions
