import ViewActionTypes from '../constants/ViewActionTypes'

const sendPhoneNumber = data => {
    return {
        type: ViewActionTypes.SEND_PHONE_NUMBER,
        payload: data,
    }
}

const PhoneNumberActions = {
    sendPhoneNumber,
}

export default PhoneNumberActions
