import { getLocalStorage, localStorageKeys } from 'services/localStorage'

export const defaultRndConfig = { x: 0, y: 0, width: 750, height: 590 }
const initRndConfig = getLocalStorage(localStorageKeys.newBookingRndCfg)
const allService = getLocalStorage(localStorageKeys.services) || []
const { user_id: userId } = getLocalStorage(localStorageKeys.user)

export const keyboardFlow = {
    none: -1,
    pickupAdd: 0,
    destinationAdd: 1,
    selectSv: 2,
    createTripBtn: 3,
    phoneNumber: 4,
}

export const defaultHandlingTripInfo = {
    info: {
        phoneNumber: '',
        address: {
            pickup: undefined,
            destination: undefined,
        },
        estimatedData: { distance: '-', duration: '-', price: '-' },
        //estimatedData: null,
        services: [
            {
                service:
                    allService?.length > 0 ? allService[0].service_id : null,
                quantity: 1,
            },
        ],
        pickupTime: null,
        note: '',
        driverAssigned: null,
        bookingDispatchType: 'auto',
    },
    metadata: {
        canCreateTrip: false,
        errorMessage: 'Điểm đón không được để trống',
        estimated: false,
        focusingEl: keyboardFlow.none,
        hasOnlyOneCoor: true,
        mapPickerCoordinates: {
            pickup: null,
        },
        userId: userId,
    },
}

const initialState = {
    rndConfig: initRndConfig || defaultRndConfig,
    allService,
    defaultService: {
        service: allService?.length > 0 ? allService[0].service_id : null,
        quantity: 1,
    },
    handlingTrip: defaultHandlingTripInfo,
}

export default initialState
