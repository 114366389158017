import tripDetailLocaleKeys from './tripDetailLocaleKeys'

const tripDetailLocale = {
    en: {
        [tripDetailLocaleKeys.btnCreate]: 'Create',
        [tripDetailLocaleKeys.btnCancel]: 'Cancel',
        [tripDetailLocaleKeys.btnUpdate]: 'Update',
        [tripDetailLocaleKeys.btnUpdateAndDispatch]: 'Update & Dispatch',
    },

    vi: {
        [tripDetailLocaleKeys.btnCreate]: 'Tạo mới',
        [tripDetailLocaleKeys.btnCancel]: 'Hủy chuyến',
        [tripDetailLocaleKeys.btnUpdate]: 'Cập nhật',
        [tripDetailLocaleKeys.btnUpdateAndDispatch]: 'Cập nhật và điều xe mới',
    },
}

export default tripDetailLocale
