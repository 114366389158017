import previewTripsKeys from 'app/preview-trips/i18n/previewTripsKeys'
import { useTrans } from 'helpers/useTranslation'
import { selector, selectorFamily } from 'recoil'
import { mapOldStatus } from 'utils/trips'
import { tripDetailState } from './atom'
import i18n from 'i18next'
import { addressFieldNames } from 'app/new-booking/context/reducers/tripInfoReducers'

export const headerStatusSelector = selector({
    key: 'tripDetail.headerStatusSelector',
    get: ({ get }) => {
        const tripDetail = get(tripDetailState)

        if (tripDetail.open) {
            const tripStatusName = mapOldStatus(tripDetail.data)
            return i18n.t(previewTripsKeys.filter[tripStatusName])
        }
        return
    },
})

export const initialAddressSelector = selectorFamily({
    key: 'tripDetail.initialAddressSelector',
    get:
        name =>
        ({ get }) => {
            const tripDetail = get(tripDetailState)

            let initialState = []

            if (tripDetail.open) {
                if (name === addressFieldNames.pickup) {
                    if (
                        tripDetail.data.start_address &&
                        tripDetail.data.start_point
                    ) {
                        const coords = tripDetail.data.start_point.split(' ')
                        let item = {
                            description: tripDetail.data.start_address,
                            coords: { lat: coords[0], lng: coords[1] },
                        }
                        initialState.push(item)
                    }
                } else if (name === addressFieldNames.destination) {
                    if (
                        tripDetail.data.end_address &&
                        tripDetail.data.end_point
                    ) {
                        const coords = tripDetail.data.end_point.split(' ')
                        let item = {
                            description: tripDetail.data.end_address,
                            coords: { lat: coords[0], lng: coords[1] },
                        }
                        initialState.push(item)
                    }
                } else if (name === addressFieldNames.note) {
                    initialState.push({ description: tripDetail.data.note })
                }
            }

            return initialState
        },
})
