import UserContainerActionTypes from '../UserContainerActionTypes'

const setListDrivers = drivers => {
    return {
        type: UserContainerActionTypes.setListDrivers,
        payload: drivers,
    }
}

const updateListDrivers = driver => {
    return {
        type: UserContainerActionTypes.updateListDrivers,
        payload: driver,
    }
}

const setIsTheFirstDisplayCarMarker = isTheFirst => {
    return {
        type: UserContainerActionTypes.setIsTheFirstDisplayCarMarker,
        payload: isTheFirst,
    }
}

const driverActions = {
    setListDrivers,
    updateListDrivers,
    setIsTheFirstDisplayCarMarker,
}

export default driverActions
