import { tripStatusName } from 'utils/trips'
import previewTripsKeys from './previewTripsKeys'

const previewTripsLocale = {
    en: {
        [previewTripsKeys.driverName]: 'Driver:',
        [previewTripsKeys.passenger]: 'Passenger:',
        [previewTripsKeys.startPoint]: 'Start point:',
        [previewTripsKeys.pickup]: 'Pickup:',
        [previewTripsKeys.destination]: 'Destination:',
        [previewTripsKeys.estimate]: 'Estimate:',

        [previewTripsKeys.filter.totalDriver]: 'Total Driver',
        [previewTripsKeys.filter.showAll]: 'Show all',
        [previewTripsKeys.filter[tripStatusName.vacant]]: 'Vacant',
        [previewTripsKeys.filter[tripStatusName.driverOnTheWay]]:
            'Driver on the way',
        [previewTripsKeys.filter[tripStatusName.pob]]: 'P.O.B',
        [previewTripsKeys.filter[tripStatusName.waitingForProcess]]:
            'Waiting for process',
        [previewTripsKeys.filter[tripStatusName.dispatching]]: 'Dispatching',
        [previewTripsKeys.filter[tripStatusName.arrivedAndWaiting]]:
            'Arrived and waiting',
        [previewTripsKeys.filter[tripStatusName.droppedOff]]: 'Dropped off',
        [previewTripsKeys.filter[tripStatusName.unavailable]]: 'Unavailable',
        [previewTripsKeys.filter[tripStatusName.carNotFound]]: 'Car not found',
        [previewTripsKeys.filter[tripStatusName.error]]: 'Error',
        [previewTripsKeys.filter[tripStatusName.pending]]: 'Reservation',
    },

    vi: {
        [previewTripsKeys.driverName]: 'Tài xế:',
        [previewTripsKeys.passenger]: 'Khách hàng:',
        [previewTripsKeys.startPoint]: 'Điểm xuất phát:',
        [previewTripsKeys.pickup]: 'Điểm đón:',
        [previewTripsKeys.destination]: 'Điểm đến:',
        [previewTripsKeys.estimate]: 'Dự tính:',

        [previewTripsKeys.filter.totalDriver]: 'Tổng tài xế',
        [previewTripsKeys.filter.showAll]: 'Tất cả',
        [previewTripsKeys.filter[tripStatusName.vacant]]: 'Trống',
        //[previewTripsKeys.filter[tripStatusName.driverOnTheWay]]:'Đi đón khách',
        [previewTripsKeys.filter[tripStatusName.driverOnTheWay]]:
            'Đang đi đón khách',
        //[previewTripsKeys.filter[tripStatusName.pob]]: 'Khách trên xe',
        [previewTripsKeys.filter[tripStatusName.pob]]: 'Đang chở khách',
        [previewTripsKeys.filter[tripStatusName.waitingForProcess]]:
            'Đợi xử lý',
        [previewTripsKeys.filter[tripStatusName.dispatching]]: 'Đang điều',
        [previewTripsKeys.filter[tripStatusName.arrivedAndWaiting]]:
            'Đã đến và chờ',
        [previewTripsKeys.filter[tripStatusName.droppedOff]]: 'Đã trả khách',
        [previewTripsKeys.filter[tripStatusName.unavailable]]: 'Không khả dụng',
        [previewTripsKeys.filter[tripStatusName.carNotFound]]:
            'Chưa có xe nhận đón',
        [previewTripsKeys.filter[tripStatusName.error]]: 'Lỗi',
        [previewTripsKeys.filter[tripStatusName.pending]]: 'Đặt hẹn',
    },
}

export default previewTripsLocale
