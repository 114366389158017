import { useEffect, useState } from "react"

const iconRadioReasonChecked = 'assets/icons/Radio_Button_Checked.svg'
const iconRadioReasonUnchecked = 'assets/icons/Radio_Button_Unchecked.svg'

const CheckboxReason = ({ name, checked, reasonCancelled, checkboxChange }) => {
    const _onClick = () => {
        _setChecked(!_checked)
        checkboxChange({ name: name, checked: !checked })
    }

    const [_checked, _setChecked] = useState(checked)

    useEffect(() => {
        _setChecked(checked)
    }, [checked])
    
    return (
        <div className="checkbox-reason"
            onClick={_onClick}
        >
            <img 
                src={_checked ? iconRadioReasonChecked : iconRadioReasonUnchecked}
            />
            {/* <span>Không liên lạc được với khách hàng</span> */}
            <span>{reasonCancelled}</span>
        </div>
    )
}

export default CheckboxReason