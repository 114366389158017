import sidebarReducers from './toggleSideBar'
import tripsReducers from './tripsReducers'
import tripStatusFilter from './tripStatusFilter'
import driversReducer from './driversReducer'

const UserContainerReducers = {
    ...sidebarReducers,
    ...tripsReducers,
    ...tripStatusFilter,
    ...driversReducer,
}

export default UserContainerReducers
