import NewBookingActionTypes from '../actionTypes'

const setPickupCoordinates = latLng => ({
    type: NewBookingActionTypes.setPickupCoordinates,
    payload: latLng,
})

const mapPicker = {
    setPickupCoordinates,
}

export default mapPicker
