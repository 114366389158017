import { geocodeApi } from 'api/trip'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useNewBookingContext } from '../context'
import { keyboardFlow } from '../context/initialState'
import { addressFieldNames } from '../context/reducers/tripInfoReducers'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'
import AddressField from './AddressField'

const PickupAddress = props => {
    const t = useTrans()

    const [_externalOptions, _setExternalOptions] = useState(null)

    const [isNewBookingOpen] = useRecoilState(isNewBookingOpenState)

    const [
        {
            handlingTrip: {
                metadata: {
                    mapPickerCoordinates: { pickup },
                },
            },
        },
    ] = useNewBookingContext()

    useEffect( () => {
        let active = true

        if (!pickup) {
            return undefined
        }

        (async () => {
            const latLngString = pickup.lat + ', ' + pickup.lng

            const locations = await geocodeApi(latLngString)

            if (active) {
                _setExternalOptions(locations)
            }
        })()

        return () => {
            active = false
        }
    }, [pickup])

    useEffect(() => {
        if (!isNewBookingOpen) _setExternalOptions(null)
    }, [isNewBookingOpen])

    return (
        <AddressField
            // title={t(newBookingLocaleKeys.pickupField)}
            name={addressFieldNames.pickup}
            debounce={500}
            keyFlow={keyboardFlow.pickupAdd}
            placeholder={t(newBookingLocaleKeys.pickupAddressPlaceholder)}
            externalOptions={_externalOptions}
            {...props}
        />
    )
}

export default PickupAddress
