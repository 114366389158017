import { propsToClassKey } from '@mui/styles'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import ViewContext from 'app/_shared/view-context'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import BookingInfo from './BookingInfo'
import MapPicker from './MapPicker'
import { useTrans } from 'helpers/useTranslation'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'
import { useNewBookingContext } from '../context'

const { useViewContext } = ViewContext

const NewBooking = () => {
    const t = useTrans()

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    const [_callingQueue, _setCallingQueue] = useState([])
    const [_handlingCall, _setHandlingCall] = useState(null)

    //Use View Context to receive phone number
    const [{ dataPhoneNumber: incomingPhoneNumber, usingSwitchboard }] =
        useViewContext()

    const [
        {
            handlingTrip: {
                info: { phoneNumber },
                metadata: { focusingEl },
            },
        },
        dispatch,
    ] = useNewBookingContext()

    // console.log('phone number current: ')
    // console.log(phoneNumber);
    // console.log('focusing element: ' + focusingEl)

    // Handling new incoming phone number from context
    useEffect(() => {
        if (!!incomingPhoneNumber) {
            //console.log(incomingPhoneNumber)
            if (incomingPhoneNumber.deleted) {
                removePhoneNumberAfterBooking(
                    incomingPhoneNumber.id,
                    _callingQueue
                )
            } else {
                if (isNewBookingOpen) {
                    const alreadyHasThisCalling = _callingQueue.some(
                        calling => calling.id === incomingPhoneNumber?.id
                    )
                    if (!alreadyHasThisCalling) {
                        _setCallingQueue([
                            ..._callingQueue,
                            incomingPhoneNumber,
                        ])
                        if (phoneNumber === '' && _handlingCall == null)
                            _setHandlingCall(incomingPhoneNumber)
                    } else {
                        _setHandlingCall(incomingPhoneNumber)
                    }
                } else {
                    _setHandlingCall(incomingPhoneNumber)
                }
            }
        }
        // eslint-disable-next-line
    }, [incomingPhoneNumber])

    // Handle when a phone number is handling or not
    useEffect(() => {
        if (_handlingCall) {
            setIsNewBookingOpen(true)
        } else {
            setIsNewBookingOpen(false)
        }

        return () => {}
        // eslint-disable-next-line
    }, [_handlingCall])

    //Handle when new bookings are open or closed
    useEffect(() => {
        if (isNewBookingOpen) {
        } else {
            if (_callingQueue.length > 0) {
                const nextHandlingCall = _callingQueue.shift()
                //setTimeout(() => {
                //_setHandlingCall(nextHandlingCall)
                _setHandlingCall(null)
                _setCallingQueue([..._callingQueue])
                setIsNewBookingOpen(false)
                //}, 150)
            } else _setHandlingCall(null)
        }

        return () => {}
        // eslint-disable-next-line
    }, [isNewBookingOpen])

    const _removePhoneNumber = callId => {
        removePhoneNumberAfterBooking(callId, _callingQueue)
    }

    const removePhoneNumberAfterBooking = (callId, arrCalls) => {
        let index = arrCalls.findIndex(item => item.id == callId)
        if (index >= 0) arrCalls.splice(index, 1)
    }

    return (
        <div className="NewBooking">
            {/* <div className="Title">{t(newBookingLocaleKeys.title)}</div> */}
            <div className="Body">
                <BookingInfo
                    processCall={_handlingCall}
                    usingSwitchboard={usingSwitchboard}
                    removePhoneNumber={_removePhoneNumber}
                />
                {!usingSwitchboard &&
                    <MapPicker className="MapPicker" />
                }
                {/* <MapPicker className="MapPicker" /> */}
            </div>
        </div>
    )
}

export default NewBooking
