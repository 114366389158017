import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import { ThemeProvider, createTheme } from '@mui/material'
import { propsToClassKey } from '@mui/styles'

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    background: '#303030',
                    color: 'orange',
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    padding: '10px 46px 10px 22px !important',
                },
            },
        },

        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: 'white',
                    fontSize: '.85rem',
                    padding: '10px 46px 10px 0px !important',
                },
            },
        },

        MuiInput: {
            styleOverrides: {
                input: {
                    color: 'white',
                    fontSize: '.8rem',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'lightgray',
                    fontSize: '.85rem',
                    '&.Mui-focused': {
                        color: 'orange !important',
                    },
                },
                focused: {},
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&:after': {
                        borderColor: 'orange !important',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: 'white !important',
                },
            },
        },
    },
})

export default function FormDialog(props) {
    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open form dialog
            </Button> */}
            <ThemeProvider theme={theme}>
                <Dialog open={props.openDialog}>
                    <DialogTitle>{props.dialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {props.dialogContentText}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="reason_cancel"
                            label="Lý do hủy chuyến"
                            type="text"
                            fullWidth
                            variant="standard"
                            spellCheck="false"
                            onChange={props.getReasonCancel}
                            onKeyDown={props.onKeyDown}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.handleCloseDialog}>Không</Button>
                        <Button onClick={props.handleClickButtonYesDialog}>
                            Có
                        </Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </div>
    )
}
