import previewTripsKeys from 'app/preview-trips/i18n/previewTripsKeys'
import DatePicker from 'app/_shared/components/DatePicker'
import Input from 'app/_shared/components/Input'
import Select from 'app/_shared/components/Select'
import { useTrans } from 'helpers/useTranslation'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPrimitiveDot } from 'react-icons/go'
import { tripStatusColor, tripStatus } from 'utils/trips'
import bookingsKeys from '../i18n/bookingsKeys'
import MultipleSelectCheckmarks from './MultipleSelecCheckmarks'
import { bookingStatusColor, bookingStatusName } from '../../../utils/Booking'
import { ActionTypes } from '@mui/base'
import useTrips from 'app/_shared/trips-context'
import moment from 'moment'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const optionsBookingStatus = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
        color: 'white',
    },
    {
        id: 1,
        name: 'Đặt hẹn',
        checked: true,
        color: bookingStatusColor[bookingStatusName.pending],
    },
    {
        id: 2,
        name: 'Đang tìm xe',
        checked: true,
        color: bookingStatusColor[bookingStatusName.dispatching],
    },
    {
        id: 3,
        name: 'Chưa có xe nhận đón',
        checked: true,
        color: bookingStatusColor[bookingStatusName.waitingForProcess],
    },
    {
        id: 4,
        name: 'Nhận chuyến đặt hẹn',
        checked: true,
        color: bookingStatusColor[bookingStatusName.confirmReservation],
    },
    {
        id: 5,
        name: 'Đang đi đón khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.DriverOnTheWay],
    },
    {
        id: 6,
        name: 'Đến điểm đang chờ khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.ArrivedAndWaiting],
    },
    {
        id: 7,
        name: 'Đang chở khách',
        checked: true,
        color: bookingStatusColor[bookingStatusName.PassengerOnBoard],
    },
    {
        id: 8,
        name: 'Lái xe hủy chuyến',
        checked: true,
        color: bookingStatusColor[bookingStatusName.DrivereCancel],
    },

    // 'Đặt hẹn',
    // 'Đang tìm xe',
    // 'Chưa có xe nhận đón',
    // 'Nhận chuyến đặt hẹn',
    // 'Đang đi đón khách',
    // 'Đến điểm đang chờ khách',
    // 'Đang chở khách',
    // 'Lái xe hủy chuyến'
]

const optionsBookingType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Đi ngay',
        checked: true,
    },
    {
        id: 2,
        name: 'Đặt hẹn',
        checked: true,
    },
]

const optionsCarType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Taxi bất kỳ',
        checked: true,
    },
    {
        id: 2,
        name: 'Taxi 4 chỗ lớn',
        checked: true,
    },
    {
        id: 3,
        name: 'Taxi 7 chỗ',
        checked: true,
    },
    {
        id: 4,
        name: 'Emddi Fixed',
        checked: true,
    },
]

const optionsBookingFrom = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'App KH',
        checked: true,
    },
    {
        id: 2,
        name: 'Tổng đài',
        checked: true,
    },
    {
        id: 3,
        name: 'Khách vẫy',
        checked: true,
    },
]

const optionsOperator = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Admin Emddi',
        checked: true,
    },
    {
        id: 2,
        name: 'dieuxe1',
        checked: true,
    },
    {
        id: 3,
        name: 'Cong2k',
        checked: true,
    },
]

const optionsCustomerType = [
    {
        id: 0,
        name: 'Tất cả',
        checked: true,
    },
    {
        id: 1,
        name: 'Khách VIP',
        checked: true,
    },
    {
        id: 2,
        name: 'Non VIP',
        checked: true,
    },
]

const FilterBookingSwitchboard = () => {
    const t = useTrans()
    const { i18n } = useTranslation()

    let timeTeporary = new Date().toISOString().substring(0, 10) + ' 06:00:00'

    let startDateTime = moment(timeTeporary).utc().format('YYYY-MM-DD HH:mm:ss')

    let endDateTime = moment(timeTeporary)
        .add(1, 'days')
        .subtract(1, 'second')
        .utc()
        .format('YYYY-MM-DD HH:mm:ss')

    const [dataFilterSwitchboard, setDataFilerSwitchboard] = useState({
        fromDate: null,
        toDate: null,
        startDate: startDateTime,
        endDate: endDateTime,
        status: '',
        users: '', //operators
        types: '', //type booking(booking from),
        services: '',
    })

    const [state, actions] = useTrips()

    useEffect(() => {
        actions.getTripsSwitchboard(dataFilterSwitchboard)
    }, [dataFilterSwitchboard])

    const [displayFilter, setDisplayFilter] = useState(true)

    const handleClickCollapse = () => {
        setDisplayFilter(!displayFilter)
    }

    return (
        <div className={displayFilter ? 'Filter' : 'Filter Hide'}>
            {displayFilter ? (
                <ExpandLessIcon
                    className="IconCollapseUp"
                    onClick={handleClickCollapse}
                />
            ) : (
                <ExpandMoreIcon
                    className="IconCollapseDown"
                    onClick={handleClickCollapse}
                />
            )}
            <div className="Row">
                <MultipleSelectCheckmarks
                    width={135}
                    title="Loại chuyến"
                    label="Tất cả"
                    options={optionsBookingType}
                />
                <DatePicker className="w-64" />
                <MultipleSelectCheckmarks
                    width={230}
                    title="Trạng thái"
                    label="Tất cả"
                    options={optionsBookingStatus}
                />
                <MultipleSelectCheckmarks
                    width={150}
                    title="Người điều"
                    label="Tất cả"
                    options={optionsOperator}
                />
                <MultipleSelectCheckmarks
                    width={160}
                    title="Loại xe"
                    label="Tất cả"
                    options={optionsCarType}
                />
                <MultipleSelectCheckmarks
                    width={130}
                    title="Loại KH"
                    label="Tất cả"
                    options={optionsCustomerType}
                />
                <MultipleSelectCheckmarks
                    width={135}
                    title="Nguồn cuốc"
                    label="Tất cả"
                    options={optionsBookingFrom}
                />
                <Input
                    className="search-input w-52"
                    placeholder={t(bookingsKeys.filter.searchPlaceholder)}
                />
            </div>
        </div>
    )
}

export default FilterBookingSwitchboard
