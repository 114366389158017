import {
    ViewContextProvider,
    useViewContext,
    useViewDispatch,
} from './ViewContext'

import ViewActions from './actions'

const ViewContext = {
    ViewContextProvider,
    useViewContext,
    useViewDispatch,
    ViewActions,
}

export default ViewContext
