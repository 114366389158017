import { estimateTripApi } from 'api/trip'
import classNames from 'classnames'
import { useTrans } from 'helpers/useTranslation'
import { useEffect } from 'react'
import { thousandAndDecimalFormat } from 'utils/number'
import { useNewBookingContext } from '../context'
import actions from '../context/actions'
import newBookingLocaleKeys from '../i18n/newBookingLocaleKeys'

const defaultEstimatedInfo = {
    estimated: false,
    data: { distance: '-', duration: '-', price: '-' },
}

const EstimateInfo = props => {
    const t = useTrans()

    const [
        {
            rndConfig,
            handlingTrip: {
                info: {
                    address: { pickup, destination },
                    estimatedData,
                    services,
                },
                metadata: { estimated },
            },
        },
        dispatch,
    ] = useNewBookingContext()
    const minimizeEstimate = rndConfig.height < '500px' ? true : false

    useEffect(() => {
        if (!!pickup && !!destination && !estimated) {
            // if (!estimated) {
            ;(async () => {
                let w1 = pickup.emddiId
                    ? {
                          emddiId: pickup.emddiId,
                          placeId: pickup.placeId,
                      }
                    : { lat: pickup.coords.lat, lng: pickup.coords.lng }
                let w2 = destination.emddiId ? {
                    emddiId: destination.emddiId,
                    placeId: destination.placeId,
                } : { lat: destination.coords.lat, lng: destination.coords.lng}

                const estimateData = {
                    service: services[0].service,
                    waypoints: [w1, w2],
                }
                const estimatedData = await estimateTripApi(estimateData)
                if (estimatedData) {
                    dispatch(
                        actions.tripInfo.setEstimateData({
                            estimated: true,
                            data: estimatedData,
                        })
                    )
                } else {
                    dispatch(
                        actions.tripInfo.setEstimateData(defaultEstimatedInfo)
                    )
                }
            })()
            // }
            // } else {
            //     dispatch(actions.tripInfo.setEstimateData(defaultEstimatedInfo))
        }
    }, [estimated, pickup, destination])

    return (
        <div
            className={
                props.usingSwitchboard
                    ? 'EstimateInfo Switchboard'
                    : 'EstimateInfo'
            }
        >
            {!minimizeEstimate && (
                <div className="EstimateTitle">
                    {t(newBookingLocaleKeys.estimate)}
                </div>
            )}
            <div className="EstimateDescription">
                <div
                    className={classNames('EstimateItem', {
                        MinimizeEstimate: minimizeEstimate,
                    })}
                >
                    <div className="EstimateLabel">
                        {t(newBookingLocaleKeys.estimateDistance)}
                    </div>
                    <div className="EstimateValue">{`${thousandAndDecimalFormat(
                        estimatedData.distance
                    )} km`}</div>
                </div>
                <div
                    className={classNames('EstimateItem', {
                        MinimizeEstimate: minimizeEstimate,
                    })}
                >
                    <div className="EstimateLabel">
                        {t(newBookingLocaleKeys.estimateDuration)}
                    </div>
                    <div className="EstimateValue">{`${thousandAndDecimalFormat(
                        estimatedData.duration
                    )} mins`}</div>
                </div>
                <div
                    className={classNames('EstimateItem', {
                        MinimizeEstimate: minimizeEstimate,
                    })}
                >
                    <div className="EstimateLabel">
                        {t(newBookingLocaleKeys.estimateFare)}
                    </div>
                    <div className="EstimateValue">{`${thousandAndDecimalFormat(
                        estimatedData.price
                    )} đ`}</div>
                </div>
            </div>
        </div>
    )
}

export default EstimateInfo
