import {
    UserContainerContextProvider,
    useUserContainerContext,
    useUserContainerDispatch,
} from './UserContainerContext'
import UserContainerActions from './user-container-actions'

const UserContainerContext = {
    UserContainerContextProvider,
    useUserContainerContext,
    useUserContainerDispatch,
    UserContainerActions,
}

export default UserContainerContext
