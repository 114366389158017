import SidebarActions from './SidebarActions'
import SwitchPopupActions from './SwitchPopupActions'
import PhoneNumberActions from './PhoneNumberActions'
import TripsActions from './TripsActions'
import UserSettingsActions from './UserSettingsActions'

const ViewActions = {
    ...SidebarActions,
    ...SwitchPopupActions,
    ...PhoneNumberActions,
    ...TripsActions,
    ...UserSettingsActions,
}

export default ViewActions
