import CheckBox from 'app/_shared/components/CheckBox'
import { tripStatusFilterState } from 'app/_shared/recoil/preview-trips/atom'
import { singleTripStatusSelector } from 'app/_shared/recoil/preview-trips/selector'
import { useTrans } from 'helpers/useTranslation'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { tripStatusColor } from 'utils/trips'
import previewTripsKeys from '../i18n/previewTripsKeys'

const StatusCheckbox = ({ name }) => {
    const t = useTrans()

    const setTripStatusFilter = useSetRecoilState(tripStatusFilterState)
    const checked = useRecoilValue(singleTripStatusSelector(name))

    const _handleSingleTripStatusChange = (state, name) => {
        setTripStatusFilter(currVal => ({ ...currVal, [name]: state }))
    }
    return (
        <CheckBox
            name={name}
            checked={checked}
            label={t(previewTripsKeys.filter[name])}
            color={tripStatusColor[name]}
            onToggle={_handleSingleTripStatusChange}
            className='Cb-Filter'
        />
    )
}

export default StatusCheckbox
