import { addressFieldNames } from 'app/new-booking/context/reducers/tripInfoReducers'
import newBookingLocaleKeys from 'app/new-booking/i18n/newBookingLocaleKeys'
import tripDetailLocaleKeys from 'app/trip-detail/i18n/tripDetailLocaleKeys'
import Select from 'app/_shared/components/Select'
import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import { useTrans } from 'helpers/useTranslation'
import { useEffect, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useRecoilValue, useRecoilState } from 'recoil'
import AddressField from './AddressField'
import CustomerInfo from './CustomerInfo'
import DispatchDriver from './DispatchDriver'
import PickupTimeField from './PickupTimeField'
import Promotion from './Promotion'
import Services from './Services'
import classNames from 'classnames'
import ViewContext from 'app/_shared/view-context'
import { createTripApi } from 'api/trip'
import { EDIT_BOOKING, NEW_BOOKING } from 'services/socket/events'
import { useSnackbar } from 'notistack'
import { localStorageKeys, getLocalStorage } from 'services/localStorage'
import { updateTripInfoApi } from 'api/trip/update/updateTripActivity'
import { editBookingTypes } from 'app/bookings/constant'
import { CollectionsOutlined } from '@mui/icons-material'
import { estimateTripApi } from 'api/trip'
import moment from 'moment'
import useTrips from 'app/_shared/trips-context'

import FormDialog from 'app/bookings/components/FormDialog'

import { sendNotify } from 'api/service'
import { bookingStatusColor, bookingStatusName } from 'utils/Booking'

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

const BookingDetail = (props) => {
    const t = useTrans()

    const { enqueueSnackbar } = useSnackbar()

    const [tripDetail, setTripDetail] = useRecoilState(tripDetailState)

    // console.log('trip detail data: ')
    // console.log(tripDetail.data)

    const [{ userId, socket, showDialogCancel, officeId }] = useViewContext()

    const [state, actions] = useTrips()

    const phoneNumber = tripDetail.data?.customer_phone

    const [bookingData, setBookingData] = useState({
        phoneNumber: tripDetail.data?.customer_phone,
        customerName: tripDetail.data?.customer_name,
        pickup: '',
        destination: '',
        service: '',
        quantity: '',
    })

    const parseAddress = address => {
        let waypoint = {}
        let latLngString = ''
        if (address.coords) {
            waypoint.x = address.coords.lat
            waypoint.y = address.coords.lng
            latLngString = address.coords.lat + ' ' + address.coords.lng
        } else if (address.coordinates) {
            const coords = address.coordinates.split(' ')
            waypoint.x = coords[0]
            waypoint.y = coords[1]
            latLngString = coords[0] + ' ' + coords[1]
        } else {
            waypoint.x = 0
            waypoint.y = 0
        }
        waypoint.geocode = address.description
        waypoint.place_id = address.placeId || ''

        return { waypoint, latLngString }
    }

    const [pickup, setPickup] = useState(null)
    const [destination, setDestination] = useState(null)

    const [pickupTime, setPickupTime] = useState(null)

    const [service, setService] = useState(null)

    const [note, setNote] = useState(null)

    const [driverAssigned, setDriverAssigned] = useState(null)

    const [startAddress, setStartAddress] = useState({
        geocode: tripDetail.data.start_address,
        point: tripDetail.data.start_point,
    })
    //const [startPoint, setStartPoint] = useState(tripDetail.data.start_point)

    const [endAddress, setEndAddress] = useState({
        geocode: tripDetail.data.end_address,
        point: tripDetail.data.end_point,
    })

    //const [endPoint, setEndPoint] = useState(tripDetail.data.end_point)

    const handleAddressFiledSelected = (name, address) => {
        if (name === 'pickup') {
            if (address) {
                setPickup(address)
                setStartAddress({
                    geocode: address.description,
                    point: address.lat + ' ' + address.lng,
                })
            } else {
                // setPickup({
                //     coords: null,
                //     description: ''
                // })
                setStartAddress({
                    geocode: '',
                    point: '',
                })
            }
        } else if (name === 'note') {
            if (address) setNote(address)
            else setNote('')
        } else {
            if (address) {
                setDestination(address)
                setEndAddress({
                    geocode: address.description,
                    point: address.lat + ' ' + address.lng,
                })
            } else {
                // setDestination({
                //     coords: null,
                //     description: ''
                // })
                setEndAddress({
                    geocode: '',
                    point: '',
                })
            }
        }
    }

    // const [service, setService] = useState({
    //     service: tripDetail.data.service_id,
    //     quantity: 1
    // })

    const _handleServiceChange = service => {
        // console.log('service change: ')
        // console.log(service)
        setService(service)
    }

    const [flagCanCreateTrip, setFlagCanCreateTrip] = useState(true)

    const _handleCreateTrip = async () => {
        if (startAddress === '')
            return enqueueSnackbar('Điểm đón không được để trống', {
                variant: 'warning',
            })

        let socketBookingData = {
            user_id: userId,
            note: note,
            distance: null,
            total_price: null,
            estimated_time: null,
            coordinator_id: null,
            customer_id: null,
            type: 1000,
            c_version: 11,
            driver_id: driverAssigned,
        }
        if (service) socketBookingData.service_id = service.service_id
        else socketBookingData.service_id = tripDetail.data.service_id

        let waypoints = []

        let waypointsCreateTrip = []

        if (pickup) {
            let startWaypoint = parseAddress(pickup)
            waypoints.push(startWaypoint.waypoint)
            socketBookingData.start_address = pickup.description
            socketBookingData.start_point = startWaypoint.latLngString

            //Create waypoint call api create trip
            waypointsCreateTrip.push({
                emddiId: pickup.emddiId,
                placeId: pickup.placeId,
            })

            if (destination) {
                let endWaypoint = parseAddress(destination)
                waypoints.push(endWaypoint.waypoint)
                socketBookingData.end_address = destination.description
                socketBookingData.end_point = endWaypoint.latLngString

                //Create waypoint call api create trip
                waypointsCreateTrip.push({
                    emddiId: destination.emddiId,
                    placeId: destination.placeId,
                })
            } else {
                if (endAddress.geocode && endAddress.point) {
                    socketBookingData.end_address = endAddress.geocode
                    socketBookingData.end_point = endAddress.point

                    if (endAddress.point) {
                        let coordinates2 = endAddress.point.split(' ')
                        let waypoint2 = {
                            x: coordinates2[0],
                            y: coordinates2[1],
                            geocode: endAddress.geocode,
                            placeId: '',
                        }
                        waypoints.push(waypoint2)

                        //Create waypoint call api create trip
                        waypointsCreateTrip.push({
                            let: coordinates2[0],
                            lng: coordinates2[1],
                            description: endAddress.geocode,
                            placeId: '',
                        })
                    }
                }
            }
        } else {
            socketBookingData.start_address = tripDetail.data.start_address
            socketBookingData.start_point = tripDetail.data.start_point
            let coordinates = tripDetail.data.start_point.split(' ')
            let waypoint1 = {
                x: coordinates[0],
                y: coordinates[1],
                geocode: tripDetail.data.start_address,
                placeId: '',
            }
            waypoints.push(waypoint1)

            //Create waypoint call api create trip
            waypointsCreateTrip.push({
                lat: coordinates[0],
                lng: coordinates[1],
                description: tripDetail.data.start_address,
                placeId: '',
            })

            if (destination) {
                let endWaypoint = parseAddress(destination)
                waypoints.push(endWaypoint.waypoint)
                socketBookingData.end_address = destination.description
                socketBookingData.end_point = endWaypoint.latLngString

                //Create waypoint call api create trip
                waypointsCreateTrip.push({
                    emddiId: destination.emddiId,
                    placeId: destination.placeId,
                })
            } else {
                if (endAddress.geocode && endAddress.point) {
                    socketBookingData.end_address = endAddress.geocode
                    socketBookingData.end_point = endAddress.point

                    if (endAddress.point) {
                        let coordinates2 = endAddress.point.split(' ')
                        let waypoint2 = {
                            x: coordinates2[0],
                            y: coordinates2[1],
                            geocode: endAddress.geocode,
                            placeId: '',
                        }
                        waypoints.push(waypoint2)

                        //Create waypoint call api create trip
                        waypointsCreateTrip.push({
                            lat: coordinates2[0],
                            lng: coordinates2[1],
                            description: endAddress.geocode,
                            placeId: '',
                        })
                    }
                }
            }
        }

        socketBookingData.waypoints = waypoints

        if (waypointsCreateTrip.length == 2) {
            const estimateData = {
                service: service.service_id,
                waypoints: waypointsCreateTrip,
            }
            const estimatedResponse = await estimateTripApi(estimateData)
            if (estimatedResponse) {
                socketBookingData = {
                    ...socketBookingData,
                    distance: estimatedResponse.distance,
                    total_price: estimatedResponse.price,
                    estimated_time: estimatedResponse.duration,
                }
            }
        }

        //Create trip
        let createTripData = {
            phone: phoneNumber,
            services: service
                ? [service]
                : [
                      {
                          service: tripDetail.data.service_id,
                          quantity: 1,
                      },
                  ],
            waypoints: waypointsCreateTrip,
            note: note,
        }

        //console.log(createTripData)

        createTripApi(createTripData)
            .then(newTrips => {
                if (newTrips?.coordinator?.length > 0) {
                    let list_coordinator = newTrips.coordinator.reduce(
                        (arr, coord) => {
                            //console.log(coord)
                            arr.push(coord.id)
                            return arr
                        },
                        []
                    )
                    socketBookingData = {
                        ...socketBookingData,
                        coordinator_id: newTrips.coordinator[0].id,
                        customer_id: newTrips.customer.customer_id,
                        list_coordinator,
                    }
                    let baseTripData = { ...socketBookingData }
                    delete baseTripData.coordinator_id
                    delete baseTripData.service_id
                    let editBookingData = newTrips.coordinator.map(trip => {
                        return {
                            ...baseTripData,
                            phoneNumber: phoneNumber,
                            coordinator_id: trip.id,
                            service_id: trip.service_id,
                            createdAt: trip.created_at,
                        }
                    })
                    _emitEditBooking(editBookingData, () =>
                        _emitCreateBooking(socketBookingData)
                    )
                }
            })
            .catch(error => {})

        // } else {
        //     enqueueSnackbar(errorMessage || 'Điền đầy đủ thông tin cần thiết', {
        //         variant: 'warning',
        //     })
        // }
    }

    const _emitCreateBooking = data => {
        console.log('data booking: ')
        console.log(data)
        socket?.emit(NEW_BOOKING, data, () => {
            enqueueSnackbar('Đặt chuyến thành công', {
                variant: 'info',
            })
            //Close modal
            setTripDetail({ open: false, data: null })
        })
    }

    const _emitEditBooking = (data, callback) => {
        socket?.emit(EDIT_BOOKING, data)
        callback?.()
    }

    const _handleCancelTrip = () => {
        if (showDialogCancel) {
            setOpenDialog(true)
        } else {
            let dataCancel = {
                ...tripDetail.data,
                user_id: userId,
                editbooking_type: editBookingTypes.TRIP_DETAIL_CANCEL,
            }
            socket.emit('edit-booking', dataCancel)
            //close modal
            setTripDetail({ open: false, data: null })
        }
    }

    const useDispatch = useViewDispatch()

    const checkInfoChanged = () => {
        if (
            pickup == null &&
            destination == null &&
            pickupTime == null &&
            service == null &&
            note == null &&
            driverAssigned == null
        )
            return false
        return true
    }

    const updateTripTinfo = flagRebooking => {
        let updateTripData = {
            type_update: 0,
            coordinator_id: tripDetail.data.id,
            user_id: userId,
            type: tripDetail.data.type,
            start_address: '',
            start_point: '',
            end_address: '',
            end_point: '',
            note: note,
            //booking_time: pickupTime ? Math.floor(moment(pickupTime).valueOf() / 100000) * 100000 : null,
            booking_time: pickupTime ? moment(pickupTime).valueOf() : null,
            list_arrived: driverAssigned != null ? driverAssigned : null,
            check_sb:
                driverAssigned == null ? null : driverAssigned === '' ? 0 : 1,
        }

        if (pickup) {
            updateTripData.start_address = pickup.description
            updateTripData.start_point = pickup.lat + ' ' + pickup.lng
            if (destination) {
                updateTripData.end_address = destination.description
                updateTripData.end_point =
                    destination.lat + ' ' + destination.lng
            } else {
                updateTripData.end_address = endAddress.geocode
                updateTripData.end_point = endAddress.point
            }
        } else {
            updateTripData.start_address = tripDetail.data.start_address
            updateTripData.start_point = tripDetail.data.start_point
            if (destination) {
                updateTripData.end_address = destination.description
                updateTripData.end_point =
                    destination.lat + ' ' + destination.lng
            } else {
                updateTripData.end_address = endAddress.geocode
                updateTripData.end_point = endAddress.point
            }
        }

        if (
            (pickup && pickup.description !== tripDetail.data.start_address) ||
            (destination &&
                destination.description !== tripDetail.data.end_address)
        ) {
            updateTripData.createNewBooking = true
        }

        //console.log(updateTripData)

        updateTripInfoApi(updateTripData).then(res => {
            //Close modal
            setTripDetail({ open: false, data: null })

            // setTimeout(() => {
            //     if (res.data.success) {
            //         useDispatch(ViewActions.updateTripInfo({...updateTripData}))
            //     }
            // }, 500)
            if (res.data.success) {
                socket.emit('edit-booking', {
                    ...updateTripData,
                    editbooking_type: editBookingTypes.UPDATE_TRIP_INFO,
                    flagRebooking: flagRebooking,
                    driverAssigned: driverAssigned,
                })
                // console.log('response update: ')
                // console.log(res.data)
                // console.log('type coor: ' + updateTripData.type)

                if (
                    updateTripData.type == 8 &&
                    (pickupTime != null || driverAssigned != null)
                ) {
                    //update interval booking reservation
                    actions.updateIntervalReservation(res.data.data)
                    //
                }
                if (note != null && note !== '') {
                    let dataReport = {
                        phone: tripDetail.data.driver_phone,
                        info:
                            'Phone: ' +
                            tripDetail.data.customer_phone +
                            ' | ' +
                            note,
                        user_id: userId,
                        office_id: officeId,
                        c_phone: tripDetail.data.customer_phone,
                    }
                    sendNotify(dataReport)
                }
            }
            //callback?.()
        })
    }

    const _handleUpdateTrip = () => {
        if (startAddress.geocode === '')
            return enqueueSnackbar('Điểm đón không được để trống', {
                variant: 'warning',
            })

        if (!checkInfoChanged()) {
            return enqueueSnackbar(
                'Thông tin chưa thay đổi, vui lòng kiểm tra lại',
                {
                    variant: 'warning',
                }
            )
        }

        if (
            moment(pickupTime).valueOf() !=
            moment(tripDetail.data.created_at_original).valueOf()
        ) {
            if (
                Math.floor(
                    (moment(pickupTime).valueOf() -
                        moment(new Date()).valueOf()) /
                        60000
                ) <= 15
            ) {
                return enqueueSnackbar(
                    `Thời gian chuyến đặt hẹn phải > thời gian hiện tại 15'`,
                    {
                        variant: 'warning',
                    }
                )
            }
        }

        updateTripTinfo()
    }

    const reBookingDriverAssigned = () => {}

    const _handleUpdateTripAndDispatch = () => {
        if (startAddress.geocode === '')
            return enqueueSnackbar('Điểm đón không được để trống', {
                variant: 'warning',
            })

        if (!checkInfoChanged()) {
            return enqueueSnackbar(
                'Thông tin chưa thay đổi, vui lòng kiểm tra lại',
                {
                    variant: 'warning',
                }
            )
        } else {
            updateTripTinfo(true) //True: rebooking after updated
        }
    }

    const _handleAddressFieldClose = e => {
        // console.log('event on booking detail: ')
        // console.log(e)
    }

    const myRef = useRef(null)

    const handleTimeChange = timePickup => {
        // console.log('time pickup: ')
        // console.log(timePickup)
        setPickupTime(timePickup)
    }

    const handleChangeAssignDriver = driverId => {
        //console.log('driver assigned: ' + driverId)
        setDriverAssigned(driverId)
    }

    const [openDialog, setOpenDialog] = useState(false)
    const [reasonCancel, setReasonCancel] = useState('')

    const _onCloseDialog = () => {
        setOpenDialog(false)
    }

    const _onClickButtonYesDialog = () => {
        let dataCancel = {
            ...tripDetail.data,
            user_id: userId,
            editbooking_type: editBookingTypes.TRIP_DETAIL_CANCEL,
            reason_cancel: reasonCancel,
        }
        socket.emit('edit-booking', dataCancel)
        //close modal
        setTripDetail({ open: false, data: null })
        setOpenDialog(false)
        setReasonCancel('')
    }

    const _getReasonCancel = e => {
        setReasonCancel(e.target.value)
    }

    const _onKeyDownInputReasonCancel = e => {
        if (e.key.toLowerCase() === 'enter') {
            let dataCancel = {
                ...tripDetail.data,
                user_id: userId,
                editbooking_type: editBookingTypes.TRIP_DETAIL_CANCEL,
                reason_cancel: reasonCancel,
            }
            socket.emit('edit-booking', dataCancel)
            //close modal
            setTripDetail({ open: false, data: null })
            setOpenDialog(false)
            setReasonCancel('')
        }
    }

    const renderStatus = data => {
        let status = data.status

        let statusBooking = data.status_booking

        let cancel = data.cancel

        if (data.type == 10 || data.type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        return (
                            <>
                                <img src='assets/icons/Dang_don_khach.svg' />
                                <span style={{marginLeft: '5px'}}>ĐANG ĐI ĐÓN KHÁCH</span>
                            </>
                        )
                    } else if (cancel == 1) {
                        return (
                            <>
                                <img src='assets/icons/huy_chuyen.svg' />
                                <span style={{marginLeft: '5px'}}>HỦY BỞI KHÁCH HÀNG</span>
                            </>
                        )
                    } else if (cancel == 2) {
                        return (
                            <>
                                <img src='assets/icons/huy_chuyen.svg' />
                                <span style={{marginLeft: '5px'}}>HỦY BỞI LÁI XE</span>
                            </>
                        )
                    } else if (cancel == 3) {
                        return (
                            <>
                                <img src='assets/icons/huy_chuyen.svg' />
                                <span style={{marginLeft: '5px'}}>HỦY BỞI TỔNG ĐÀI</span>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <img src='assets/icons/Dang_tim_xe.svg' />
                                <span style={{marginLeft: '5px'}}>ĐANG TÌM XE</span>
                            </>
                        )
                    }
                case 1:
                    return (
                        <>
                            <img src='assets/icons/Dang_cho_khach.svg' />
                            <span style={{marginLeft: '5px'}}>ĐANG CHỞ KHÁCH</span>
                        </>
                    )
                case 2:
                    return (
                        <>
                            <img src='assets/icons/Dang_tim_xe.svg' className='finished-booking'/>
                            <span style={{marginLeft: '5px'}}>HOÀN THÀNH</span>
                        </>
                    )
                case 3:
                    return (
                        <>
                            <img src='assets/icons/Dang_tim_xe.svg' className='car-found'/>
                            <span style={{marginLeft: '5px'}}>ĐÃ THẤY XE</span>
                        </>
                    )
                case -1:
                    return (
                        <>
                            <img src='assets/icons/Chua_co_xe_nhan.svg' />
                            <span style={{marginLeft: '5px'}}>CHƯA CÓ XE NHẬN</span>
                        </>
                    )
                default:
                    return '<span>---</span>'
            }
        } else if (data.type == 0) {
            switch (status) {
                case 0:
                    return (
                        <>
                            <img src='assets/icons/Dang_tim_xe.svg' />
                            <span style={{marginLeft: '5px'}}>ĐANG TÌM XE</span>
                        </>
                    )
                case 1:
                    if (statusBooking == 3) {
                        return (
                            <>
                                <img src='assets/icons/Dang_tim_xe.svg' className='car-found'/>
                                <span style={{marginLeft: '5px'}}>ĐÃ THẤY XE</span>
                            </>
                        )
                    } else {
                        switch (statusBooking) {
                            case 0:
                                return (
                                    <>
                                        <img src='assets/icons/Dang_don_khach.svg' />
                                        <span style={{marginLeft: '5px'}}>ĐANG ĐI ĐÓN KHÁCH</span>
                                    </>
                                )
                            case 1:
                                return (
                                    <>
                                        <img src='assets/icons/Dang_cho_khach.svg' />
                                        <span style={{marginLeft: '5px'}}>ĐANG CHỞ KHÁCH</span>
                                    </>
                                )
                            case 2:
                                return (
                                    <>
                                        <img src='assets/icons/Dang_tim_xe.svg' className='finished-booking'/>
                                        <span style={{marginLeft: '5px'}}>HOÀN THÀNH</span>
                                    </>
                                )
                            case -3:
                                return (
                                    <>
                                        <img src='assets/icons/huy_chuyen.svg' />
                                        <span style={{marginLeft: '5px'}}>HỦY BỞI TỔNG ĐÀI</span>
                                    </>
                                )
                            case -4:
                                return (
                                    <>
                                        <img src='assets/icons/huy_chuyen.svg' />
                                        <span style={{marginLeft: '5px'}}>HỦY BỞI LÁI XE</span>
                                    </>
                                )
                            case -5:
                                return (
                                    <>
                                        <img src='assets/icons/huy_chuyen.svg' />
                                        <span style={{marginLeft: '5px'}}>HỦY BỞI KHÁCH HÀNG</span>
                                    </>
                                )
                            default:
                                return '<span>---</span>'
                        }
                    }
                case -1:
                    return (
                        <>
                            <img src='assets/icons/Chua_co_xe_nhan.svg' />
                            <span style={{marginLeft: '5px'}}>CHƯA CÓ XE NHẬN</span>
                        </>
                    )
                case -2:
                    return (
                        <>
                            <img src='assets/icons/Loi.svg' />
                            <span style={{marginLeft: '5px'}}>LỖI</span>
                        </>
                    )
                case -3:
                    return (
                        <>
                            <img src='assets/icons/huy_chuyen.svg' />
                            <span style={{marginLeft: '5px'}}>HỦY BỞI TỔNG ĐÀI</span>
                        </>
                    )
                case -4:
                    return (
                        <>
                            <img src='assets/icons/huy_chuyen.svg' />
                            <span style={{marginLeft: '5px'}}>HỦY BỞI LÁI XE</span>
                        </>
                    )
                case -5:
                    return (
                        <>
                            <img src='assets/icons/huy_chuyen.svg' />
                            <span style={{marginLeft: '5px'}}>HỦY BỞI KHÁCH HÀNG</span>
                        </>
                    )
                default:
                    return '<span>---</span>'
            }
            //return element
        } else if (data.type == 1) {
            let s = ''
            if (
                statusBooking == 3 ||
                statusBooking == 4 ||
                statusBooking == 5
            ) {
                s = '<span style = "color: green;">---</span>'
                return s
            } else {
                switch (parseInt(status)) {
                    case 1:
                        if (data.driver_id != '') {
                            s = '<span style="color:blue;">Hoàn thành</span>'
                        } else {
                            s = '<span style="color:green;">---</span>'
                        }
                        break
                    case 0:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -1:
                        s = '<span style="color:red;">Không thấy xe</span>'
                        break
                    case -2:
                        s = '<span style="color:green;">Đang tìm xe</span>'
                        break
                    case -3:
                        s = '<span style="color:red;">Quản trị hủy</span>'
                        break
                    case -4:
                        s =
                            '<span class = "DriverCancelBlink" style="color:red;">Lái xe hủy chuyến</span>'
                        break
                    case 5:
                        s =
                            '<span style="color:red;">Đã X.Lý, Không Đ.Xe</span>'
                        break
                    case -5:
                        s = '<span style="color:red;">Khách hủy</span>'
                        break
                    case 6:
                        s = '<span style="color:red;">Khách hẹn</span>'
                        break
                    default:
                        s = '<span style="color:green;">---</span>'
                        break
                }

                if (data.cmd_sb == 3 || data.cmd_cd == 'k') {
                    return '<span style="color:red;">Không thấy xe</span>'
                }
                return s
            }
        } else if (data.type == 8) {
            return `<span style="color:${
                bookingStatusColor[bookingStatusName.pending]
            }">Đặt hẹn</span>`
        }
    }

    return (
        <div className={props.flagMaximumSize ? "BookingDetail zoomout" : "BookingDetail"}>
            {/* <Scrollbars
                className={'BookingDetailScrollbar'}
                autoHideTimeout={1000}
                autoHideDuration={200}
                renderThumbVertical={props => (
                    <div {...props} className={'ScrollThumbVertical'} />
                )}
                renderView={props => (
                    <div {...props} className="ScrollContent" />
                )}
            > */}
           
            <div className="Title">
                <div className='left-title'>
                    <div className="TripId">
                        {` #${
                            tripDetail.data.id
                                ? tripDetail.data.id
                                : tripDetail.data.booking_id
                        }`}
                        {/* 8220330002146139 */}
                    </div>
                    <div className='trip-status'>
                        {/* <img src='assets/icons/Dang_tim_xe.svg' />
                        <span style={{marginLeft: '5px'}}>ĐANG TÌM XE</span> */}
                        { renderStatus(tripDetail.data) }
                    </div>
                </div>
                <div className='right-title'>
                    <div className='group-icons'>
                        <img 
                            src='assets/icons/icon_zoom_out.svg'
                            onClick={props.handleZoomInOut}
                        />
                        <img src='assets/icons/icon_options.svg' />
                        <img 
                            src='assets/icons/icon_close.svg' 
                            onClick={props.clickCloseIcon}
                        />
                    </div>
                </div>
            </div>
            <form className="FormBooking" autoComplete="off">
                <div className="Heading">
                    {/* {t(newBookingLocaleKeys.bookingHeader)} */}
                </div>
                <div className="Content">
                    <CustomerInfo />
                    <div className='address'>
                        <div className='start-point'>
                            <img src='assets/icons/markers/marker_start.svg' />
                            <AddressField
                                //title={t(newBookingLocaleKeys.pickupField)}
                                name={addressFieldNames.pickup}
                                debounce={500}
                                placeholder={t(
                                    newBookingLocaleKeys.pickupAddressPlaceholder
                                )}
                                canUpdateInfo={true}
                                handleSelected={handleAddressFiledSelected}
                                handleAddressFieldClose={
                                    _handleAddressFieldClose
                                }
                            />
                        </div>
                        <div className='end-point'>
                            <img src='assets/icons/markers/marker_end.svg' />
                            <AddressField
                                // title={t(
                                //     newBookingLocaleKeys.destinationField
                                // )}
                                name={addressFieldNames.destination}
                                debounce={500}
                                placeholder={t(
                                    newBookingLocaleKeys.destinationAddressPlaceholder
                                )}
                                canUpdateInfo={true}
                                handleSelected={handleAddressFiledSelected}
                                handleAddressFieldClose={
                                    _handleAddressFieldClose
                                }
                            />
                        </div>
                    </div>
                    <AddressField
                        className='note'
                        title={t(newBookingLocaleKeys.noteField)}
                        name={addressFieldNames.note}
                        //debounce={500}
                        canUpdateInfo={true}
                        handleSelected={handleAddressFiledSelected}
                        flagQuery={false}
                        handleAddressFieldClose={
                            _handleAddressFieldClose
                        }
                    />
                            
                    <Services
                        title={t(newBookingLocaleKeys.serviceField)}
                        name="service"
                        handleServiceChange={_handleServiceChange}
                        disabled={false}
                    />
                    <PickupTimeField
                        title={t(
                            newBookingLocaleKeys.pickupTimeField
                        )}
                        name="pickupTime"
                        onChange={time => handleTimeChange(time)}
                        disabled={
                            tripDetail.data.type == 8 ? false : true
                        }
                        //Using time created_at_original not yet convert to processing quickly
                        initValue={
                            tripDetail.data.type == 8 &&
                            tripDetail.data.created_at_original
                                ? moment(
                                        tripDetail.data.created_at_original.toLocaleString()
                                    )
                                : ''
                        }
                    />
                    <DispatchDriver
                        assignDriver={handleChangeAssignDriver}
                        initValue={tripDetail.data.list_arrived}
                    />
                        
                    <div className='customer-payment'>
                        <div className='group-button customer'>
                            <div className='title'>
                                <img src= 'assets/icons/customer.svg'/>
                                <span>Khách hàng</span>
                            </div>
                            <div className='btn-content'>
                                <button onClick={e => e.preventDefault()}>Cá nhân</button>
                                <button onClick={e => e.preventDefault()}>Công ty</button>
                            </div>
                        </div>
                        <div className='group-button payment'>
                            <div className='title'>
                                <span>Thanh toán</span>
                            </div>
                            <div className='btn-content'>
                                <button onClick={e => e.preventDefault()}>Tiền mặt</button>
                                <button onClick={e => e.preventDefault()}>Thẻ</button>
                            </div>
                        </div>
                    </div>
                    <div className='coupon'>
                        <div className='title'>
                            <img src='assets/icons/coupon.svg'/>
                            <span>Tip-Khuyến mại</span>
                        </div>
                        <div className='content'>
                            <div className='left-content'>
                                <input placeholder='Số lượng'/>
                                <span>%</span>
                            </div>
                            <div className='right-content'>
                                <input placeholder='Mã khuyến mại'/>
                                <span>Áp dụng</span>
                            </div>
                        </div>
                    </div>

                    <div className='operator-bookingfrom'>
                        <div className='operator'>
                            <span>Người điều:&nbsp;</span>
                            <span>adminemddi</span>
                        </div>
                        <div className='bookingfrom'>
                            <span>Nguồn:&nbsp;</span>
                            <span>App khách hàng</span>
                        </div>
                    </div>
                </div>
            </form>
            <div className="ActionBtns light-mode">
                <img
                    src='assets/icons/button_cancel.svg'
                    className={classNames('btn-cancel', {
                    })}
                    onClick={_handleCreateTrip}
                    title='Hủy chuyến'
                />
                <img
                    src='assets/icons/button_add.svg'
                    className={classNames('btn-cancel', {
                    })}
                    onClick={_handleCreateTrip}
                    title='Tạo chuyến mới'
                />
                <img
                    src='assets/icons/button_save.svg'
                    className={classNames('btn-save', {
                    })}
                    onClick={_handleCreateTrip}
                    title='Cập nhật'
                />
                <button
                    className="btn-update-and-dispatch"
                    onClick={_handleUpdateTripAndDispatch}
                >
                    {t(tripDetailLocaleKeys.btnUpdateAndDispatch)}
                </button>
            </div>
            {/* </Scrollbars> */}
            <FormDialog
                dialogTitle="Hủy chuyến"
                dialogContentText="Bạn có chắc chắn muốn hủy chuyến không ?"
                openDialog={openDialog && showDialogCancel}
                handleCloseDialog={_onCloseDialog}
                handleClickButtonYesDialog={_onClickButtonYesDialog}
                getReasonCancel={_getReasonCancel}
                onKeyDown={_onKeyDownInputReasonCancel}
            />
        </div>
    )
}

export default BookingDetail
