import ViewActionTypes from '../constants/ViewActionTypes'

const toggleFullListTrips = targertState => {
    return {
        type: ViewActionTypes.TOGGLE_FULL_LIST_TRIPS,
        payload: {
            fullListTripsOpenState: targertState,
        },
    }
}

const toggleCardTableGroup = targertState => {
    return {
        type: ViewActionTypes.TOGGLE_CARD_TABLE_GROUP,
        payload: {
            cardTableGroupOpenState: targertState,
        },
    }
}

const SidebarActions = {
    toggleFullListTrips,
    toggleCardTableGroup,
}

export default SidebarActions
