const addAllTrip = (state, payload) => {
    const { trips } = payload
    return {
        ...state,
        trips,
    }
}

const tripsReducers = { addAllTrip }

export default tripsReducers
