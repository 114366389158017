import { createStore, createHook } from 'react-sweet-state'
import fetcher from 'services/fetcher'
import { bookingApiPrefix } from 'api'
import moment from 'moment'
import { tripStatusName } from 'utils/trips'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

import { estimateTripApi } from 'api/trip'

import { rebookingTypes, editBookingTypes } from 'app/bookings/constant'

import updateTypeCoordinatorApi from 'api/trip/update/updateTypeCoordinatorApi'

// init state
const initialState = {
    trips: [],
    tripsFinished: [],
    filterForm: {},
    filterFormFinished: {},

    users: [],
    services: [],
    reloadData: false,

    switchboardTrips: [],
    filterFormSwitchboard: [],

    socket: null,
    dataBookingReservation: null,
    newTrips: []
}

const TIME_INTERVAL_BOOKING = 15

var intervalReservation = []

const clearIntervalReservation = id => {
    let index = intervalReservation.findIndex(item => item.id == id)
    if (index >= 0) {
        clearInterval(intervalReservation[index].interval)
        intervalReservation.splice(index, 1)
        localStorage.setItem(
            'intervalReservation',
            JSON.stringify(intervalReservation)
        )
    }
}

const updateInterval = (socket, record) => {
    clearIntervalReservation(record.id)
    setIntervalReservation(socket, record)
}

var socketGlobal = null

const updateTypeCoordinatorAndRebooking = async (socket, record) => {
    let dataUpdate = {
        id: record.id,
        user_id: record.user_id,
        status: 0,
        type_update: rebookingTypes.REBOOKING_RESERVATION,
    }
    const resUpdate = await updateTypeCoordinatorApi(dataUpdate)
    if (resUpdate.success == true) {
        let dataEditBooking = {
            editbooking_type: editBookingTypes.REBOOKING_RESERVATION,
            coordinator_id: record.id,
        }

        socket.emit('edit-booking', dataEditBooking, function () {})

        setTimeout(() => {
            rebooking(socket, record)
        }, 1000)
    }
}

const setIntervalReservation = (socket, record) => {
    let timeDistance = Math.floor(
        (moment(record.created_at).valueOf() - moment(new Date()).valueOf()) /
            60000
    )
    if (timeDistance >= TIME_INTERVAL_BOOKING) {
        let count = 0
        let alarmBookingInterval = window.setInterval(() => {
            if (count < timeDistance - TIME_INTERVAL_BOOKING) {
                count++
            }
            if (count == timeDistance - TIME_INTERVAL_BOOKING) {
                updateTypeCoordinatorAndRebooking(socket, record)
                clearIntervalReservation(record.id)
            }
        }, 60000)

        let itemInterval = {
            id: record.id,
            data: record,
            interval: alarmBookingInterval,
        }
        intervalReservation.push(itemInterval)
        localStorage.setItem(
            'intervalReservation',
            JSON.stringify(intervalReservation)
        )
    }
}

const rebooking = async (socket, record) => {
    //Function rebooking reservation
    let arrCoor = record.start_point.split(' ')
    let lat = arrCoor[0]
    let lng = arrCoor[1]
    let dataBooking = {
        type: 1000,
        coordinator_id: record.id,
        service_id: record.service_id,
        user_id: record.user_id,
        customer_id: record.customer_id,
        start_address: record.start_address,
        start_point: lat + ' ' + lng,
        waypoints: [
            {
                x: lat,
                y: lng,
                place_id: '',
                geocode: record.start_address,
            },
        ],
        note: record.note,
        driver_id: record.list_arrived ? record.list_arrived : record.list_vh,
    }
    if (record.end_point != null && record.end_point != '') {
        let arrTemp = record.end_point.split(' ')
        let lat = arrTemp[0]
        let lgn = arrTemp[1]
        dataBooking.waypoints[1] = {
            x: lat,
            y: lng,
            place_id: '',
            geocode: record.end_address,
        }

        //Estimated
        let w1 = {
            lat: dataBooking.waypoints[0].x,
            lng: dataBooking.waypoints[0].y,
        }
        let w2 = {
            lat: dataBooking.waypoints[1].x,
            lng: dataBooking.waypoints[1].y,
        }
        const estimateData = {
            service: record.service_id,
            waypoints: [w1, w2],
        }
        const estimatedResponse = await estimateTripApi(estimateData)
        if (estimatedResponse) {
            dataBooking = {
                ...dataBooking,
                distance: estimatedResponse.distance,
                total_price: estimatedResponse.price,
                estimated_time: estimatedResponse.duration,
            }
        }
    }

    socket.emit('booking', dataBooking, function () {})
}

// define the actions that mutate the state
const actions = {
    getTrips:
        filterForm =>
        async ({ getState, setState }) => {
            try {
                const response = await fetcher.get(`${bookingApiPrefix}/trip`, {
                    params: {
                        switchboard: getLocalStorage(localStorageKeys.user)
                            .user_service.switchboard,
                        page: 0,
                        limit: 20,
                        status: filterForm.status,
                        fromDate: filterForm.fromDate
                            ? filterForm.fromDate
                            : null,
                        toDate: filterForm.toDate ? filterForm.toDate : null,
                        types: filterForm.types ? filterForm.types : null,
                        users: filterForm.users ? filterForm.users : null,
                        services: filterForm.services
                            ? filterForm.services
                            : null,
                        startDate: filterForm.startDate
                            ? filterForm.startDate
                            : null,
                        endDate: filterForm.endDate ? filterForm.endDate : null,
                        searching: filterForm.searching
                            ? filterForm.searching
                            : null,
                        typeSearching: filterForm.typeSearching
                            ? filterForm.typeSearching
                            : null,
                    },
                })

                let listTrips = response.data.data.trips

                // console.log('list active trips: ')
                // console.log(listTrips)

                let arrTemp = listTrips.map(trip => {
                    if (
                        trip.customer_phone &&
                        trip.customer_phone.indexOf('+84') == 0
                    )
                        trip.customer_phone =
                            '0' + trip.customer_phone.substring(3)

                    trip.created_at_original = trip.created_at //Using time created_at_original not yet convert to processing quickly
                    trip.created_at = moment(
                        trip.created_at.toLocaleString()
                    ).format('HH:mm:ss DD-MM-yyyy')

                    trip.service_render = trip.service_id
                    if (trip.waypoints) {
                        trip.waypoints = JSON.parse(trip.waypoints)
                        if (trip.waypoints.length == 2) {
                            trip.end_point =
                                trip.waypoints[1].x + ' ' + trip.waypoints[1].y
                            trip.end_address = trip.waypoints[1].geocode
                        }
                    }

                    if (trip.driver_phone) {
                        trip.driver_phone = '0' + trip.driver_phone.substring(3)
                        trip.driver_phone_render =
                            trip.driver_name + '-' + trip.driver_phone
                    }

                    if (trip.vh_code != null && trip.vh_code !== '') {
                        trip.service_render =
                            trip.service_id +
                            ';' +
                            trip.vh_code +
                            ';' +
                            trip.license_plate
                    } else {
                        if (trip.license_plate) {
                            trip.service_render =
                                trip.service_id +
                                ';' +
                                '' +
                                ';' +
                                trip.license_plate
                        }
                    }

                    return trip
                })

                setState({
                    trips: arrTemp,
                    filterForm: filterForm,
                })
            } catch (error) {
                setState({
                    trips: [],
                    filterForm: {},
                })
            }
        },

    getTripsFinished:
        (filterFormFinished, flagLoadMoreData) =>
        async ({ getState, setState }) => {
            try {
                const response = await fetcher.get(
                    `${bookingApiPrefix}/trips-finished`,
                    {
                        params: {
                            switchboard: getLocalStorage(localStorageKeys.user)
                                .user_service.switchboard,
                            start: filterFormFinished.start,
                            limit: filterFormFinished.limit,
                            status: filterFormFinished.status,
                            fromDate: filterFormFinished.fromDate
                                ? filterFormFinished.fromDate
                                : null,
                            toDate: filterFormFinished.toDate
                                ? filterFormFinished.toDate
                                : null,
                            types: filterFormFinished.types
                                ? filterFormFinished.types
                                : null,
                            users: filterFormFinished.users
                                ? filterFormFinished.users
                                : null,
                            services: filterFormFinished.services
                                ? filterFormFinished.services
                                : null,
                            startDate: filterFormFinished.startDate
                                ? filterFormFinished.startDate
                                : null,
                            endDate: filterFormFinished.endDate
                                ? filterFormFinished.endDate
                                : null,
                            searching: filterFormFinished.searching
                                ? filterFormFinished.searching
                                : null,
                            typeSearching: filterFormFinished.typeSearching
                                ? filterFormFinished.typeSearching
                                : null,
                        },
                    }
                )

                let listTrips = response.data.data.trips

                let arrTemp = listTrips.map(trip => {
                    if (
                        trip.customer_phone &&
                        trip.customer_phone.indexOf('+84') == 0
                    )
                        trip.customer_phone =
                            '0' + trip.customer_phone.substring(3)

                    trip.created_at = moment(
                        trip.created_at.toLocaleString()
                    ).format('HH:mm:ss DD-MM-yyyy')

                    trip.service_render = trip.service_id
                    
                    if (trip.driver_phone) {
                        trip.driver_phone = '0' + trip.driver_phone.substring(3)
                        trip.driver_phone_render =
                            trip.driver_name + '-' + trip.driver_phone
                    }

                    if (trip.vh_code != null && trip.vh_code !== '') {
                        trip.service_render =
                            trip.service_id +
                            ';' +
                            trip.vh_code +
                            ';' +
                            trip.license_plate
                    } else {
                        if (trip.license_plate) {
                            trip.service_render =
                                trip.service_id +
                                ';' +
                                '' +
                                ';' +
                                trip.license_plate
                        }
                    }
                    return trip
                })

                if (flagLoadMoreData) {
                    setState({
                        filterFormFinished: filterFormFinished,
                    })
                } else {
                    setState({
                        tripsFinished: arrTemp,
                        filterFormFinished: filterFormFinished,
                    })
                }
                return arrTemp
            } catch (error) {
                setState({
                    tripsFinished: [],
                    filterFormFinished: {},
                })
                return []
            }
        },

    setFilterForm:
        filterForm =>
        async ({ getState, setState }) => {
            setState({ filterForm: filterForm })
        },

    setFilterFormFinished:
        filterForm =>
        async ({ getState, setState }) => {
            setState({ filterFormFinished: filterForm })
        },

    setTrips:
        listTrips =>
        async ({ getState, setState }) => {
            setState({ trips: listTrips })
        },

    setTripsFinished:
        listTrips =>
        async ({ getState, setState }) => {
            setState({ tripsFinished: listTrips })
        },

    removeTrip:
        coordId =>
        async ({ getState, setState }) => {
            setState({
                trips: getState().trips.filter(item => item.id != coordId),
            })
        },

    addTrips:
        trip =>
        async ({ getState, setState }) => {
            let arrTripsTemp = getState().trips
            arrTripsTemp.unshift(trip)
            setState({ trips: [...arrTripsTemp] })
        },

    addMultipleTrips:
        trips =>
        async ({ getState, setState }) => {
            setState({ trips: [...trips, ...getState().trips] })
        },

    setSocket:
        socket =>
        async ({ getState, setState }) => {
            setState({ socket: socket })
            socketGlobal = socket
        },
    createIntervalReservation:
        record =>
        async ({ getState, setState }) => {
            setIntervalReservation(getState().socket, record)
        },

    updateIntervalReservation:
        record =>
        async ({ getState, setState }) => {
            updateInterval(getState().socket, record)
        },

    updateTrips:
        data =>
        async ({ getState, setState }) => {
            switch (data.updateType) {
                case 'booking':
                    setState({
                        trips: getState().trips.map(trip =>
                            trip.id == data.coordinator_id
                                ? {
                                        ...trip,
                                        status: data.status,
                                        status_booking: data.status_booking,
                                        driver_id: data.driver
                                            ? data.driver.id
                                            : null,
                                        driver_name: data.driver ? data.driver.full_name : null,
                                        vh_code: data.driver ? data.vh_code : null,
                                        license_plate: data.driver ? data.driver.license_plate: null
                                  }
                                : trip
                        ),
                    })
                    break
                case 'start-ji':
                    setState({
                        trips: getState().trips.map(trip =>
                            trip.id == data.coordinator_id
                                ? {
                                      ...trip,
                                      status_booking: 1,
                                      booking_id: data.booking_id,
                                  }
                                : trip
                        ),
                    })
                    break
                case 'stop-journey':
                    setState({
                        trips: getState().trips.map(trip =>
                            trip.id == data.coordinator_id
                                ? { ...trip, status_booking: 2 }
                                : trip
                        ),
                    })
                    break
                case 'cancel-trip':
                    if (data.status == -3) {
                        setState({
                            trips: getState().trips.filter(trip => trip.id != data.id),
                        })

                        let arrTemp = getState().tripsFinished
                        arrTemp.unshift({ ...data })
                        setState({
                            tripsFinished: [...arrTemp]
                        })
                    }else {
                        setState({
                            trips: getState().trips.map(trip =>
                                trip.id == data.coordinator_id
                                    ? {
                                        ...trip,
                                        status: data.status,
                                        status_booking: data.status_booking,
                                        reason_cancel: data.reason_cancel,
                                        booking_id: data.booking_id,
                                    }
                                    : trip
                            ),
                        })
                    }
                    break
                case 'SUCCESS':
                    setState({
                        trips: getState().trips.map(trip =>
                            trip.booking_id == data.booking_id
                                ? {
                                      ...trip,
                                      status: data.status,
                                      status_booking: data.status_booking,
                                  }
                                : trip
                        ),
                    })
                    break
            }
        },

    updateFinishedTrips:
        data =>
        async ({ getState, setState }) => {
            let arrTripsTemp = getState().tripsFinished
            arrTripsTemp.unshift(data)
            setState({
                tripsFinished: [...arrTripsTemp],
            })
        },

    setUsers:
        users =>
        async ({ getState, setState }) => {
            setState({ users: users })
        },

    setServices:
        services =>
        async ({ getState, setState }) => {
            setState({ services: services })
        },

    addSwitchboardTrip:
        record =>
        async ({ getState, setState }) => {
            let arrTemp = getState().switchboardTrips
            arrTemp.unshift(record)
            setState({
                switchboardTrips: [...arrTemp],
            })
        },

    getTripsSwitchboard:
        filterFormSwitchboard =>
        async ({ getState, setState }) => {
            try {
                const response = await fetcher.get(
                    `${bookingApiPrefix}/trips-switchboard`,
                    {
                        params: {
                            switchboard: getLocalStorage(localStorageKeys.user).user_service.switchboard
                        },
                    }
                )

                let { trips } = response.data.data

                let arrTemp = trips.map(trip => {
                    if (
                        trip.customer_phone &&
                        trip.customer_phone.indexOf('+84') == 0
                    )
                        trip.customer_phone =
                            '0' + trip.customer_phone.substring(3)

                    trip.created_at = moment(
                        trip.created_at.toLocaleString()
                    ).format('HH:mm:ss DD-MM-yyyy')

                    trip.service_render = trip.service_id

                    return trip
                })

                setState({
                    switchboardTrips: [...arrTemp],
                    filterFormSwitchboard: filterFormSwitchboard,
                })
            } catch (error) {}
        },
    updateNewTripsCreated: newTrips => {
        return ({ getState, setState }) => {
            setState({
                newTrips: [...newTrips]
            })
        }
    }
}

// create store
const TripsStore = createStore({ initialState, actions })

// create component access data from store
const useTrips = createHook(TripsStore)

window.onload = () => {
    setTimeout(() => {
        if (localStorage.getItem('intervalReservation')) {
            let arrIntervalTemp = JSON.parse(
                localStorage.getItem('intervalReservation')
            )
            if (arrIntervalTemp.length > 0) {
                arrIntervalTemp.forEach(function (item) {
                    setIntervalReservation(socketGlobal, item.data)
                })
            }
        }
    }, 2000)
}

export default useTrips
