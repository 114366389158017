import PropTypes from 'prop-types'

const propTypes = {
    name: PropTypes.string.isRequired,
}

export const EmddiMaterialIcon = props => {
    const { name = 'MdOutlineCheckBoxOutlineBlank', ...otherOpts } = props

    const Icon = require('react-icons/md')[name]

    return <Icon {...otherOpts} />
}

export const EmddiHeroIcon = props => {
    const { name = 'HiMinusSm', ...otherOpts } = props

    const Icon = require('react-icons/hi')[name]

    return <Icon {...otherOpts} />
}

export const EmddiFaIcon = props => {
    const { name = 'FaRegWindowMinimize', ...otherOpts } = props

    const Icon = require('react-icons/fa')[name]

    return <Icon {...otherOpts} />
}

EmddiFaIcon.propTypes = propTypes
EmddiHeroIcon.propTypes = propTypes
EmddiMaterialIcon.propTypes = propTypes
