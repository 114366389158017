import React, {useEffect, useState, memo} from 'react';
import {useSnackbar} from "notistack";
import classNames from "classnames/bind";
import styles from "../scss/_ListAccount.scss";

const cx = classNames.bind(styles)

const ListBox = ({data, onReload, onClick}) => {
    const itemsPerPage = 30
    const [currentPage, setCurrentPage] = useState(1)
    const [reload, setReload] = useState(1)
    const {enqueueSnackbar} = useSnackbar()
    const [loading, setLoading] = useState(false)


    const totalPages = Math.ceil(data.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = data.slice(startIndex, endIndex)

    useEffect(() => {
        setLoading(false);
    }, [data])

    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const _handleBackBtn = () => {
        setCurrentPage(1)
    }
    const _handleNextBtn = () => {
            setCurrentPage(totalPages)
    }


    const _handleReload = () => {
        setLoading(true);
        onReload(reload + 1)
        setLoading(true);
    };
    const _handleCellClick = (item) => {
        onClick(item)
    };


    return (
        <div className={cx('list-box')}>
            <table className={cx('list-box-table')}>
                <thead>
                <tr>
                    <th>STT</th>
                    <th>Tên Đăng Nhập</th>
                    <th>Họ Tên</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(currentData).map((key, rowIndex) => {
                    const userItem = currentData[key];
                    return (
                        <tr key={rowIndex} onClick={() => _handleCellClick(userItem)}>
                            <td>{ rowIndex + itemsPerPage * (currentPage - 1) + 1}</td>
                            <td className={cx('row-shortcut')}>{userItem.user_name}</td>
                            <td>{userItem.full_name}</td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <div className={cx('pagination')}>
                <button className={cx('paginate-btn')} onClick={_handleBackBtn}>
                    &laquo;
                </button>
                {currentPage > 4 && <span>...</span>}
                {Array.from({length: totalPages}, (_, index) => {
                    if (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2) {
                        return (
                            <button
                                className={index + 1 === currentPage ? 'active' : ''}
                                key={index}
                                onClick={() => _handlePageChange(index + 1)}
                            >
                                {    index + 1 }
                            </button>
                        );
                    }
                    return null;
                })}
                {currentPage < totalPages - 3 && <span className={cx('space-more')}> ... </span>}
                {currentPage < totalPages - 2 && (
                    <button className={cx('paginate-btn')} onClick={() => _handlePageChange(totalPages)}>
                        { totalPages}
                    </button>
                )}
                <button className={cx('paginate-btn')} onClick={_handleNextBtn}>
                    &raquo;
                </button>
                <button className={cx('reload-icon')}>
                    <img src='assets/icons/undo.png' alt="reload" onClick={_handleReload}/>
                </button>
            </div>
        </div>
    );
};

export default memo(ListBox);

