import LoginContainer from 'app/login-container/components/LoginContainer'
import UserContainer from 'app/user-container/components/UserContainer'
import setUser from 'app/_shared/main-app-context/main-app-actions/setUser'
import {
    MainAppContextProvider,
    useMainAppContext,
} from 'app/_shared/main-app-context/MainAppContext'
import { useEffect, useState, Suspense } from 'react'
import { getLocalStorage, localStorageKeys } from 'services/localStorage'
import '../scss/App.scss'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'

//import ViewContext from 'app/_shared/view-context'

const App = () => {
    const [{ user }, dispatch] = useMainAppContext()

    const [_loading, _setLoading] = useState(true)

    useEffect(() => {
        const getUser = () => {
            const foundUser = getLocalStorage(localStorageKeys.user)

            _setLoading(false)
            dispatch(setUser(foundUser))
        }

        getUser()
    }, [dispatch])

    if (_loading)
        return (
            <div className="w-full flex justify-center items-center pt-40">
                <div className="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )

    if (!user?.user_id)
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <Route path="/login" component={LoginContainer} />
                        <Redirect from="/" to="/login" />
                    </Switch>
                </Router>
            </div>
        )

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/" component={UserContainer} />
                </Switch>
            </Router>
        </div>
    )
}

const AppContainer = props => (
    <MainAppContextProvider>
        <Suspense fallback={<div>Loading... </div>}>
        <App {...props} />
        </Suspense>
    </MainAppContextProvider>
)

export default AppContainer
