import { createContext, useContext, useReducer, useState } from 'react'
import ViewActionTypes from './constants/ViewActionTypes'

import ViewReducers from './reducers/index'

import ViewInitState from './ViewInitState'

const ViewContext = createContext()

const reducer = (state, action) => {
    const { type, payload } = action

    if (type === ViewActionTypes.SET_USER_SETTINGS)
        return ViewReducers.setUserSettings(state, action)

    switch (type) {
        case ViewActionTypes.TOGGLE_FULL_LIST_TRIPS:
            return {
                ...state,
                ...ViewReducers.toggleFullListTrips(state, payload),
            }
        case ViewActionTypes.SWITCH_POPUP:
            return {
                ...state,
                ...ViewReducers.switchPopup(state, payload),
            }
        case ViewActionTypes.TOGGLE_CARD_TABLE_GROUP:
            return {
                ...ViewReducers.toggleCardTableGroup(state, payload),
            }
        case ViewActionTypes.SEND_PHONE_NUMBER:
            return {
                ...state,
                ...ViewReducers.sendPhoneNumber(state, payload),
            }
        case ViewActionTypes.SET_FILTER_FORM:
            return {
                ...state,
                ...ViewReducers.setFilterForm(state, payload),
            }
        case ViewActionTypes.SEND_FINISHED_TRIP:
            return {
                ...state,
                ...ViewReducers.sendFinishedTrip(state, payload),
            }
        case ViewActionTypes.UPDATE_TRIP_INFO:
            return {
                ...state,
                ...ViewReducers.updateTripInfo(state, payload),
            }
        default:
            return state
    }
}

export const ViewContextProvider = props => {

    const [state, dispatch] = useReducer(reducer, ViewInitState)

    return (
        <ViewContext.Provider value={[state, dispatch]}>
            {props.children}
        </ViewContext.Provider>
    )
}

export const useViewContext = () => useContext(ViewContext)

export const useViewDispatch = () => {
    const d = useContext(ViewContext)

    return d[1]
}

