import classNames from 'classnames'
import { useTrans } from 'helpers/useTranslation'
import { useState, useEffect } from 'react'
import bookingsKeys from '../i18n/bookingsKeys'

import ViewContext from 'app/_shared/view-context'

import FilterBookingActive from './FilterBookingActive'

import FilterBookingFinished from './FilterBookingFinished'

import FilterBookingSwitchboard from './FilterBookingSwitchboard'

import GridBookingActive from './GridBookingActive'

import GridBookingFinished from './GridBookingFinished'

import GridBookingSwitchboard from './GridBookingSwitchboard'

import Tabs from './Tabs'
import CustomButton from 'app/_shared/components/CustomButton'

import useTrips from 'app/_shared/trips-context'

import { useRecoilState } from 'recoil'
import { isSidebarOpenState } from 'app/_shared/recoil/sidebar/atom'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'

const { useViewContext } = ViewContext

const tabs = ['active', 'finished']

const Bookings = () => {
    const t = useTrans()

    const [_activeTab, _setActiveTab] = useState(tabs[0])

    const [{ fullListTripsOpen, usingSwitchboard }] = useViewContext()

    const [flagShowingTrips, setFlagShowingTrips] = useState(fullListTripsOpen)

    const [state, actions] = useTrips()

    const [isNewBookingOpen, setIsNewBookingOpen] = useRecoilState(
        isNewBookingOpenState
    )

    useEffect(() => {
        setFlagShowingTrips(fullListTripsOpen)
    }, [fullListTripsOpen])

    useEffect(() => {
        if (usingSwitchboard == true) setFlagShowingTrips(true)
    }, [])

    const _handleOpenFormBooking = () => {
        setIsNewBookingOpen(true)
    }

    return (
        <div className={!flagShowingTrips ? 'BookingsHidden' : 'Bookings'}>
            <div className='booking-header'>
                <h1>Danh sách chuyến</h1>
                {/* <span>+&nbsp;&nbsp;Thêm chuyến</span> */}
                <CustomButton 
                    text='+&nbsp;&nbsp;Thêm chuyến'
                    primary
                    onClick={_handleOpenFormBooking}
                />
            </div>
            
            <Tabs>
                <Tabs.TabPane
                    key="Tab-1"
                    tab={t(bookingsKeys.tabHeader.active) + '(' + state.trips.length + ')'}
                    //tab={t(bookingsKeys.tabHeader.active)}
                >
                    <FilterBookingActive />
                    <GridBookingActive />
                </Tabs.TabPane>
                <Tabs.TabPane
                    key="Tab-2"
                    tab={t(bookingsKeys.tabHeader.finished) + (state.tripsFinished.length > 0 ? '(' + state.tripsFinished.length + ')' : '')}
                    //tab={t(bookingsKeys.tabHeader.finished)}
                >
                    <FilterBookingFinished />
                    <GridBookingFinished />
                </Tabs.TabPane>
                {!usingSwitchboard && (
                    <Tabs.TabPane key="Tab-3" tab="Đàm">
                        <FilterBookingSwitchboard />
                        <GridBookingSwitchboard />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </div>
    )
}

export default Bookings
