const setTotalDrivers = (state, payload) => {
    const { totalDrivers } = payload
    return {
        ...state,
        previewTrips: {
            ...state.previewTrips,
            totalDrivers,
        },
    }
}

const toggleFilterTripCollapse = (state, payload) => {
    const { collapseState } = payload
    return {
        ...state,
        previewTrips: {
            ...state.previewTrips,
            filterTripCollapsed: collapseState,
        },
    }
}

const tripStatusFilter = {
    setTotalDrivers,
    toggleFilterTripCollapse,
}

export default tripStatusFilter
