import fetcher from "../../services/fetcher";
import {bookingApiPrefix} from "../index";
import httpStatus from "../httpStatus";

export const getUserByOfficeId = async (id) => {
    try {
        if (id) {
            const res = await fetcher.get(`${bookingApiPrefix}/user/office/get`, {
                params: { officeId: id }
            })

            if (res.data.success) {
                return res.data.data
            }
        }
    }
    catch (err) {
        console.log(err)
    }
}

export const updateUser = async data => {
    try {
        const res = await fetcher.post(
            `${bookingApiPrefix}/user/update`,
            {...data}
        )
        if (res) {
            if (res.status === httpStatus.OK) {
                if (res.data.success) {
                    return res.data.success
                }
            }
        }
        return false
    } catch (e) {
        console.log(e)

    }
}