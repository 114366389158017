import NewBookingActionTypes from '../actionTypes'
import { defaultHandlingTripInfo } from '../initialState'

const setHandlingTripInfoToDefault = () => {
    return {
        type: NewBookingActionTypes.setHandlingTripInfoToDefault,
        payload: defaultHandlingTripInfo,
    }
}

const setAddressField = (fieldName, value) => {
    return {
        type: NewBookingActionTypes.setAddressField,
        payload: { fieldName, value },
    }
}

const setEstimateData = ({ estimated, data }) => {
    return {
        type: NewBookingActionTypes.setEstimateData,
        payload: { estimated, estimatedData: data },
    }
}

const setFocusingEl = element => {
    return {
        type: NewBookingActionTypes.setFocusingEl,
        payload: element,
    }
}

const setOnlyOneCoor = state => {
    return {
        type: NewBookingActionTypes.setOnlyOneCoor,
        payload: state,
    }
}

const setPhoneNumber = phoneNumber => {
    return {
        type: NewBookingActionTypes.setPhoneNumber,
        payload: phoneNumber,
    }
}

const setServices = services => {
    return { type: NewBookingActionTypes.setServices, payload: services }
}

const setPickupTime = pickupTime => {
    return { type: NewBookingActionTypes.setPickupTime, payload: pickupTime }
}

const setNote = note => {
    return {
        type: NewBookingActionTypes.setNote,
        payload: note,
    }
}

const setDriverAssigned = driverId => {
    return {
        type: NewBookingActionTypes.setDriverAssigned,
        payload: driverId,
    }
}

const tripInfo = {
    setHandlingTripInfoToDefault,
    setAddressField,
    setEstimateData,
    setFocusingEl,
    setPhoneNumber,
    setOnlyOneCoor,
    setServices,
    setPickupTime,
    setNote,
    setDriverAssigned,
}

export default tripInfo
