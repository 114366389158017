import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatchMainApp } from 'app/_shared/main-app-context/MainAppContext'
import setUser from 'app/_shared/main-app-context/main-app-actions/setUser'
import { EmddiMaterialIcon } from 'app/_shared/EmddiIcon/components'
import UserContainerContext from 'app/_shared/user-container-context'

import ToggleSwitch from '../../_shared/components/ToggleSwitch'
import { useState } from 'react'

import ViewContext from 'app/_shared/view-context'

const { useUserContainerContext } = UserContainerContext

const { useViewContext, ViewActions } = ViewContext

const Nav = props => {
    const { i18n } = useTranslation()

    const mainAppDispatch = useDispatchMainApp()

    const _logOut = () => {
        return mainAppDispatch(setUser({}))
    }

    const _changeLang = lang => i18n.changeLanguage(lang)

    const sideBarOpen = true

    //const [checkedSwitchPopup, setCheckedSwitchPopup] = useState(false)

    const [{ checkedSwitchPopup }, dispatch] = useViewContext()

    const handleChangeSwitchPopup = checked => {
        //setCheckedSwitchPopup(checked)

        dispatch(ViewActions.switchPopup(checked))

        dispatch(ViewActions.toggleFullListTrips(true))

        dispatch(ViewActions.toggleCardTableGroup(true))
    }
    return (
        <div className="Nav shadow">
            <div
                className={classNames(
                    sideBarOpen ? 'w-1/6' : 'SideBarWidth',
                    'transition-all'
                )}
            ></div>
            <div
                className={classNames(
                    // sideBarOpen ? 'w-5/6' : 'AppWidth',
                    'flex justify-between items-center transition-al'
                )}
            >
                <Link to="/" className="LogoLink">
                    <img src="/favicon.ico" className="Logo" alt="logo" />
                    <div className="Heading">
                        <span className="font-bold">
                            HỆ THỐNG ĐẶT VÀ ĐIỀU VẬN XE
                        </span>
                        <span className="font-thin text-xs">
                            EMDDI JSC @ 2021
                        </span>
                    </div>
                </Link>
                <div>
                    {/* <ToggleSwitch
                        id="switch_popup"
                        checked={checkedSwitchPopup}
                        onChange={ handleChangeSwitchPopup }
                        disabled={false}
                        small={true}
                    />
                    <label htmlFor="switch_popup">Popup</label> */}
                </div>
                <div className="flex items-center Language">
                    <button
                        className={classNames('font-bold mx-2', {
                            Active: i18n.language === 'en',
                        })}
                        onClick={() => _changeLang('en')}
                    >
                        EN
                    </button>
                    <button
                        className={classNames('font-bold mx-2', {
                            Active: i18n.language === 'vi',
                        })}
                        onClick={() => _changeLang('vi')}
                    >
                        VI
                    </button>
                    <button className="font-bold ml-4 mr-2" onClick={_logOut}>
                        <EmddiMaterialIcon
                            name="MdLogout"
                            className="text-2xl"
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

Nav.propTypes = {}

export default Nav
