import { AgGridReact, AgGridColumn } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useEffect, useState, useRef } from 'react'
import iconRefresh from '../../../static/images/refresh.png'
import iconRemove from '../../../static/images/close.png'
import ViewContext from 'app/_shared/view-context'
import { bookingStatusColor, bookingStatusName } from 'utils/Booking'
import useTrips from 'app/_shared/trips-context'
import { updateCmdSbApi } from 'api/trip/update/updateTripSwitchboard'
import configBooking from '../ConfigBooking'
import { getLocalStorage, localStorageKeys } from 'services/localStorage'
import ModalConfirmCancel from "./ModalConfirmCancel";
import {useRecoilState} from "recoil";
import {isSidebarOpenState} from "../../_shared/recoil/sidebar/atom";

const { useViewContext, ViewActions, useViewDispatch } = ViewContext
const { user_id: userId } = getLocalStorage(localStorageKeys.user)

class RefreshAction {
    init(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-refresh'>
                <img src="${iconRefresh}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-refresh')
        this.eventClickListener = () => {
            alert(params.id)
            console.log(params)
        }
        this.eButton.addEventListener('click', this.eventClickListener)

        if (
            params.data.status == -1 ||
            params.data.status == -4 ||
            params.data.status == -2 ||
            (params.data.type == 1 && params.data.status == 0)
        ) {
            this.eButton.style.display = 'initial'
        } else {
            this.eButton.style.display = 'none'
        }
    }

    getGui() {
        return this.eGui
    }
}

const { unit_id: officeId } = getLocalStorage(localStorageKeys.user)

const GridBookingSwitchboard = props => {
    const [gridApi, setGridApi] = useState(null)
    const [gridColumnApi, setGridColumnApi] = useState(null)
    const [gridCellApi, setGridCellApi] = useState(null)
    const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(isSidebarOpenState)
    const [flagHiddenMenuAction, setFlagHiddenMenuAction] = useState(true)

    const [state, actions] = useTrips()

    const [switchboardTrips, setSwitchboardTrips] = useState([])

    useEffect(() => {
        const filter = state.switchboardTrips.filter( (data) => {
            return data.type = 1
        })
        setSwitchboardTrips(filter)
    }, [state.filterFormSwitchboard])

    const [flagHiddenModalConfirm, setFlagHiddenModalConfirm] = useState(true)
    const [dataAction, setDataAction] = useState({})

    const _hideModalConfirm = () => {
        setFlagHiddenModalConfirm(true)
    }

    const viewDispatch = useViewDispatch()

    const [{ socket }] = useViewContext()

    const onGridReady = params => {
        setGridApi(params.api)
        setGridColumnApi(params.columnApi)
        setGridCellApi(params.cellApi)

        //socket on events to update gird view
        socketOnEvents(params.api)
    }

    const socketOnEvents = agGridApi => {
        socket.on('edit-booking', function (data) {
            console.log('socket on the grid switchboard: ')
            console.log(data)
        })

        socket.on('check-sbg', function (data) {
            let record = {
                id: data.coordinator_id,
                customer_phone: data.customer_phone,
                line: data.line,
                start_address: data.start_address,
                start_point: data.start_point,
                number_vh: data.number_vh,
                created_at: data.call_time,
                user_id: data.user_id,
                user_name: data.user_name,
                type: data.type,
                customer_name: data.customer_name,
                service_id: data.service_id,
                status: data.status,
                status_booking: data.status_booking,
                count_call: data.count_call,
                check_sb: 0, // define coordinator has transfered switchboard
                note: data.noteReasonCancelled,
                area_code: data.area,
            }

            agGridApi.applyTransaction({
                add: [record],
                addIndex: 0,
            })
        })

        socket.on('cmd-coordinator', function (data) {
            let rowNode = agGridApi.getRowNode(data.coordinator_id)

            if (rowNode) {
                if (data.cmd_cd) rowNode.setDataValue('cmd_cd', data.cmd_cd)
                if (data.status) rowNode.setDataValue('status', data.status)
                if (data.note) rowNode.setDataValue('note', data.note)
            }
        })

        socket.on('update-cmd', function (data) {
            let rowNode = agGridApi.getRowNode(data.coordinator_id)

            if (rowNode) {
              if (data.cmd_sb) rowNode.setDataValue('cmd_sb', data.cmd_sb)
              if (data.list_arrived) rowNode.setDataValue('list_arrived', data.list_arrived)
              if (data.list_vh) rowNode.setDataValue('list_vh', data.list_vh)
              if (data.note) rowNode.setDataValue('note', data.note)
              if (data.status) rowNode.setDataValue('status', data.status)
            }

        //Event app customer
        return () => {
            // socket.removeAllListeners()
            // socket.close()
        }
        })
    }

    const onBtStopEditing = () => {
        gridApi.stopEditing()
    }

    const onBtStartEditing = (key, char, pinned) => {
        gridApi.setFocusedCell(1, 'lastName', pinned)
        gridApi.startEditingCell({
            rowIndex: 1,
            colKey: 'lastName',
            rowPinned: pinned,
            keyPress: key,
            charPress: char,
        })
    }

    const onBtNextCell = () => {
        gridApi.tabToNextCell()
    }

    const onBtPreviousCell = () => {
        gridApi.tabToPreviousCell()
    }

    const onBtWhich = () => {
        var cellDefs = gridApi.getEditingCells()
        if (cellDefs.length > 0) {
            var cellDef = cellDefs[0]
        }
    }

    const onCellKeyDown = e => {
        let colId = e.column.colId

        if (e.event.code === 'ArrowUp') {
            let rowTopIndex = e.node.rowIndex - 1

            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowTopIndex, colId)
                gridApi.startEditingCell({
                    rowIndex: rowTopIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            } else {
            }
        } else if (e.event.code === 'ArrowDown') {
            let rowDownIndex = e.node.rowIndex + 1
            if (gridApi.getEditingCells().length > 0) {
                gridApi.setFocusedCell(rowDownIndex, colId)

                gridApi.startEditingCell({
                    rowIndex: rowDownIndex,
                    colKey: colId,
                    keyPress: null,
                    charPress: null,
                })
            }
        } else if (e.event.code === 'ArrowRight') {
            // if (colId === 'start_address') {
            //     let rowIndex = e.node.rowIndex
            //     if (gridApi.getEditingCells().length > 0 ) {
            //         gridApi.setFocusedCell(rowIndex, 'note')

            //         gridApi.startEditingCell({
            //             rowIndex: rowIndex,
            //             colKey: 'note',
            //             keyPress: null,
            //             charPress: null
            //         })
            //     }
            // }else {
            //     onBtNextCell();
            // }

            onBtNextCell()
        } else if (e.event.code === 'ArrowLeft') {
            onBtPreviousCell()
        }
    }
    const updateCmdCd = (data) => {
        let dataUpdate = {
            id: data.id,
            cmd_cd: data.cmd_cd,
        }

        updateCmdSbApi(dataUpdate)
            .then(() => {
                const cd = {
                    coordinator_id: dataUpdate.id,
                    cmd_cd: dataUpdate?.cmd_cd,
                }
                socket.emit('cmd-coordinator', cd, function () {})
            })
            .catch(error => {
                console.log(error)
            })
    }

    const updateCmdSb = (column, data) => {
        let id = data.id
        let listVh = data.list_vh
        let vhCode = data.vh_code
        let cmdSb = data.cmd_sb
        let cmdCd = data.cmd_cd
        let listVhArr = data.list_arrived
        let status = data.status
        let note = data.note

        switch (column) {
            case 'list_vh':
                if (listVh != '' || listVh != null)
                    if (
                        listVh.toLowerCase() === 'huy' ||
                        listVh === 'hủy' ||
                        listVh.indexOf('huy') >= 0 ||
                        listVh.indexOf('hủy') >= 0
                    )
                        status = 5
                break
            case 'vh_code':
                if (vhCode != '' || vhCode != null)
                    if (
                        vhCode === 'huy' ||
                        vhCode === 'hủy' ||
                        vhCode.indexOf('huy') >= 0 ||
                        vhCode.indexOf('hủy') >= 0
                    )
                        status = 5
                    else status = 1
                if (vhCode === '0' || parseInt(vhCode) == 0) status = 5

                if (vhCode === '00') status = -1

                if (vhCode === '000') status = -1
                break
            case 'cmd_sb':
                if (cmdSb == 1 && vhCode != '' && vhCode != null) status = 1
                if (cmdSb == 3 || cmdSb == 33) status = -1
                break
            case 'list_arrived':
                break
        }
        let dataUpdate = {
            id: id,
            cmd_sb: cmdSb,
            cmd_cd: cmdCd,
            list_vh: listVh,
            vh_code: vhCode,
            list_arrived: listVhArr,
            status: status,
            note: note,
            deleted:
                status == 1 &&
                configBooking.outCoorSwitchboard.officeIds.indexOf(officeId) > -1
                    ? 1
                    : 0,
        }

        updateCmdSbApi(dataUpdate)
            .then(() => {
                const sb = {
                    coordinator_id: dataUpdate.id,
                    cmd_sb: dataUpdate?.cmd_sb,
                    list_vh: dataUpdate?.list_vh,
                    vh_code: dataUpdate?.vh_code,
                    list_arrived: dataUpdate?.list_arrived,
                    status: dataUpdate?.status,
                    note: dataUpdate?.note,
                    deleted: dataUpdate?.deleted,
                }
                socket.emit('update-cmd', sb, function () {})
            })
            .catch(error => {
                console.log(error)
            })
    }

    const onCellKeyPress = e => {
        var keyCode = e.event.code
        let colId = e.column.colId

        if (keyCode === 'Enter') {
            var rowNode = e.node
            colId == 'cmd_cd' ? updateCmdCd(rowNode.data) :  updateCmdSb(colId, rowNode.data)
        }
    }
    const cellEditingStarted = e => {}

    const colDefs = [
        {
            headerName: '',
        },
    ]

    function renderCmdSb(params) {
        let string = ''
        switch (params.value) {
            case '1':
                // statements_1
                string = '<span style = "color:black"><b>Mời khách</b></span>'
                break
            case '2':
                // statements_1
                string =
                    '<span style = "color:#DD5145"><b>Không xe lần 1</b></span>'
                break
            case '22':
                // statements_1
                string =
                    '<span style = "color:#DD5145"><b>XL khách lần 1</b></span>'
                break
            case '3':
                // statements_1
                string = '<span style = "color:red"><b>Không xe</b></span>'
                break
            case '33':
                // statements_1
                string = '<span style = "color:red"><b>XL khách</b></span>'
                break
            case '4':
                // statements_1
                string = '<span style = "color:blue"><b>Hỏi lại ĐC</b></span>'
                break
            case '44':
                // statements_1
                string = '<span style = "color:blue"><b>Kiểm tra</b></span>'
                break
            case '5':
                // statements_1
                string = '<span style = "color:green"><b>Giữ khách</b></span>'
                break
            case '6':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Kiểm tra khách</b></span>'
                break
            case 'g':
                // statements_1
                string =
                    '<span style = "color:green"><b>Xe gặp khách</b></span>'
                break
            case 'd':
                // statements_1
                string = '<span style = "color:"><b>Xe đến điểm</b></span>'
                break
            case 'n':
                // statements_1
                string = '<span style = "color:blue"><b>Xe nhận</b></span>'
                break
            case 'l':
                // statements_1
                string = '<span style = "color:blue"><b>Gọi lại</b></span>'
                break
            case 't':
                // statements_1
                string = '<span style = "color:blue"><b>Cuốc trượt</b></span>'
                break
            case 'h':
                // statements_1
                string = '<span style = "color:blue"><b>Cuốc hoãn</b></span>'
                break
            case 'o':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Cuốc không xe</b></span>'
                break
            case 'm':
                // statements_1
                string = '<span style = "color:blue"><b>Cuộc hỏi đàm</b></span>'
                break
            case 'x':
                // statements_1
                string = '<span style = "color:blue"><b>Xe đến</b></span>'
                break
            case 'c':
                // statements_1
                string = '<span style = "color:blue"><b>Gọi taxi</b></span>'
                break
            case '444':
                // statements_1
                string =
                    '<span style = "color:blue"><b>Kiểm tra lần 2</b></span>'
                break
            default:
                // statements_def
                //string = '<span>---</span>'
                return params.value
        }
        return string
    }

    function renderCmdCd(params) {
        let string = ''
        switch (params.value) {
            case '1':
                // statements_1
                string = '<span style = "color:black"><b>Đã mời</b></span>'
                break
            case '11':
                // statements_2
                string = '<span style = "color:red"><b>Đã mời lần 2</b></span>'
                break
            case '2':
                // statements_3
                string = '<span style = "color:green"><b>Gặp xe</b></span>'
                break
            case '3':
                // statements_4
                string = '<span style = "color:red"><b>Đã XL khách</b></span>'
                break
            case '4':
                // statements_5
                string = '<span style = "color:orange"><b>Máy bận</b></span>'
                break
            case '5':
                // statements_6
                string =
                    '<span style = "color:#DD5145"><b>Không LL được</b></span>'
                break
            case '55':
                // statements_6
                string = '<span style = "color:blue"><b>Đã giữ</b></span>'
                break
            case '6':
                // statements_7
                string =
                    '<span style = "color:orange"><b>Không nghe máy</b></span>'
                break
            case '66':
                // statements_8
                string = '<span style = "color:red"><b>K.Nghe lần 2</b></span>'
                break
            case '7':
                // statements_7
                string =
                    '<span style = "color:black"><b>Không nói gì</b></span>'
                break
            case '8':
                // statements_13
                string =
                    '<span style = "color:#FF5733"><b>Khách hoãn xe</b></span>'
                break
            case '9':
                // statements_13
                string = '<span style = "color:#FF5733"><b>Gọi hộ</b></span>'
                break
            case 't':
                // statements_9
                string =
                    '<span style = "color:#FF5733"><b>Trượt khách</b></span>'
                break
            case 'o':
                // statements_10
                string = '<span style = "color:red"><b>Không thấy xe</b></span>'
                break
            case 'l':
                // statements_11
                string =
                    '<span style = "color:green"><b>Khách gọi lại</b></span>'
                break
            case '0':
                // statements_12
                string = '<span style = "color:blue"><b>Nhắn chờ</b></span>'
                break
            case 'z':
                // statements_14
                string =
                    '<span style = "color:#FF5733"><b>Trượt khách</b></span>'
                break
            case 'u':
                // statements_15
                string =
                    '<span style = "color:#FF5733"><b>Khách từ chối</b></span>'
                break
            case 'm':
                // statements_15
                string = '<span style = "color:#FF5733"><b>Mất sóng</b></span>'
                break
            case 'd':
                // statements_15
                string =
                    '<span style = "color:green"><b>Đang gọi khách</b></span>'
                break
            case 'k':
                // statements_15
                string = '<span style = "color:green"><b>Khách đặt</b></span>'
                break
            default:
                // statements_def
                //string = '<span>---</span>'
                return params.value
                break
        }
        return string
    }

    const renderStatus = params => {
        // console.log('data status render: ')
        // console.log(params.data)
        let status = params.data.status

        let statusBooking = params.data.status_booking

        let cancel = params.data.cancel

        if (params.data.type == 10 || params.data.type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        return '<span style="color:#f5a623">Đang đi đón khách</span>'
                    } else if (cancel == 1) {
                        return '<span style="color:">Khách hàng hủy chuyến</span>'
                    } else if (cancel == 2) {
                        return '<span style="color:red" class="DriverCancelBlink">Lái xe hủy chuyến</span>'
                    } else if (cancel == 3) {
                        return '<span style="color:">Tổng đài hủy chuyến</span>'
                    } else {
                        return `<span style="color:${
                            bookingStatusColor[bookingStatusName.dispatching]
                        }">Đang tìm xe</span>`
                    }
                case 1:
                    return '<span style="color:#00759A">Đang chở khách</span>'
                case 2:
                    return '<span style="color:black">Hoàn thành</span>'
                case 3:
                    return '<span style="color:orange">Đã thấy xe</span>'
                case -1:
                    return '<span style="color:red">Chưa có xe nhận đón</span>'
                default:
                    return '<span>---</span>'
            }
        } else if (params.data.type == 0) {
            switch (status) {
                case 0:
                    return `<span style="color:${
                        bookingStatusColor[bookingStatusName.dispatching]
                    }">Đang tìm xe</span>`
                case 1:
                    if (statusBooking == 3) {
                        return '<span style="color:orange">Đã thấy xe<span>'
                    } else {
                        switch (statusBooking) {
                            case 0:
                                return '<span style="color:#f5a623">Đang đi đón khách<span>'
                            case 1:
                                return '<span style="color:#00759A">Đang chở khách</span>'
                            case 2:
                                return `<span style="color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                }">Hoàn thành</span>`
                            case -3:
                                return '<span style="color:">Tổng đài hủy chuyến</span>'
                            case -4:
                                return '<span style="color:red">Lái xe hủy chuyến</span>'
                            case -5:
                                return '<span style="color:">Khách hàng hủy chuyến</span>'
                            default:
                                return '<span>---</span>'
                        }
                    }
                case -1:
                    return '<span style="color:red">Chưa có xe nhận đón</span>'
                case -2:
                    return '<span style="color:red">Lỗi</span>'
                case -3:
                    return '<span style="color:">Tổng đài hủy chuyến</span>'
                case -4:
                    return '<span style="color:red">Lái xe hủy chuyến</span>'
                case -5:
                    return '<span style="color:red">Khách hàng hủy chuyến</span>'
                default:
                    return '<span>---</span>'
            }
        } else if (params.data.type == 1) {
            switch (status) {
                case 0:
                    return `<span style="color:${
                        bookingStatusColor[bookingStatusName.dispatching]
                    }">Đang tìm xe</span>`
                case 1:
                    return '<span style="color:blue">Hoàn thành</span>'
                case 3:
                    return '<span style="color:red">Tổng đài hủy chuyến</span>'
                case -1:
                    return '<span style="color:red">Không thấy xe</span>'
                case -2:
                    return '<span style="color:red">Lỗi</span>'
                case -3:
                    return '<span style="color:">Quản trị hủy</span>'
                case -4:
                    return '<span style="color:red">Tài xế hủy</span>'
                case -5:
                    return '<span style="color:red">Khách hủy</span>'
                case 5:
                    return '<span style="color:red">Đã x.lý, không điều xe</span>'
                case 6:
                    return '<span style="color:red">Khách hẹn</span>'
                default:
                    return '<span>---</span>'
            }
        }
    }

    const renderStartAddress = params => {
        // if (params.value != null && params.value != '') {
        //     if (params.data.end_address != null && params.data.end_address !== '') {
        //         return params.value + '.' + '</br>' + params.data.end_address
        //     }else return params.value
        // }else {
        //     return ''
        // }
        return params.value
    }

    const renderDriverPhone = params => {
        if (params.value && params.value !== null && params.value !== '') {
            let arrTemp = params.value.split('-')
            if (arrTemp.length == 2) {
                return arrTemp[0] + '</br>' + arrTemp[1]
            }
        }
        return ''
    }

    const renderCreatedAt = params => {
        // if (params.value.indexOf(' ') > -1) {
        //     let arrTemp = params.value.split(' ')
        //     return arrTemp[0] + '</br>' + arrTemp[1]
        // }else {
        //     return params.value
        // }
        return params.value
    }

    const renderOperator = params => {
        let operator = state.users.filter(user => user.id == params.value)
        if (operator.length == 1) return operator[0].name
        else return ''
    }

    const renderService = params => {
        // console.log('render service: ')
        // console.log(params.data)
        // console.log(params.data.service_render)

        if (params.value != null && params.value !== '') {
            let serviceRender = ''
            if (params.value.toString().indexOf(';') > -1) {
                let arrTemp = params.value.split(';')
                if (arrTemp.length == 3) {
                    let service = state.services.find(
                        sr => sr.id == parseInt(arrTemp[0].trim())
                    )
                    if (service)
                        serviceRender =
                            service.name +
                            ' / </br>' +
                            arrTemp[1] +
                            ' (' +
                            arrTemp[2] +
                            ')'
                }
            } else {
                let service = state.services.filter(
                    service => service.id == params.value
                )
                if (service.length == 1) serviceRender = service[0].name
                return serviceRender
            }
            return serviceRender
        }
    }

    const renderIconStatus = params => {
        let status = params.value
        let statusBooking = params.data.status_booking
        let type = params.data.type
        let cancel = params.data.cancel
        if (type == 0) {
            switch (status) {
                case 0:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.dispatching]
                    };border-radius:4px"></div>`
                case 1:
                    if (statusBooking == 3) {
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.DriverAccepted]
                        };border-radius:4px"></div>`
                    } else {
                        switch (statusBooking) {
                            case 0:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DriverOnTheWay
                                    ]
                                };border-radius:4px"></div>`
                            case 1:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.PassengerOnBoard
                                    ]
                                };border-radius:4px"></div>`
                            case 2:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.finished
                                    ]
                                };border-radius:4px"></div>`
                            case -3:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                };border-radius:4px"></div>`
                            case -4:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                };border-radius:4px"></div>`
                            case -5:
                                return `<div style="width:15px;height:15px;background-color:${
                                    bookingStatusColor[
                                        bookingStatusName.DroppedOff
                                    ]
                                };border-radius:4px"></div>`
                            default:
                                return `<div style="width:15px;height:15px;background-color:black;border-radius:4px"></div>`
                        }
                    }
                case -1:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.waitingForProcess]
                    };border-radius:4px"></div>`
                case -2:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.error]
                    };border-radius:4px"></div>`
                case -3:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -4:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case -5:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                default:
                    return `<div style="width:15px;height:15px;background-color:black;border-radius:4px"></div>`
            }
        } else if (type == 10 || type == 11) {
            switch (statusBooking) {
                case 0:
                    if (cancel == 0) {
                        //Đang đi đón khách
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.DriverOnTheWay]
                        };border-radius:4px"></div>`
                    } else if (cancel == 1) {
                        //QT Hủy;
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.AdCancel]
                        };border-radius:4px"></div>`
                    } else if (cancel == 2) {
                        //KH Hủy;
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.CustomerCancel]
                        };border-radius:4px"></div>`
                    } else if (cancel == 3) {
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.DriverCancel]
                        };border-radius:4px"></div>`
                        //TX Hủy;
                    } else {
                        //Đang tìm xe
                        return `<div style="width:15px;height:15px;background-color:${
                            bookingStatusColor[bookingStatusName.dispatching]
                        };border-radius:4px"></div>`
                    }
                    break
                case 1:
                    //Đang chở khách
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.PassengerOnBoard]
                    };border-radius:4px"></div>`
                case 2:
                    //Hoàn thành
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.DroppedOff]
                    };border-radius:4px"></div>`
                case 3:
                    //Đã thấy xe
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.FoundDriver]
                    };border-radius:4px"></div>`
                case -1:
                    //Không thấy xe
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.waitingForProcess]
                    };border-radius:4px"></div>`
                default:
                    return `<div style="width:15px;height:15px;background-color:${
                        bookingStatusColor[bookingStatusName.finished]
                    };border-radius:4px"></div>`
            }
        } else if (type == 1) {
        }
    }

    const renderBookingFrom = params => {
        switch (params.data.type) {
            case 10:
                return 'App KH'
            case 11:
                return 'Khách vẫy'
            default:
                return 'Tổng đài'
        }
    }

    function BtnAction () {}
    // render action
    BtnAction.prototype.init = function(params) {
        this.eGui = document.createElement('div')
        this.eGui.innerHTML = `
            <button class='btn-options'>
                <img src="${iconRemove}"/>
            </button>
        `
        this.eButton = this.eGui.querySelector('.btn-options')
        this.eventClickListener = (e) => {
            setFlagHiddenModalConfirm(false)
            setFlagHiddenMenuAction(true)

            setDataAction({
                ...params.data
            })
        }

        this.eButton.addEventListener('click', this.eventClickListener)
    }

    BtnAction.prototype.getGui = function() {
        return this.eGui
    }


    return (
        <div className="grid-wrapper">
            <div
                id="gridbooking_switchboard"
                style={{
                    height: '100%',
                    width: '100%',
                }}
                className="ag-theme-alpine"
            >
                <AgGridReact
                    rowData={switchboardTrips}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 30,
                        //editable: true,
                        resizable: true,
                        wrapText: false,
                        autoHeight: true,
                    }}
                    //pinnedTopRowData={getPinnedTopData()}
                    //pinnedBottomRowData={getPinnedBottomData()}
                    onGridReady={onGridReady}
                    onCellKeyDown={onCellKeyDown}
                    onCellKeyPress={onCellKeyPress}
                    cellEditingStarted={cellEditingStarted}
                    getRowNodeId={data => data.id}
                >
                    <AgGridColumn
                        headerName=""
                        field="status"
                        maxWidth={40}
                        minWidth={30}
                        cellRenderer={renderIconStatus}
                        cellStyle={() => {
                            return {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 'auto',
                                height: '100%',
                                marginRight: '20px',
                            }
                        }}
                        hide={true}
                    />
                    <AgGridColumn
                        headerName="Id"
                        field="id"
                        //maxWidth={90}
                        minWidth={85}
                    />
                    <AgGridColumn
                        headerName="Thời gian"
                        field="created_at"
                        minWidth={110}
                        cellRenderer={renderCreatedAt}
                    />
                    <AgGridColumn
                        headerName="Khách hàng"
                        field="customer_phone"
                        minWidth={120}
                    />
                    <AgGridColumn
                        headerName="Điểm đón"
                        field="start_address"
                        minWidth={200}
                        cellRenderer={renderStartAddress}
                        editable={true}
                    />
                    <AgGridColumn
                        headerName="Xe nh.điểm"
                        field="list_vh"
                        editable={true}
                        //cellRenderer={renderService}
                        minWidth={110}
                    />

                    <AgGridColumn
                        minWidth={100}
                        headerName="Xe đ.điểm"
                        field="list_arrived"
                        editable={true}
                        cellRenderer={params => {
                            return params.value
                        }}
                    />
                    <AgGridColumn
                        minWidth={80}
                        headerName="Xe đón"
                        field="vh_code"
                        editable={true}
                        cellRenderer={params => {
                            return params.value
                        }}
                    />
                    <AgGridColumn
                        minWidth={110}
                        headerName="L ĐHV"
                        field="cmd_sb"
                        editable={true}
                        cellRenderer={renderCmdSb}
                    />
                    <AgGridColumn
                        minWidth={110}
                        headerName="L ĐTV"
                        field="cmd_cd"
                        editable={true}
                        cellRenderer={renderCmdCd}
                        // cellRenderer={renderDriverPhone}
                    />
                    <AgGridColumn
                        minWidth={120}
                        headerName="Dịch vụ"
                        field="service_id"
                        editable={true}
                        cellRenderer={renderService}
                    />
                    <AgGridColumn
                        minWidth={130}
                        headerName="Trạng thái"
                        field="status"
                        cellRenderer={renderStatus}
                        cellStyle={{ whiteSpace: 'wrap', color: 'red' }}
                    />

                    <AgGridColumn
                        headerName="Th.lại app"
                        field="Điều lại"
                        minWidth={100}
                        cellRenderer={RefreshAction}
                        cellStyle={() => {
                            return {
                                border: 'none',
                            }
                        }}
                    />
                    <AgGridColumn
                        headerName="Hủy"
                        field="Hủy"
                        minWidth={70}
                        cellRenderer={BtnAction}
                        cellStyle={() => {
                            return {
                                border: 'none',
                            }
                        }}
                    />
                    <AgGridColumn
                        headerName="LD H.Chuyến"
                        field="reason_cancel"
                        minWidth={120}
                    />
                    <AgGridColumn
                        headerName="Ghi chú"
                        field="note"
                        editable={true}
                        minWidth={130}
                    />
                    {/* <AgGridColumn headerName="Đ.Xe" field="" />
                    <AgGridColumn 
                        headerName="Nguồn" 
                        field="" 
                        cellRenderer={renderBookingFrom}
                    />
                    <AgGridColumn 
                        headerName="Ng.Điều" 
                        field="user_id"
                        minWidth={100}
                        cellRenderer={renderOperator}
                    /> */}
                </AgGridReact>
                <ModalConfirmCancel
                    className={!isSidebarOpen? 'modal-sidebarclose' : ''}
                    hidden={flagHiddenModalConfirm}
                    hideModalConfirm={_hideModalConfirm}
                />
            </div>
        </div>
    )
}

export default GridBookingSwitchboard
