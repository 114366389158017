import { Paper } from '@mui/material'
import { MdClose } from 'react-icons/md'
import KeyboardEventHandler from 'react-keyboard-event-handler'

const ModalDetail = ({ type, hidden, data, closeModalDetail, x, y }) => {
    return(
        <Paper
            sx={{
                width: type === 'reason' ? '200px' : '300px',
                // maxHeight: '160px',
                // minHeight: '142px',
                height: 'auto',
                position: 'fixed',
                top: y,
                left: x,
                padding: '8px 16px'
            }}
            elevation={3}
            className={hidden ? 'address-detail hide' : 'address-detail'}
        >
            {(() => {
                if (type === 'address') return (
                    <>
                        <h3 className='header'>Điểm đón/Điểm trả</h3>
                        <div className='content'>
                            <img src='assets/icons/markers/marker_start.svg' />
                            <p>{data.startAddress}</p>
                        </div>
                        <div className='content'>
                            <img src='assets/icons/markers/marker_end.svg' />
                            <p>{data.endAddress}</p>
                        </div>
                    </>
                )
                else if (type === 'reason') return (
                    <>
                        <h3 className='header'>Lý do hủy chuyến</h3>
                        <div className='content'>
                            <p className='reason-detail'>{data.reasonCancelled}</p>
                        </div>
                    </>
                )
                else if (type === 'note') return (
                    <>
                        <h3 className='header'>Ghi chú</h3>
                        <div className='content'>
                            <p className='note-detail'>{data.note}</p>
                        </div>
                    </>
                )
            })()}
                
            <MdClose size={16}
                style={{
                    position: 'absolute',
                    top: '6px',
                    right: '6px',
                    cursor: 'pointer'
                }}
                onClick={closeModalDetail}
            />
            <KeyboardEventHandler
                handleKeys={['Esc']}
                handleFocusableElements
                onKeyEvent={(key, e) => {
                    if (key === 'Esc') closeModalDetail()
                }}
            />
        </Paper>
    )
}

export default ModalDetail