import Setting from './Setting'
import '../scss/SettingContainer.scss'

const SettingContainer = () => {
    return (
        <div className="SettingContainer">
            <Setting />
        </div>
    )
}

export default SettingContainer
