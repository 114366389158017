import userContainerLocaleKeys from 'app/user-container/i18n/userContainerLocaleKeys'
import { EmddiMaterialIcon } from 'app/_shared/EmddiIcon/components'
import classNames from 'classnames'
import { useTrans } from 'helpers/useTranslation'
import { useCallback, useEffect, useRef, useState } from 'react'

import ViewContext from 'app/_shared/view-context'

import socketClient from 'socket.io-client'
import GridCallComing from './GridCallComing'
import CustomQueue from 'app/user-container/static/CustomQueue'

import { getLocalStorage, localStorageKeys } from 'services/localStorage'

import moment from 'moment'

const headerTitles = [
    userContainerLocaleKeys.waitingCalls.phoneNumber,
    userContainerLocaleKeys.waitingCalls.history,
    userContainerLocaleKeys.waitingCalls.time,
    userContainerLocaleKeys.waitingCalls.channel,
    userContainerLocaleKeys.waitingCalls.customer,
    userContainerLocaleKeys.waitingCalls.skip,
]

const { useViewContext, ViewActions, useViewDispatch } = ViewContext

const WaitingCallTable = () => {
    const t = useTrans()

    const [_hideTable, _setHideTable] = useState(false)

    const [fullTable, setFullTable] = useState(false)

    const [newCallItem, setNewCallItem] = useState([])
    const [removePhoneNumber, setRemovePhoneNumber] = useState(null)

    const [queueCalls, setQueueCalls] = useState(null)

    const _toggleTable = () => {
        _setHideTable(v => !v)
    }

    const _renderHeaders = () =>
        headerTitles.map((title, idx) => (
            <th key={`header_${idx}`}>{t(title)}</th>
        ))

    const headers = _renderHeaders()

    const _toggleFullTable = () => {
        setFullTable(!fullTable)
    }

    // const [{ socket }] = useViewContext()

    // console.log(socket)

    const viewDispatch = useViewDispatch()

    const sendPhoneNumber = data => {
        viewDispatch(ViewActions.sendPhoneNumber(data))
    }

    // const [{ dataPhoneNumber: incomingPhoneNumber }] = useViewContext()

    // console.log('waiting call phone number : ' + incomingPhoneNumber)
    // console.log(incomingPhoneNumber)
    const [{ userId }] = useViewContext()

    const arrLines = [userId]

    const socketRef = useRef(null)
    useEffect(() => {
        //Create socket listen coming calls
        const SOCKET_HOST_DEV = 'http://sdev.emddi.com:80'
        //const SOCKET_TOKEN = getLocalStorage(localStorageKeys.socketToken)
        const SOCKET_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTAwMSwibmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBlbWRkaS5jb20iLCJjb250ZW50IjoiYWRtaW4gY29vcmRpbmF0b3IiLCJ0eXBlIjoxMDAwLCJpYXQiOjE1MTI0NDQ5ODh9.IyLv0V1Y-zMqcOb7ndUauMRyJPqEb63ulV0I0ma6CVY'
        const user = getLocalStorage(localStorageKeys.user)
        if (!user) return
        const {
            hosts,
            user_id,
            user_service: { switchboard },
            unit_id: office_id,
        } = user
        const SOCKET_HOST =
            hosts.length > 0 ? 'https://' + hosts[0] : SOCKET_HOST_DEV
        socketRef.current = socketClient(SOCKET_HOST, {
            query: {
                token: SOCKET_TOKEN,
                type: 1000,
                office_id: office_id,
                user_id: user_id,
                switchboard: switchboard,
            },
        })

        if (!socketRef.current) return

        socketRef.current.on('send-phone', function (data) {
            // console.log('on send-phone: ')
            // console.log(data)

            let phoneNumber = data.phone_number
            phoneNumber = '0' + phoneNumber.substring(3)
            let line = parseInt(data.channel_transmit)
            let callStatus = parseInt(data.call_status)
            //let callTime = convertTime(data.call_time)
            let callTime = data.call_time
            let callId = data.coordinator_id
            let customerType = data.marketing_type
            let customer_id = data.c_id
            let customer_name = data.c_name
            let marketing_id = data.m_id
            let marketing_address = data.m_address
            let marketing_location = data.m_location
            let coordinators = data.coordinators
            let type = 0
            let timeHistory = ''

            if (arrLines.indexOf(line) >= -1) {
                if (callStatus == 1) {
                    if (_hideTable == true) _setHideTable(false)

                    insertCallComing(
                        callId,
                        line,
                        phoneNumber,
                        callStatus,
                        callTime,
                        0,
                        customerType,
                        customer_id,
                        customer_name,
                        marketing_address,
                        marketing_location,
                        marketing_id,
                        coordinators,
                        type,
                        timeHistory
                    )

                    sendPhoneNumber({
                        id: callId,
                        line: line,
                        phoneNumber: phoneNumber,
                        callTime: callTime,
                        customerId: customer_id,
                        customerName: customer_name,
                        marketingId: marketing_id,
                        coordinators: coordinators,
                    })
                }
            } else {
                console.log('not handle')
            }
        })

        socketRef.current.on('error', function (msg) {})

        socketRef.current.on('connect', function (msg) {
            //console.log('socket connected...')
        })

        socketRef.current.on('disconnect', function (msg) {
            //console.log('ĐƯỜNG TRUYỀN MẤT KẾT NỐi')
        })

        socketRef.current.on('reconnect', function (msg) {
            //console.log('socket reconnect...')
        })

        socketRef.current.on('edit-booking', function (data) {
            // console.log('socket on edit booking waiting call table...')
            // console.log(data)

            if (data instanceof Array && data[0].type == 1000) {
                setRemovePhoneNumber(data[0].coordinator_id)
            }
        })

        return () => {
            socketRef.current.removeAllListeners()
            socketRef.current.close()
        }
    }, [])

    const insertCallComing = (
        callId,
        callChannel,
        phoneNumber,
        callStatus,
        callTime,
        processed,
        customerType,
        customerId,
        customerName,
        marketingAddress,
        marketingLocation,
        marketingId,
        coordinators,
        type,
        timeHistory
    ) => {
        let callItem = {
            id: callId,
            line: callChannel,
            phone_number: phoneNumber,
            status: callStatus,
            time: moment(callTime).format('HH:mm:ss DD-MM-YYYY'),
            processed: processed,
            customer_type: customerType,
            c_id: customerId,
            c_name: customerName,
            m_address: marketingAddress,
            m_location: marketingLocation,
            m_id: marketingId,
            coordinators: coordinators,
            type: type,
            time_history: timeHistory,
        }

        setNewCallItem(callItem)
    }

    function convertTime(time) {
        time = time.trim()
        let arrTime = time.split(' ')
        let hour = arrTime[1].trim()
        let tempDate = arrTime[0].trim()
        let arrDate = tempDate.split('-')
        let date = hour + ' ' + arrDate[2] + '-' + arrDate[1] + '-' + arrDate[0]
        return date
    }

    //console.log('component wating call render...')
    const [count, setCount] = useState(0)

    const _setCountPhoneNumber = (count) => {
        setCount(count)
    }

    return (
        <div
            className={classNames(
                fullTable ? 'WaitingCallTable full-height' : 'WaitingCallTable',
                _hideTable ? 'TableHide' : ''
            )}
        >
            <div className="Heading">
                <span>
                    {t(userContainerLocaleKeys.waitingCalls.waitingCallList) + ' (' + count + ')'}
                </span>
                <span
                    className={
                        !_hideTable ? 'fulltable-icon' : 'fulltable-icon hide'
                    }
                    onClick={_toggleFullTable}
                >
                    <EmddiMaterialIcon
                        name={!fullTable ? 'MdFullscreen' : 'MdMaximize'}
                        //name={'MdFullscreen'}
                    />
                </span>
                <span className="cursor-pointer" onClick={_toggleTable}>
                    <EmddiMaterialIcon
                        name={_hideTable ? 'MdFullscreen' : 'MdCloseFullscreen'}
                    />
                </span>
            </div>
            <GridCallComing
                newCallItem={newCallItem}
                removePhoneNumber={removePhoneNumber}
                setCountPhoneNumber={_setCountPhoneNumber}
            />
        </div>
    )
}

export default WaitingCallTable
