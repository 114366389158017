const userContainerLocaleKeys = {
    newBooking: 'userContainer.newBooking',

    sideBar: {
        vehicleMap: 'userContainer.sideBar.vehicleMap',
        trips: 'userContainer.sideBar.trips',
        clickToToggle: 'userContainer.sideBar.clickToToggle',
        setting: 'userContainer.sideBar.setting',
        logout: 'userContainer.sideBar.logout',
        quickSearch: 'userContainer.sideBar.quickSearch',
        grantRole: 'userContainer.sideBar.grantRole'
    },

    waitingCalls: {
        waitingCallList: 'userContainer.waitingCalls.waitingCallList',
        phoneNumber: 'userContainer.waitingCalls.phoneNumber',
        history: 'userContainer.waitingCalls.history',
        time: 'userContainer.waitingCalls.time',
        channel: 'userContainer.waitingCalls.channel',
        customer: 'userContainer.waitingCalls.customer',
        skip: 'userContainer.waitingCalls.skip',
    },
}

export default userContainerLocaleKeys
