import { bookingApiPrefix } from 'api'
import httpStatus from 'api/httpStatus'
import { useState, useEffect } from 'react'
import fetcher from 'services/fetcher'
import moment from 'moment'

function ActiveTripsAPI() {
    const [filterForm, setFilterForm] = useState({
        fromDate: '',
        toDate: '',
        status: '',
    })
    const [listActiveTrips, setListActiveTrips] = useState([])

    useEffect(async () => {
        try {
            const response = await fetcher.get(`${bookingApiPrefix}/trip`, {
                params: {
                    page: 0,
                    limit: 20,
                    status: '',
                    fromDate: filterForm.fromDate ? filterForm.fromDate : null,
                    toDate: filterForm.toDate ? filterForm.toDate : null,
                },
            })
            let listTrips = response.data.data.trips
            listTrips.forEach(trip => {
                trip.created_at = moment(
                    trip.created_at.toLocaleString()
                ).format('h:mm:ss DD-MM-yyyy')
                if (trip.booking && trip.booking.driver) {
                    trip.driver_phone =
                        '0' + trip.booking.driver.phone_number.substring(3)
                    trip.status_booking = trip.booking.status
                }
            })
            setListActiveTrips([...listTrips])
        } catch (err) {
            setListActiveTrips([])
        }
    }, [filterForm])

    return {
        listActiveTrips: [listActiveTrips, setListActiveTrips],
        filterForm: [filterForm, setFilterForm],
    }
}

export default ActiveTripsAPI
