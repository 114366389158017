import { atom, atomFamily, selector, selectorFamily } from 'recoil'
import { tripStatusName } from 'utils/trips'

export const focusingDriverState = atom({
    key: 'previewTrips.trips.focusingDriver',
    default: null,
})

export const isPreviewTripsOpenState = atom({
    key: 'previewTrips.isPreviewTripsOpen',
    default: false,
})

export const tripStatusFilterState = atom({
    key: 'previewTrips.filter.tripStatusFilter',
    default: {
        [tripStatusName.vacant]: true,
        [tripStatusName.driverOnTheWay]: true,
        [tripStatusName.pob]: true,
        [tripStatusName.waitingForProcess]: true,
        [tripStatusName.dispatching]: true,
        [tripStatusName.arrivedAndWaiting]: true,
        [tripStatusName.droppedOff]: true,
        [tripStatusName.unavailable]: true,
    },
})
