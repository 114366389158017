import { ContentPasteSearchOutlined } from '@mui/icons-material'
import {
    Autocomplete,
    CircularProgress,
    createTheme,
    Paper,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
} from '@mui/material'
import { placeDetailApi, searchPlaceApi } from 'api/trip'
import { isNewBookingOpenState } from 'app/_shared/recoil/new-booking/atom'
import { tripDetailState } from 'app/_shared/recoil/trip-detail/atom'
import { initialAddressSelector } from 'app/_shared/recoil/trip-detail/selector'
import classNames from 'classnames'
import { formatDistance } from 'date-fns'
import { enUS, vi } from 'date-fns/esm/locale'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ENTER, TAB } from 'static/keyboard-code'

let debounceTimer = false

const theme = createTheme({
    palette: { primary: { main: '#edab25' } },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: { padding: '0.125rem' },
                endAdornment: { right: '0px !important' },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    //background: '#212121',
                    backgroud: '#ffffff',
                    padding: '0px 3rem 0px 0.125rem !important',
                    borderRadius: '0.125rem',
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.5rem',
                    padding: '0.125rem !important',
                    color: '#333537',
                    fontSize: '18px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    fontFamily: 'Roboto'
                },
            },
        },
        MuiSvgIcon: { styleOverrides: { root: { color: 'white' } } },
    },
})

let _highlightingOption = null
const searchTypes = { all: 'all', elastic: 'elastic', google: 'google' }

const AddressField = ({
    name,
    className,
    debounce,
    placeholder,
    title,
    canUpdateInfo,
    handleSelected,
    flagQuery,
    handleAddressFieldClose,
    ...props
}) => {
    const { i18n } = useTranslation()

    const tripDetail = useRecoilValue(tripDetailState)
    const initialAddressState = useRecoilValue(initialAddressSelector(name))

    const [_selectedOptions, _setSelectedOptions] = useState(
        initialAddressState[0] || null
    )
    const [_inputValue, _setInputValue] = useState('')

    const [_options, _setOptions] = useState(initialAddressState)
    const [_loading, _setLoading] = useState(false)

    const [_doCallApi, _setDoCallApi] = useState(false)
    const [_searchType, _setSearchType] = useState(searchTypes.elastic)

    let _inputRef = useRef()

    //console.log(_options)

    useEffect(() => {
        let active = true

        if (!_doCallApi) {
            return undefined
        }

        ;(async () => {
            //try {
            const places = await searchPlaceApi(_inputValue, _searchType)
            if (active) {
                _setLoading(false)
                _setOptions(places)
                _setDoCallApi(false)
            }
            // }catch (error) {
            //     _setLoading(false)
            //     _setOptions([])
            //     _setDoCallApi(false)
            // }
        })()

        return () => {
            active = false
        }
    }, [_inputValue, _doCallApi])

    useEffect(() => {
        if (!tripDetail.open) {
            _setSelectedOptions(null)
            _setOptions([])
            _setSearchType(searchTypes.elastic)
            _setInputValue('')
        } else {
        }
    }, [tripDetail.open])

    const _handleOnFocus = (e) => {
        if (name === 'note') _setOptions([]) // do not show dropdown options
    }

    const _handleInputChange = (e, value, reason) => {
        if (flagQuery == false) {
            // do not call api searching
            _setInputValue(value)
            handleSelected(name, value)
        } else {
            if (reason === 'input' && !!value) {
                _setInputValue(value)
                if (debounceTimer) clearTimeout(debounceTimer)
                debounceTimer = setTimeout(() => {
                    _setDoCallApi(true)
                    _setLoading(true)
                }, debounce)

                if (_selectedOptions) {
                }
            } else _setInputValue(value)
        }
    }

    const _handleSelectOption = async (e, value, reason, detail) => {
        _setSelectedOptions(value)
        let returnAddress = null
        if (reason === 'clear') {
            // return onChange(returnAddress)
            handleSelected(name, returnAddress)
        }
        if (reason === 'selectOption') {
            _searchPlaceDetailWhenSelected(detail.option)
        }
    }

    const _handleOnCloseOptions = (e, reason) => {
        // if (reason === 'selectOption' && setFocusEl)
        // setFocusEl(keyboardFlow.createTripBtn)
        // console.log('event on address field: ')
        // console.log(e)
        handleAddressFieldClose(e)
    }

    const _handleKeyDown = e => {
        if (e.key === TAB) {
            if (
                _highlightingOption &&
                Object.keys(_highlightingOption).length > 0
            ) {
                _setSelectedOptions(_highlightingOption)
                _searchPlaceDetailWhenSelected(_highlightingOption)
            }
        }
        if (e.key === ENTER) {
        }
        if (e.ctrlKey && e.altKey && !e.repeat) {
            if (_searchType === searchTypes.elastic)
                _setSearchType(searchTypes.google)

            if (_searchType === searchTypes.google)
                _setSearchType(searchTypes.elastic)

            _setDoCallApi(true)
        }
    }

    const _searchPlaceDetailWhenSelected = async option => {
        let returnAddress = option
        if (!option.coordinates) {
            const placeDetail = await placeDetailApi(returnAddress.emddiId)
            if (placeDetail) {
                returnAddress = { ...returnAddress, ...placeDetail }
            }
        }
        if (!option.emddiId) {
            const places = await searchPlaceApi(option.start_address)
            if (places.length > 0) {
                const placeDetail = await placeDetailApi(places[0].emddiId)
                if (placeDetail) {
                    returnAddress = { ...returnAddress, ...placeDetail }
                }
                // returnAddress = { ...returnAddress, ...places[0] }
            }
        }
        _setSelectedOptions(returnAddress)

        handleSelected(name, returnAddress)
    }

    useEffect(() => {
        const closeIcon = document.getElementsByClassName(
            'MuiAutocomplete-clearIndicator'
        )[0]

        // Add a Click Event Listener to the icon close
        closeIcon.addEventListener('click', e => {
            //console.log(e)
            handleAddressFieldClose(e)
        })
    }, [])

    return (
        <div className={classNames('AddressWrapper', className)}>
            {title && <label htmlFor={props.name}>{title}</label>}
            <ThemeProvider theme={theme}>
                <Autocomplete
                    isOptionEqualToValue={(option, value) => {
                        if (option.id) {
                            return option.id === value.id
                        }
                        if (option.emddiId) {
                            return option.emddiId === value.emddiId
                        }
                    }}
                    getOptionLabel={option => option.description || ''}
                    // getOptionDisabled={option =>
                    //     option.coordinates === null ||
                    //     option.start_address === null
                    // }
                    filterOptions={x => x}
                    options={_options}
                    loading={_loading}
                    value={_selectedOptions}
                    inputValue={_inputValue}
                    onInputChange={_handleInputChange}
                    onChange={_handleSelectOption}
                    onHighlightChange={(e, o) =>
                        (_highlightingOption = { ...o })
                    }
                    onFocus={_handleOnFocus}
                    onClose={_handleOnCloseOptions}
                    onKeyDown={_handleKeyDown}
                    clearOnBlur={false}
                    openOnFocus={false}
                    selectOnFocus
                    autoHighlight
                    freeSolo
                    readOnly={!canUpdateInfo}
                    renderInput={params => (
                        <TextField
                            sx={{ padding: 0 }}
                            {...params}
                            placeholder={placeholder}
                            inputRef={_inputRef}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {_loading ? (
                                            <CircularProgress size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                                startAdornment: (
                                    name === 'note' ? <img src='assets/icons/n-edit.svg' /> : ''
                                )
                            }}
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            sx={{
                                minWidth: 'fit-content',
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                    renderOption={(props, option, state) => {
                        props.key = option.id || option.emddiId ||
                            Math.random.toString(36).substring(2)
                        return (
                            <li
                                {...props}
                                style={{ paddingLeft: 8, paddingRight: 8 }}
                            >
                                <Tooltip
                                    title={option.description}
                                    placement="right"
                                    enterDelay={2000}
                                >
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 14, maxWidth: 300 }}
                                    >
                                        {option.description}
                                    </Typography>
                                </Tooltip>
                            </li>
                        )
                    }}
                    {...props}
                />
            </ThemeProvider>
        </div>
    )
}

export default AddressField
