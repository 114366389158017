import CheckBox from 'app/_shared/components/CheckBox'
import { tripStatusFilterState } from 'app/_shared/recoil/preview-trips/atom'
import { isAllStatusCheckedSelector } from 'app/_shared/recoil/preview-trips/selector'
import { useTrans } from 'helpers/useTranslation'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { tripStatusName } from 'utils/trips'
import previewTripsKeys from '../i18n/previewTripsKeys'
import StatusCheckbox from './StatusCheckbox'

function StatusFilter() {
    return (
        <div className="StatusFilter">
            <AllStatusCheckbox />
            <div className="StatusGroup">
                <div className="CheckBoxGroup">
                    <StatusCheckbox name={tripStatusName.vacant} />
                    <StatusCheckbox name={tripStatusName.driverOnTheWay} />
                    <StatusCheckbox name={tripStatusName.pob} />
                </div>
                <div className="CheckBoxGroup">
                    <StatusCheckbox name={tripStatusName.waitingForProcess} />
                    <StatusCheckbox name={tripStatusName.dispatching} />
                    <StatusCheckbox name={tripStatusName.arrivedAndWaiting} />
                </div>
                <div className="CheckBoxGroup ml-4">
                   
                    <StatusCheckbox name={tripStatusName.droppedOff} />
                    <StatusCheckbox name={tripStatusName.unavailable} />
                </div>
            </div>
        </div>
    )
}

export default StatusFilter

export const AllStatusCheckbox = () => {
    const t = useTrans()

    const isAllStatusChecked = useRecoilValue(isAllStatusCheckedSelector)
    const setTripStatusFilter = useSetRecoilState(tripStatusFilterState)

    const _handleShowAllStatusChange = state => {
        setTripStatusFilter(currVal => {
            let newStatus = { ...currVal }
            for (const status in currVal) {
                if (Object.hasOwnProperty.call(newStatus, status)) {
                    newStatus[status] = state
                }
            }
            return newStatus
        })
    }
    return (
        <CheckBox
            checked={isAllStatusChecked}
            label={t(previewTripsKeys.filter.showAll)}
            color="#219737"
            onToggle={_handleShowAllStatusChange}
            className='Cb-Filter'
            name={0}
        />
    )
}
