import { tripStatusName } from 'utils/trips'

const previewTripsKeys = {
    driverName: 'previewTrips.driverName',
    passenger: 'previewTrips.passenger',
    startPoint: 'previwTrips.startPoint',
    pickup: 'previewTrips.pickup',
    destination: 'previewTrips.destination',
    estimate: 'previewTrips.estimate',

    filter: {
        totalDriver: 'previewTrips.filter.totalDriver',
        showAll: 'previewTrips.filter.showAll',
        [tripStatusName.vacant]: 'previewTrips.filter.vacant',
        [tripStatusName.driverOnTheWay]: 'previewTrips.filter.driverOnTheWay',
        [tripStatusName.pob]: 'previewTrips.filter.pob',
        [tripStatusName.waitingForProcess]:
            'previewTrips.filter.waitingToProcess',
        [tripStatusName.dispatching]: 'previewTrips.filter.dispatching',
        [tripStatusName.arrivedAndWaiting]:
            'previewTrips.filter.arrivedAndWaiting',
        [tripStatusName.droppedOff]: 'previewTrips.filter.droppedOff',
        [tripStatusName.unavailable]: 'previewTrips.filter.unavailable',
        [tripStatusName.carNotFound]: 'previewTrips.filter.carNotFound',
        [tripStatusName.error]: 'previewTrips.filter.error',
        [tripStatusName.pending]: 'previewTrips.filter.pending',
    },
}

export default previewTripsKeys
