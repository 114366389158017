import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const PageWrapper = props => {
    const _title = props.title ? `${props.title} | EMDDI` : 'EMDDI'

    return (
        <>
            <Helmet>
                <title>{_title}</title>
            </Helmet>
            <div className=
                {
                    classNames(props.className)
                }
            >
                {props.children}
            </div>
        </>
    )
}

PageWrapper.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
}

export default PageWrapper
