import newBookingLocaleKeys from './newBookingLocaleKeys'

const newBookingLocale = {
    en: {
        [newBookingLocaleKeys.bookingHeader]: 'Booking Info',
        [newBookingLocaleKeys.passengerHeader]: 'Passenger',
        [newBookingLocaleKeys.tipPromoHeader]: 'Tip - Promo ',
        [newBookingLocaleKeys.dispatchHeader]: 'Dispatch',
        [newBookingLocaleKeys.paymentHeader]: 'Payment',

        [newBookingLocaleKeys.pickupField]: 'Pickup',
        [newBookingLocaleKeys.destinationField]: 'Destination',
        [newBookingLocaleKeys.pickupTimeField]: 'Pickup Time',
        [newBookingLocaleKeys.serviceField]: 'Service',
        [newBookingLocaleKeys.phoneNumberField]: 'Phone Number',
        [newBookingLocaleKeys.fullNameField]: 'Full Name',
        [newBookingLocaleKeys.emailField]: 'Email',
        [newBookingLocaleKeys.amountField]: 'Amount',
        [newBookingLocaleKeys.promotionField]: 'Promotion',

        [newBookingLocaleKeys.addressPlaceholder]: 'Enter a location',
        [newBookingLocaleKeys.pickupAddressPlaceholder]: 'Enter pickup address',
        [newBookingLocaleKeys.destinationAddressPlaceholder]:
            'Enter destination address',
        [newBookingLocaleKeys.phoneNumberPlaceholder]: 'Phone number',
        [newBookingLocaleKeys.fullNamePlaceholder]: 'Full name',
        [newBookingLocaleKeys.emailPlaceholder]: 'Email',
        [newBookingLocaleKeys.amountPlaceholder]: 'Amount',
        [newBookingLocaleKeys.promotionPlaceholder]: 'Promotion code',

        [newBookingLocaleKeys.estimate]: 'Trip Estimate',
        [newBookingLocaleKeys.estimateDistance]: 'Distance',
        [newBookingLocaleKeys.estimateDuration]: 'Duration',
        [newBookingLocaleKeys.estimateFare]: 'Fare',

        [newBookingLocaleKeys.btnCreate]: 'Create',
        [newBookingLocaleKeys.btnCreate]: 'Switchboard(F4)',
        [newBookingLocaleKeys.btnCancel]: 'Cancel',

        [newBookingLocaleKeys.noteField]: 'Note',
        [newBookingLocaleKeys.noteBookingAssigned]: 'booking reservation',
        [newBookingLocaleKeys.dispatchAuto]: 'Auto',
        [newBookingLocaleKeys.dispatchAssign]: 'Assign',
        [newBookingLocaleKeys.title]: 'New Booking',
    },

    vi: {
        [newBookingLocaleKeys.bookingHeader]: 'Thông tin chuyến',
        [newBookingLocaleKeys.passengerHeader]: 'Khách hàng',
        [newBookingLocaleKeys.tipPromoHeader]: 'Tip - Khuyến mại ',
        [newBookingLocaleKeys.dispatchHeader]: 'Điều chuyến',
        [newBookingLocaleKeys.paymentHeader]: 'Thanh toán',

        [newBookingLocaleKeys.pickupField]: 'Điểm đón',
        [newBookingLocaleKeys.destinationField]: 'Điểm đến',
        [newBookingLocaleKeys.pickupTimeField]: 'Thời gian đón',
        [newBookingLocaleKeys.serviceField]: 'Dịch vụ',
        [newBookingLocaleKeys.phoneNumberField]: 'Số điện thoại',
        [newBookingLocaleKeys.fullNameField]: 'Họ tên',
        [newBookingLocaleKeys.emailField]: 'Email',
        [newBookingLocaleKeys.amountField]: 'Số lượng',
        [newBookingLocaleKeys.promotionField]: 'Mã KM',

        [newBookingLocaleKeys.addressPlaceholder]: 'Nhập địa chỉ',
        [newBookingLocaleKeys.pickupAddressPlaceholder]: 'Nhập điểm đón',
        [newBookingLocaleKeys.destinationAddressPlaceholder]: 'Nhập điểm đến',
        [newBookingLocaleKeys.phoneNumberPlaceholder]: 'Số điện thoại',
        [newBookingLocaleKeys.fullNamePlaceholder]: 'Họ tên',
        [newBookingLocaleKeys.emailPlaceholder]: 'Email',
        [newBookingLocaleKeys.amountPlaceholder]: 'Số lượng',
        [newBookingLocaleKeys.promotionPlaceholder]: 'Mã KM',

        [newBookingLocaleKeys.estimate]: 'Dự tính',
        [newBookingLocaleKeys.estimateDistance]: 'Q.đường',
        [newBookingLocaleKeys.estimateDuration]: 'T.gian',
        [newBookingLocaleKeys.estimateFare]: 'Cước',

        //[newBookingLocaleKeys.btnCreate]: 'Tạo',
        [newBookingLocaleKeys.btnCreate]: 'Lưu chuyến',
        [newBookingLocaleKeys.btnCreateSwitchboard]: 'Đàm(F4)',
        [newBookingLocaleKeys.btnCancel]: 'Hủy',

        [newBookingLocaleKeys.noteField]: 'Ghi chú',
        [newBookingLocaleKeys.noteBookingAssigned]: 'Chuyến đặt hẹn',
        [newBookingLocaleKeys.dispatchAuto]: 'Tự động',
        [newBookingLocaleKeys.dispatchAssign]: 'Chỉ định',
        [newBookingLocaleKeys.title]: 'Tạo chuyến mới',
    },
}

export default newBookingLocale
