import { createContext, useContext, useReducer } from 'react'
import UserContainerActionTypes from './UserContainerActionTypes'
import userContainerInitState from './userContainerInitState'
import UserContainerReducers from './user-container-reducers'

const UserContainerContext = createContext(userContainerInitState)

const reducer = (state, action) => {
    const { type, payload } = action

    switch (type) {
        case UserContainerActionTypes.toggleFullListTrips:
            return {
                ...state,
                ...UserContainerReducers.toggleFullListTrips(state, payload),
            }
        // Trip Filter
        case UserContainerActionTypes.setSingleFilterTripStatus:
            return {
                ...state,
                ...UserContainerReducers.setSingleFilterTripStatus(
                    state,
                    payload
                ),
            }
        case UserContainerActionTypes.setAllFilterTripStatus:
            return {
                ...state,
                ...UserContainerReducers.setAllFilterTripStatus(state, payload),
            }
        case UserContainerActionTypes.setTotalDrivers:
            return {
                ...state,
                ...UserContainerReducers.setTotalDrivers(state, payload),
            }
        case UserContainerActionTypes.toggleFilterTripCollapse:
            return {
                ...state,
                ...UserContainerReducers.toggleFilterTripCollapse(
                    state,
                    payload
                ),
            }

        // Trips
        case UserContainerActionTypes.addAllTrip:
            return {
                ...state,
                ...UserContainerReducers.addAllTrip(state, payload),
            }
        // Drivers
        case UserContainerActionTypes.setListDrivers:
            return {
                ...state,
                ...UserContainerReducers.setListDriver(state, payload),
            }
        case UserContainerActionTypes.updateListDrivers:
            return {
                ...state,
                ...UserContainerReducers.updateListDriver(state, payload),
            }
        case UserContainerActionTypes.setIsTheFirstDisplayCarMarker:
            return {
                ...state,
                ...UserContainerReducers.setIsTheFirstDisplayCarMarker(
                    state,
                    payload
                ),
            }
        default:
            break
    }

    return state
}

export const UserContainerContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, userContainerInitState)

    return (
        <UserContainerContext.Provider value={[state, dispatch]}>
            {props.children}
        </UserContainerContext.Provider>
    )
}

export const useUserContainerContext = () => useContext(UserContainerContext)

export const useUserContainerDispatch = () => {
    const d = useContext(UserContainerContext)

    return d[1]
}
