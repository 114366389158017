import {
    createTheme,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    ThemeProvider,
    Autocomplete,
} from '@mui/material'
import React, { useState, useEffect } from 'react'

import UserContainerContext from 'app/_shared/user-container-context'

const { useUserContainerContext, UserContainerActions } = UserContainerContext

const theme = createTheme({
    palette: {
        primary: {
            main: '#edab25',
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginTop: '0px !important'
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: { root: { marginLeft: 0, marginRight: 8 } },
        },
        MuiRadio: { styleOverrides: { root: { padding: 2 } } },
        MuiTypography: { styleOverrides: { root: { fontSize: 12 } } },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: '#ffffff !important',
                    height: '34px',
                    borderRadius: '4px',
                    border: '1px solid #e6e9ec',
                    padding: '0px',
                    '&.Mui-disabled': {
                        backgroundColor: '#393939',
                    },
                },
                notchedOutline: {
                    border: '0px !important'
                }
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '34px !important',
                    padding: '0.125rem !important',
                    color: '#181918 !important',
                    fontSize: '14px !important',
                    marginTop: '-12px'
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                clearIndicator: { padding: '0.125rem' },
                endAdornment: { right: '0px !important' },
            },
        },
    },
})

const dispatchType = {
    auto: { name: 'Auto', value: 'auto' },
    assign: { name: 'Assign', value: 'assign' },
}

const DispatchDriver = props => {
    const [_dispatchType, _setDispatchType] = useState(
        props.initValue ? dispatchType.assign.value : dispatchType.auto.value
    )

    const [{ drivers }] = useUserContainerContext()

    const [driversReady, setDriversReady] = useState([])

    const [selectedOptions, setSelectedOptions] = useState(null)

    // console.log('list drivers: ')
    // console.log(drivers)

    useEffect(() => {
        let listDriversReady = drivers.reduce((arr, item) => {
            //if (item.is_online && item.status == 0) {
            //console.log(item)
            let driverTemp = {
                label: item.vh_code
                    ? item.full_name + ' | ' + item.vh_code
                    : item.full_name,
                id: item.driver_id,
            }
            arr.push(item)
            //}
            return arr
        }, [])

        setDriversReady(listDriversReady)
    }, [drivers])

    useEffect(() => {
        const driver = drivers.filter(dr => dr.driver_id === props.initValue)[0]
        setSelectedOptions(driver ? driver : null)
    }, [])

    const _handleAssingChanged = e => {
        e.preventDefault()
        _setDispatchType(e.target.name)
        setSelectedOptions(null)
        //onChange && onChange(e.target.value)
    }

    const handleSelectOption = (e, value, reason, detail) => {
        setSelectedOptions(value)
        props.assignDriver(value ? value.driver_id : '')
    }

    const handleInputChange = e => {
        //console.log(e.target.value)
    }

    const renderColor = option => {
        if (option.is_online) {
            if (option.status == 0) return 'green'
            return 'orange'
        }
        return 'gray'
    }

    return (
        <div className="w-full flex flex-col dispatcher">
            <p className='title'>Điều chuyến</p>
            <ThemeProvider theme={theme}>
            <div className={_dispatchType === 'assign' ? 'options assign' : 'options'}>
                <div>
                    <button name='auto' onClick={_handleAssingChanged}>Tự động</button>
                    <button name='assign' onClick={_handleAssingChanged}>Chỉ định</button>
                </div>
                <Autocomplete
                    id="combo_box_drivers_booking_details"
                    options={driversReady}
                    sx={{ width: '100%' }}
                    value={selectedOptions}
                    onInputChange={handleInputChange}
                    disabled={_dispatchType === dispatchType.auto.value}
                    onChange={handleSelectOption}
                    renderInput={params => (
                        <TextField
                            {...params}
                            sx={{ mt: 1 / 4 }}
                            disabled={_dispatchType === dispatchType.auto.value}
                        />
                    )}
                    getOptionLabel={option =>
                        option.vh_code
                            ? option.full_name + ' | ' + option.vh_code
                            : option.full_name || ''
                    }
                    renderOption={(props, option) => {
                        props.key = option.driver_id
                        return (
                            <li
                                {...props}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderBottom: '1px solid lightgray',
                                }}
                            >
                                <div>
                                    <p>{option.full_name}</p>
                                    <p>{option.vh_code}</p>
                                </div>
                                <span
                                    style={{
                                        backgroundColor: renderColor(option),
                                        width: '12px',
                                        height: '12px',
                                        borderRadius: '50%',
                                        float: 'right',
                                    }}
                                ></span>
                            </li>
                        )
                    }}
                />
            </div>
            </ThemeProvider>
        </div>
    )
}

export default DispatchDriver
